<template>
    <div class="custom-main-panel">
        <div>
            <md-progress-bar v-if="isLoading" md-mode="indeterminate"></md-progress-bar>
        </div>

        <div class="md-layout custom-content-panel">
            <div v-if="isLoading" class="overlay"></div>
            <div class="md-layout-item md-size-100">
                <div class="custom-button" @click="goBack">
                    <md-icon class="custom-icon">keyboard_backspace</md-icon>
                    <span class="md-body-1">Back to Communication Channels</span>
                </div>
            </div>
            <div class="md-layout-item md-size-30 md-small-size-100">
                <md-card>
                    <md-card-content>
                        <i :class="channelIconClass + ' fa-6x'"></i>
                        <p class="md-headline">{{ channelTitle }} integration</p>
                        <CreateCcWizard v-if="accounts.length === 0" @accountConnected="fetchCommunicationChannels" />
                        <md-button v-else disabled class="md-primary md-block" :md-ripple="false">
                            Connect more (Coming Soon)
                        </md-button>
                        <p class="md-body-2">{{ channelContent[communicationChannelType].description }}</p>
                        <p class="md-body-2">{{ channelContent[communicationChannelType].benefitsTitle }}</p>
                        <ul>
                            <li
                                class="md-body-2"
                                v-for="benefit in channelContent[communicationChannelType].benefits"
                                :key="benefit"
                            >
                                {{ benefit }}
                            </li>
                        </ul>
                    </md-card-content>
                </md-card>
            </div>

            <div class="md-layout-item md-size-70 md-small-size-100">
                <p class="md-headline">Accounts Connected</p>
                <div v-if="accounts.length === 0">
                    <md-empty-state
                        md-icon="contact_support"
                        md-label="No Accounts Yet? Connect Now!"
                        md-description="Take the first step! Connect your account to Lixsa and experience seamless communication."
                    >
                    </md-empty-state>
                </div>
                <div v-else>
                    <div v-for="item in accounts" :key="item.uuid">
                        <md-card>
                            <md-card-header class="custom-card-header">
                                <div class="account-details">
                                    <i :class="channelIconClass + ' fa-3x'"></i>
                                    <div>
                                        <div class="md-body-2">Account {{ item.business_account_id }}</div>
                                        <div class="md-caption">Created at {{ formatDate(item.date_created) }}</div>
                                    </div>
                                </div>
                                <div>
                                    <md-menu md-size="small" :md-offset-x="-90" :md-offset-y="3">
                                        <md-button md-menu-trigger class="md-icon-button">
                                            <i class="fas fa-ellipsis-v"></i>
                                        </md-button>
                                        <md-menu-content>
                                            <md-menu-item :disabled="!isAdminAccount" @click="HanldeEdit">
                                                <div>
                                                    <i class="fas fa-edit"></i>
                                                    <span>Edit</span>
                                                </div>
                                            </md-menu-item>
                                            <md-menu-item
                                                :disabled="!isAdminAccount"
                                                class="custom-remove-item md-simple"
                                                @click="handleDelete(item)"
                                            >
                                                <div>
                                                    <i class="fas fa-trash-alt"></i>
                                                    <span>Delete</span>
                                                </div>
                                            </md-menu-item>
                                            <md-menu-item @click="copyWebhook">
                                                <div>
                                                    <i class="fas fa-link"></i>
                                                    <span>Webhook</span>
                                                </div>
                                            </md-menu-item>
                                        </md-menu-content>
                                    </md-menu>
                                </div>
                            </md-card-header>
                        </md-card>

                        <EditCcWizard
                            @accountEdited="fetchCommunicationChannels"
                            :showEditDialog.sync="showEditDialog"
                            :account="item"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CreateCcWizard from '@/components/CommunicationChannels/channelsConnectModal.vue';
import EditCcWizard from '@/components/CommunicationChannels/EditCcWizard.vue';

import communicationChannelsService from '@/services/account/communicationChannelsService.js';

import Swal from 'sweetalert2';
import { mapGetters } from 'vuex';

export default {
    name: 'CommunicationChannelDetails',
    components: {
        CreateCcWizard,
        EditCcWizard,
    },
    data() {
        return {
            communicationChannelType: '',
            channelContent: {
                WHATSAPP: {
                    description:
                        'The WhatsApp integration allows you to connect Lixsa to your WhatsApp Business account, enabling the AI to respond to customer queries or requests directly on the platform. Additionally, Lixsa can be set up to operate as a dedicated contact within WhatsApp, ensuring personalized assistance for each user reaching out.',
                    benefitsTitle: 'Through this integration, you can:',
                    benefits: [
                        'Streamline customer support via WhatsApp.',
                        'Assist customers in real-time with automated responses.',
                        'Utilize Lixsa to schedule appointments or set reminders.',
                        'Provide instant answers to frequently asked questions about your products or services.',
                    ],
                },
                // ... Add content for other channels as needed
            },
            accounts: [],
            showEditDialog: false,

            isLoading: false,
        };
    },
    computed: {
        ...mapGetters({
            canAccessUser: 'user/canAccess',
            isWalkonLivosAccount: 'user/isWalkonLivosAccount',
        }),
        whatsappWebhook() {
            return ` https://api.lixsa.ai/channels/whatsapp/webhook?account=${this.$store.state.user.account.uuid}`;
        },
        channelIconClass() {
            switch (this.communicationChannelType) {
                case 'WHATSAPP':
                    return 'whatsapp-icon fab fa-whatsapp-square';
                default:
                    return '';
            }
        },
        channelTitle() {
            switch (this.communicationChannelType) {
                case 'WHATSAPP':
                    return 'Whatsapp';
                default:
                    return '';
            }
        },
        isAdminAccount() {
            return this.canAccessUser(['ADMIN']) || this.isWalkonLivosAccount;
        },
    },
    methods: {
        copyWebhook() {
            navigator.clipboard.writeText(this.whatsappWebhook);
            this.showSuccessToast('Webhook Copied');
        },
        goBack() {
            this.$router.push('/communication-channels');
        },
        HanldeEdit() {
            if (!this.isAdminAccount) {
                this.showErrorToast("You don't have permission to perform this action");
                return;
            }
            this.showEditDialog = true;
        },
        formatDate(timestamp) {
            const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
            const date = new Date(timestamp * 1000); // convert to milliseconds
            return date.toLocaleDateString('en-US', options); // adjust 'en-US' as per your need
        },
        async fetchCommunicationChannels() {
            this.isLoading = true;
            try {
                const response = await communicationChannelsService.fetchCommunicationChannels();
                if (response && response.data && response.data.content) {
                    this.accounts = response.data.content.filter(
                        (account) => account.type === this.communicationChannelType,
                    );
                } else {
                    this.accounts = [];
                }
            } catch (error) {
                console.error('Error fetching the communication channels: ' + error);
                this.accounts = [];
            } finally {
                this.isLoading = false;
            }
        },
        handleDelete(item) {
            if (!this.isAdminAccount) {
                this.showErrorToast("You don't have permission to perform this action");
                return;
            }
            Swal.fire({
                title: 'Are you sure?',
                text: `You won't be able to revert this!`,
                type: 'warning',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#4caf50',
                confirmButtonText: 'Yes, delete it!',
                buttonsStyling: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteCommunicationChannel(item);
                }
            });
        },
        async deleteCommunicationChannel(item) {
            this.isLoading = true;
            try {
                const response = await communicationChannelsService.deleteCommunicationChannel(item.uuid);
                if (response.status === 200) {
                    this.fetchCommunicationChannels();
                    this.showSuccessToast('Communication channel deleted');
                }
            } catch (error) {
                console.error('Error removing the communication channel: ' + error);
                this.showErrorToast('Error removing the communication channel');
            } finally {
                this.isLoading = false;
            }
        },
        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },
        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },
    },
    mounted() {
        this.communicationChannelType = this.$route.params.channelName.toUpperCase();
        this.fetchCommunicationChannels();
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/md/variables';

.custom-button {
    color: $gray !important;
    margin-bottom: 15px;
    width: fit-content;

    .custom-icon {
        color: $gray !important;
        margin-right: 8px;
    }

    &:hover {
        cursor: pointer;
        color: $gray-darker !important;

        i {
            color: $gray-darker !important;
        }
    }
}

.custom-remove-item {
    span {
        color: $brand-danger !important;
    }

    i {
        color: $brand-danger !important;
    }

    &:hover {
        span {
            color: white !important;
        }

        i {
            color: white !important;
        }
    }
}

.whatsapp-icon {
    color: #25d366;
}

.custom-card {
    margin-top: 0px;
}

.custom-card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.account-details {
    display: flex;
    align-items: center;

    .whatsapp-icon {
        color: #25d366;
        margin-bottom: 0px;
        margin-right: 12px;
    }
}

.custom-main-panel {
    position: relative;
    overflow-x: auto;
    width: 100%;
}

.custom-content-panel {
    margin-bottom: 40px;
    padding-left: 30px;
    padding-right: 30px;

    @media (max-width: 1278px) {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media (max-width: 959px) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.custom-menu-item {
    display: flex;
    align-items: center;
    justify-content: left;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(238, 238, 238, 0.5);
    z-index: 10;
    cursor: not-allowed;
}

.custom-action-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>
