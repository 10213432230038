<template>
    <div class="custom-main-panel">
        <div>
            <md-progress-bar v-if="isLoading" md-mode="indeterminate"></md-progress-bar>
        </div>
        <div class="md-layout custom-content-panel">
            <div v-if="isLoading" class="overlay md-layout-item md-size-100"></div>
            <div class="md-layout-item md-size-100">
                <div class="custom-action-save">
                    <md-button :disabled="!canSave" @click="updateAccount()" class="md-raised md-primary">
                        <md-progress-spinner
                            v-if="isEditing"
                            :md-diameter="12"
                            :md-stroke="2"
                            class="md-white"
                            md-mode="indeterminate"
                        ></md-progress-spinner>
                        <span class="md-body-2">Save</span>
                    </md-button>
                </div>
            </div>
            <div class="as__layout-item--margin-bottom md-layout-item md-size-100">
                <p class="md-subheading">Account Information</p>
            </div>
            <div class="md-layout-item md-size-100">
                <md-card>
                    <md-card-content>
                        <div class="md-layout">
                            <div class="md-layout-item md-small-size-100 md-size-100">
                                <md-field class="custom-field">
                                    <label> Email </label>
                                    <md-input :disabled="!isAdminAccount" v-model="form.email" type="text"></md-input>
                                    <span class="md-helper-text">The email associated with this account</span>
                                </md-field>
                            </div>
                            <div class="md-layout-item md-small-size-100 md-size-100">
                                <md-field class="custom-field">
                                    <label> Contact name </label>
                                    <md-input
                                        :disabled="!isAdminAccount"
                                        v-model="form.contact_name"
                                        type="text"
                                    ></md-input>
                                    <span class="md-helper-text">The personal name associated with this account</span>
                                </md-field>
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
            <div class="as__layout-item--margin-bottom md-layout-item md-size-100">
                <p class="md-subheading">Company Information</p>
            </div>
            <div class="md-layout-item md-size-100">
                <md-card>
                    <md-card-content>
                        <!-- <div v-if="vertical" style="width: fit-content; margin-top: 15px;" @click="redirectToShop">
              <md-card class="custom-md-card" md-with-hover>
                <md-card-header class="custom-card-header">
                  <div class="shop-details">
                    <img src="@/assets/icons/shopifyIcon.svg" alt="Shopify Icon" class="shopify-icon">
                    <div>
                      <div class="md-body-2">Shopify</div>
                      <div class="md-caption">{{ form.shop_domain }}</div>
                    </div>
                  </div>
                </md-card-header>
              </md-card>
            </div> -->

                        <div class="md-layout">
                            <div
                                class="md-layout-item md-small-size-100 md-size-33"
                                style="display: flex; align-items: center"
                            >
                                <md-field class="custom-field">
                                    <label for="vertical">Business Type</label>
                                    <md-select
                                        :disabled="accountAlreadyHasBusinessType || !isAdminAccount"
                                        v-model="vertical"
                                        name="vertical"
                                        id="vertical"
                                    >
                                        <md-option class="custom-md-option-container" value="shopify">
                                            <div class="custom-md-option">
                                                <!-- <img
                                                    src="@/assets/icons/shopifyIcon_bw.svg"
                                                    alt="Shopify Icon"
                                                    class="vertical-icon"
                                                /> -->
                                                Ecommerce
                                            </div>
                                        </md-option>
                                        <!-- <md-option class="custom-md-option-container" value="woocomerce">
                      <div class="custom-md-option">
                        <img src="@/assets/icons/woocomerceIcon.svg" alt="woocomerce Icon" class="vertical-icon">
                        Ecommerce - Woocomerce
                      </div>
                    </md-option> -->
                                        <md-option class="custom-md-option-container" value="others">
                                            <div class="custom-md-option">
                                                <img
                                                    src="@/assets/icons/othersBusinessIcon.svg"
                                                    alt="others Icon"
                                                    class="vertical-icon"
                                                />
                                                Others
                                            </div>
                                        </md-option>
                                    </md-select>
                                </md-field>
                                <!-- <img
                                    v-if="vertical"
                                    :src="require(`@/assets/icons/${getbusinessTypeIcon(vertical)}.svg`)"
                                    alt="type Icon"
                                    class="vertical-icon-selected"
                                /> -->
                            </div>
                        </div>

                        <transition name="slide-fade">
                            <div v-show="accountBusinessTypeIsOther || accountAlreadyHasBusinessType" class="md-layout">
                                <div class="md-layout-item md-small-size-100 md-size-33">
                                    <md-field class="custom-field">
                                        <label> Business name </label>
                                        <md-input
                                            :disabled="!isAdminAccount"
                                            v-model="form.shop_name"
                                            type="text"
                                        ></md-input>
                                        <span class="md-helper-text">The name of the business</span>
                                    </md-field>
                                </div>
                                <div class="md-layout-item md-small-size-100 md-size-33">
                                    <md-field class="custom-field">
                                        <label> Business phone </label>
                                        <md-input
                                            :disabled="!isAdminAccount"
                                            v-model="form.shop_phone"
                                            type="text"
                                        ></md-input>
                                        <span class="md-helper-text">Contact phone number for the business</span>
                                    </md-field>
                                </div>
                                <div class="md-layout-item md-small-size-100 md-size-33">
                                    <currency-selector :disabled="!isAdminAccount" v-model="form.shop_currency" />
                                </div>
                                <div class="md-layout-item md-small-size-100 md-size-100">
                                    <md-field class="custom-field">
                                        <label> Customer support email </label>
                                        <md-input
                                            :disabled="!isAdminAccount"
                                            v-model="form.customer_support_email"
                                            type="text"
                                        ></md-input>
                                        <span class="md-helper-text">Support email for customer inquiries</span>
                                    </md-field>
                                </div>
                                <div class="md-layout-item md-small-size-100 md-size-100">
                                    <md-field class="custom-field">
                                        <label> Business public domain </label>
                                        <md-input
                                            :disabled="!isAdminAccount"
                                            v-model="form.shop_public_domain"
                                            type="text"
                                        ></md-input>
                                        <span class="md-helper-text">Publicly accessible domain for the business</span>
                                    </md-field>
                                </div>
                                <div class="md-layout-item md-small-size-100 md-size-100">
                                    <md-field class="custom-field">
                                        <label> Business Description </label>
                                        <md-textarea
                                            :disabled="!isAdminAccount"
                                            v-model="form.short_business_description"
                                            md-autogrow
                                            maxlength="250"
                                        ></md-textarea>
                                        <span class="md-helper-text">Shop description of your business</span>
                                    </md-field>
                                </div>
                            </div>
                        </transition>
                    </md-card-content>
                </md-card>
            </div>
        </div>
        <updateEcommerceSettingsModal
            :dialogActive="showDialog"
            @close-modal="closeModal"
            @ecommerce-settings-updated="ecommerceSettingsUpdated"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import updateEcommerceSettingsModal from '@/components/AccountSettings/updateEcommerceSettingsModal.vue';
import CurrencySelector from '@/components/Selectors/CurrencySelector.vue';

import Swal from 'sweetalert2';
import accountService from '@/services/account/accountService';

export default {
    name: 'AccountSettings',
    components: {
        updateEcommerceSettingsModal,
        CurrencySelector,
    },
    data() {
        return {
            buttonDisabled: true,
            defaultform: {},
            form: {},
            vertical: null,

            isLoading: false,
            isEditing: false,

            showDialog: false,
        };
    },
    computed: {
        ...mapGetters({
            canAccessUser: 'user/canAccess',
            isWalkonLivosAccount: 'user/isWalkonLivosAccount',
        }),
        isEdited() {
            return JSON.stringify(this.form) !== JSON.stringify(this.defaultform);
        },
        canSave() {
            return this.isEdited;
        },
        accountAlreadyHasBusinessType() {
            return this.$store.state.user.account.business_type !== null;
        },
        accountBusinessTypeIsOther() {
            return this.$store.state.user.account.business_type === 'OTHER';
        },
        isAdminAccount() {
            return this.canAccessUser(['ADMIN']) || this.isWalkonLivosAccount;
        },
    },
    watch: {
        vertical(newVal) {
            if (newVal === 'shopify' && !this.accountAlreadyHasBusinessType) {
                this.showDialog = true;
            } else if (newVal === 'others' && !this.accountAlreadyHasBusinessType) {
                this.changeBusinessTypeToOther();
            }
        },
    },
    methods: {
        ...mapActions('user', [
            'actionUpdateAccount', // Action from the 'user' module
        ]),

        closeModal() {
            if (!this.accountAlreadyHasBusinessType) {
                this.vertical = null;
            }
            this.showDialog = false;
        },

        async ecommerceSettingsUpdated() {
            await this.actionGetAccount();
            await this.fetchAccountData();
            this.closeModal();
            this.showSuccessToast('Shopify configured correctly!');
        },

        getbusinessTypeIcon(businessType) {
            let icon;
            switch (businessType) {
                case 'shopify':
                    icon = 'shopifyIcon_bw';
                    break;
                case 'woocomerce':
                    icon = 'woocomerceIcon';
                    break;
                case 'others':
                    icon = 'othersBusinessIcon';
                    break;

                default:
                    break;
            }

            return icon;
        },

        changeBusinessTypeToOther() {
            if (!this.isAdminAccount) {
                this.showErrorToast("You don't have permission to perform this action");
                return;
            }
            Swal.fire({
                title: 'Is this your business category?',
                text: `You won't be able to revert this!`,
                type: 'warning',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#4caf50',
                confirmButtonText: 'Yes, it is!',
                buttonsStyling: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.updateAccountBusinessType();
                    this.actionGetAccount();
                    this.fetchAccountData();
                    this.showSuccessToast('Business configured correctly!');
                } else if (result.isDenied) {
                    this.vertical = null;
                }
            });
        },

        async updateAccountBusinessType() {
            if (!this.isAdminAccount) {
                this.showErrorToast("You don't have permission to perform this action");
                return;
            }
            const formData = {
                business_type: 'OTHER',
            };
            try {
                await this.actionUpdateAccount(formData);
            } catch (error) {
                console.error('Error updating business type OTHER: ', error);
            }
        },

        async fetchAccountData() {
            this.isLoading = true;
            try {
                const accData = await accountService.fetchAccount(this.$store.state.user.user.account_id);
                if (accData.data) {
                    this.form = { ...accData.data };
                    this.defaultform = { ...accData.data };
                }
            } catch (err) {
                const errorMessage = err.response || 'An error occurred.';
                console.error('Error:', errorMessage);
            } finally {
                this.isLoading = false;
            }
        },
        async updateAccount() {
            if (!this.isAdminAccount) {
                this.showErrorToast("You don't have permission to perform this action");
                return;
            }
            this.isEditing = true;
            try {
                const reqData = {
                    email: this.form.email,
                    contact_name: this.form.contact_name,
                    customer_support_email: this.form.customer_support_email,
                    shop_name: this.form.shop_name,
                    shop_phone: this.form.shop_phone,
                    shop_domain: this.form.shop_domain,
                    shop_public_domain: this.form.shop_public_domain,
                    shop_currency: this.form.shop_currency,
                    short_business_description: this.form.short_business_description,
                };
                const response = await this.actionUpdateAccount(reqData);
                if ((response && response.status === 200) || response.status === 201) {
                    this.showSuccessToast('Account updated');
                    await this.fetchAccountData();
                }
            } catch (err) {
                const errorMessage = err.response || 'An error occurred.';
                console.error('Error:', errorMessage);
                this.showErrorToast('Error updating account');
            } finally {
                this.isEditing = false;
            }
        },
        redirectToShop() {
            const shopURL = `https://${this.form.shop_public_domain}`;
            window.open(shopURL, '_blank');
        },
        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },
        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },

        getBusinessType() {
            const businessType = this.$store.state.user.account.business_type;
            let newVertical = null;
            switch (businessType) {
                case 'ECOMMERCE':
                    newVertical = 'shopify';
                    break;
                case 'OTHER':
                    newVertical = 'others';
                    break;
                default:
                    newVertical = null;
                    break;
            }

            this.vertical = newVertical;
        },
    },
    mounted() {
        this.fetchAccountData();
        this.getBusinessType();
    },
};
</script>

<style lang="scss" scoped>
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(238, 238, 238, 0.5);
    z-index: 10;
    cursor: not-allowed;
}

.custom-main-panel {
    position: relative;
    overflow-x: auto;
    width: 100%;
}

.custom-content-panel {
    margin-bottom: 40px;
    padding-left: 30px;
    padding-right: 30px;

    @media (max-width: 1278px) {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media (max-width: 959px) {
        padding-left: 0px;
        padding-right: 0px;
    }

    p {
        margin-top: 24px;
    }

    .md-card {
        margin-top: 0px;
    }
}

.custom-field {
    margin-top: 20px;
    margin-bottom: 20px;
}

.custom-card-header {
    display: flex;
    align-items: center;
}

.custom-md-card {
    max-width: 350px;
    margin-left: 15px;
}

// .shop-details {
//   display: flex;
//   align-items: center;

//   .shopify-icon {
//     width: 12px;
//     height: 12px;
//     margin: 0px;
//     margin-right: 4px;

//   }
// }

.custom-md-option {
    display: flex;
    align-items: center;
    justify-content: start;

    .vertical-icon {
        width: 16px;
        height: 16px;
        margin: 0;
        margin-right: 6px;
    }
}

/* Hover effect for md-option */
.custom-md-option-container:hover .vertical-icon {
    /* CSS filter to change the image color to white */
    filter: brightness(0) invert(1);
    fill: white;
}

.vertical-icon-selected {
    width: 16px;
    height: 16px;
    margin-left: 16px;
}

.custom-action-save {
    display: flex;
    align-items: center;

    justify-content: flex-end;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: all 0.5s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
    opacity: 0;
    transform: translateY(-30px);
    .as__layout-item--margin-bottom {
        margin-bottom: 15px;
    }
}
</style>
