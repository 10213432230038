<script>
import DiscoverySkillCard from '@/components/Skills/DiscoverySkillCard.vue';
import SkillsService from '@/services/skills/SkillsService';
import AgentSettingsService from '@/services/account/agentSettingsService';
import { mapGetters } from 'vuex';

// Tour
import skillsTourSteps from '@/services/tours/sectionsTours/skillsTour';
import tourService from '@/services/tours/tourService';

function camelCaseToSpaces(str) {
    // Step 1: Add a space before each uppercase letter
    let spacedStr = str.replace(/([A-Z])/g, ' $1');

    // Step 2: Convert the entire string to lowercase
    let lowerCaseStr = spacedStr.toLowerCase();

    // Step 3: Trim any leading spaces (optional)
    return lowerCaseStr.trim();
}

export default {
    name: 'DiscoverySkill',
    components: { DiscoverySkillCard },
    data() {
        return {
            skillsList: [],
            searchSkillList: [],
            filteredSkillList: [],
            activeCategoryFilter: 'allSkills',
            searchTerm: '',
            categories: [
                {
                    id: 'allSkills',
                    name: 'All Skills',
                },
                {
                    id: 'channel',
                    name: 'Channels',
                },
                {
                    id: 'main app',
                    name: 'Main Apps',
                },
                {
                    id: 'customerSupport',
                    name: 'Customer Support',
                },
                {
                    id: 'shopify',
                    name: 'Shopify',
                },
                {
                    id: 'ecommerce',
                    name: 'Ecommerce',
                },
                {
                    id: 'orders',
                    name: 'Orders',
                },
            ],
            accountActiveSkills: [],
            specialCategories: ['main app', 'channel'],
            tourInstance: null,
        };
    },
    computed: {
        ...mapGetters('tours', ['isCompleted']),
        currentSkillsList() {
            if (this.searchTerm !== '') {
                return this.searchSkillList;
            } else if (this.activeCategoryFilter !== 'allSkills') {
                return this.filteredSkillList;
            } else {
                return this.skillsList.filter(
                    (item) => !item.categories.some((category) => this.specialCategories.includes(category)),
                ); // All skills but special categories
            }
        },

        currentChannelList() {
            return this.skillsList.filter((item) => item.categories.includes('channel')); // All channels but skills
        },

        currentMainAppSkills() {
            return this.skillsList.filter((item) => item.categories.includes('main app'));
        },
    },
    watch: {
        searchTerm(newValue) {
            this.searchSkills(newValue);
        },
    },
    methods: {
        async searchSkills(searchTerm) {
            this.activeCategoryFilter = 'allSkills';
            try {
                const searchResult = await SkillsService.searchSkills(searchTerm);
                const publicSearchedSkills = searchResult.data.content.filter((skill) => skill.public === true);
                if (searchResult.data) {
                    this.searchSkillList = [...publicSearchedSkills];
                }
            } catch (error) {
                console.error('Error searching skills: ', error);
            }
        },

        async changeActiveCategoryFilter(newActiveFilter) {
            this.activeCategoryFilter = newActiveFilter;
            if (this.activeCategoryFilter === 'allSkills') return;

            const formatedActiveCategoryFilter = camelCaseToSpaces(this.activeCategoryFilter);
            try {
                const filteredResult = await SkillsService.searchSkills(null, formatedActiveCategoryFilter);
                const publicFilteredSkills = filteredResult.data.content.filter((skill) => skill.public === true);
                if (filteredResult.data) {
                    this.filteredSkillList = [...publicFilteredSkills];
                }
            } catch (error) {
                console.error('Error filtering skills: ', error);
            }
        },

        async fetchSkills() {
            try {
                const response = await SkillsService.getSkills();
                const publicSkills = response.data.content.filter((skill) => skill.public === true);
                this.skillsList = [...publicSkills];
            } catch (error) {
                console.error('Error fetching Skills: ', error);
            }
        },

        async fetchAgentSettings() {
            try {
                const response = await AgentSettingsService.getAgentSettings();
                if (response.data.skills) {
                    this.accountActiveSkills = [...response.data.skills];
                }
            } catch (error) {
                console.error('Error fetching agent settings: ', error);
            }
        },

        checkIfSkillIsEnabledForTheAccount(skillId) {
            return this.accountActiveSkills.some((skill) => skill.skill_id === skillId);
        },

        handleChannelCardClick() {
            if (!this.isCompleted('skills-tour')) {
                this.tourInstance.complete();
            }
        },

        handleSkillCardClick() {
            if (!this.isCompleted('skills-tour')) {
                this.tourInstance.cancel();
            }
        },
    },
    async mounted() {
        await this.fetchSkills();
        await this.fetchAgentSettings();

        const type = this.$route.query.type;

        if (type) {
            this.changeActiveCategoryFilter(type);
        }

        if (!this.isCompleted('skills-tour')) {
            this.$nextTick(async () => {
                // Wait for the element to be available
                const maxAttempts = 10;
                let attempts = 0;

                const waitForElement = () => {
                    return new Promise((resolve) => {
                        const element = document.getElementById('skills-list');
                        if (element) {
                            const elementPosition = element.getBoundingClientRect();
                            const offsetPosition = elementPosition.top + window.pageYOffset - window.innerHeight / 2;

                            window.scrollTo({
                                top: offsetPosition,
                                behavior: 'auto',
                            });
                            resolve();
                        } else if (attempts < maxAttempts) {
                            attempts++;
                            setTimeout(() => waitForElement().then(resolve), 100);
                        } else {
                            resolve(); // Give up after max attempts
                        }
                    });
                };

                await waitForElement();
                this.tourInstance = tourService.startTour('skills-tour', skillsTourSteps);
            });
        }
    },
};
</script>

<template>
    <div>
        <div>
            <md-card class="discovery-skill__banner">
                <md-card-content class="discovery-skill__banner-content">
                    <div class="discovery-skill__banner-text">
                        <div class="md-title">
                            <strong> Explore New Horizons with Skills </strong>
                        </div>
                        <div class="md-body-1">
                            Browse the latest official Lixsa skills to enhance your chat experience with tailored,
                            team-crafted enhancements for smarter interactions and workflow efficiency.
                        </div>
                    </div>
                    <div class="discovery-skill__banner-image">
                        <img src="@/assets/img/skill-discovery-banner.png" alt="Skill Discovery Banner" />
                    </div>
                </md-card-content>
            </md-card>
        </div>
        <div>
            <md-card class="discovery-skill__search-card">
                <md-field md-clearable>
                    <md-icon>search</md-icon>
                    <md-input v-model="searchTerm" type="text" placeholder="Search skills..."></md-input>
                </md-field>
            </md-card>
        </div>
        <div v-if="searchTerm === ''">
            <div class="md-subheading">Filter by category</div>
            <div class="discovery-skill__categories-filter">
                <md-chip
                    v-for="category in categories"
                    class="md-outline discovery-skill__category-chip"
                    :class="{ activeFilter: category.id === activeCategoryFilter }"
                    @click="changeActiveCategoryFilter(category.id)"
                    >{{ category.name }}</md-chip
                >
            </div>
        </div>

        <div v-if="searchTerm === '' && activeCategoryFilter === 'allSkills'" class="discovery-skill__channels-list">
            <div class="md-subheading">Main Apps</div>
            <div class="md-caption">Connect Lixsa to your main business</div>
            <div class="md-layout discovery-skill__skill-card">
                <div
                    v-for="mainApp in currentMainAppSkills"
                    class="md-layout-item md-size-25 md-medium-size-50 md-small-size-100 discovery-skill__layout-item"
                >
                    <DiscoverySkillCard
                        @card-click="handleSkillCardClick"
                        :active="checkIfSkillIsEnabledForTheAccount(mainApp.uuid)"
                        :skill="mainApp"
                    />
                </div>
            </div>
        </div>

        <div
            id="channels-list"
            v-if="searchTerm === '' && activeCategoryFilter === 'allSkills'"
            class="discovery-skill__channels-list"
        >
            <div class="md-subheading">Channels</div>
            <div class="md-caption">Teach Lixsa to use your main channels</div>
            <div class="md-layout discovery-skill__skill-card">
                <div
                    v-for="channel in currentChannelList"
                    class="md-layout-item md-size-25 md-medium-size-50 md-small-size-100 discovery-skill__layout-item"
                >
                    <DiscoverySkillCard
                        @card-click="handleChannelCardClick"
                        :active="checkIfSkillIsEnabledForTheAccount(channel.uuid)"
                        :skill="channel"
                    />
                </div>
            </div>
        </div>

        <div id="skills-list" v-if="currentSkillsList.length > 0">
            <div v-if="searchTerm === '' && activeCategoryFilter === 'allSkills'" class="md-subheading">
                Official Skills
            </div>
            <div v-if="searchTerm === '' && activeCategoryFilter === 'allSkills'" class="md-caption">
                Add superpowers to Lixsa and enhance your business
            </div>
            <div class="md-layout discovery-skill__skill-card">
                <div
                    v-for="skill in currentSkillsList"
                    class="md-layout-item md-size-25 md-medium-size-50 md-small-size-100 discovery-skill__layout-item"
                >
                    <DiscoverySkillCard
                        @card-click="handleSkillCardClick"
                        :active="checkIfSkillIsEnabledForTheAccount(skill.uuid)"
                        :skill="skill"
                    />
                </div>
            </div>
        </div>

        <div class="md-caption discovery-skill__no-results-message" v-else>No skills match your search</div>
    </div>
</template>

<style scoped lang="scss">
.discovery-skill__banner {
    overflow: hidden;
    background-color: #f2f2f2;
}

.discovery-skill__banner-content {
    display: flex;
    align-items: center;
}

.discovery-skill__banner-text {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-right: 40rem;
    padding-top: 20px;
    padding-bottom: 20px;

    @media (max-width: 959px) {
        padding-right: 0;
    }
}

.discovery-skill__banner-image {
    width: 500px;
    position: absolute;
    right: 0;

    @media (max-width: 959px) {
        display: none;
    }
}

.discovery-skill__search-card {
    padding: 2px 20px;
}

.discovery-skill__categories-filter {
    margin-bottom: 2rem;
    margin-top: 1rem;

    .discovery-skill__category-chip {
        height: 30px !important;
        line-height: 30px !important;
        cursor: pointer;

        &.activeFilter {
            border: 1px solid #929292 !important;
        }

        &:hover {
            background-color: #f2f2f2 !important; // Light grey background on hover
        }

        &:active {
            background-color: #d6d6d6 !important; // Darker grey background on click
        }
    }
}

.discovery-skill__layout-item {
    padding: 16px 16px 0 0;
}

/* Large screens: every 4th item should not have right padding */
@media (max-width: 1919px) {
    .md-layout-item:nth-child(4n) {
        padding-right: 0;
    }
}

/* Medium screens: every 3rd item should not have right padding */
@media (max-width: 1279px) {
    .md-layout-item:nth-child(2n) {
        padding-right: 0;
    }
}

// Remove right padding to all elements (there is only one card per row)
@media (max-width: 959px) {
    .md-layout-item {
        padding-right: 0;
    }
}

.discovery-skill__no-results-message {
    display: flex;
    justify-content: center;
}

.discovery-skill__channels-list {
    margin-bottom: 2rem;
}
</style>
