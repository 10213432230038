<template>
    <div>
        <md-button @click="launchInstagramSignup" class="md-primary" :disabled="isLoading">
            {{ isLoading ? 'Connecting...' : 'Connect' }}
        </md-button>
        <div id="status"></div>
    </div>
</template>

<script>
import instagramService from '@/services/meta/instagramService';
import { mapActions } from 'vuex';

export default {
    name: 'ChannelsInstagramLoginButton',

    emits: ['account-connected'],

    data() {
        return {
            isLoading: false,
        };
    },

    methods: {
        ...mapActions('websocket', ['subscribe', 'unsubscribe']),

        async launchInstagramSignup() {
            this.isLoading = true;
            try {
                const redirectUri = encodeURIComponent('https://task.lixsa.ai/webhook/setup/instagram');
                const accountId = this.$store.getters['user/user'].account_id;

                const authUrl =
                    `https://www.instagram.com/oauth/authorize?` +
                    `enable_fb_login=0` +
                    `&force_authentication=1` +
                    `&client_id=${process.env.VUE_APP_INSTAGRAM_CONFIGURATION_ID}` +
                    `&redirect_uri=${redirectUri}` +
                    `&response_type=code` +
                    `&scope=instagram_business_basic,instagram_business_manage_messages,instagram_business_manage_comments,instagram_business_content_publish,instagram_business_manage_insights` +
                    `&state=${accountId}`;

                const width = 600;
                const height = 700;
                const left = (window.innerWidth - width) / 2;
                const top = (window.innerHeight - height) / 2;

                const authWindow = window.open(
                    authUrl,
                    'Instagram Authorization',
                    `width=${width},height=${height},left=${left},top=${top}`,
                );

                this.subscribeToSkillEvents();

                const checkRedirect = setInterval(async () => {
                    try {
                        if (authWindow.closed) {
                            clearInterval(checkRedirect);
                            setTimeout(() => {
                                this.isLoading = false;
                            }, 3500);

                            return;
                        }
                    } catch (e) {}
                }, 3000);
            } catch (error) {
                this.showErrorToast(error.message);
                this.isLoading = false;
            }
        },

        subscribeToSkillEvents() {
            this.isLoading = true;
            const accountId = this.$store.getters['user/user'].account_id;
            const topic = `/account/${accountId}/communication-channel`;

            this.subscribe({
                topic,
                callback: this.handleSkillEvent,
            });
        },

        handleSkillEvent(message) {
            if (!message || !message.data) return;

            if (message.name === 'CommunicationChannelCreated') {
                this.$emit('account-connected');
                this.isLoading = false;
            }
        },

        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },

        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },
    },

    beforeDestroy() {
        const accountId = this.$store.getters['user/user'].account_id;
        const topic = `/account/${accountId}/communication-channel`;
        this.unsubscribe({
            topic,
            callback: this.handleSkillEvent,
        });
    },
};
</script>

<style scoped>
/* Add any additional styles here */
</style>
