export default [
    {
        id: 'whatsapp-channel-tour',
        title: '<p class="md-title">${tours.whatsapp.title}</p>',
        text: `
            <div>
                <p class="md-body-2">\${tours.whatsapp.intro.p1}</p>
                <p class="md-caption">\${tours.whatsapp.intro.p2}</p>
            </div>
        `,
        buttons: [
            {
                text: 'tours.whatsapp.buttons.skipTour',
                action: 'cancel',
                secondary: true,
            },
            {
                text: 'tours.whatsapp.buttons.startTour',
                action: 'next',
            },
        ],
    },
    {
        id: 'whatsapp-login-button-step',
        attachTo: {
            element: '#whatsapp-login-button',
            on: 'bottom',
        },
        text: `
            <div>
                <p class="md-body-2">\${tours.whatsapp.loginButton.p1}</p>
                <p class="md-body-2">\${tours.whatsapp.loginButton.p2}</p>
            </div>
        `,
        buttons: [
            {
                text: 'tours.whatsapp.buttons.skipTour',
                action: 'complete',
                secondary: true,
            },
        ],
    },
    {
        id: 'whatsapp-final-step',
        text: `
            <div>
                <p class="md-body-2">\${tours.whatsapp.finalStep.p1}</p>
                <p class="md-body-2">\${tours.whatsapp.finalStep.p2}</p>
            </div>
        `,
        buttons: [
            {
                text: 'tours.whatsapp.buttons.goToInbox',
                action: 'complete',
            },
        ],
    },
];
