export default [
    {
        id: 'playground-chat-step',
        attachTo: { element: '#playground-chat', on: 'right' },
        text: `
            <div>
                <p class="md-body-2">\${tours.playground.chat.p1} \${tours.playground.chat.p2}</p>
                <p class="md-caption">\${tours.playground.chat.p3}</p>
            </div>
        `,
        buttons: [
            {
                text: 'tours.playground.buttons.skipTour',
                action: 'cancel',
                secondary: true,
            },
            {
                text: 'tours.playground.buttons.next',
                action: 'next',
            },
        ],
    },
    {
        id: 'playground-chat__agent-selector-step',
        attachTo: { element: '#playground-chat__agent-selector', on: 'top' },
        text: `
            <div>
                <p class="md-body-2">\${tours.playground.agentSelector}</p>
            </div>
        `,
        buttons: [
            {
                text: 'tours.playground.buttons.next',
                action: 'next',
            },
        ],
    },
    {
        id: 'playground-chat__clear-conversation-step',
        attachTo: { element: '#playground-chat__clear-conversation', on: 'top' },
        text: `
            <div>
                <p class="md-body-1">\${tours.playground.clearConversation.p1}</p>
                <p class="md-body-2">\${tours.playground.clearConversation.p2}</p>
            </div>
        `,
        buttons: [
            {
                text: 'tours.playground.buttons.next',
                action: 'next',
            },
        ],
    },
    {
        id: 'playground-chat-step',
        attachTo: { element: '#playground-chat', on: 'right' },
        text: `
            <div>
                <p class="md-body-2">\${tours.playground.startMessage.p1}</p>
                <p class="md-body-2">\${tours.playground.startMessage.p2}</p>
            </div>
        `,
        buttons: [
            {
                text: 'tours.playground.buttons.exploreLixsaSkills',
                action: 'complete',
            },
        ],
    },
];
