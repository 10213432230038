// currencyService.js

import getSymbolFromCurrency from 'currency-symbol-map';
import currencyCodes from 'currency-codes';

/**
 * @typedef {Object} currencyCodes
 * @property {string} code - ISO 4217 currency code
 * @property {string} number - ISO 4217 numeric code
 * @property {number} digits - Number of decimal digits
 * @property {string} currency - Full currency name
 * @property {string[]} countries - List of countries using this currency
 */

/**
 * @typedef {Object} CurrencyDto
 * @property {string} code - ISO 4217 currency code
 * @property {string} name - Full currency name
 * @property {string} symbol - Currency symbol
 */

/**
 * Service class for handling currency-related operations
 */
export default class CurrencyService {
    /**
     * Retrieves all available currencies
     * @returns {Promise<CurrencyDto[]>} Array of currency objects
     */
    static async getAllCurrencies() {
        /** @type {currencyCodes[]} */
        const currencies = currencyCodes.data;

        /** @type {CurrencyDto[]} */
        const finalCurrencies = currencies.map((currency) => {
            return {
                code: currency.code,
                name: currency.currency,
                symbol: getSymbolFromCurrency(currency.code),
            };
        });

        return finalCurrencies;
    }
}
