<template>
    <div class="user-profile-modal">
        <div class="user-profile-modal__close_bar">
            <md-button @click="closeModal()" class="md-just-icon">
                <md-icon>close</md-icon>
            </md-button>
        </div>
        <md-dialog-content>
            <!-- <p class="md-title">User Profile</p> -->
            <profile-information
                :user="user"
                :is-permission-disabled="isPermissionDisabled"
                :show-description="false"
                @update:user="handleUserUpdate"
                @update:profile-picture="handleProfilePictureUpdate"
            />

            <div v-if="isPermissionDisabled" class="md-layout">
                <div class="user-profile-modal__language-selector-item md-layout-item md-size-100">
                    <div class="md-subheading">User Language</div>
                    <div class="md-caption">The language of the platform</div>
                </div>
                <div class="md-layout-item md-size-100">
                    <!-- <LanguageSelector v-model="selectedLanguage" @change="changeLanguage" /> -->
                    <LanguageSelector
                        mode="custom"
                        :custom-languages="['en', 'es']"
                        v-model="selectedLanguage"
                        @change="changeLanguage"
                    />
                </div>
            </div>
        </md-dialog-content>
        <md-dialog-actions>
            <md-button class="md-primary" @click="handleSave" :disabled="!canSave">Save Changes</md-button>
        </md-dialog-actions>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ProfileInformation from '@/components/Settings/ProfileSettings/ProfileInformation.vue';
import userService from '@/services/account/userService';
import LanguageSelector from '@/components/Selectors/LanguageSelector.vue';

export default {
    name: 'UserProfileModal',
    components: { ProfileInformation, LanguageSelector },
    emits: ['update:user'],
    props: {
        user: {
            type: Object,
            required: true,
        },
        isPermissionDisabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            localUser: {},
        };
    },
    watch: {
        user: {
            handler(newValue) {
                this.localUser = {
                    ...newValue,
                    availability: Array.from(newValue.availability || []),
                    operational_region: Array.from(newValue.operational_region || []),
                };
            },
            immediate: true,
            deep: true,
        },
    },
    computed: {
        ...mapGetters({
            currentLanguage: 'user/currentLanguage',
        }),
        isEdited() {
            return (
                this.localUser.username !== this.user.username ||
                this.localUser.role !== this.user.role ||
                this.localUser.capabilities !== this.user.capabilities ||
                this.localUser.permission !== this.user.permission ||
                !this.areArraysEqual(this.localUser.availability, this.user.availability) ||
                !this.areArraysEqual(this.localUser.operational_region, this.user.operational_region) ||
                this.localUser.contact_phone !== this.user.contact_phone
            );
        },
        canSave() {
            return this.isEdited && this.isPhoneNumberValid(this.localUser.contact_phone);
        },
        selectedLanguage: {
            get() {
                return this.currentLanguage;
            },
            set(value) {
                // This is handled by the changeLanguage method
            },
        },
    },
    methods: {
        ...mapActions('modalStore', ['closeModal']),
        ...mapActions('user', ['actionUpdateUser', 'setLanguage']),
        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },
        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },
        changeLanguage(lang) {
            this.setLanguage(lang);
            this.showSuccessToast('Language updated successfully');
        },
        isPhoneNumberValid(phoneNumber) {
            if (!phoneNumber || phoneNumber === '') return true;
            const phoneRegex = /^(\+)?(?:[0-9] ?){6,14}[0-9]$/;
            return phoneRegex.test(phoneNumber);
        },
        areArraysEqual(arr1, arr2) {
            if (arr1 === null && arr2 === null) return true;
            if (arr1 === null || arr2 === null) return false;
            if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false;
            if (arr1.length !== arr2.length) return false;
            return arr1.every((item, index) => item === arr2[index]);
        },

        handleUserUpdate(updatedUser) {
            this.localUser = { ...updatedUser };
        },
        handleProfilePictureUpdate(newPictureUrl) {
            this.localUser.profile_picture = newPictureUrl;
            this.$emit('modalEvent', { eventName: 'update:user', eventPayload: this.localUser });
            this.closeModal();
        },
        async handleSave() {
            try {
                const req = {
                    user_id: this.localUser.uuid,
                    username: this.localUser.username,
                    role: this.localUser.role,
                    permission: this.localUser.permission,
                    capabilities: this.localUser.capabilities,
                    availability: this.localUser.availability,
                    operational_region: this.localUser.operational_region,
                    contact_phone: this.localUser.contact_phone,
                };
                // await this.actionUpdateUser(req);
                await userService.editUser(this.localUser.uuid, req);
                this.showSuccessToast('Profile updated successfully');
                this.$emit('modalEvent', { eventName: 'update:user', eventPayload: this.localUser });
                this.closeModal();
            } catch (error) {
                console.error('Error updating user profile:', error);
                this.showErrorToast('Failed to update profile');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.user-profile-modal {
    max-width: 800px;
    max-height: 800px;
    overflow: auto;
    padding: 20px;

    &__close_bar {
        text-align: end;
        margin-bottom: 16px;
    }
}

.md-dialog-content {
    padding: 0;
}

.md-dialog-actions {
    padding: 16px;
}

.user-profile-modal__language-selector-item {
    margin-top: 2rem;
}
</style>
