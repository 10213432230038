<script>
import CatalogServiceV2 from '@/services/productCatalog/CatalogServiceV2';
import { mapActions } from 'vuex';
import Swal from 'sweetalert2';
import { isEqual } from 'lodash';

export default {
    name: 'CatalogSchemaEditor',
    data() {
        return {
            catalogSchema: null, // Unchanged schema object (for comparisons).
            baseCatalogSchema: null, // Dynamic schema object.
            activeMetadataForms: [], // Form dropdown control.
            isLoading: false,
            isLoadingSchema: false,
        };
    },
    watch: {
        catalogSchema(newVal) {
            this.baseCatalogSchema = this.extractObjectToNewFormat(newVal);
        },
    },
    computed: {
        isEdited() {
            const currentSchema = this.extractObjectToNewFormat(this.catalogSchema);
            return !isEqual(currentSchema, this.baseCatalogSchema);
        },
    },
    methods: {
        ...mapActions('modalStore', ['closeModal']),

        async loadSchema() {
            this.isLoadingSchema = true;
            try {
                const metadataSchema = await CatalogServiceV2.getCatalog();
                this.catalogSchema = { ...metadataSchema.data };
                // Assuming you want to sort the attributes array by weight in descending order
                if (this.catalogSchema.schema && Array.isArray(this.catalogSchema.schema.attributes)) {
                    this.catalogSchema.schema.attributes.sort((a, b) => b.weight - a.weight);
                }
            } catch (error) {
                console.error('Error loading schema: ', error);
                // this.showErrorToast('Error loading schema');
            } finally {
                this.isLoadingSchema = false;
            }
        },

        activeMetadataForm(metadataName, indexToRemove) {
            if (this.isMetadataFormActive(indexToRemove)) {
                this.activeMetadataForms = this.activeMetadataForms.filter((item) => item !== indexToRemove);
            } else {
                this.activeMetadataForms = [...this.activeMetadataForms, indexToRemove];
            }
        },

        isMetadataFormActive(indexToRemove) {
            return this.activeMetadataForms.includes(indexToRemove);
        },

        handleUpdateCatalog() {
            Swal.fire({
                title: 'Are you sure?',
                text: `These changes will affect the structure of your catalog. You will not be able to reverse it!`,
                type: 'warning',
                // icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#4A25E1',
                confirmButtonText: 'Apply Changes',
                buttonsStyling: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.udpateCatalogSchema();
                }
            });
        },

        async udpateCatalogSchema() {
            this.isLoading = true;
            try {
                await CatalogServiceV2.updateCatalogSchema(this.baseCatalogSchema);
                this.showSuccessToast('Successfully updated catalog schema');
                this.closeModal();
            } catch (e) {
                this.showErrorToast('Error updating catalog schema');
                console.error('Error updating catalog schema');
            } finally {
                this.isLoading = false;
            }
        },

        extractObjectToNewFormat(catalogObject) {
            if (!catalogObject) return null;

            return {
                description: catalogObject.description,
                sales_guideline: catalogObject.sales_guideline,
                schema: catalogObject.schema.attributes.map((attribute) => ({
                    name: attribute.name,
                    type: attribute.type,
                    description: attribute.description,
                    weight: attribute.weight,
                    required: attribute.required,
                    options: attribute.options ? [...attribute.options] : [],
                })),
            };
        },

        addNewMetadata() {
            const newMetadata = {
                name: '',
                type: 'STRING',
                description: '',
                weight: 0,
                required: true,
                options: [],
            };
            this.baseCatalogSchema.schema = [newMetadata, ...this.baseCatalogSchema.schema];
            this.activeMetadataForms = [0];

            // Scroll up and focus in the name input of the new metadata.
            this.$nextTick(() => {
                let dialogContent = document.getElementById('dialogContent');
                if (dialogContent) {
                    dialogContent.scrollTop = 0;
                }
                let nameInput = document.getElementById('nameInput0');
                if (nameInput) {
                    nameInput.focus(); // For instance, you might want to focus the newly added input.
                }
            });
        },

        deleteItem(indexToRemove) {
            this.baseCatalogSchema.schema.splice(indexToRemove, 1);
        },

        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },

        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },

        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },

        getMetadataTypeIcon(type) {
            switch (type) {
                case 'STRING':
                    return 'title';
                case 'NUMBER':
                    return '123';
                case 'BOOLEAN':
                    return 'toggle_off';
                case 'ARRAY':
                    return 'list';
            }
        },
    },
    beforeMount() {
        this.loadSchema();
    },
};
</script>

<template>
    <div>
        <div class="dcse__close_bar">
            <md-button @click="closeModal()" class="md-just-icon"><md-icon>close</md-icon></md-button>
        </div>
        <md-dialog-content id="dialogContent">
            <div class="dcse__loader" v-if="isLoadingSchema">
                <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
            </div>
            <div v-else class="dcse__layout">
                <div class="dcse__main-panel">
                    <div class="md-headline">Metadata Schema Editor</div>
                    <md-field>
                        <label>Description</label>
                        <md-input type="text" v-model="baseCatalogSchema.description"></md-input>
                    </md-field>
                    <md-field>
                        <label>Sales Guidelines</label>
                        <md-textarea type="text" md-autogrow v-model="baseCatalogSchema.sales_guideline"></md-textarea>
                    </md-field>
                    <div class="dcse__metadata-group">
                        <div class="md-title">Metadata:</div>
                        <p class="md-caption">
                            This schema represents solely the product catalog with which the Lixsa has been trained and
                            to which it has access. Therefore, there may be differences compared to the current catalog
                            schema of your other platforms. Ensure to check for correspondence or necessary updates to
                            ensure compatibility and optimal use of AI functionalities.
                        </p>
                        <div
                            v-for="(metadataField, index) in baseCatalogSchema.schema"
                            :key="index"
                            class="dcse__metadata-field"
                        >
                            <div @click="activeMetadataForm(metadataField.name, index)">
                                <md-card class="dcse__metadata-field-card">
                                    <md-card-content>
                                        <div class="dcse_metadata-field-card-content">
                                            <div class="dcse_metadata-field-card-content-resume">
                                                <md-icon>{{ getMetadataTypeIcon(metadataField.type) }}</md-icon>
                                                <div class="md-subheading">
                                                    <strong>
                                                        {{ capitalizeFirstLetter(metadataField.name) }}
                                                    </strong>
                                                </div>
                                                <md-chip class="md-success">
                                                    <strong>Relevance: </strong>
                                                    {{ metadataField.weight }}
                                                </md-chip>
                                            </div>
                                            <div>
                                                <md-button @click.stop="deleteItem(index)" class="md-just-icon">
                                                    <md-icon>delete</md-icon>
                                                </md-button>
                                            </div>
                                        </div>
                                    </md-card-content>
                                </md-card>
                            </div>
                            <!--          <Transition name="slide-from-top">-->
                            <div v-if="isMetadataFormActive(index)" class="dcse__metadata-field-form">
                                <md-field>
                                    <label>Name</label>
                                    <md-input
                                        :ref="`nameInput${index}`"
                                        :id="`nameInput${index}`"
                                        type="text"
                                        v-model="metadataField.name"
                                    ></md-input>
                                </md-field>
                                <md-field>
                                    <label>Type</label>
                                    <md-select v-model="metadataField.type" name="type" id="type">
                                        <md-option value="STRING">String</md-option>
                                        <md-option value="NUMBER">Number</md-option>
                                        <md-option value="BOOLEAN">Boolean</md-option>
                                        <md-option value="ARRAY">List</md-option>
                                    </md-select>
                                </md-field>
                                <md-field>
                                    <label>Description</label>
                                    <md-input type="text" v-model="metadataField.description"></md-input>
                                </md-field>
                                <md-field>
                                    <label>Weight</label>
                                    <md-input type="text" v-model="metadataField.weight"></md-input>
                                </md-field>
                                <md-chips
                                    v-model="metadataField.options"
                                    md-placeholder="Add options..."
                                    :md-auto-insert="true"
                                >
                                    <label>Options</label>
                                </md-chips>
                                <md-switch v-model="metadataField.required">Required</md-switch>
                            </div>
                            <!--          </Transition>-->
                        </div>
                    </div>
                </div>
            </div>
        </md-dialog-content>
        <div v-if="baseCatalogSchema !== null" class="dcse__tool-bar">
            <md-button @click="addNewMetadata" class="md-simple md-primary"
                ><md-icon>add</md-icon> Add Metadata</md-button
            >
            <div class="dcse__save-bar">
                <md-button @click="closeModal()" class="">Cancel</md-button>
                <md-button v-if="isLoading" disabled>
                    <md-progress-spinner :md-diameter="20" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
                </md-button>
                <md-button v-else @click="handleUpdateCatalog" :disabled="!isEdited" class="md-primary">
                    <span>Save</span>
                </md-button>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
@import '@/assets/scss/md/variables';
.dcse__close_bar {
    text-align: end;
}

.dcse__layout {
    width: 70vw;
    height: 80vh;
}

.dcse__loader {
    display: flex;
    align-items: center;
    justify-content: center;
}

.dcse__main-panel {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.dcse__metadata-group {
    margin-bottom: 70px;
}

.dcse__metadata-field {
    cursor: pointer;

    .dcse__metadata-field-card {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
        transition:
            background-color 0.3s ease,
            box-shadow 0.3s ease; /* Smooth transition for hover effects */

        .dcse_metadata-field-card-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            //align-content: space-between;
        }

        .dcse_metadata-field-card-content-resume {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;
        }
    }

    .dcse__metadata-field-form {
        padding: 0.5rem 1rem;
    }
}

.dcse__metadata-field-card:hover {
    background-color: #f0f0f0;
}

.dcse__tool-bar {
    height: 70px;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff; // Change as needed
    border-top: 1px solid #ccc; // Change as needed
    padding: 10px 15px;
    display: flex;
    //text-align: end; // Center the button, adjust as needed
    align-items: center;
    justify-content: space-between;

    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); // Optional shadow for depth
    z-index: 2;

    // Ensure the button is not obscured by the fixed bar
    .md-button {
        margin: 0; // Center the button if the container is flex
    }

    .dcse__save-bar {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
    }
}

///* Adjustments for animation */
//.slide-from-top-enter-active, .slide-from-top-leave-active {
//  transition: opacity 0.5s ease, max-height 0.5s ease;
//  overflow: hidden;
//}
//
//.slide-from-top-enter, .slide-from-top-leave-to {
//  opacity: 0;
//  max-height: 0;
//}
//
//.slide-from-top-enter-active {
//  opacity: 1;
//  max-height: 500px; /* Adjust based on the actual height of the content */
//}
//
//.slide-from-top-leave-active {
//  opacity: 0;
//  max-height: 0;
//}
</style>
