<template>
    <md-card class="whatsapp-connect-card">
        <md-card-content>
            <div class="md-title"><strong>Connect Lixsa with communication channels</strong></div>
            <p class="md-body-1">
                Enhance your Lixsa experience by connecting it with communication channels. This integration allows for
                seamless communication and improved customer engagement.
            </p>
            <md-button class="md-primary md-raised" @click="goToCommunicationChannelSkills">
                Connect to communication channels
            </md-button>
        </md-card-content>
    </md-card>
</template>

<script>
export default {
    name: 'PlaygroundConnectToWhatsappCta',
    methods: {
        goToCommunicationChannelSkills() {
            this.$router.push('/skills/discovery?type=channel');
        },
    },
};
</script>

<style scoped>
.whatsapp-connect-card {
    background-color: #f5f5f5;
    margin: 20px auto;
}
</style>
