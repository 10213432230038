<template>
    <div>
        <md-dialog id="business-knowledge-upload-wizard" :md-active.sync="showDialog">
            <md-dialog-title><strong>Upload a document</strong></md-dialog-title>
            <div class="modal-content">
                <div
                    class="dropzone"
                    :class="{ over: isDraggingOver, disabled: url != '' }"
                    @dragover.prevent="dragOver"
                    @dragenter.prevent="dragEnter"
                    @dragleave.prevent="dragLeave"
                    @drop.prevent="handleDrop"
                    @click="triggerFileInput"
                >
                    <input type="file" ref="fileInput" @change="handleFiles" class="hidden" accept="application/pdf" />
                    <div v-if="!file">
                        <p>Drag & Drop your file or <span @click="triggerFileInput">Browse</span></p>
                        <!-- <i class="custom-icon fas fa-file-csv fa-4x"></i> -->
                        <i class="custom-icon fas fa-file-pdf fa-4x"></i>
                    </div>
                    <div v-else>
                        <p>{{ file.name }}</p>
                        <i v-if="file.type === 'application/pdf'" class="custom-icon fas fa-file-pdf fa-4x"></i>
                    </div>
                </div>

                <div class="or-separator">
                    <div class="separator-line"></div>
                    OR
                    <div class="separator-line"></div>
                </div>

                <h4><strong>Import from URL</strong></h4>
                <md-field>
                    <label>Url</label>
                    <md-input :disabled="file != null" type="url" v-model="url"></md-input>
                </md-field>

                <h4><strong>Name (Required)</strong></h4>
                <md-field>
                    <label>Name</label>
                    <md-input type="text" v-model="name" required></md-input>
                </md-field>
            </div>

            <md-dialog-actions>
                <md-button
                    id="business-knowledge-upload-wizard-close-button"
                    class="md-white modal-button"
                    @click="handleClose"
                    >Close</md-button
                >
                <md-button
                    id="business-knowledge-upload-wizard-save-button"
                    class="md-primary modal-button"
                    @click="handleUpload"
                    :disabled="!canSave"
                >
                    <span v-if="!isLoading">Save</span>
                    <md-progress-spinner
                        v-else
                        :md-diameter="20"
                        :md-stroke="3"
                        class="md-accent"
                        md-mode="indeterminate"
                    ></md-progress-spinner>
                </md-button>
            </md-dialog-actions>
        </md-dialog>
        <md-button id="business-knowledge-add-button" class="md-primary custom-button" @click="showDialog = true">
            <div>
                <md-icon class="custom-button-icon">add</md-icon>
                <span>Add Knowledge</span>
            </div>
        </md-button>
    </div>
</template>

<script>
import businessKnowledgeService from '../../services/account/businessKnowledgesService';

export default {
    name: 'UploadWizard',
    data: () => ({
        showDialog: false,
        file: null,
        url: '',
        name: '',

        // Style controlers
        isDraggingOver: false,
        isLoading: false,
    }),
    computed: {
        canSave() {
            // Check if either file is not null or url is not empty AND name is not empty
            return (this.file || this.url) && this.name;
        },
    },
    methods: {
        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },
        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },
        dragOver() {
            this.isDraggingOver = true;
        },
        dragEnter() {
            this.isDraggingOver = true;
        },
        dragLeave() {
            this.isDraggingOver = false;
        },
        handleDrop(event) {
            this.isDraggingOver = false;
            const files = event.dataTransfer.files;
            this.addFile(files[0]); // Only take the first file
        },
        handleFiles(event) {
            this.addFile(event.target.files[0]); // Only take the first file
        },
        triggerFileInput() {
            this.$refs.fileInput.click();
        },
        addFile(file) {
            this.file = file;
            this.name = file.name;
        },

        handleClose() {
            this.url = this.name = '';
            this.file = null;
            this.showDialog = false;
            this.isLoading = false;
            this.$emit('close');
        },
        handleUpload() {
            this.isLoading = true;
            if (this.file != null) this.uploadFile();
            else if (this.url != '') this.uploadFromUrl();
        },
        async uploadFile() {
            try {
                const { storageURL, uuid } = await businessKnowledgeService.getStorageURL(this.file.type);

                const uploadFileToGoogleCloudResponse = await businessKnowledgeService.uploadFileToGoogleCloud(
                    storageURL,
                    this.file,
                    this.file.type,
                );

                if (uploadFileToGoogleCloudResponse.status === 200) {
                    let type = '';
                    switch (this.file.type) {
                        case 'application/pdf':
                            type = 'PDF';
                            break;
                        default:
                            break;
                    }

                    const businessKnowledgeData = {
                        uuid: uuid,
                        resource_url: type !== 'JSON' ? storageURL.split('?')[0] : storageURL,
                        name: this.name,
                        type: type,
                    };

                    const createBusinessKnowledgeResponse =
                        await businessKnowledgeService.createBusinessKnowledge(businessKnowledgeData);

                    if (createBusinessKnowledgeResponse.status === 201) {
                        this.isLoading = false;
                        this.notifyParent();
                        this.handleClose();
                        this.showSuccessToast(
                            'Knowledge created. Lixsa is learning, this process will take a few minutes...',
                        );
                    }
                }
            } catch (error) {
                console.error('Error uploading from file: ', error);
                this.isLoading = false;
                this.handleClose();
                this.showErrorToast('There was an error processing the document, try again later.');
            }
        },
        async uploadFromUrl() {
            try {
                const request = {
                    resource_url: this.url,
                    name: this.name,
                    type: 'WEB',
                };
                const response = await businessKnowledgeService.createBusinessKnowledge(request);
                if (response.status === 201) {
                    this.isLoading = false;
                    this.notifyParent();
                    this.handleClose();
                    this.showSuccessToast(
                        'Knowledge created. Lixsa is learning, this process will take a few minutes...',
                    );
                }
            } catch (error) {
                console.error('Error uploading from url: ', error);
                this.isLoading = false;
                this.handleClose();
                this.showErrorToast('There was an error processing the document, try again later.');
            }
        },

        notifyParent() {
            this.$emit('fileUploaded');
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/md/_variables.scss';
.md-dialog .md-dialog-container {
    max-width: 768px;
}

.custom-button {
    color: $white-color !important;

    .custom-button-icon {
        color: $white-color !important;
    }
}

.modal-content {
    background: #fff;
    padding: 30px;
    text-align: center;
    /* max-width: 500px; */
    margin: 0 auto;
}

.modal-content h4 {
    text-align: left;
}

.modal-button {
    text-transform: none !important;
    height: 40px !important;
}

.or-separator {
    text-align: center;
    margin: 20px 0;
}

.dropzone {
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: border-color 0.3s;
    margin-bottom: 30px;
    width: 50vw;
    height: 25vh;
    border-radius: 12px;
}

.dropzone.over {
    border-color: #2196f3;
}

.dropzone.disabled {
    background-color: #f0f0f0;
    pointer-events: none;
    cursor: not-allowed;
    color: #ccc;
}

.dropzone.disabled span {
    color: #ccc;
    cursor: not-allowed;
    text-decoration: none !important;
}

.hidden {
    display: none;
}

.dropzone span {
    color: #2196f3;
    cursor: pointer;
    text-decoration: underline;
}

.custom-icon {
    margin: 10px;
    color: #ccc;
}

.separator-line {
    width: 22vw;
    height: 0;
    border: thin solid #f9f9f9;
    margin: 3px;
    display: inline-block;
}
</style>
