var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md-layout text-center"},[_c('div',{staticClass:"custom-login-panel md-layout md-layout-item md-size-70 md-xsmall-size-100"},[_vm._m(0),(_vm.error !== '')?_c('div',{staticClass:"md-layout-item md-size-100"},[_c('AuthErrorPanel',{attrs:{"error-message":_vm.error},on:{"close-panel":_vm.clearError}})],1):_vm._e(),_c('login-form',{attrs:{"loading":_vm.loading},on:{"perform-login":(args) => _vm.login(args)}})],1),_c('div',{staticClass:"md-layout-item md-size-100 md-xsmall-size-100"},[_c('p',{staticClass:"md-body-1"},[_c('router-link',{attrs:{"to":"/register"}},[_vm._v("CREATE AN ACCOUNT")])],1)]),_vm._m(1),_c('div',{staticClass:"md-layout-item md-size-70 md-xsmall-size-100"},[_c('div',{},[_c('login-social-button-google',{attrs:{"query-params":_vm.queryParams},on:{"onError":(socialButtonError) => {
                        _vm.handleError(socialButtonError);
                    }}}),_c('login-social-button-facebook',{attrs:{"query-params":_vm.queryParams},on:{"onError":(socialButtonError) => {
                        _vm.handleError(socialButtonError);
                    }}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md-layout-item md-size-100"},[_c('div',{staticClass:"md-display-1 login__title"},[_c('strong',[_vm._v(" Sign in ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md-layout-item md-size-70 md-xsmall-size-100"},[_c('div',{staticClass:"login__divider"})])
}]
render._withStripped = true
export { render, staticRenderFns }