<template>
    <div class="vbm">
        <div>
            <md-dialog
                class="vbm__dialog"
                :md-close-on-esc="false"
                :md-click-outside-to-close="false"
                :md-active.sync="showDialog"
            >
                <div v-if="this.currentStep === 1" ref="confettiContainer" class="vbm__confetti"></div>
                <md-dialog-content>
                    <div class="vbm--flex-container">
                        <div>
                            <div class="vbm__close-header">
                                <md-button @click="handleClose" class="md-icon-button"
                                    ><md-icon>close</md-icon></md-button
                                >
                            </div>

                            <div v-if="currentStep === 1" class="vbm__choose-business">
                                <div class="md-display-1">
                                    <strong>{{ $t('dashboard.verticalBusinessModal.welcome') }}</strong>
                                </div>
                                <div class="md-body-2">
                                    {{ $t('dashboard.verticalBusinessModal.chooseBusinessApp') }}
                                </div>
                                <p class="md-title">{{ $t('dashboard.verticalBusinessModal.ecommerce') }}</p>
                                <div class="md-layout">
                                    <div
                                        @click="selectCard('shopify')"
                                        class="md-layout-item md-size-50 md-small-size-100 md-large-size-100 vbm--no-padding-left vbm--no-padding-right"
                                    >
                                        <verticalCard
                                            class="vbm--no-horizontal-margin vbm__vertical-card"
                                            :class="{
                                                'vbm__vertical-card--selected': selectedCard === 'shopify',
                                            }"
                                            miniature="shopify_image"
                                            :title="$t('dashboard.verticalBusinessModal.shopify.title')"
                                            :description="$t('dashboard.verticalBusinessModal.shopify.description')"
                                        />
                                    </div>
                                    <!-- <div
                                        class="md-layout-item md-size-50 md-small-size-100 md-large-size-100 vbm--no-padding-left vbm--no-padding-right"
                                    >
                                        <verticalCard
                                            class="vbm--no-horizontal-margin vbm__vertical-card vbm__vertical-card--disabled"
                                            :class="{
                                                'vbm__vertical-card--selected': selectedCard === 'woocomerce',
                                            }"
                                            miniature="woocomerce_image"
                                            title="Woocomerce"
                                            description="WooCommerce is a customizable, open-source eCommerce platform built on WordPress."
                                            :comingSoon="true"
                                        />
                                    </div> -->
                                </div>
                                <p class="md-title">{{ $t('dashboard.verticalBusinessModal.others') }}</p>
                                <div class="md-layout">
                                    <div
                                        @click="selectCard('others')"
                                        class="md-layout-item md-size-50 md-small-size-100 md-large-size-100 vbm--no-padding-left vbm--no-padding-right"
                                    >
                                        <verticalCard
                                            class="vbm--no-horizontal-margin vbm__vertical-card"
                                            :class="{
                                                'vbm__vertical-card--selected': selectedCard === 'others',
                                            }"
                                            miniature="skill7"
                                            :title="$t('dashboard.verticalBusinessModal.other.title')"
                                            :description="$t('dashboard.verticalBusinessModal.other.description')"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div v-else-if="currentStep === 2" class="vbm__fill-form">
                                <div class="md-display-1">
                                    {{ $t('dashboard.verticalBusinessModal.quickSetup.title') }}
                                </div>
                                <div class="md-body-2">
                                    {{ $t('dashboard.verticalBusinessModal.quickSetup.description') }}
                                </div>

                                <md-field class="vbm__form-field">
                                    <label for="name">{{
                                        $t('dashboard.verticalBusinessModal.quickSetup.domainLabel')
                                    }}</label>
                                    <span class="md-prefix md-body-1">{{ prefix }}</span>
                                    <md-input v-model="shopifyDomain"></md-input>
                                    <span class="md-suffix md-body-1">{{ suffix }}</span>
                                </md-field>
                            </div>
                            <div v-else-if="currentStep === 3" class="vbm__loading-data">
                                <div class="md-display-1">
                                    {{ $t('dashboard.verticalBusinessModal.learningBusiness.title') }}
                                </div>
                                <div class="md-body-2">
                                    {{ $t('dashboard.verticalBusinessModal.learningBusiness.description') }}
                                </div>
                                <div class="vbm__ecommerce-training-card">
                                    <md-progress-spinner
                                        v-if="!ECOMMERCE_POLICIES_UPDATED"
                                        :md-diameter="16"
                                        :md-stroke="3"
                                        md-mode="indeterminate"
                                    ></md-progress-spinner>
                                    <md-icon v-else class="vbm__check-icon">check_circle_outline</md-icon>
                                    <div class="md-subheading">
                                        {{ $t('dashboard.verticalBusinessModal.learningBusiness.readingPolicies') }}
                                    </div>
                                </div>
                                <div class="vbm__ecommerce-training-card">
                                    <md-progress-spinner
                                        v-if="!ECOMMERCE_CATALOG_UPDATED"
                                        :md-diameter="15"
                                        :md-stroke="3"
                                        md-mode="indeterminate"
                                    ></md-progress-spinner>
                                    <md-icon v-else class="vbm__check-icon">check_circle_outline</md-icon>
                                    <div class="md-subheading">
                                        {{ $t('dashboard.verticalBusinessModal.learningBusiness.memorizingCatalog') }}
                                    </div>
                                </div>
                            </div>

                            <div v-else-if="currentStep === 4" class="vbm__fill-form">
                                <div class="md-display-1">
                                    {{ $t('dashboard.verticalBusinessModal.businessInfo.title') }}
                                </div>
                                <div class="md-body-2">
                                    {{ $t('dashboard.verticalBusinessModal.businessInfo.description') }}
                                </div>
                                <div class="md-layout company-info-form">
                                    <div class="md-layout-item md-small-size-100 md-size-100">
                                        <md-field class="custom-field">
                                            <label>{{
                                                $t(
                                                    'dashboard.verticalBusinessModal.businessInfo.fields.businessName.label',
                                                )
                                            }}</label>
                                            <md-input v-model="form.shop_name" type="text"></md-input>
                                            <span class="md-helper-text">{{
                                                $t(
                                                    'dashboard.verticalBusinessModal.businessInfo.fields.businessName.helper',
                                                )
                                            }}</span>
                                        </md-field>
                                    </div>
                                    <div class="md-layout-item md-small-size-100 md-size-100">
                                        <md-field class="custom-field">
                                            <label>{{
                                                $t(
                                                    'dashboard.verticalBusinessModal.businessInfo.fields.businessPhone.label',
                                                )
                                            }}</label>
                                            <md-input v-model="form.shop_phone" type="text"></md-input>
                                            <span class="md-helper-text">{{
                                                $t(
                                                    'dashboard.verticalBusinessModal.businessInfo.fields.businessPhone.helper',
                                                )
                                            }}</span>
                                        </md-field>
                                    </div>
                                    <div class="md-layout-item md-small-size-100 md-size-100">
                                        <currency-selector v-model="form.shop_currency" />
                                    </div>
                                    <div class="md-layout-item md-small-size-100 md-size-100">
                                        <md-field class="custom-field">
                                            <label>{{
                                                $t(
                                                    'dashboard.verticalBusinessModal.businessInfo.fields.businessEmail.label',
                                                )
                                            }}</label>
                                            <md-input v-model="form.customer_support_email" type="text"></md-input>
                                            <span class="md-helper-text">{{
                                                $t(
                                                    'dashboard.verticalBusinessModal.businessInfo.fields.businessEmail.helper',
                                                )
                                            }}</span>
                                        </md-field>
                                    </div>
                                    <div class="md-layout-item md-small-size-100 md-size-100">
                                        <md-field class="custom-field">
                                            <label>{{
                                                $t(
                                                    'dashboard.verticalBusinessModal.businessInfo.fields.businessWebsite.label',
                                                )
                                            }}</label>
                                            <md-input v-model="form.shop_public_domain" type="text"></md-input>
                                            <span class="md-helper-text">{{
                                                $t(
                                                    'dashboard.verticalBusinessModal.businessInfo.fields.businessWebsite.helper',
                                                )
                                            }}</span>
                                        </md-field>
                                    </div>
                                </div>
                            </div>

                            <div v-else-if="currentStep === 5" class="vbm__finish">
                                <img
                                    src="https://firebasestorage.googleapis.com/v0/b/lixsa-prod.appspot.com/o/public_images%2Fparty_Face.png?alt=media&token=c7f3aa54-0937-4634-bd1c-67aaf2ad5598"
                                    alt="party_Face"
                                />
                                <p class="md-display-1">{{ $t('dashboard.verticalBusinessModal.allDone.title') }}</p>
                                <p class="md-subheading">
                                    {{ $t('dashboard.verticalBusinessModal.allDone.description') }}
                                </p>
                                <md-button @click="handleClose" class="md-primary">{{
                                    $t('dashboard.verticalBusinessModal.buttons.letsStart')
                                }}</md-button>
                            </div>
                        </div>
                    </div>
                </md-dialog-content>
                <md-dialog-actions>
                    <div v-if="!isFirstOrLastStep" class="vbm__footer">
                        <md-button v-if="this.currentStep > 2 && this.currentStep !== 3" @click="goToPreviousStep">{{
                            $t('dashboard.verticalBusinessModal.buttons.previous')
                        }}</md-button>
                        <md-button
                            v-if="currentStep !== 2 && currentStep !== 3 && currentStep !== 4"
                            :disabled="selectedCard === ''"
                            @click="goToNextStep"
                            class="md-primary"
                            >{{ $t('dashboard.verticalBusinessModal.buttons.next') }}</md-button
                        >
                        <md-button
                            v-else-if="currentStep === 3"
                            :disabled="!businessSynced"
                            @click="goToNextStep"
                            class="md-primary"
                            >{{ $t('dashboard.verticalBusinessModal.buttons.finish') }}</md-button
                        >
                        <md-button
                            v-else-if="currentStep === 4"
                            :disabled="!form.shop_name || !form.customer_support_email"
                            @click="updateAccount"
                            class="md-primary"
                            >{{ $t('dashboard.verticalBusinessModal.buttons.saveCompanyInfo') }}</md-button
                        >
                        <md-button v-else>
                            <md-progress-spinner
                                :md-diameter="20"
                                :md-stroke="3"
                                md-mode="indeterminate"
                            ></md-progress-spinner>
                        </md-button>
                    </div>
                </md-dialog-actions>
            </md-dialog>
        </div>
    </div>
</template>
<script>
import verticalCard from '@/components/Dashboard/verticalBusinessModal/verticalCard/verticalCard.vue';
import { mapActions } from 'vuex';
import CurrencySelector from '@/components/Selectors/CurrencySelector.vue';

export default {
    name: 'verticalBusinessModal',
    components: {
        verticalCard,
        CurrencySelector,
    },
    data() {
        return {
            showDialog: true,
            isSelectingBusinessType: false,
            currentStep: 1,
            selectedCard: '',
            isLoading: false,
            ECOMMERCE_POLICIES_UPDATED: false,
            ECOMMERCE_CATALOG_UPDATED: false,
            shopifyDomain: '',
            prefix: 'https://',
            suffix: '.myshopify.com',
            form: {
                customer_support_email: '',
                shop_name: '',
                shop_phone: '',
                shop_public_domain: '',
                shop_currency: '',
            },
        };
    },
    computed: {
        canSaveQuickSetupForm() {
            return this.shopifyDomain;
        },
        isFirstOrLastStep() {
            return this.currentStep === 5;
        },
        businessSynced() {
            return this.ECOMMERCE_CATALOG_UPDATED && this.ECOMMERCE_POLICIES_UPDATED;
        },
    },
    watch: {
        async currentStep(newVal) {
            if (newVal === 2) {
                await this.subscribeToChannel();
            }
        },
    },
    methods: {
        ...mapActions('user', ['actionGetAccount', 'actionUpdateAccount']),
        ...mapActions('websocket', ['subscribe', 'unsubscribe']),

        async handleClose() {
            this.$confetti.stop();
            this.showDialog = false;
            if (this.currentStep > 2) {
                await this.actionGetAccount();
                this.$router.push('/training');
            }
        },

        selectCard(option) {
            this.selectedCard = option;
        },

        goToSelectBusinessType() {
            this.isSelectingBusinessType = true;
        },

        async updateEcommerceSettings() {
            const redirectUrl = `${process.env.VUE_APP_SHOPIFY_FUNCTIONS_URL}/api/shopify`;
            const clientId = process.env.VUE_APP_SHOPIFY_CLIENT_ID;
            const scopes = [
                'read_customer_events',
                'read_customers',
                'read_discounts',
                'read_draft_orders',
                'read_fulfillments',
                'read_gdpr_data_request',
                'read_inventory',
                'read_legal_policies',
                'read_locations',
                'read_marketing_events',
                'read_merchant_managed_fulfillment_orders',
                'read_metaobject_definitions',
                'read_metaobjects',
                'read_online_store_navigation',
                'read_online_store_pages',
                'read_order_edits',
                'read_orders',
                'read_payment_customizations',
                'read_payment_terms',
                'read_price_rules',
                'read_product_feeds',
                'read_product_listings',
                'read_products',
                'read_purchase_options',
                'read_returns',
                'read_shipping',
                'read_locales',
                'read_content',
                'read_third_party_fulfillment_orders',
                'read_custom_fulfillment_services',
                'read_customer_merge',
                'read_delivery_customizations',
                'read_fulfillment_constraint_rules',
                'read_themes',
                'write_themes',
                'write_customers',
                'write_fulfillments',
                'write_order_edits',
                'write_orders',
                'write_draft_orders',
            ];
            const scopesQuery = scopes.join('%2C');
            const installShopifyAppUrl = `https://admin.shopify.com/oauth/authorize?client_id=${clientId}&scope=${scopesQuery}&redirect_uri=${redirectUrl}`;

            window.location.href = installShopifyAppUrl;
        },

        goToNextStep() {
            if (this.currentStep < 5) {
                if (this.selectedCard === 'others') {
                    if (this.currentStep === 1) {
                        // this.updateAccountBusinessType();
                        this.goToBusinessCompanyForm();
                    } else if (this.currentStep === 4) {
                        this.updateAccount();
                    } else {
                        this.currentStep++;
                    }
                } else if (this.selectedCard === 'shopify') {
                    this.updateEcommerceSettings();
                } else {
                    this.currentStep++;
                }
            }
        },

        goToPreviousStep() {
            if (this.currentStep > 1) {
                if (this.currentStep === 2) {
                    this.unsubscribeFromChannel();
                }

                if (this.currentStep === 4) {
                    this.currentStep = 1;
                } else {
                    this.currentStep--;
                }
            }
        },

        goToFinalStep() {
            this.currentStep = 5;
        },

        goToBusinessCompanyForm() {
            this.currentStep = 4;
        },

        handleMessageOutput(message) {
            try {
                const parsedData = JSON.parse(message.data);
                if (message && parsedData.current_step) {
                    if (parsedData.current_step === 'ECOMMERCE_POLICIES_UPDATED') {
                        this.ECOMMERCE_POLICIES_UPDATED = true;
                    } else if (parsedData.current_step === 'ECOMMERCE_CATALOG_UPDATED') {
                        this.ECOMMERCE_CATALOG_UPDATED = true;
                    }
                } else {
                    console.log('no hay mensaje');
                }
            } catch (error) {
                console.error('Error processing messagge event:', error);
            }
        },

        subscribeToChannel() {
            const accountId = this.$store.getters['user/user'].account_id;
            const channelPath = `/account/${accountId}`;
            this.subscribe({
                topic: channelPath,
                callback: this.handleMessageOutput,
            });
        },

        unsubscribeFromChannel() {
            const accountId = this.$store.getters['user/user'].account_id;
            const channelPath = `/account/${accountId}`;
            this.unsubscribe({
                topic: channelPath,
                callback: this.handleMessageOutput,
            });
        },

        async updateAccount() {
            try {
                const reqData = {
                    business_type: 'OTHER',
                    account_id: null,
                    customer_support_email: this.form.customer_support_email,
                    shop_name: this.form.shop_name,
                    shop_phone: this.form.shop_phone,
                    shop_public_domain: this.form.shop_public_domain,
                    shop_currency: this.form.shop_currency,
                };
                const response = await this.actionUpdateAccount(reqData);
                if ((response && response.status === 200) || response.status === 201) {
                    this.showSuccessToast('Account updated successfully');
                    this.goToFinalStep();
                }
            } catch (err) {
                const errorMessage = err.response || 'An error occurred.';
                console.error('Error:', errorMessage);
                this.showErrorToast('Error updating account');
            }
        },

        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },

        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },
    },
    async mounted() {
        if (this.currentStep === 1) {
            let canvas = document.createElement('canvas');
            canvas.id = 'canvas-confetti';
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
            this.$refs.confettiContainer.appendChild(canvas);

            this.$confetti.start({
                canvasId: 'canvas-confetti',
                particles: [
                    {
                        type: 'rect',
                    },
                ],
            });

            try {
                setTimeout(() => {
                    this.$confetti.stop();
                    if (this.$refs.confettiContainer) {
                        this.$refs.confettiContainer.style.pointerEvents = 'none';
                    }
                }, 2000);

                setTimeout(() => {
                    if (this.$refs.confettiContainer) {
                        this.$refs.confettiContainer.removeChild(this.$refs.confettiContainer.firstChild);
                    }
                }, 5000);
            } catch (error) {
                console.error('Confetti already removed');
            }
        } else if (this.currentStep === 2 || this.currentStep === 3) {
            await this.subscribeToChannel();
        }
    },

    beforeUnmount() {
        this.$confetti.stop();
        if (this.currentStep === 2 || this.currentStep === 3) {
            this.unsubscribeFromChannel();
        }
    },
};
</script>

<style lang="scss" scoped>
.custom-field {
    margin-top: 20px;
    margin-bottom: 20px;
}

.company-info-form {
    // margin-top: 10px;
}
</style>
