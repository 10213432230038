import { mapActions } from 'vuex';
import messageMetadataInspector from '@/components/Chat/MessageMetadataInspector.vue';
import conversationsService from '@/services/conversations/conversationsService';

export default {
    data() {
        return {
            messageActions: [{ name: 'seeMetadata', title: 'See Metadata' }],
        };
    },

    methods: {
        ...mapActions('modalStore', ['openModal']),

        handleMessageActions({ action, message }) {
            switch (action.name) {
                case 'seeMetadata':
                    this.openMessageMetadataModal(message._id);
                    break;
                default:
                    // Do nothing
                    break;
            }
        },

        async openMessageMetadataModal(messageId) {
            try {
                const apiMessage = await conversationsService.getMessage(messageId);
                const roomMessage = this.messages.find((message) => message._id === messageId);

                const message = {
                    apiMessage: {
                        ...apiMessage.data,
                    },
                    roomMessage: {
                        ...roomMessage,
                    },
                };

                this.openModal({
                    component: messageMetadataInspector,
                    props: { message: message },
                });
            } catch (error) {
                console.error('Error opening message metadata modal', error);
            }
        },
    },
};
