import App from './App.vue';
import Vue from 'vue';
import VueRouter from 'vue-router';
import TheModalWindow from '@/components/TheModalWindow.vue';
import store from './store';

// Plugins
import DashboardPlugin from './material-dashboard';
import Toasted from 'vue-toasted';
import VueConfetti from 'vue-confetti';
import VueCarousel from 'vue-carousel';
import Chartist from 'chartist';
import Particles from '@tsparticles/vue2';
import { loadSlim } from '@tsparticles/slim';
import VuePrism from 'vue-prism';
import { AgGridVue } from 'ag-grid-vue'; // Vue Data Grid Component
import { clickOutside } from './directives/clickOutside';
import VueShepherd from 'vue-shepherd';
import { i18n } from './i18n';

// Styles
import 'prismjs/themes/prism.css';
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional Theme applied to the Data Grid
import 'shepherd.js/dist/css/shepherd.css';
import './assets/scss/shepherd-custom.scss';

// Router setup
import routes from './routes/routes';

import './registerServiceWorker';

// Stripe script
const stripeScript = document.createElement('script');
stripeScript.src = 'https://js.stripe.com/v3/pricing-table.js';
stripeScript.async = true;
document.head.appendChild(stripeScript);

// Tolt script
const toltScript = document.createElement('script');
toltScript.src = 'https://cdn.tolt.io/tolt.js';
toltScript.async = true;
toltScript.setAttribute('data-tolt', process.env.VUE_APP_TOLT_ID);
document.head.appendChild(toltScript);

// Clarity script with error handling
const clarityScript = document.createElement('script');
clarityScript.type = 'text/javascript';
clarityScript.onerror = (error) => {
    console.error('Failed to load Clarity script:', error);
};
clarityScript.innerHTML = `
    try {
        (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "oifgotxm83");
    } catch (error) {
        console.error('Error initializing Clarity:', error);
    }
`;
document.head.appendChild(clarityScript);

// Google Tag Manager script
const gtmScript = document.createElement('script');
gtmScript.innerHTML = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-N254BQLG');
`;
document.head.appendChild(gtmScript);

// Google Analytics script
const gtagScript = document.createElement('script');
gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-VVG1003KZN';
gtagScript.async = true;
document.head.appendChild(gtagScript);

const gtagConfigScript = document.createElement('script');
gtagConfigScript.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-VVG1003KZN', {
        debug_mode: ${process.env.VUE_APP_ENVIROMENT === 'local'},
        transport_url: 'https://www.googletagmanager.com'
    });
    window.GA_INITIALIZED = true;
`;
document.head.appendChild(gtagConfigScript);

// Add initialization check
const checkGAInitialized = setInterval(() => {
    if (window.gtag && window.GA_INITIALIZED) {
        clearInterval(checkGAInitialized);
    }
}, 1000);

setTimeout(() => {
    clearInterval(checkGAInitialized);
    if (!window.GA_INITIALIZED) {
        console.error('GA4 failed to initialize after 10 seconds');
    }
}, 10000);

// Use Vue plugins
Vue.use(VueRouter);
Vue.use(DashboardPlugin);
Vue.use(Toasted);
Vue.use(VueConfetti);
Vue.use(VueCarousel);
Vue.use(Particles, { init: async (engine) => await loadSlim(engine) });
Vue.use(VuePrism);
Vue.use(VueShepherd);

// Register global directive
Vue.directive('click-outside', clickOutside);

// Configure router
const router = new VueRouter({
    mode: 'history',
    routes, // short for routes: routes
    scrollBehavior: (to) => (to.hash ? { selector: to.hash } : { x: 0, y: 0 }),
    linkExactActiveClass: 'nav-item active',
});

// Helper functions
function redirectToDashboardIfNeeded(to, isAuthenticated) {
    const isPublicPath = ['/', '/register', '/invite', '/forgot-password'].includes(to.path);
    const shouldRedirect = isAuthenticated && isPublicPath && !to.query.logout;
    return shouldRedirect ? { name: 'Dashboard', query: to.query } : null;
}

function redirectToLoginIfNeeded(to, isAuthenticated) {
    if (to.matched.some((record) => record.meta.requiresAuth) && !isAuthenticated) {
        return { name: 'Login', query: to.query };
    }
    return null;
}

function checkRolePermission(to, isAuthenticated, userRole) {
    if (isAuthenticated && to.matched.some((record) => record.meta.requiresAuth)) {
        const requiredRoles = to.meta.permissions;
        const lacksRequiredRole = requiredRoles && !requiredRoles.includes(userRole);
        if (lacksRequiredRole) {
            return { name: 'Dashboard', query: to.query };
        }
    }
    return null;
}

async function syncMembership() {
    try {
        await store.dispatch('membership/getAccountMembership');
    } catch (error) {
        console.error('Error syncing membership: ', error);
    }
}

async function syncAccount() {
    try {
        await store.dispatch('user/actionGetAccount');
    } catch (error) {
        console.error('Error syncing account: ', error);
    }
}

async function syncUser() {
    try {
        await store.dispatch('user/actionGetUser');
    } catch (error) {
        console.error('Error syncing user: ', error);
    }
}

async function syncStripeCustomer() {
    if (!store.getters['user/isShopifyBillingClient']) {
        try {
            await store.dispatch('membership/fetchStripeSubscription', store.getters['membership/membershipId']);
        } catch (error) {
            console.error('Error syncing Stripe data:', error);
        }
    }
}

async function syncAgentSettings() {
    try {
        await store.dispatch('agentSettings/updateAgentSetting');
    } catch (error) {
        console.error('Error updating agent settings:', error);
    }
}

async function syncTags() {
    try {
        await store.dispatch('tags/fetchTags');
    } catch (error) {
        console.error('Error syncing tags:', error);
    }
}

// Main Navigation Guard
router.beforeEach(async (to, from, next) => {
    const isAuthenticated = store.getters['user/ifAuthenticated'];
    const userRole = store.state.user.user.permission;

    // Identify the user in Clarity with error handling
    if (store.getters['user/account'].uuid) {
        try {
            window.clarity('identify', store.getters['user/account'].uuid);
        } catch (error) {
            console.error('Error identifying user in Clarity:', error);
        }
    }

    // Redirect unauthenticated users to the login page for routes that require auth
    const loginRedirect = redirectToLoginIfNeeded(to, isAuthenticated);
    if (loginRedirect) return next(loginRedirect);

    // Redirect authenticated users from public paths to the dashboard
    const dashboardRedirect = redirectToDashboardIfNeeded(to, isAuthenticated);
    if (dashboardRedirect) {
        await syncAccount();
        await syncMembership();
        syncStripeCustomer();
        await syncAgentSettings();
        await syncTags();
        await syncUser();
        return next(dashboardRedirect);
    }

    // Check if the authenticated user has the required role for the route
    const rolePermissionRedirect = checkRolePermission(to, isAuthenticated, userRole);
    if (rolePermissionRedirect) {
        await syncAccount();
        await syncMembership();
        syncStripeCustomer();
        await syncAgentSettings();
        await syncTags();
        await syncUser();
        return next(rolePermissionRedirect);
    }

    // Proceed for all other cases
    const isPublicPath = ['/', '/register', '/invite', '/forgot-password'].includes(to.path);
    if (!isPublicPath) {
        await syncAccount();
        await syncMembership();
        syncStripeCustomer();
        await syncAgentSettings();
        await syncTags();
        await syncUser();
    }
    next();
});

// After each navigation, update document title
router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = to.meta.title ? `Lixsa | ${to.meta.title}` : 'Lixsa | Your Dream Team, Powered by AI';
    });
});

// Global library setup
Vue.prototype.$Chartist = Chartist;

// Register global components
Vue.component('TheModalWindow', TheModalWindow);
Vue.component('AgGridVue', AgGridVue);

// Initialize language before Vue instance creation
store.dispatch('user/initializeLanguage');

// Initialize Vue instance
new Vue({
    el: '#app',
    render: (h) => h(App),
    store,
    router,
    i18n,
    created() {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker
                .register('/firebase-messaging-sw.js')
                .then((registration) => {
                    console.log('Service Worker registered with scope:', registration.scope);
                })
                .catch((error) => {
                    console.error('Service Worker registration failed:', error);
                });
        }

        this.$store.dispatch('websocket/connectSocket').then(() => {
            if (this.$store.getters['user/ifAuthenticated']) {
                this.$store.dispatch('membership/subscribeToMembershipUpdates');
                this.$store.dispatch('agentSettings/subscribeToAgentSettingsUpdates');
                this.$store.dispatch('tags/subscribeToTagUpdates');
            }
        });

        try {
            window.clarity('consent');
        } catch (error) {
            console.error('Error calling Clarity consent:', error);
        }
    },
});
