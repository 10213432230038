import BaseApiRepository from '../_common/BaseApiRepository';
// eslint-disable-next-line no-unused-vars
import TemplateRequestDto from './Dto/TemplateRequestDto';
import TemplateVariablesDto from './Dto/TemplateVariablesDto';
import TemplateDto from './Dto/TemplateDto';
import PageTemplateDto from './Dto/PageTemplateDto';
import TemplateVariablesResponseDto from './Dto/TemplateVariablesResponseDto';

import CheckoutSchedulerDto from './Dto/CheckoutSchedulerDto';

class TemplatesApiRepository extends BaseApiRepository {
    /**
     * Get template details
     * @param {string} templateId - Template ID
     * @returns {Promise<Template>}
     */
    async getTemplate(templateId) {
        const response = await this.apiRequest('GET', `template/${templateId}`);
        return new TemplateDto.Builder()
            .withUuid(response.data.uuid)
            .withAccountId(response.data.account_id)
            .withBody(response.data.body)
            .withHeader(response.data.header)
            .withChannelType(response.data.channel_type)
            .withTemplateName(response.data.template_name)
            .withLanguage(response.data.language)
            .withCategory(response.data.category)
            .withStatus(response.data.status)
            .withScore(response.data.score)
            .withType(response.data.type)
            .withTemplateType(response.data.template_type)
            .withFlowType(response.data.flow_type)
            .withSkillId(response.data.skill_id)
            .withInternalTemplateId(response.data.internal_template_id)
            .withTemplateParameters(response.data.template_parameters)
            .withExtraParameters(response.data.extra_parameters)
            .withIssues(response.data.issues)
            .withModifyBy(response.data.modify_by)
            .withDateCreated(response.data.date_created)
            .withVariables(response.data.variables)
            .build();
    }

    /**
     * Update template
     * @param {string} templateId - Template ID
     * @param {TemplateRequestDto} request - Template request data
     * @returns {Promise<Object>}
     */
    async updateTemplate(templateId, request) {
        const response = await this.apiRequest('PUT', `template/${templateId}`, request.toJSON());
        return response;
    }

    /**
     * Delete template
     * @param {string} templateId - Template ID
     * @param {TemplateRequestDto} request - Template request data
     * @returns {Promise<Object>}
     */
    async deleteTemplate(templateId, request) {
        const response = await this.apiRequest('DELETE', `template/${templateId}`, request.toJSON());
        return response;
    }

    /**
     * Get templates list
     * @param {string} flowType - Flow type
     * @param {number} page - Page number
     * @param {number} size - Page size
     * @returns {Promise<PageTemplateDto>}
     */
    async getTemplates(flowType, page = 0, size = 20) {
        const response = await this.apiRequest('GET', 'template', null, {
            flowType,
            page,
            size,
        });
        return new PageTemplateDto.Builder()
            .withTotalElements(response.data.totalElements)
            .withTotalPages(response.data.totalPages)
            .withFirst(response.data.first)
            .withLast(response.data.last)
            .withNumberOfElements(response.data.numberOfElements)
            .withSize(response.data.size)
            .withContent(response.data.content)
            .withNumber(response.data.number)
            .withEmpty(response.data.empty)
            .build();
    }

    /**
     * Create template
     * @param {TemplateRequestDto} request - Template request data
     * @returns {Promise<Object>}
     */
    async createTemplate(request) {
        const response = await this.apiRequest('POST', 'template', request);
        return response;
    }

    /**
     * Get template variables
     * @param {string} skillId - Skill ID
     * @param {string} flowType - Flow type
     * @param {string} language - Language code
     * @returns {Promise<TemplateVariablesResponseDto>}
     */
    async getTemplateVariables(skillId, flowType, language) {
        const response = await this.apiRequest('GET', 'template-variables', null, {
            skillId,
            flowType,
            language,
        });
        return new TemplateVariablesResponseDto.Builder()
            .withUuid(response.data.uuid)
            .withTemplateType(response.data.template_type)
            .withFlowType(response.data.flow_type)
            .withSkillId(response.data.skill_id)
            .withVariables(response.data.variables)
            .withContentExample(response.data.content_example)
            .withLanguage(response.data.language)
            .withDateCreated(response.data.date_created)
            .build();
    }

    /**
     * Create template variables
     * @param {TemplateVariablesDto} request - Template variables request data
     * @returns {Promise<TemplateVariablesDto>}
     */
    async createTemplateVariables(request) {
        const response = await this.apiRequest('POST', 'template-variables', request.toJSON());
        return new TemplateVariablesDto.Builder()
            .withUuid(response.data.uuid)
            .withSkillId(response.data.skill_id)
            .withTemplateType(response.data.template_type)
            .withFlowType(response.data.flow_type)
            .withVariables(response.data.variables)
            .withLanguage(response.data.language)
            .withContentExample(response.data.content_example)
            .build();
    }

    /**
     * Get available languages
     * @returns {Promise<Object>}
     */
    async getAvailableLanguages() {
        const response = await this.apiRequest('GET', 'languages');
        const languages = response.data.map((language) => ({
            tag: language.tag,
            name: language.name,
        }));
        return languages;
    }

    /**
     * Create checkout scheduler
     * @param {string} skillId - Skill ID
     * @param {CheckoutSchedulerDto} request - Checkout scheduler request data
     * @returns {Promise<Object>}
     */
    async createCheckoutScheduler(skillId, request) {
        const response = await this.apiRequest('POST', `skill/${skillId}/checkout-scheduler`, request);
        return response;
    }

    /**
     * Get checkout scheduler
     * @param {string} skillId - Skill ID
     * @returns {Promise<CheckoutSchedulerDto>}
     */
    async getCheckoutScheduler(skillId) {
        const response = await this.apiRequest('GET', `skill/${skillId}/checkout-scheduler`);
        return new CheckoutSchedulerDto.Builder()
            .withTtlMinutes(response.data.ttl_minutes)
            .build();
    }
}

export default new TemplatesApiRepository();
