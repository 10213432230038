export default [
    {
        id: 'agent-basic-info-card-step',
        attachTo: { element: '#agent-basic-info-card', on: 'top' },
        text: `
            <div>
                <p class="md-body-2">\${tours.agent.basicInfo.p1}</p>
            </div>
        `,
        buttons: [
            {
                text: 'tours.agent.buttons.skipTour',
                action: 'cancel',
                secondary: true,
            },
            {
                text: 'tours.agent.buttons.gotIt',
                action: 'next',
            },
        ],
    },
    {
        id: 'agent-final-step',
        text: `
            <div>
                <p class="md-body-2">\${tours.agent.finalStep.p1}</p>
                <p class="md-body-2">\${tours.agent.finalStep.p2}</p>
            </div>
        `,
        buttons: [
            {
                text: 'tours.agent.buttons.goToPlayground',
                action: 'next',
            },
        ],
    },
];
