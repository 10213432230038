import Vue from 'vue';
import webSocketService from '@/services/webSocket/webSocketService';

const state = () => ({
    subscriptions: {},
    connected: false,
});

const getters = {
    isConnected: (state) => state.connected,
};

const mutations = {
    SET_CONNECTED(state, connected) {
        state.connected = connected;
    },
    ADD_SUBSCRIPTION(state, { topic, callback }) {
        if (!state.subscriptions[topic]) {
            Vue.set(state.subscriptions, topic, []);
        }
        state.subscriptions[topic].push(callback);
    },
    REMOVE_SUBSCRIPTION(state, { topic, callback }) {
        if (state.subscriptions[topic]) {
            state.subscriptions[topic] = state.subscriptions[topic].filter((cb) => cb !== callback);
            if (state.subscriptions[topic].length === 0) {
                Vue.delete(state.subscriptions, topic);
            }
        }
    },
    CLEAR_ALL_SUBSCRIPTIONS(state) {
        state.subscriptions = {};
    },
};

const actions = {
    async connectSocket({ commit, dispatch }) {
        webSocketService.setConnectionChangeHandler((connected) => {
            commit('SET_CONNECTED', connected);
        });

        await dispatch(
            'user/apiCall',
            async (token) => {
                await webSocketService.connect(token);
            },
            { root: true },
        );
    },

    async subscribe({ dispatch, commit }, { topic, callback }) {
        if (!webSocketService.isConnected()) {
            await dispatch('connectSocket');
        }
        await webSocketService.subscribe(topic, callback);
        commit('ADD_SUBSCRIPTION', { topic, callback });
    },

    unsubscribe({ commit }, { topic, callback }) {
        webSocketService.unsubscribe(topic, callback);
        commit('REMOVE_SUBSCRIPTION', { topic, callback });
    },

    unsubscribeFromAll({ commit }) {
        webSocketService.unsubscribeAll();
        commit('CLEAR_ALL_SUBSCRIPTIONS');
    },

    disconnectSocket({ commit }) {
        webSocketService.disconnect();
        commit('CLEAR_ALL_SUBSCRIPTIONS');
        commit('SET_CONNECTED', false);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};
