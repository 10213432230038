<template>
    <div class="tag-selector" v-click-outside="closeDropdown">
        <div
            id="tag-selector-display"
            class="tag-selector__display md-field"
            @click="toggleDropdown"
            :class="{ 'is-focused': isOpen }"
        >
            <div class="tag-selector__input-content">
                <span v-if="selectedTagNames.length" class="tag-selector__selected md-body-1">
                    {{ selectedTagNames.join(', ') }}
                </span>
                <span v-else class="tag-selector__placeholder">Select tags...</span>
            </div>
            <svg-icon name="chevron" class="tag-selector__arrow" :class="{ 'tag-selector__arrow--open': isOpen }" />
        </div>

        <div
            v-if="isOpen"
            class="tag-selector__dropdown"
            :class="{ 'tag-selector__dropdown--scrollable': filteredTags.length > 3 }"
        >
            <div class="tag-selector__search">
                <md-field md-clearable>
                    <md-input v-model="searchQuery" placeholder="Search..." @click.stop />
                </md-field>
            </div>
            <div v-if="loading" class="tag-selector__loading">Loading...</div>
            <template v-else>
                <div class="tag-selector__options">
                    <div v-for="tag in filteredTags" :key="tag.uuid" class="tag-selector__option">
                        <div class="tag-selector__option-content" @click="toggleTag(tag)">
                            <div class="tag-selector__checkbox">
                                <input type="checkbox" :checked="isTagSelected(tag.uuid)" />
                            </div>
                            <div class="tag-selector__color-dot" :style="{ backgroundColor: tag.color }"></div>
                            <span class="md-body-1 tag-selector__tag-name" :title="tag.name">{{ tag.name }}</span>
                        </div>

                        <md-menu md-size="medium" md-align-trigger class="tag-selector__menu">
                            <md-button class="md-just-icon md-simple md-gray" md-menu-trigger>
                                <!-- <i class="material-symbols-outlined"> more_vert </i> -->
                                <svg-icon name="more_vert" class="tag-selector__option-icon" />
                            </md-button>

                            <md-menu-content>
                                <md-menu-item @click="handleDeleteTag(tag)">
                                    <div class="tag-selector__menu-item">
                                        <!-- <i class="material-symbols-outlined tag-selector__menu-item-icon"> delete </i> -->
                                        <svg-icon name="delete" class="tag-selector__menu-item-icon" />
                                        <span class="md-body-1 tag-selector__menu-item-text">Delete</span>
                                    </div>
                                </md-menu-item>
                            </md-menu-content>
                        </md-menu>
                    </div>

                    <div
                        v-if="shouldShowCreateOption"
                        class="tag-selector__option tag-selector__option--create"
                        @click="createNewTag"
                    >
                        <span class="md-caption"> + Create tag "{{ searchQuery }}" </span>
                    </div>

                    <div v-if="filteredTags.length === 0 && !searchQuery" class="tag-selector__no-results">
                        No tags found
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import TagRequestDto from '@/services/conversations/Dto/TagRequestDto';
import SvgIcon from '@/components/Chat/SvgIcon/SvgIcon.vue';
import { mapActions, mapGetters } from 'vuex';
import tagsApiRepository from '@/services/conversations/TagsApiRepository';

export default {
    name: 'TagSelector',

    components: {
        SvgIcon,
    },

    props: {
        preselectedTags: {
            type: Array,
            default: () => [],
        },
        label: {
            type: String,
            default: 'Include Tags',
        },
    },

    data() {
        return {
            isOpen: false,
            selectedTags: new Set(this.preselectedTags),
            searchQuery: '',
        };
    },

    computed: {
        ...mapGetters('tags', ['allTags', 'isLoading']),

        loading() {
            return this.isLoading;
        },

        selectedTagNames() {
            return Array.from(this.selectedTags)
                .map((uuid) => this.allTags.find((tag) => tag.uuid === uuid)?.name)
                .filter(Boolean);
        },

        filteredTags() {
            if (!this.searchQuery) return this.allTags;
            const query = this.searchQuery.toLowerCase();
            return this.allTags.filter((tag) => tag.name.toLowerCase().includes(query));
        },

        shouldShowCreateOption() {
            return (
                this.searchQuery &&
                this.searchQuery.length >= 2 &&
                !this.filteredTags.some((tag) => tag.name.toLowerCase() === this.searchQuery.toLowerCase())
            );
        },
    },

    watch: {
        preselectedTags: {
            handler(newTags) {
                this.selectedTags = new Set(newTags);
            },
            immediate: true,
        },
    },

    methods: {
        ...mapActions('tags', ['createTag', 'deleteTag']),

        toggleDropdown() {
            this.isOpen = !this.isOpen;
            this.$emit('dropdown-toggle', this.isOpen);
        },

        closeDropdown() {
            this.isOpen = false;
            this.$emit('dropdown-toggle', false);
        },

        toggleTag(tag) {
            const newSelection = new Set(this.selectedTags);
            if (newSelection.has(tag.uuid)) {
                newSelection.delete(tag.uuid);
            } else {
                newSelection.add(tag.uuid);
            }
            this.selectedTags = newSelection;
            this.$emit('update:selected', Array.from(newSelection));
        },

        isTagSelected(uuid) {
            return this.selectedTags.has(uuid);
        },

        async createNewTag() {
            try {
                const newTag = new TagRequestDto.Builder()
                    .withUuid(uuidv4())
                    .withName(this.searchQuery)
                    .withAccountId(this.$store.state.user.user.account_id)
                    .build();

                const createdTag = await this.createTag(newTag);
                this.toggleTag(createdTag);
                this.searchQuery = ''; // Clear search after creating
            } catch (error) {
                console.error('Error creating new tag:', error);
                this.$toasted.error('Failed to create tag. Please try again.', {
                    position: 'bottom-center',
                    duration: 3000,
                });
            }
        },

        async handleDeleteTag(tag) {
            try {
                await this.deleteTag(tag.uuid);
                this.$toasted.success('Tag deleted successfully', {
                    position: 'bottom-center',
                    duration: 3000,
                });
                // Remove from selected if it was selected
                if (this.selectedTags.has(tag.uuid)) {
                    const newSelection = new Set(this.selectedTags);
                    newSelection.delete(tag.uuid);
                    this.selectedTags = newSelection;
                    this.$emit('update:selected', Array.from(newSelection));
                }
            } catch (error) {
                console.error('Error deleting tag:', error);
                this.$toasted.error('Failed to delete tag. Please try again.', {
                    position: 'bottom-center',
                    duration: 3000,
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.tag-selector {
    position: relative;
    width: 100%;
    padding-bottom: 4px;

    &__label {
        display: block;
        color: #aaaaaa !important;
        font-size: 11px !important;
        font-weight: 400;
        transition: color 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    }

    &__display {
        min-height: 32px;
        padding: 4px 0;
        position: relative;
        cursor: pointer;
        background: transparent;
        transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        border-bottom: 1px solid #d2d2d2;
        display: flex;
        align-items: center;
        margin-top: 0;
        padding-top: 0;

        &::after {
            position: absolute;
            bottom: -1px;
            right: 0;
            left: 0;
            height: 2px;
            content: '';
            transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
            transform: scaleX(0);
            background-color: #4a24e1 !important;
        }

        &.is-focused {
            border-color: #4a24e1;
            &::after {
                transform: scaleX(1);
            }
        }
    }

    &__input-content {
        padding: 0 12px 0 8px;
        min-height: 32px;
        display: flex;
        align-items: center;
        flex: 1;
    }

    &__placeholder {
        color: rgba(0, 0, 0, 0.6);
        font-size: 14px;
        transition: color 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    }

    &__selected {
        color: rgba(0, 0, 0, 0.87);
        font-size: 14px;
    }

    &__icon {
        width: 15px;
        height: 15px;
        fill: rgba(0, 0, 0, 0.87);
        // margin-left: 12px;
    }

    &__arrow {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        fill: #808080;
        transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

        &--open {
            transform: translateY(-50%) rotate(180deg);
        }
    }

    &__dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        margin-top: 4px;
        background: white;
        border: 1px solid #ddd;
        border-radius: 12px;
        z-index: 111;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);

        &--scrollable {
            .tag-selector__options {
                max-height: 144px;
                overflow-y: auto;

                &::-webkit-scrollbar {
                    width: 8px;
                }

                &::-webkit-scrollbar-track {
                    background: #f1f1f1;
                    border-radius: 4px;
                }

                &::-webkit-scrollbar-thumb {
                    background: #888;
                    border-radius: 4px;

                    &:hover {
                        background: #666;
                    }
                }
            }
        }
    }

    &__options {
        display: flex;
        flex-direction: column;
    }

    &__option {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 38px;
        padding: 0 12px;
        cursor: pointer;

        &:hover {
            background: #f5f5f5;
        }

        &--create {
            color: var(--md-theme-default-primary, #1976d2);
            font-weight: 500;

            &:hover {
                background: rgba(25, 118, 210, 0.04);
            }
        }
    }

    &__option-content {
        display: flex;
        align-items: center;
        gap: 8px;
        flex: 1;
        padding: 8px 0;
    }

    &__checkbox {
        display: flex;
        align-items: center;
    }

    &__color-dot {
        width: 12px;
        height: 12px;
        border-radius: 50%;
    }

    &__loading {
        padding: 12px;
        text-align: center;
        color: #666;
    }

    &__search {
        padding: 0 12px;
        // border-bottom: 1px solid #eee;
    }

    &__search-input {
        width: 100%;
        height: 32px;
        padding: 0 8px;
        border: 1px solid #ddd;
        border-radius: 4px;
        font-size: 14px;
        outline: none;
        transition: border-color 0.3s ease;

        &:focus {
            border-color: var(--md-theme-default-primary, #4a24e1);
        }

        &::placeholder {
            color: rgba(0, 0, 0, 0.38);
        }
    }

    &__no-results {
        padding: 12px;
        text-align: center;
        color: rgba(0, 0, 0, 0.54);
        font-size: 14px;
    }

    &__create-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        color: var(--md-theme-default-primary, #4a24e1);
    }

    &__tag-name {
        max-width: 99px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
    }
}

.tag-selector__menu {
    opacity: 0;
    transition: opacity 0.2s ease;

    .md-button {
        margin: 0;
    }
}

.tag-selector__menu-item {
    display: flex;
    align-items: center;
}

.tag-selector__menu-item-icon {
    font-size: 18px;
    width: 18px;
    height: 18px;
}

.tag-selector__option-icon {
    width: 23px;
    height: 23px;
}

.tag-selector__menu-item-text {
    padding-left: 8px !important;
}

.tag-selector__option:hover .tag-selector__menu {
    opacity: 1;
}

.md-menu-content {
    z-index: 112 !important;
}

.md-menu-item:hover {
    .tag-selector__menu-item-icon {
        fill: white;
    }
}
</style>
