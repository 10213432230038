<!-- eslint-disable prettier/prettier -->
<template>
    <div :id="message._id" ref="message" class="vac-message-wrapper">
        <div v-if="showDate" class="vac-card-info vac-card-date">
            {{ message.date }}
        </div>

        <div v-if="newMessage._id === message._id" class="vac-line-new">
            {{ textMessages.NEW_MESSAGES }}
        </div>

        <div v-if="message.system" class="vac-card-info vac-card-system">
            <slot :name="'message_' + message._id">
                <format-message
                    :message-id="message._id"
                    :content="message.content"
                    :deleted="!!message.deleted"
                    :users="roomUsers"
                    :text-messages="textMessages"
                    :text-formatting="textFormatting"
                    :link-options="linkOptions"
                    @open-user-tag="openUserTag"
                >
                    <template v-for="(idx, name) in $slots" #[name]="data">
                        <slot :name="name" v-bind="data" />
                    </template>
                </format-message>
            </slot>
        </div>

        <div
            v-else
            class="vac-message-box"
            :class="{ 'vac-offset-current': message.senderId === currentUserId }"
            @click="selectMessage"
        >
            <slot :name="'message_' + message._id">
                <slot v-if="message.senderId !== currentUserId" :name="'message-avatar_' + message._id">
                    <div
                        v-if="message.avatar"
                        class="vac-avatar"
                        :style="{ 'background-image': `url('${message.avatar}')` }"
                    />
                </slot>
                <div v-if="hasSenderUserAvatar && !message.avatar" class="vac-avatar-offset" />
                <div
                    class="vac-message-container"
                    :class="{
                        'vac-message-container-offset': messageOffset,
                    }"
                >
                    <div
                        class="vac-message-card"
                        :class="{
                            'vac-message-highlight': isMessageHover,
                            'vac-message-current': message.senderId === currentUserId,
                            'vac-message-deleted': message.deleted,
                            'vac-item-clickable': messageSelectionEnabled,
                            'vac-message-selected': isMessageSelected,
                        }"
                        @mouseover="onHoverMessage"
                        @mouseleave="onLeaveMessage"
                    >
                        <div
                            v-if="showUsername"
                            class="vac-text-username"
                            :class="{
                                'vac-username-reply': !message.deleted && message.replyMessage,
                            }"
                        >
                            <span>{{ message.username }}</span>
                        </div>

                        <message-reply
                            v-if="!message.deleted && message.replyMessage"
                            :message="message"
                            :room-users="roomUsers"
                            :text-formatting="textFormatting"
                            :link-options="linkOptions"
                        >
                            <template v-for="(i, name) in $slots" #[name]="data">
                                <slot :name="name" v-bind="data" />
                            </template>
                        </message-reply>

                        <format-message
                            v-if="!!message.deleted || !message.files || !message.files.length"
                            :message-id="message._id"
                            :content="message.content"
                            :deleted="!!message.deleted"
                            :users="roomUsers"
                            :text-formatting="textFormatting"
                            :text-messages="textMessages"
                            :link-options="linkOptions"
                            @open-user-tag="openUserTag"
                        >
                            <template v-for="(idx, name) in $slots" #[name]="data">
                                <slot :name="name" v-bind="data" />
                            </template>
                        </format-message>

                        <message-files
                            v-else-if="!isAudio || message.files.length > 1"
                            :current-user-id="currentUserId"
                            :message="message"
                            :room-users="roomUsers"
                            :text-formatting="textFormatting"
                            :link-options="linkOptions"
                            :message-selection-enabled="messageSelectionEnabled"
                            @open-file="openFile"
                            @open-user-tag="openUserTag"
                        >
                            <template v-for="(i, name) in $slots" #[name]="data">
                                <slot :name="name" v-bind="data" />
                            </template>
                        </message-files>

                        <template v-else>
                            <audio-player
                                :message-id="message._id"
                                :src="message.files[0].url"
                                :message-selection-enabled="messageSelectionEnabled"
                                @update-progress-time="progressTime = $event"
                                @hover-audio-progress="hoverAudioProgress = $event"
                            >
                                <template v-for="(i, name) in $slots" #[name]="data">
                                    <slot :name="name" v-bind="data" />
                                </template>
                            </audio-player>

                            <div v-if="!message.deleted" class="vac-progress-time">
                                {{ progressTime }}
                            </div>
                        </template>

                        <div class="vac-text-timestamp">
                            <div v-if="message.edited && !message.deleted" class="vac-icon-edited">
                                <slot :name="'pencil-icon_' + message._id">
                                    <svg-icon name="pencil" />
                                </slot>
                            </div>
                            <span>{{ message.timestamp }}</span>
                            <span v-if="isCheckmarkVisible">
                                <slot :name="'checkmark-icon_' + message._id">
                                    <svg-icon
                                        :name="message.distributed ? 'double-checkmark' : 'checkmark'"
                                        :param="message.seen ? 'seen' : ''"
                                        class="vac-icon-check"
                                    />
                                </slot>
                            </span>
                        </div>

                        <message-actions
                            :current-user-id="currentUserId"
                            :message="message"
                            :message-actions="messageActions"
                            :show-reaction-emojis="showReactionEmojis"
                            :message-hover="messageHover"
                            :hover-message-id="hoverMessageId"
                            :hover-audio-progress="hoverAudioProgress"
                            :emoji-data-source="emojiDataSource"
                            @update-message-hover="messageHover = $event"
                            @update-options-opened="optionsOpened = $event"
                            @update-emoji-opened="emojiOpened = $event"
                            @message-action-handler="messageActionHandler"
                            @send-message-reaction="sendMessageReaction"
                        >
                            <template v-for="(i, name) in $slots" #[name]="data">
                                <slot :name="name" v-bind="data" />
                            </template>
                        </message-actions>
                    </div>

                    <message-reactions
                        :current-user-id="currentUserId"
                        :message="message"
                        @send-message-reaction="sendMessageReaction"
                    />
                </div>
                <slot :name="'message-failure_' + message._id">
                    <div
                        v-if="message.failure && message.senderId === currentUserId"
                        class="vac-failure-container vac-svg-button"
                        :class="{
                            'vac-failure-container-avatar': message.avatar && message.senderId === currentUserId,
                        }"
                        @click="$emit('open-failed-message', { message })"
                    >
                        <svg-icon name="error" />
                        <md-tooltip md-direction="bottom">
                            <p class="md-caption">{{ message.failure_reason || 'Failed message' }}</p>
                        </md-tooltip>
                    </div>
                </slot>
                <slot v-if="message.senderId === currentUserId" :name="'message-avatar_' + message._id">
                    <md-avatar
                        v-if="message.avatar"
                        class="vac-avatar vac-avatar-current md-avatar-icon md-small"
                        :title="message.username"
                    >
                        <img :src="message.avatar" :alt="message.username" />
                    </md-avatar>

                    <md-avatar
                        v-else
                        class="vac-avatar vac-avatar-current md-avatar-icon md-small"
                        :title="message.username"
                    >
                        <span class="vac-avatar-current-letter">{{ usernameFirstLetter }}</span>
                    </md-avatar>
                </slot>
                <!-- <div v-if="hasCurrentUserAvatar && !message.avatar" class="vac-avatar-current-offset" /> -->
            </slot>
        </div>
        <div
            v-if="message.username === 'Lixsa' && message.senderId === currentUserId"
            class="custom-fine-tuning-buttons"
        >
            <div
                @click="$emit('fineTuning', 'like')"
                class="fine-tuning-icon"
                :class="[haveScenario(message, 'LIKE'), { disabled: message.valoration === 'LIKE' ? true : false }]"
            >
                <svg-icon name="thumb_up_off_alt" />
            </div>
            <div
                @click="$emit('fineTuning', 'dislike')"
                class="fine-tuning-icon"
                :class="[
                    haveScenario(message, 'DISLIKE'),
                    { disabled: message.valoration === 'DISLIKE' ? true : false },
                ]"
            >
                <svg-icon name="thumb_down_off_alt" />
            </div>
        </div>
    </div>
</template>

<script>
import SvgIcon from '@/components/Chat/SvgIcon/SvgIcon.vue';
import FormatMessage from '@/components/Chat/FormatMessage/FormatMessage.vue';

import MessageReply from './MessageReply/MessageReply.vue';
import MessageFiles from './MessageFiles/MessageFiles.vue';
import MessageActions from './MessageActions/MessageActions.vue';
import MessageReactions from './MessageReactions/MessageReactions.vue';
import AudioPlayer from './AudioPlayer/AudioPlayer.vue';

import { messagesValidation } from '@/utils/data-validation';
import { isAudioFile } from '@/utils/media-file';

export default {
    name: 'RoomMessage',
    components: {
        SvgIcon,
        FormatMessage,
        AudioPlayer,
        MessageReply,
        MessageFiles,
        MessageActions,
        MessageReactions,
    },

    props: {
        currentUserId: { type: [String, Number], required: true },
        textMessages: { type: Object, required: true },
        index: { type: Number, required: true },
        message: { type: Object, required: true },
        messages: { type: Array, required: true },
        editedMessageId: { type: [String, Number], default: null },
        roomUsers: { type: Array, default: () => [] },
        messageActions: { type: Array, required: true },
        newMessages: { type: Array, default: () => [] },
        showReactionEmojis: { type: Boolean, required: true },
        showNewMessagesDivider: { type: Boolean, required: true },
        textFormatting: { type: Object, required: true },
        linkOptions: { type: Object, required: true },
        usernameOptions: { type: Object, required: true },
        messageSelectionEnabled: { type: Boolean, required: true },
        selectedMessages: { type: Array, default: () => [] },
        emojiDataSource: { type: String, default: undefined },
    },

    emits: [
        'message-added',
        'open-file',
        'open-user-tag',
        'open-failed-message',
        'message-action-handler',
        'send-message-reaction',
        'select-message',
        'unselect-message',
    ],

    data() {
        return {
            hoverMessageId: null,
            messageHover: false,
            optionsOpened: false,
            emojiOpened: false,
            newMessage: {},
            progressTime: '- : -',
            hoverAudioProgress: false,
        };
    },

    computed: {
        usernameFirstLetter() {
            return this.message.username ? this.message.username.charAt(0).toUpperCase() : 'L';
        },
        showUsername() {
            if (!this.usernameOptions.currentUser && this.message.senderId === this.currentUserId) {
                return false;
            } else {
                return this.roomUsers.length >= this.usernameOptions.minUsers;
            }
        },
        showDate() {
            return this.index > 0 && this.message.date !== this.messages[this.index - 1].date;
        },
        messageOffset() {
            return this.index > 0 && this.message.senderId !== this.messages[this.index - 1].senderId;
        },
        isMessageHover() {
            return this.editedMessageId === this.message._id || this.hoverMessageId === this.message._id;
        },
        isAudio() {
            return this.message.files?.some((file) => isAudioFile(file));
        },
        isCheckmarkVisible() {
            return (
                this.message.senderId === this.currentUserId &&
                !this.message.deleted &&
                (this.message.saved || this.message.distributed || this.message.seen)
            );
        },
        hasCurrentUserAvatar() {
            return this.messages.some((message) => message.senderId === this.currentUserId && message.avatar);
        },
        hasSenderUserAvatar() {
            return this.messages.some((message) => message.senderId !== this.currentUserId && message.avatar);
        },
        isMessageSelected() {
            return (
                this.messageSelectionEnabled &&
                !!this.selectedMessages.find((message) => message._id === this.message._id)
            );
        },
    },

    watch: {
        newMessages: {
            immediate: true,
            deep: true,
            handler(val) {
                if (!val.length || !this.showNewMessagesDivider) {
                    this.newMessage = {};
                    return;
                }

                this.newMessage = val.reduce((res, obj) => (obj.index < res.index ? obj : res));
            },
        },
        messageSelectionEnabled() {
            this.resetMessageHover();
        },
    },

    mounted() {
        messagesValidation(this.message);

        this.$emit('message-added', {
            message: this.message,
            index: this.index,
            ref: this.$refs.message,
        });
    },

    methods: {
        onHoverMessage() {
            if (!this.messageSelectionEnabled) {
                this.messageHover = true;
                if (this.canEditMessage()) this.hoverMessageId = this.message._id;
            }
        },
        canEditMessage() {
            return !this.message.deleted;
        },
        onLeaveMessage() {
            if (!this.messageSelectionEnabled) {
                if (!this.optionsOpened && !this.emojiOpened) this.messageHover = false;
                this.hoverMessageId = null;
            }
        },
        resetMessageHover() {
            this.messageHover = false;
            this.hoverMessageId = null;
        },
        openFile(file) {
            this.$emit('open-file', { message: this.message, file: file });
        },
        openUserTag(user) {
            this.$emit('open-user-tag', { user });
        },
        messageActionHandler(action) {
            this.resetMessageHover();

            setTimeout(() => {
                this.$emit('message-action-handler', { action, message: this.message });
            }, 300);
        },
        sendMessageReaction({ emoji, reaction }) {
            this.$emit('send-message-reaction', {
                messageId: this.message._id,
                reaction: emoji,
                remove: reaction && reaction.indexOf(this.currentUserId) !== -1,
            });
            this.messageHover = false;
        },
        selectMessage() {
            if (this.messageSelectionEnabled) {
                if (this.isMessageSelected) {
                    this.$emit('unselect-message', this.message._id);
                } else {
                    this.$emit('select-message', this.message);
                }
            }
        },
        haveScenario(message, buttonType) {
            return message.valoration === buttonType ? 'have-scenario' : '';
        },
    },
};
</script>
