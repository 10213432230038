import tagsApiRepository from '@/services/conversations/TagsApiRepository';
import TagDto from '@/services/conversations/Dto/TagDto';

const state = {
    tags: [],
    loading: false,
    error: null,
    initialized: false,
};

const getters = {
    allTags: (state) => state.tags,
    getTagById: (state) => (id) => state.tags.find((tag) => tag.uuid === id),
    isLoading: (state) => state.loading,
    hasError: (state) => state.error !== null,
    isInitialized: (state) => state.initialized,
};

const mutations = {
    SET_TAGS(state, tags) {
        state.tags = tags;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_INITIALIZED(state, initialized) {
        state.initialized = initialized;
    },
    ADD_TAG(state, tag) {
        if (!state.tags.find((t) => t.uuid === tag.uuid)) {
            state.tags.push(tag);
        }
    },
    UPDATE_TAG(state, updatedTag) {
        const index = state.tags.findIndex((tag) => tag.uuid === updatedTag.uuid);
        if (index !== -1) {
            state.tags.splice(index, 1, updatedTag);
        }
    },
    REMOVE_TAG(state, tagId) {
        state.tags = state.tags.filter((tag) => tag.uuid !== tagId);
    },
};

const actions = {
    async fetchTags({ commit }) {
        commit('SET_LOADING', true);
        try {
            const tags = await tagsApiRepository.getTags();
            commit('SET_TAGS', tags);
            commit('SET_ERROR', null);
            commit('SET_INITIALIZED', true);
        } catch (error) {
            console.error('Error fetching tags:', error);
            commit('SET_ERROR', error);
        } finally {
            commit('SET_LOADING', false);
        }
    },

    async createTag({ commit }, tagRequest) {
        commit('SET_LOADING', true);
        try {
            await tagsApiRepository.createTag(tagRequest);
            const createdTag = await tagsApiRepository.getTag(tagRequest.uuid);
            console.log('Created tag:', createdTag);
            commit('ADD_TAG', createdTag);
            commit('SET_ERROR', null);
            return createdTag;
        } catch (error) {
            console.error('Error creating tag:', error);
            commit('SET_ERROR', error);
            throw error;
        } finally {
            commit('SET_LOADING', false);
        }
    },

    async updateTag({ commit }, { tagId, tagRequest }) {
        commit('SET_LOADING', true);
        try {
            const updatedTag = await tagsApiRepository.updateTag(tagId, tagRequest);
            commit('UPDATE_TAG', updatedTag);
            commit('SET_ERROR', null);
            return updatedTag;
        } catch (error) {
            console.error('Error updating tag:', error);
            commit('SET_ERROR', error);
            throw error;
        } finally {
            commit('SET_LOADING', false);
        }
    },

    async deleteTag({ commit }, tagId) {
        commit('SET_LOADING', true);
        try {
            await tagsApiRepository.deleteTag(tagId);
            commit('REMOVE_TAG', tagId);
            commit('SET_ERROR', null);
        } catch (error) {
            console.error('Error deleting tag:', error);
            commit('SET_ERROR', error);
            throw error;
        } finally {
            commit('SET_LOADING', false);
        }
    },

    async subscribeToTagUpdates({ rootState, dispatch }) {
        const topic = `/account/${rootState.user.account.uuid}/tag`;
        try {
            await dispatch(
                'websocket/subscribe',
                { topic, callback: (data) => dispatch('handleTagEvent', data) },
                { root: true },
            );
        } catch (error) {
            console.error(`Failed to subscribe to ${topic}:`, error);
        }
    },

    unsubscribeFromTagUpdates({ rootState, dispatch }) {
        const topic = `/account/${rootState.user.account.uuid}/tag`;
        dispatch('websocket/unsubscribe', { topic }, { root: true });
    },

    handleTagEvent({ commit }, event) {
        if (!event || !event.data) return;

        // Parse JSON string if it's a string
        const tagData = typeof event.data === 'string' ? JSON.parse(event.data) : event.data;

        // Create tag using builder pattern
        const tag = new TagDto.Builder()
            .withUuid(tagData.uuid)
            .withAccountId(tagData.account_id)
            .withName(tagData.name)
            .withColor(tagData.color)
            .build();

        switch (event.name) {
            case 'TagCreated':
                console.log('TagCreated event:', tag);
                commit('ADD_TAG', tag);
                break;
            case 'TagUpdated':
                commit('UPDATE_TAG', tag);
                break;
            case 'TagDeleted':
                commit('REMOVE_TAG', tag.uuid);
                break;
            default:
                console.warn(`Unhandled tag event: ${event.name}`);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
