<template>
    <div class="md-layout text-center">
        <div class="custom-signup-panel md-layout md-layout-item md-size-70 md-xsmall-size-100">
            <div class="md-layout-item md-size-100">
                <div class="md-display-1 register__text--left register__title">
                    <strong>
                        You've been invited to join
                        <template v-if="contactName"> the {{ contactName }}'s </template>
                        <template v-else> an </template>
                        organization
                    </strong>
                </div>
            </div>

            <!-- Add error panel if there's an error -->
            <div v-if="errorMessage" class="md-layout-item md-size-100 error-section">
                <div class="error-panel">
                    <md-icon class="md-accent">error</md-icon>
                    <span class="error-message">{{ errorMessage }}</span>
                    <md-button class="md-icon-button" @click="errorMessage = ''">
                        <md-icon>close</md-icon>
                    </md-button>
                </div>
            </div>

            <div class="md-layout-item md-size-100 register__social-section">
                <SocialAuthButton
                    provider="google"
                    :query-params="{}"
                    :is-register="true"
                    :loading="loading"
                    @before-auth="validateTerms"
                    @on-error="handleError"
                />
                <SocialAuthButton
                    provider="facebook"
                    :query-params="{}"
                    :is-register="true"
                    :loading="loading"
                    @before-auth="validateTerms"
                    @on-error="handleError"
                />
            </div>

            <div class="md-layout-item md-size-100 register__text--left">
                <p class="md-body-1">Or sign up with your email:</p>
            </div>

            <div class="register-form">
                <div class="register-form__inputs-section md-layout-item md-size-100">
                    <md-field v-for="item in inputs" :key="item.name">
                        <label
                            ><strong>{{ item.name }}</strong></label
                        >
                        <md-input
                            v-model="item.value"
                            :type="item.type"
                            required
                            @keyup.enter="register"
                            :disabled="item.type === 'email'"
                        ></md-input>
                    </md-field>

                    <div class="phone-input">
                        <md-field class="country-code">
                            <label for="country-code">Country</label>
                            <md-select
                                v-model="selectedCountryCode"
                                name="country-code"
                                id="country-code"
                                @md-selected="updatePhoneNumber"
                            >
                                <md-option v-for="country in countries" :key="country.code" :value="country.code">
                                    {{ country.name }} (+{{ country.dialCode }})
                                </md-option>
                            </md-select>
                        </md-field>
                        <md-field :class="{ 'md-invalid': showPhoneError }">
                            <label>Phone Number (optional)</label>
                            <md-input
                                v-model="phoneNumber"
                                type="tel"
                                @input="validatePhoneInput"
                                @focusout="validatePhoneInput"
                            ></md-input>
                            <span class="md-error" v-if="showPhoneError">Invalid phone number</span>
                        </md-field>
                    </div>

                    <md-checkbox
                        v-model="termsAndConditions"
                        :class="{ 'register__checkbox--error': showCheckboxError }"
                    >
                        I agree to the <a href="https://www.lixsa.ai/terms">terms and conditions</a>.
                    </md-checkbox>

                    <p class="md-caption text-danger" v-if="errorMessage">
                        {{ errorMessage }}
                    </p>
                </div>

                <div class="md-layout-item md-size-100">
                    <md-button :disabled="!isFormValid" v-if="!loading" class="md-primary md-block" @click="register">
                        <span class="md-body-2">Sign Up</span>
                    </md-button>
                    <md-progress-spinner
                        v-else
                        class="md-primary"
                        :md-diameter="12"
                        :md-stroke="2"
                        md-mode="indeterminate"
                    ></md-progress-spinner>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { mapActions } from 'vuex';
import userService from '@/services/account/userService';
import countryCodes from '@/assets/countryCodesData.json';
import SocialAuthButton from '@/components/Auth/SocialAuthButton.vue';

export default {
    name: 'TheInvite',
    components: {
        SocialAuthButton,
    },
    data() {
        return {
            // Query params.
            account: null,
            user: null,
            contactName: null,
            userEmail: null,

            selectedCountryCode: 'ES',
            phoneNumber: '',
            isPhoneValid: true,
            countries: this.sortCountries(countryCodes),
            hasPhoneBeenEdited: false,

            termsAndConditions: false,
            showCheckboxError: false,
            errorMessage: null,
            loading: false,
            inputs: [
                {
                    icon: 'email',
                    name: 'Email...',
                    nameAttr: 'email',
                    type: 'email',
                    value: null,
                    formatValue: (value) => value.replace(/\s/g, '+'),
                },

                {
                    icon: 'lock_outline',
                    name: 'Password..',
                    nameAttr: 'password',
                    type: 'password',
                    value: null,
                },
            ],
            error: '', // Add this for consistent error handling
        };
    },
    computed: {
        isFormValid() {
            const allInputsFilled = this.inputs.every((input) => input.value);
            const isCheckboxChecked = this.termsAndConditions;
            const isPhoneValid = !this.phoneNumber || this.isPhoneValid;
            return allInputsFilled && isCheckboxChecked && isPhoneValid;
        },
        selectedCountry() {
            return this.countries.find((country) => country.code === this.selectedCountryCode) || this.countries[0];
        },
        showPhoneError() {
            return !this.isPhoneValid && this.hasPhoneBeenEdited;
        },
    },
    created() {
        this.fetchQueryParams();
    },
    methods: {
        ...mapActions('user', [
            'actionGetUser',
            'actionSignup',
            'actionCreateAccount',
            'refreshToken',
            'actionGetAccount',
            'actionSignUpWithPopup',
            'actionSignUpWithFacebook',
        ]),
        async register() {
            const [rawEmail, password] = this.inputs.map((input) => input.value);
            const email = this.inputs.find((input) => input.type === 'email').formatValue(rawEmail);
            const myUuid = uuidv4();
            this.errorMessage = '';
            this.loading = true;

            // Validation
            if (!this.validateInputs(email, password)) {
                this.loading = false;
                return;
            }

            try {
                const userData = await this.actionSignup({ email, password });

                if (!userData) {
                    throw new Error('Authentication failed');
                }

                await userService.acceptInvitation(this.user, userData.localId);
                await this.refreshToken();
                await this.actionGetUser();
                const response = await this.actionGetAccount();

                const successfulResponse = [200, 201].includes(response.status);
                if (successfulResponse) {
                    this.$router.push({ name: 'Dashboard' });
                    this.showSuccessToast('Sign up complete!');
                }
            } catch (err) {
                const errorMessage = 'An error occurred.';
                this.errorMessage = errorMessage;
                console.error('Error:', errorMessage);
                this.logout();
            } finally {
                this.loading = false;
            }
        },

        logout() {
            this.$store
                .dispatch('user/logout')
                .then(() => {
                    if (this.$route.path !== '/') {
                        this.$router.push({ name: 'Login', query: { logout: 'true' } });
                    }
                })
                .catch((error) => {
                    this.error = error.message;
                });
        },

        validateTerms() {
            if (!this.termsAndConditions) {
                this.showCheckboxError = true;
                setTimeout(() => (this.showCheckboxError = false), 1000);
                return false;
            }
            return true;
        },

        handleError(error) {
            // Handle Firebase authentication errors
            if (error.response?.data?.error) {
                switch (error.response.data.error.message) {
                    case 'EMAIL_EXISTS':
                        this.errorMessage = 'This email is already registered. Please sign in instead.';
                        break;
                    case 'OPERATION_NOT_ALLOWED':
                        this.errorMessage = 'Social sign-in is not enabled for this application.';
                        break;
                    case 'TOO_MANY_ATTEMPTS_TRY_LATER':
                        this.errorMessage = 'Too many attempts. Please try again later.';
                        break;
                    default:
                        this.errorMessage = 'Authentication failed. Please try again.';
                }
                return;
            }

            // Handle invitation-specific errors
            if (error.response?.status) {
                switch (error.response.status) {
                    case 400:
                        this.errorMessage = 'Invalid invitation or email mismatch.';
                        break;
                    case 404:
                        this.errorMessage = 'Invitation not found or expired.';
                        break;
                    case 409:
                        this.errorMessage = 'This invitation has already been used.';
                        break;
                    case 500:
                        this.errorMessage = 'Server error. Please try again later.';
                        break;
                    default:
                        this.errorMessage = 'An error occurred. Please try again.';
                }
                return;
            }

            // Handle general errors
            this.errorMessage = error.message || 'An unexpected error occurred.';
        },

        fetchQueryParams() {
            this.account = this.$route.query.account;
            this.user = this.$route.query.user;
            this.contactName = this.$route.query.contactName;

            const emailInputIndex = this.inputs.findIndex((input) => input.type === 'email');
            const rawEmail = this.$route.query.userEmail;
            this.inputs[emailInputIndex].value = rawEmail ? this.inputs[emailInputIndex].formatValue(rawEmail) : null;
            this.userEmail = this.inputs[emailInputIndex].value;
        },

        validateInputs(email, password) {
            // Format the email before validation
            email = this.inputs.find((input) => input.type === 'email').formatValue(email);

            if (!email || !password) {
                this.errorMessage = 'All fields are required';
                return false;
            }

            if (!this.isValidEmail(email)) {
                this.errorMessage = 'Invalid email format';
                return false;
            }

            if (!this.isValidPassword(password)) {
                this.errorMessage = 'Password must be at least 6 characters and include alphanumeric characters';
                return false;
            }

            return true;
        },

        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },

        isValidEmail(email) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        },

        isValidPassword(password) {
            const passwordRegex = /^(?=.*[0-9a-zA-Z]).{6,}$/;
            return passwordRegex.test(password);
        },

        validatePhoneInput() {
            if (!this.phoneNumber) {
                this.isPhoneValid = true;
                return;
            }

            const phoneRegex = /^(\+)?(?:[0-9] ?){6,14}[0-9]$/;
            const fullPhoneNumber = this.getFullPhoneNumber();
            this.isPhoneValid = phoneRegex.test(fullPhoneNumber);
            this.hasPhoneBeenEdited = true;
        },

        updatePhoneNumber() {
            this.phoneNumber = '';
            this.isPhoneValid = true;
            this.hasPhoneBeenEdited = false;
        },

        getFullPhoneNumber() {
            return `+${this.selectedCountry.dialCode}${this.phoneNumber}`;
        },

        sortCountries(countries) {
            const spain = countries.find((country) => country.code === 'ES');
            const otherCountries = countries.filter((country) => country.code !== 'ES');
            return [spain, ...otherCountries.sort((a, b) => a.name.localeCompare(b.name))];
        },
    },
};
</script>

<style scoped lang="scss">
.custom-signup-panel {
    padding-left: 0;
    padding-right: 0;
}

.register__title {
    margin-bottom: 2rem;
}

.register__text--left {
    text-align: start;
}

.register__social-section {
    display: flex;
    justify-content: start;
    gap: 1rem;
    margin-bottom: 1rem;
}

.register-form {
    width: 100%;
    height: 100%;
}

.register-form__inputs-section {
    margin-top: 15px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.phone-input {
    display: flex;
    gap: 8px;
}

.country-code {
    flex: 0 0 40%;
}

.md-field {
    margin-bottom: 0;
}

.register__checkbox--error {
    animation: shake 0.5s;
    color: red !important;
}

@keyframes shake {
    0%,
    100% {
        transform: translateX(0);
    }
    25%,
    75% {
        transform: translateX(-10px);
    }
    50% {
        transform: translateX(10px);
    }
}

.error-section {
    margin-bottom: 1rem;
}

.error-panel {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    background-color: #ffebee;
    border-radius: 4px;
    color: #d32f2f;

    .md-icon {
        margin-right: 8px;
        font-size: 20px;
    }

    .error-message {
        flex-grow: 1;
        font-size: 14px;
    }

    .md-button {
        min-width: 36px;
        margin: 0;
        padding: 0;
    }
}
</style>
