<script>
export default {
    name: 'codeSnippet',
    data() {
        return {
            copied: false,
        };
    },
    props: {
        language: {
            type: String,
            required: true,
            default: 'language-html',
        },
        code: {
            required: true,
            default:
                '&lt;script src="https://lixsa-prod.web.app/whatsapp-widget.js" phone="{Your-whatsapp-Business-Number}" message="{Your-Default-Message}"&gt;&lt;/script&gt;',
        },
    },
    computed: {
        langName() {
            // Check if the input starts with "language-"
            if (this.language.startsWith('language-')) {
                // Remove the "language-" prefix
                let formatedLangName = this.language.slice(9);
                // Capitalize the first letter of the remaining string
                formatedLangName = formatedLangName.charAt(0).toUpperCase() + formatedLangName.slice(1);
                return formatedLangName;
            }
            // If the input doesn't start with "language-", return the input as is
            return this.language;
        },

        encodedCode() {
            // return this.code.replace(/</g, '&lt;').replace(/>/g, '&gt;');
            if (this.language === 'language-html') {
                return this.code.replace(/</g, '&lt;').replace(/>/g, '&gt;');
            }
            return this.code;
        },
    },
    methods: {
        copyCodeToClipboard() {
            // Decode HTML entities before copying
            const decodedCode = this.code
                .replace(/&lt;/g, '<')
                .replace(/&gt;/g, '>')
                .replace(/&amp;/g, '&')
                .replace(/&quot;/g, '"')
                .replace(/&#039;/g, "'");

            navigator.clipboard
                .writeText(decodedCode)
                .then(() => {
                    this.copied = true;
                    setTimeout(() => {
                        this.copied = false;
                    }, 500);
                })
                .catch((err) => {
                    console.error('Could not copy text: ', err);
                });
        },
    },
};
</script>

<template>
    <div class="code-snippet">
        <div class="code-snippet__toolbar">
            <div class="md-body-1 code-snippet__toolbar-text">{{ langName }}</div>
            <md-button class="md-sm md-simple md-gray code-snippet__copy-button" @click="copyCodeToClipboard">
                <i class="material-symbols-outlined">{{ copied ? 'check' : 'content_copy' }}</i>
                {{ copied ? 'Copied!' : 'Copy code' }}
            </md-button>
        </div>
        <pre :class="language" class="code-snippet__code">
            <code :class="language" v-html="encodedCode"></code>
        </pre>
    </div>
</template>

<style scoped lang="scss">
.code-snippet {
    background-color: #f5f2f0;
    border-radius: 12px;
    overflow: hidden;
}

.code-snippet__toolbar {
    background-color: #ebebeb;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 1rem;
}

.code-snippet__toolbar-text {
    color: #555555;
}

.code-snippet__code {
    display: flex;
    align-items: start;
}
</style>
