export default [
    {
        id: 'qna-welcome-modal-step',
        title: '<p class="md-title">${tours.qna.title}</p>',
        text: `
            <div>
                <p class="md-body-2">\${tours.qna.intro.p1}</p>
                <p class="md-body-1">\${tours.qna.intro.p2}</p>
                <p class="md-caption">\${tours.qna.intro.note}</p>
            </div>
        `,
        buttons: [
            {
                text: 'tours.qna.buttons.skipTour',
                action: 'cancel',
                secondary: true,
            },
            {
                text: 'tours.qna.buttons.startTour',
                action: 'next',
            },
        ],
    },
    {
        id: 'add-button-step',
        attachTo: { element: '#qna-add-button', on: 'top' },
        text: `
            <div>
                <p class="md-body-2">\${tours.qna.addButton.p1} \${tours.qna.addButton.p2}</p>
                
            </div>
        `,
        advanceOn: { selector: '#qna-add-button', event: 'click' },
    },
    {
        id: 'qna-item-step',
        attachTo: { element: '#qna-item-0', on: 'top' },
        text: `
            <div>
                <p class="md-body-2">\${tours.qna.itemStep.p1}</p>
                <p class="md-body-2">\${tours.qna.itemStep.p2}</p>
            </div>
        `,
        advanceOn: { selector: '#qna-item-card__header-actions', event: 'click' },
        buttons: [
            {
                text: 'tours.qna.buttons.continue',
                action: 'next',
            },
        ],
    },
    {
        id: 'qna-sync-step',
        text: `
            <div>
                <p class="md-body-2">\${tours.qna.syncStep.p1}</p>
                <p class="md-body-2">\${tours.qna.syncStep.p2}</p>
            </div>
        `,
        buttons: [
            {
                text: 'tours.qna.buttons.continue',
                action: 'next',
            },
        ],
    },
    {
        id: 'qna-next-tab-step',
        attachTo: { element: '#tab-business-knowledge', on: 'top' },
        text: `
            <div>
                <p class="md-body-2">\${tours.qna.nextTab}</p>
            </div>
        `,
        advanceOn: { selector: '#tab-business-knowledge', event: 'click' },
    },
];
