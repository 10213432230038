<template>
    <div class="currency-selector">
        <md-field class="custom-field">
            <label>{{ $t('common.currencySelector.currency') }}</label>
            <md-select v-model="selectedCurrency" name="currency" @md-selected="handleCurrencyChange">
                <div class="search-container">
                    <md-field md-clearable>
                        <md-input v-model="searchQuery" placeholder="Search currency..." @input="handleSearch" />
                    </md-field>
                </div>

                <md-option v-for="currency in filteredCurrencies" :key="currency.code" :value="currency.code">
                    {{ currency.displayName }}
                </md-option>
            </md-select>
            <span class="md-helper-text">{{ $t('common.currencySelector.selectCurrency') }}</span>
        </md-field>
    </div>
</template>

<script>
import CurrencyService from '@/services/_common/currencyService';

export default {
    name: 'CurrencySelector',

    props: {
        value: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            loading: true,
            availableCurrencies: [],
            selectedCurrency: this.value,
            searchQuery: '',
        };
    },

    computed: {
        formattedCurrencies() {
            return this.availableCurrencies.map((currency) => ({
                ...currency,
                displayName: `${currency.name}${currency.symbol ? ` (${currency.symbol})` : ''}`,
            }));
        },
        filteredCurrencies() {
            if (!this.searchQuery) return this.formattedCurrencies;
            const query = this.searchQuery.toLowerCase();
            return this.formattedCurrencies.filter(
                (currency) =>
                    currency.displayName.toLowerCase().includes(query) || currency.code.toLowerCase().includes(query),
            );
        },
    },

    watch: {
        value(newValue) {
            this.selectedCurrency = newValue;
        },
    },

    async mounted() {
        await this.loadCurrencies();
    },

    methods: {
        async loadCurrencies() {
            this.loading = true;
            try {
                this.availableCurrencies = await CurrencyService.getAllCurrencies();
            } catch (error) {
                console.error('Error loading currencies:', error);
                this.availableCurrencies = [];
            } finally {
                this.loading = false;
            }
        },

        handleCurrencyChange(currency) {
            this.$emit('input', currency);
            this.$emit('change', currency);
        },

        handleSearch() {
            // Handle search logic
        },
    },
};
</script>

<style lang="scss" scoped>
.currency-selector {
    width: 100%;
}

.custom-field {
    margin-top: 20px;
    margin-bottom: 20px;
}

.search-container {
    padding: 8px 16px;
    margin: 0;

    .md-input {
        width: 100%;
        height: 32px;
        margin: 0;
        padding: 0;
    }

    ::after {
        padding: 0 20px 0 20px;
    }
}
</style>
