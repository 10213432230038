<template>
    <div class="playground__main-panel">
        <playground-chat />
    </div>
</template>

<script>
import PlaygroundChat from '@/components/Playground/PlaygroundChat.vue';
export default {
    name: 'Playground',
    components: {
        PlaygroundChat,
    },
};
</script>

<style scoped lang="scss">
.playground__main-panel {
    position: relative;
    overflow-x: auto;
    width: 100%;
}
</style>
