class VariablesAdapter {
    /**
     * @param {TemplateVariablesResponseDto} templateVariablesDto
     * @returns {Array<{label: string, value: string}>}
     */
    static execute(templateVariablesDto) {
        if (!templateVariablesDto?.variables) {
            return [];
        }

        return Object.entries(templateVariablesDto.variables).map(([key, value]) => ({
            label: key,
            value: `{{${key}}}`,
            example: value,
        }));
    }
}

export default VariablesAdapter;
