/**
 * @typedef {Object} ProviderConfig
 * @property {string} name - The provider's name
 * @property {string} icon - The icon class or component to use
 * @property {string} buttonClass - The CSS class for the button
 * @property {string} action - The Vuex action to call for authentication
 * @property {boolean} showText - Whether to show text in the button
 * @property {string} buttonType - The type of button styling ('outline' or 'solid')
 */

/**
 * @type {Object.<string, ProviderConfig>}
 */
export const providers = {
    google: {
        name: 'Google',
        icon: 'google-logo.svg',
        buttonClass: 'md-outline md-gray',
        action: 'actionSignUpWithPopup',
        showText: true,
        buttonType: 'outline',
    },
    facebook: {
        name: 'Facebook',
        icon: 'fab fa-facebook-f',
        buttonClass: 'md-facebook',
        action: 'actionSignUpWithFacebook',
        showText: false,
        buttonType: 'solid',
    },
    // Add new providers here following the same pattern
    // Example:
    // twitter: {
    //     name: 'Twitter',
    //     icon: 'fab fa-twitter',
    //     buttonClass: 'md-twitter',
    //     action: 'actionSignUpWithTwitter',
    //     showText: true,
    //     buttonType: 'solid'
    // }
};
