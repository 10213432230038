<script>
import { mapActions, mapGetters } from 'vuex';
import { providers } from '@/services/auth/providers';

export default {
    name: 'SocialAuthButton',
    emits: ['on-error'],
    props: {
        provider: {
            type: String,
            required: true,
            validator: (value) => Object.keys(providers).includes(value),
        },
        queryParams: {
            type: Object,
            default: () => ({}),
        },
        isRegister: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters('user', ['isShopifyBillingClient', 'currentLanguage']),

        providerConfig() {
            return providers[this.provider];
        },

        buttonClass() {
            const baseClass = this.isRegister ? 'register-social-button' : 'login-social-button';
            return [
                `${baseClass}-${this.provider}`,
                this.providerConfig.buttonClass,
                this.isRegister && !this.providerConfig.showText ? 'md-just-icon md-round' : 'md-block',
            ];
        },

        buttonText() {
            if (this.isRegister) {
                return this.providerConfig.showText ? this.providerConfig.name : '';
            }
            return `Sign in with ${this.providerConfig.name}`;
        },

        iconClass() {
            if (this.providerConfig.icon.startsWith('fab')) {
                return this.providerConfig.icon;
            }
            return '';
        },

        isIconImage() {
            return this.providerConfig.icon.endsWith('.svg');
        },
    },
    methods: {
        ...mapActions('user', [
            'actionGetUser',
            'refreshToken',
            'actionGetAccount',
            'actionSignUpWithPopup',
            'actionSignUpWithFacebook',
        ]),
        ...mapActions('membership', ['getAccountMembership', 'subscribeToMembershipUpdates']),
        ...mapActions('agentSettings', ['updateAgentSetting']),

        logout() {
            this.$store
                .dispatch('user/logout')
                .then(() => {
                    if (this.$route.path !== '/') {
                        this.$router.push({ name: 'Login', query: { logout: 'true' } });
                    }
                })
                .catch((error) => {
                    this.error = error.message;
                });
        },

        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },

        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },

        async handleSocialAuth() {
            this.error = '';
            this.loading = true;

            try {
                const signupResponse = await this[this.providerConfig.action]();

                if (!signupResponse) {
                    this.error = 'Authentication failed';
                    return;
                }

                if (signupResponse.isNewUser) {
                    await this.refreshToken();
                }

                await this.actionGetUser();
                await this.updateAgentSetting();
                const response = await this.actionGetAccount();

                if (response.status === 200 || response.status === 201) {
                    await this.getAccountMembership();
                    if (!this.isShopifyBillingClient) {
                        this.$store.dispatch(
                            'membership/fetchStripeSubscription',
                            this.$store.getters['membership/membershipId'],
                        );
                    }
                    this.subscribeToMembershipUpdates();

                    if (signupResponse.isNewUser && window.tolt) {
                        window.tolt.signup(signupResponse.userData.email);
                    }

                    await this.$router.push({ name: 'Dashboard', query: this.queryParams });

                    if (signupResponse.isNewUser) {
                        this.showSuccessToast('Sign up complete!');
                    }
                }
            } catch (err) {
                if (signupResponse.isNewUser) {
                    if (err.message === 'Account already exists') {
                        this.showErrorToast('Account already exists');
                    } else if (err.message === 'Shop already registered') {
                        this.showErrorToast('Shop already registered');
                    } else {
                        this.$emit('on-error', err);
                    }
                } else {
                    this.$emit('on-error', err);
                }
                this.logout();
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<template>
    <md-button :class="buttonClass" @click="handleSocialAuth">
        <template v-if="isIconImage">
            <div :class="`${buttonClass[0]}__icon`"></div>
        </template>
        <template v-else>
            <i :class="[iconClass, `${buttonClass[0]}__icon`]"></i>
        </template>
        <span v-if="!isRegister || providerConfig.showText">{{ buttonText }}</span>
    </md-button>
</template>

<style scoped lang="scss">
.login-social-button-google,
.register-social-button-google {
    &__icon {
        height: 17px;
        width: 17px;
        background-image: url('@/assets/icons/google-logo.svg');
        margin-right: 0.7rem;
    }
}

.login-social-button-facebook,
.register-social-button-facebook {
    &__icon {
        margin-right: 1rem;
    }
}

.register-social-button-facebook {
    &__icon {
        margin: 0 !important;
    }
}

.register-social-button-google {
    width: 100%;
}

// Add styles for new providers here
// Example:
// .login-social-button-twitter,
// .register-social-button-twitter {
//     &__icon {
//         margin-right: 1rem;
//     }
// }
</style>
