import { mapActions } from 'vuex';
import CreateRoom from '@/components/Chat/CreateRoom/CreateRoom.vue';
import { v4 as uuidv4 } from 'uuid';

export default {
    methods: {
        ...mapActions('modalStore', ['openModal']),

        showAddRoomModal() {
            this.openModal({
                component: CreateRoom,
                props: { searchParam: this.lastSearchTerm },

                /**
                 * @param {string} eventName - The name of the event
                 * @param {Object} eventPayload - The payload of the room-created event
                 * @param {string} eventPayload.channel - The selected channel for the room
                 * @param {string} eventPayload.phoneNumber - The phone number associated with the room
                 * @param {string} eventPayload.contactName - The name of the contact for the room
                 */
                onEvent: (eventName, eventPayload) => {
                    if (eventName === 'room-created') {
                        const room = this.createRoomObject(eventPayload);
                        this.rooms = [room, ...this.rooms];
                        this.currentUserName = eventPayload.contactName || 'Guest';
                        this.currentUserPhone = eventPayload.phoneNumber;
                        this.currentUserEmail = null;
                    }
                },
            });
        },

        createRoomObject(payload) {
            const { channel, phoneNumber, contactName } = payload;
            const newCurrentUserId = uuidv4(); // Assuming current user ID is generated

            return {
                roomId: uuidv4(),
                currentUserId: newCurrentUserId,
                roomName: contactName || 'Guest',
                avatar: process.env.VUE_APP_USER_AVATAR_URL,
                userEmail: null,
                userPhone: phoneNumber,
                userSatisfaction: 'POSITIVE',
                unreadCount: 0,
                channelId: phoneNumber,
                channel_type: channel.toUpperCase(),
                index: 1,
                lastMessage: {
                    _id: uuidv4(),
                    content: '',
                    senderId: '4321',
                    username: 'Lixsa',
                    timestamp: new Date().toLocaleTimeString('en-US', {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true,
                    }),
                    date: new Date().toLocaleDateString('es-ES', {
                        weekday: 'short',
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric',
                    }),
                    saved: true,
                    distributed: false,
                    seen: true,
                    new: true,
                },
                users: [
                    {
                        _id: '4321',
                        username: 'Lixsa',
                        avatar: process.env.VUE_APP_LIXSA_AVATAR_URL,
                        status: {
                            state: 'offline',
                            lastChanged: new Date().toLocaleString('en-US', {
                                weekday: 'long',
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: true,
                            }),
                        },
                    },
                    {
                        _id: newCurrentUserId,
                        username: contactName || 'Guest',
                        avatar: process.env.VUE_APP_USER_AVATAR_URL,
                        status: {
                            state: 'offline',
                            lastChanged: new Date().toLocaleString('en-US', {
                                weekday: 'long',
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: true,
                            }),
                        },
                    },
                ],
                typingUsers: ['4321'],
                is_aion: false,
                room_ai_type: 'SUPPORT_AGENT',
                communication_channel: channel.toUpperCase(),
                note: null,
            };
        },
    },
};
