import BaseDto from '../../_common/BaseDto';

class TemplateVariablesDtoBuilder {
    constructor() {
        this.uuid = undefined;
        this.skillId = undefined;
        this.templateType = undefined;
        this.flowType = undefined;
        this.variables = undefined;
        this.language = undefined;
        this.contentExample = undefined;
    }

    withUuid(uuid) {
        this.uuid = uuid;
        return this;
    }

    withSkillId(skillId) {
        this.skillId = skillId;
        return this;
    }

    withTemplateType(templateType) {
        this.templateType = templateType;
        return this;
    }

    withFlowType(flowType) {
        this.flowType = flowType;
        return this;
    }

    withVariables(variables) {
        this.variables = variables;
        return this;
    }

    withLanguage(language) {
        this.language = language;
        return this;
    }

    withContentExample(contentExample) {
        this.contentExample = contentExample;
        return this;
    }

    build() {
        return new TemplateVariablesDto(this);
    }
}

class TemplateVariablesDto extends BaseDto {
    constructor(builder) {
        super();
        this.uuid = builder.uuid;
        this.skillId = builder.skillId;
        this.templateType = builder.templateType;
        this.flowType = builder.flowType;
        this.variables = builder.variables;
        this.language = builder.language;
        this.contentExample = builder.contentExample;
    }

    static get Builder() {
        return TemplateVariablesDtoBuilder;
    }
}

export default TemplateVariablesDto;
