<script>
import { mapActions } from 'vuex';
import TagSelector from '@/lib/RoomsList/FiltersSideBar/TagSelector.vue';
import userService from '@/services/account/userService';
import conversationsService from '@/services/conversations/conversationsService';

export default {
    name: 'ScaleToUserModal',

    components: {
        TagSelector,
    },

    emits: ['update:selectedUser'],

    props: {
        room: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            localUsers: [],
            isLoading: false,
            selectedUser: null,
        };
    },
    computed: {
        canSave() {
            // return JSON.stringify(this.localUsers) !== JSON.stringify([]);
            return this.selectedUser;
        },
    },

    methods: {
        ...mapActions('modalStore', ['closeModal']),

        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },

        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },

        async handleSave() {
            try {
                await conversationsService.scaleConversationToUser(this.room.roomId, this.selectedUser);
                await conversationsService.updateStateAI(this.room.roomId, false);
                this.showSuccessToast('Conversation scaled to user');
                this.$emit('modalEvent', {
                    eventName: 'update:selectedUser',
                    eventPayload: this.selectedUser,
                });
                this.closeModal();
            } catch (error) {
                console.error(error);
                this.showErrorToast('Error scaling conversation to user');
            }
        },
    },

    async mounted() {
        try {
            const response = await userService.fetchUsers();
            this.localUsers = response.data.content;
        } catch (error) {
            console.error(error);
        }
    },
};
</script>

<template>
    <div class="edit-tags">
        <div class="edit-tags__header">
            <div class="edit-tags__title-container">
                <span class="material-symbols-outlined edit-tags__icon">support_agent</span>
                <p class="edit-tags__title md-title">Scale to User</p>
            </div>
            <md-button @click="closeModal()" class="md-icon-button">
                <md-icon>close</md-icon>
            </md-button>
        </div>

        <md-content class="edit-tags__content">
            <div class="edit-tags__selector-wrapper">
                <md-field>
                    <md-select v-model="selectedUser" name="user" id="user" placeholder="Select User">
                        <md-option v-for="user in localUsers" :key="user.uuid" :value="user.uuid">
                            {{ user.username }}
                        </md-option>
                    </md-select>
                </md-field>
            </div>
        </md-content>

        <div class="edit-tags__footer">
            <md-button class="md-gray md-outline" @click="closeModal()">Cancel</md-button>
            <md-button class="md-primary" @click="handleSave" :disabled="isLoading || !canSave">
                {{ isLoading ? 'Saving...' : 'Save' }}
            </md-button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.edit-tags {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: 400px;
    max-width: 100%;
    border-radius: 4px;
    position: relative;
    min-height: 200px;
    transition: min-height 0.3s cubic-bezier(0.4, 0, 0.2, 1);

    &--expanded {
        min-height: 380px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 16px;
        height: 48px;
    }

    &__title-container {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__title {
        font-size: 20px;
        font-weight: 500;
        margin: 0;
    }

    &__icon {
        font-size: 20px;
        color: rgba(0, 0, 0, 0.54);
    }

    &__content {
        padding: 0 16px;
        overflow: visible !important;
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    &__selector-wrapper {
        position: relative;
        z-index: 1100;
        width: 100%;
        padding-top: 8px;
    }

    &__footer {
        display: flex;
        justify-content: flex-end;
        gap: 8px;
        padding: 8px 16px;
        position: sticky;
        bottom: 0;
    }
}

::v-deep .md-content {
    overflow: visible !important;
}
</style>
