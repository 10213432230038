export default [
    {
        id: 'business-knowledge-welcome-modal-step',
        title: '<p class="md-title">${tours.businessKnowledge.title}</p>',
        text: `
            <div>
                <p class="md-body-2">\${tours.businessKnowledge.intro.p1}</p>
                <p class="md-caption">\${tours.businessKnowledge.intro.p2}</p>
            </div>
        `,
        buttons: [
            {
                text: 'tours.businessKnowledge.buttons.skipTour',
                action: 'cancel',
                secondary: true,
            },
            {
                text: 'tours.businessKnowledge.buttons.startTour',
                action: 'next',
            },
        ],
    },
    {
        id: 'business-knowledge-add-step',
        attachTo: { element: '#business-knowledge-add-button', on: 'top' },
        text: `
            <div>
                <p class="md-body-2">\${tours.businessKnowledge.addStep}</p>
            </div>
        `,
        advanceOn: { selector: '#business-knowledge-add-button', event: 'click' },
    },
    {
        id: 'business-knowledge-upload-wizard-step',
        attachTo: { element: '#business-knowledge-upload-wizard', on: 'right' },
        text: `
            <div>
                <p class="md-body-2">\${tours.businessKnowledge.uploadWizard.p1}</p>
                <p class="md-body-2">\${tours.businessKnowledge.uploadWizard.p2}</p>
            </div>
        `,
    },
    {
        id: 'business-knowledge-table-card-step',
        attachTo: { element: '#business-knowledge-table-card', on: 'bottom' },
        text: `
            <div>
                <p class="md-body-2">\${tours.businessKnowledge.tableCard}</p>
            </div>
        `,
        buttons: [
            {
                text: 'tours.businessKnowledge.buttons.next',
                action: 'next',
            },
        ],
    },
    {
        id: 'business-knowledge-next-tab-step',
        attachTo: { element: '#tab-agent', on: 'top' },
        text: `
            <div>
                <p class="md-body-2">\${tours.businessKnowledge.nextTab}</p>
            </div>
        `,
        advanceOn: { selector: '#tab-agent', event: 'click' },
    },
];
