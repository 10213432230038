// Auth Pages
import Login from '../pages/Auth/Login.vue';
import Dashboard from '../pages/Dashboard/Dashboard/DashboardPage.vue';
import DashboardLayout from '../pages/Dashboard/Layout/DashboardLayout.vue';
import Register from '../pages/Auth/Register.vue';
import ChatPage from '../pages/Dashboard/Conversations/ChatPage.vue';
import Onboard from '../pages/Auth/Onboard.vue';

import AccountSettings from '../pages/Dashboard/Settings/Account/AccountSettings.vue';
import ApiKeys from '../pages/Dashboard/Settings/apiKeys/ApiKeys.vue';
import UsersManagement from '../pages/Dashboard/Settings/UsersManagement/UsersManagement.vue';
import Subscription from '../pages/Dashboard/Settings/Subscription/Subscription.vue';
import NotificationsSettings from '../pages/Dashboard/Settings/Notifications/NotificationsSettings.vue';
import ToursSettings from '../pages/Dashboard/Settings/Tours/ToursSettings.vue';
import SettingsLayout from '../pages/Dashboard/Settings/SettingsLayout.vue';

import CommunicationChannels from '../pages/Dashboard/CommunicationChannels/CommunicationChannels.vue';
import Details_CommunicationChannels from '../pages/Dashboard/CommunicationChannels/Details_CommunicationChannels.vue';
// import Sales from '../pages/Dashboard/Sales/Sales.vue';
import ForgotPassword from '../pages/Auth/ForgotPassword.vue';

import Playground from '../pages/Dashboard/Playground/Playground.vue';
import Training from '../pages/Dashboard/Training/Training.vue';
import BusinessKnowledge from '../pages/Dashboard/Training/BusinessKnowledge.vue';
import Catalog from '../pages/Dashboard/Training/CatalogPage.vue';
import Agent from '../pages/Dashboard/Training/Agent.vue';
import ChatTest from '../pages/Dashboard/Training/ChatTest.vue';
import FineTuning from '../pages/Dashboard/Training/FineTuning.vue';
import Invite from '../pages/Auth/Invite.vue';
import NotFound from '../pages/Common/NotFound.vue';
import LinkExpired from '../pages/Common/LinkExpired.vue';
import Skills from '@/pages/Dashboard/Skills/SkillsPage.vue';
import SkillsDiscoveryTab from '@/pages/Dashboard/Skills/SkillsDiscoveryTab.vue';
import SkillsMySkillsTab from '@/pages/Dashboard/Skills/SkillsMySkillsTab.vue';
import SkillsDetails from '@/pages/Dashboard/Skills/SkillsDetailsPage.vue';
import QnATab from '@/pages/Dashboard/Training/QnATab.vue';

const linkExpiredPage = { path: '/link-expired', component: LinkExpired };

const notFoundPage = { path: '/404', component: NotFound };

const invitePage = {
    path: '/invite',
    component: Invite,
    name: 'Invite',
    meta: { title: 'Invite' },
};

const registerPage = {
    path: '/register',
    component: Register,
    name: 'Register',
    meta: { title: 'Signup' },
};

const forgotPasswordPage = {
    path: '/forgot-password',
    component: ForgotPassword,
    name: 'Forgot Password',
    meta: { title: 'Forgot Password' },
};

const loginPage = {
    path: '/',
    component: Login,
    name: 'Login',
    meta: { title: 'Signin' },
};

const onboardPage = {
    path: '/onboard',
    component: Onboard,
    name: 'Onboard',
    meta: {
        requiresAuth: true,
        title: 'Onboarding',
        permissions: ['ADMIN', 'EMPLOYEE'],
    },
};

const dashboardPage = {
    path: '/dashboard',
    name: 'Dashboard',
    components: { default: Dashboard },
    meta: {
        requiresAuth: true,
        title: 'Dashboard',
        permissions: ['ADMIN', 'EMPLOYEE'],
    },
};

const conversationsPage = {
    path: '/conversations',
    name: 'Customer Support',
    meta: {
        requiresAuth: true,
        title: 'Customer Support',
        permissions: ['ADMIN', 'EMPLOYEE'],
    },
    components: { default: ChatPage },
};

const settingsPage = {
    path: '/settings',
    name: 'Settings',
    meta: { requiresAuth: true, title: 'Settings Overview', permissions: ['ADMIN', 'EMPLOYEE'] },
    components: { default: SettingsLayout },
    redirect: '/settings/account',
    children: [
        {
            path: 'account',
            name: 'Account Settings',
            components: { default: AccountSettings },
            meta: {
                requiresAuth: true,
                title: 'Account Settings',
                permissions: ['ADMIN', 'EMPLOYEE'],
            },
        },
        {
            path: 'api-keys',
            name: 'API Keys',
            components: { default: ApiKeys },
            meta: {
                requiresAuth: true,
                title: 'API Keys',
                permissions: ['ADMIN', 'EMPLOYEE'],
            },
        },
        {
            path: 'members',
            name: 'User Management',
            components: { default: UsersManagement },
            meta: {
                requiresAuth: true,
                title: 'User Management',
                permissions: ['ADMIN', 'EMPLOYEE'],
            },
        },
        {
            path: 'billing',
            name: 'Billing Settings',
            components: { default: Subscription },
            meta: {
                requiresAuth: true,
                title: 'Billing',
                permissions: ['ADMIN', 'EMPLOYEE'],
            },
        },
        {
            path: 'notifications',
            name: 'Notifications Settings',
            components: { default: NotificationsSettings },
            meta: {
                requiresAuth: true,
                title: 'Notifications',
                permissions: ['ADMIN', 'EMPLOYEE'],
            },
        },
        {
            path: 'tours',
            name: 'Tours',
            components: { default: ToursSettings },
            meta: {
                requiresAuth: true,
                title: 'Tours',
                permissions: ['ADMIN', 'EMPLOYEE'],
            },
        },
    ],
};

const TrainingPage = {
    path: '/training',
    name: 'Training Overview',
    meta: {
        requiresAuth: true,
        title: 'Training Overview',
        permissions: ['ADMIN', 'EMPLOYEE'],
    },
    components: { default: Training },
    redirect: '/training/qna',
    children: [
        {
            path: 'qna',
            name: 'Training',
            components: { default: QnATab },
            meta: {
                requiresAuth: true,
                title: 'Q&A',
                permissions: ['ADMIN', 'EMPLOYEE'],
            },
        },
        {
            path: 'business-knowledge',
            name: 'Training',
            components: { default: BusinessKnowledge },
            meta: {
                requiresAuth: true,
                title: 'Business Knowledge',
                permissions: ['ADMIN', 'EMPLOYEE'],
            },
        },
        {
            path: 'agent',
            name: 'Training',
            components: { default: Agent },
            meta: {
                requiresAuth: true,
                title: 'Agent Training',
                permissions: ['ADMIN', 'EMPLOYEE'],
            },
        },
        {
            path: 'chat',
            name: 'Training',
            components: { default: ChatTest },
            meta: {
                requiresAuth: true,
                title: 'Chat Training',
                permissions: ['ADMIN', 'EMPLOYEE'],
            },
        },
        {
            path: 'training-model',
            name: 'Training',
            components: { default: FineTuning },
            meta: {
                requiresAuth: true,
                title: 'Model Training',
                permissions: ['ADMIN', 'EMPLOYEE'],
            },
        },
    ],
};

const communicationChannelsPage = {
    path: '/communication-channels',
    name: 'Channels Overview',
    meta: {
        requiresAuth: true,
        title: 'Channels Overview',
        permissions: ['ADMIN', 'EMPLOYEE'],
    },
    components: { default: CommunicationChannels },
};

const communicationChannelDetailsPage = {
    path: 'communication-channels/:channelName',
    name: 'Channel Details',
    component: Details_CommunicationChannels,
    meta: {
        requiresAuth: true,
        title: 'Channel Details',
        permissions: ['ADMIN', 'EMPLOYEE'],
    },
};

// const SalesPage = {
//     path: '/sales',
//     name: 'Sales Overview',
//     components: { default: Sales },
//     meta: {
//         requiresAuth: true,
//         title: 'Sales Overview',
//         permissions: ['ADMIN', 'EMPLOYEE'],
//     },
// };

const SkillsPage = {
    path: '/skills',
    name: 'Skills',
    components: { default: Skills },
    meta: {
        requiresAuth: true,
        title: 'Skills Overview',
        permissions: ['ADMIN', 'EMPLOYEE'],
    },
    redirect: '/skills/discovery',
    children: [
        {
            path: 'discovery',
            name: 'Skills Store',
            components: { default: SkillsDiscoveryTab },
            meta: {
                requiresAuth: true,
                title: 'Skills Discovery',
                permissions: ['ADMIN', 'EMPLOYEE'],
            },
        },
        {
            path: 'my-skills',
            name: 'My Skills',
            components: { default: SkillsMySkillsTab },
            meta: {
                requiresAuth: true,
                title: 'My Skills',
                permissions: ['ADMIN', 'EMPLOYEE'],
            },
        },
    ],
};

const skillDetailsPage = {
    path: 'skills/details/:skillId',
    name: 'Skill Details',
    components: { default: SkillsDetails },
    meta: {
        requiresAuth: true,
        title: 'Skill Details',
        permissions: ['ADMIN', 'EMPLOYEE'],
    },
};

const catalog = {
    path: 'catalog',
    name: 'Business Catalog',
    components: { default: Catalog },
    meta: {
        requiresAuth: true,
        title: 'Business Catalog',
        permissions: ['ADMIN', 'EMPLOYEE'],
    },
};

const playgroundPage = {
    path: '/playground',
    name: 'Playground',
    components: { default: Playground },
    meta: {
        requiresAuth: true,
        title: 'Playground',
        permissions: ['ADMIN', 'EMPLOYEE'],
    },
};

const baseDashboard = {
    path: '',
    component: DashboardLayout,
    name: 'Dashboard layout',
    meta: {
        requiresAuth: true,
        title: 'Dashboard',
        permissions: ['ADMIN', 'EMPLOYEE'],
    },
    children: [
        dashboardPage,
        conversationsPage,
        TrainingPage,
        communicationChannelsPage,
        communicationChannelDetailsPage,
        // SalesPage,
        settingsPage,
        SkillsPage,
        skillDetailsPage,
        catalog,
        playgroundPage,
    ],
};

const routes = [
    { path: '*', redirect: '/404' },
    loginPage,
    forgotPasswordPage,
    registerPage,
    invitePage,
    notFoundPage,
    linkExpiredPage,
    onboardPage,
    baseDashboard,
];

export default routes;
