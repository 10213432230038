const state = () => ({
    activeFilters: JSON.parse(localStorage.getItem('activeFilters')) || {},
    presets: JSON.parse(localStorage.getItem('filterPresets')) || [],
});

const getters = {
    activeFilters: (state) => state.activeFilters,
    hasActiveFilters: (state) => Object.keys(state.activeFilters).length > 0,
    presets: (state) => state.presets,
};

const mutations = {
    SET_ACTIVE_FILTERS(state, filters) {
        state.activeFilters = filters;
        localStorage.setItem('activeFilters', JSON.stringify(filters));
    },

    REMOVE_FILTER(state, filterKey) {
        const filters = { ...state.activeFilters };
        delete filters[filterKey];
        state.activeFilters = filters;
        localStorage.setItem('activeFilters', JSON.stringify(filters));
    },

    CLEAR_FILTERS(state) {
        state.activeFilters = {};
        localStorage.removeItem('activeFilters');
    },

    SAVE_PRESET(state, { name, filters }) {
        const preset = {
            id: Date.now(),
            name,
            filters,
        };
        state.presets = [...state.presets, preset];
        localStorage.setItem('filterPresets', JSON.stringify(state.presets));
    },

    REMOVE_PRESET(state, presetId) {
        state.presets = state.presets.filter((preset) => preset.id !== presetId);
        localStorage.setItem('filterPresets', JSON.stringify(state.presets));
    },

    UPDATE_PRESET(state, { id, name, filters }) {
        state.presets = state.presets.map((preset) => (preset.id === id ? { ...preset, name, filters } : preset));
        localStorage.setItem('filterPresets', JSON.stringify(state.presets));
    },

    CLEAR_PRESETS(state) {
        state.presets = [];
        localStorage.removeItem('filterPresets');
    },
};

const actions = {
    setActiveFilters({ commit }, filters) {
        const cleanedFilters = {};
        if (filters.agent && filters.agent !== 'ALL') {
            cleanedFilters.agent = filters.agent;
        }
        if (filters.sentiment && filters.sentiment !== 'ALL') {
            cleanedFilters.sentiment = filters.sentiment;
        }
        if (filters.tags && filters.tags.length > 0) {
            cleanedFilters.tags = [...filters.tags];
        }
        if (filters.excludedTags && filters.excludedTags.length > 0) {
            cleanedFilters.excludedTags = [...filters.excludedTags];
        }
        if (filters.channel && filters.channel !== 'ALL') {
            cleanedFilters.channel = filters.channel;
        }
        commit('SET_ACTIVE_FILTERS', cleanedFilters);
    },

    removeFilter({ commit, state }, filterToRemove) {
        if (Array.isArray(filterToRemove)) {
            const currentTags = state.activeFilters.tags || [];
            const currentExcludedTags = state.activeFilters.excludedTags || [];

            const isInTags = currentTags.some((tag) => filterToRemove.includes(tag));
            const isInExcludedTags = currentExcludedTags.some((tag) => filterToRemove.includes(tag));

            if (isInTags) {
                const updatedTags = currentTags.filter((tag) => !filterToRemove.includes(tag));
                if (updatedTags.length === 0) {
                    commit('REMOVE_FILTER', 'tags');
                } else {
                    commit('SET_ACTIVE_FILTERS', { ...state.activeFilters, tags: updatedTags });
                }
            } else if (isInExcludedTags) {
                const updatedExcludedTags = currentExcludedTags.filter((tag) => !filterToRemove.includes(tag));
                if (updatedExcludedTags.length === 0) {
                    commit('REMOVE_FILTER', 'excludedTags');
                } else {
                    commit('SET_ACTIVE_FILTERS', { ...state.activeFilters, excludedTags: updatedExcludedTags });
                }
            }
        } else {
            // eslint-disable-next-line no-unused-vars
            const keyToDelete = Object.entries(state.activeFilters).find(([_, value]) => value === filterToRemove)?.[0];
            if (keyToDelete) {
                commit('REMOVE_FILTER', keyToDelete);
                if (keyToDelete === 'platform') {
                    commit('platformFilter/SET_SELECTED_PLATFORM', 'ALL', { root: true });
                }
            }
        }
    },

    clearFilters({ commit }) {
        commit('CLEAR_FILTERS');
    },

    savePreset({ commit }, { name, filters }) {
        commit('SAVE_PRESET', { name, filters });
    },

    removePreset({ commit }, presetId) {
        commit('REMOVE_PRESET', presetId);
    },

    updatePreset({ commit }, { id, name, filters }) {
        commit('UPDATE_PRESET', { id, name, filters });
    },

    applyPreset({ commit }, presetId) {
        const preset = state().presets.find((p) => p.id === presetId);
        if (preset) {
            commit('SET_ACTIVE_FILTERS', preset.filters);
        }
    },

    clearPresets({ commit }) {
        commit('CLEAR_PRESETS');
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
