import Vue from 'vue';
import VueI18n from 'vue-i18n';
import enLocale from './locales/en';
import esLocale from './locales/es';

Vue.use(VueI18n);

const messages = {
    en: enLocale,
    es: esLocale,
};

// Get browser language and determine default locale
const browserLang = (navigator.language || navigator.userLanguage).toLowerCase();
const defaultLocale = browserLang.startsWith('es') ? 'es' : 'en';

// Create VueI18n instance with options - this should be the ONLY instance
export const i18n = new VueI18n({
    locale: localStorage.getItem('defaultBrowserLanguage') || defaultLocale,
    fallbackLocale: 'en',
    messages,
    silentTranslationWarn: true,
});

// Add a method to handle locale changes
i18n.setLocaleAndNotify = function (locale) {
    this.locale = locale;
    if (typeof this.onLocaleChanged === 'function') {
        this.onLocaleChanged(locale);
    }
};

// Default export should be the same instance
export default i18n;
