var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper",class:[{ 'nav-open': _vm.$sidebar.showSidebar }, { rtl: _vm.$route.meta.rtlActive }],attrs:{"id":"dashboard-layout"}},[(_vm.isWalkonLivosAccount)?_c('AdminNavBar'):_vm._e(),_c('side-bar',{attrs:{"active-color":_vm.sidebarBackground,"data-background-color":_vm.sidebarBackgroundColor}},[_c('div',{attrs:{"slot":"userMenu"},slot:"userMenu"},[_c('mobile-menu')],1),_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{ name: 'Dashboard', icon: 'dashboard', path: '/dashboard' }}}),_c('sidebar-item',{attrs:{"link":{
                    name: 'Inbox',
                    icon: 'question_answer',
                    path: '/conversations',
                }}}),_c('sidebar-item',{attrs:{"link":{
                    name: 'Training',
                    icon: 'neurology',
                    path: '/training',
                }}}),_c('sidebar-item',{attrs:{"link":{
                    name: 'Skills',
                    icon: 'rocket_launch',
                    path: '/skills',
                }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Playground', icon: 'terminal', path: '/playground' }}}),(_vm.isCatalogSkillActive)?_c('sidebar-item',{attrs:{"link":{
                    name: 'Catalog',
                    icon: 'storefront\n',
                    path: '/catalog',
                }}}):_vm._e()],1),_c('template',{slot:"fixedBottom"},[(_vm.$store.getters['membership/ifSubscribed'])?_c('sidebar-paid-plan-panel'):_c('sidebar-free-plan-panel'),_c('div',{staticClass:"sidebar-separator"}),_c('div',[_c('SidebarNotificationsBellButton',{ref:"notificationsPanel",attrs:{"link":{ name: 'Notifications', icon: 'notifications' },"unreadCount":_vm.unreadNotificationsCount},on:{"notificationPanelOpen":_vm.toggleNotificationsPanel}})],1),_c('sidebar-profile-button')],1)],2),(_vm.showNotificationPanel)?_c('sidebar-notifications-panel',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.handleClickOutside),expression:"handleClickOutside"}],on:{"notificationsRead":_vm.getUnreadNotificationsCount,"notificationsReadAll":_vm.setAllNotificationsAsRead,"closePanel":function($event){_vm.showNotificationPanel = false}}}):_vm._e(),_c('div',{staticClass:"main-panel",class:{ 'main-panel--padding': _vm.isWalkonLivosAccount }},[(_vm.shouldShowNavbar)?_c('top-navbar'):_vm._e(),_c('div',{staticClass:"md-layout",class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.path !== '/conversations'),expression:"$route.path !== '/conversations'"}]}):_vm._e()],1),_c('TheModalWindow'),(!_vm.isInChatPage)?_c('SupportBubbleFab'):_vm._e()],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }