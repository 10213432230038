<template>
    <div class="container">
        <div class="loading-bar" v-if="isLoading">
            <md-progress-bar md-mode="indeterminate"></md-progress-bar>
        </div>
        <div v-else class="template-config">
            <md-card class="template-config__toolbar">
                <md-card-content class="template-config__toolbar-content">
                    <div class="template-config__toolbar-content-left">
                        <LixsaSkillAbandonCartTimer v-if="timer" :timer.sync="timer" :is-invalid="isInvalidTimer" />
                        <div class="template-config__toolbar-language">
                            <div class="md-body-2">Language:</div>
                            <md-field>
                                <md-select v-model="language" name="language" id="language">
                                    <md-option
                                        v-for="language in getAvailableLanguages"
                                        :key="language.tag"
                                        :value="language.tag"
                                    >
                                        {{ language.name }}
                                    </md-option>
                                </md-select>
                            </md-field>
                        </div>
                        <div class="template-config__toolbar-status">
                            <div class="md-body-2">Status:</div>
                            <md-chip :class="statusChipClass">
                                <span>{{ getCurrentTemplateStatus }}</span>
                            </md-chip>
                        </div>
                    </div>
                    <SaveButton :state="saveButtonState" @save="saveTemplate" />
                </md-card-content>
            </md-card>
            <div class="template-config__container">
                <div class="template-config__editor">
                    <TheTinymceEditor
                        :variables="availableVariables"
                        :initial-content="templateContent"
                        @update:content="handleContentUpdate"
                    />
                </div>
                <div class="template-config__preview">
                    <WhatsappPreview :variables="availableVariables" :content="templateContent" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TheTinymceEditor from '@/components/TheTinymceEditor.vue';
import WhatsappPreview from './LixsaSkillAbandonCartWhatsappPreview.vue';
import LixsaSkillAbandonCartTimer from './LixsaSkillAbandonCartTimer.vue';
import SaveButton from '@/components/SaveButton.vue';
import { mapGetters, mapActions } from 'vuex';
import debounce from 'lodash/debounce';

import VariablesAdapter from './Utils/VariablesAdapter';
import TemplateContentAdapter from './Utils/TemplateContentAdapter';

import CheckoutSchedulerDto from '@/services/skills/Dto/CheckoutSchedulerDto';

const STATUS_OPTIONS = ['NEW', 'DRAFT', 'APPROVED', 'REJECTED', 'DISABLED', 'FLAGGED'];

export default {
    name: 'LixsaSkillAbandonCartTemplateConfig',
    components: {
        TheTinymceEditor,
        WhatsappPreview,
        LixsaSkillAbandonCartTimer,
        SaveButton,
    },
    props: {
        isSkillEnabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            originalTemplateContent:
                '<p>Hello&nbsp;<span class="variable" data-variable="{{firstName}}">{{firstName}}</span>.</p>',
            templateContent:
                '<p>Hello&nbsp;<span class="variable" data-variable="{{firstName}}">{{firstName}}</span>.</p>',
            availableVariables: [
                { label: 'First Name', value: '{{firstName}}' },
                { label: 'Cart Items', value: '{{cartItems}}' },
                { label: 'Cart Total', value: '{{cartTotal}}' },
            ],
            status: 'Waiting',
            isInvalidTimer: false,
            saveButtonState: 'saved',
            originalTimer: null,
            pendingTimerValue: null,
            isLoading: true,
            forceTimerSave: false,
        };
    },
    computed: {
        ...mapGetters('abandonedCheckout', [
            'getCurrentTemplate',
            'getCurrentLanguage',
            'getSchedulerTimer',
            'getCurrentTemplateStatus',
            'getAvailableLanguages',
            'currentTemplateIsSavedInTemplatesList',
        ]),
        ...mapGetters('agentSettings', ['agentSettings']),
        language: {
            get() {
                return this.getCurrentLanguage;
            },
            set(value) {
                this.changeCurrentLanguage(value);
            },
        },
        timer: {
            get() {
                return this.getSchedulerTimer;
            },
            set(value) {
                this.debouncedChangeSchedulerTimer(value);
            },
        },
        statusChipClass() {
            const status = this.getCurrentTemplateStatus;
            return {
                'status-new': status === 'NEW',
                'status-draft': status === 'DRAFT',
                'status-approved': status === 'APPROVED',
                'status-rejected': status === 'REJECTED',
                'status-disabled': status === 'DISABLED',
                'status-flagged': status === 'FLAGGED',
            };
        },
        shouldAllowSave() {
            const hasTemplateChanges =
                !this.currentTemplateIsSavedInTemplatesList || this.templateContent !== this.originalTemplateContent;
            const hasTimerChanges =
                this.forceTimerSave ||
                (this.originalTimer !== null &&
                    this.pendingTimerValue !== null &&
                    this.originalTimer !== this.pendingTimerValue);
            return hasTemplateChanges || hasTimerChanges;
        },
    },
    watch: {
        shouldAllowSave: {
            immediate: true,
            handler(newValue) {
                if (this.saveButtonState !== 'saving' && this.saveButtonState !== 'success') {
                    this.saveButtonState = newValue ? 'save' : 'saved';
                }
            },
        },
        isSkillEnabled(newIsSkillEnabled) {
            if (newIsSkillEnabled) {
                this.pendingTimerValue = this.timer;
                this.forceTimerSave = true;
                this.saveButtonState = 'save';
                this.saveTemplate();
            }
        },
    },
    methods: {
        ...mapActions('abandonedCheckout', [
            'fetchTemplatesList',
            'fetchAvailableLanguages',
            'fetchCurrentLanguage',
            'setCurrentLanguage',
            'setSchedulerTimer',
            'updateTemplate',
            'fetchCurrentTemplate',
            'fetchCheckoutScheduler',
            'subscribeToTemplateUpdates',
            'unsubscribeFromTemplateUpdates',
        ]),

        ...mapActions('websocket', ['subscribe', 'unsubscribe']),

        async changeCurrentLanguage(language) {
            this.setCurrentLanguage(language);
            await this.fetchTemplateInfo();
            this.populateWithCurrentTemplate();
        },

        async changeSchedulerTimer(timer) {
            if (timer < 10) {
                this.isInvalidTimer = true;
                return;
            }

            this.isInvalidTimer = false;
            if (this.originalTimer === null) {
                this.originalTimer = this.getSchedulerTimer;
            }
            this.pendingTimerValue = timer;
        },

        async saveTemplate() {
            if (this.saveButtonState !== 'save') return;

            this.saveButtonState = 'saving';
            const hasTemplateChanges =
                !this.currentTemplateIsSavedInTemplatesList || this.templateContent !== this.originalTemplateContent;
            const hasTimerChanges =
                this.forceTimerSave ||
                (this.originalTimer !== null &&
                    this.pendingTimerValue !== null &&
                    this.originalTimer !== this.pendingTimerValue);

            try {
                if (hasTemplateChanges) {
                    const contentInString = TemplateContentAdapter.fromWysiwyg(this.templateContent);
                    await this.updateTemplate({
                        templateContent: contentInString,
                        accountId: this.$store.state.user.account.uuid,
                    });
                    await this.fetchTemplateInfo();
                    this.populateWithCurrentTemplate();
                }

                if (hasTimerChanges) {
                    const checkoutScheduler = new CheckoutSchedulerDto.Builder()
                        .withAccountId(this.$store.state.user.account.uuid)
                        .withAgentName(this.agentSettings.name)
                        .withTtlMinutes(this.pendingTimerValue)
                        .build();

                    await this.setSchedulerTimer(checkoutScheduler);
                    this.originalTimer = this.pendingTimerValue;
                    this.forceTimerSave = false;
                }

                if (hasTemplateChanges || hasTimerChanges) {
                    this.saveButtonState = 'success';
                    setTimeout(() => {
                        this.saveButtonState = 'saved';
                    }, 2000);
                } else {
                    this.saveButtonState = 'saved';
                }
            } catch (error) {
                console.error('Error saving:', error);
                this.saveButtonState = 'save';
                this.forceTimerSave = false;
            }
        },

        handleContentUpdate(newContent) {
            this.templateContent = newContent;
        },

        populateWithCurrentTemplate() {
            try {
                const template = this.getCurrentTemplate;
                if (template) {
                    if (template.variables && template.variables[0]) {
                        this.setAvailableVariables(template.variables[0]);
                    }
                    this.setTemplateContent(template.body || (template.variables[0]?.contentExample ?? ''));
                }
            } catch (error) {
                console.error(error);
            }
        },

        async fetchTemplateInfo() {
            try {
                await this.fetchTemplatesList();
                await this.fetchAvailableLanguages();
                await this.fetchCurrentTemplate();
            } catch (error) {
                console.error('Error fetching template content:', error);
            }
        },

        async fetchCheckoutSchedulerInfo() {
            try {
                await this.fetchCheckoutScheduler();
                this.originalTimer = this.getSchedulerTimer;
            } catch (error) {
                console.warn('No checkout scheduler found, using default value of 30 minutes');
            }
        },

        async setAvailableVariables(templateVariables) {
            this.availableVariables = VariablesAdapter.execute(templateVariables);
        },

        async setTemplateContent(content) {
            this.templateContent = TemplateContentAdapter.execute(content);
            this.originalTemplateContent = this.templateContent;
        },
    },
    async created() {
        this.debouncedChangeSchedulerTimer = debounce(this.changeSchedulerTimer, 500);
        await this.fetchTemplateInfo();
        await this.fetchCheckoutSchedulerInfo();
        await this.fetchCurrentLanguage();
        await this.populateWithCurrentTemplate();
        await this.subscribeToTemplateUpdates();
        this.isLoading = false;
    },
    beforeDestroy() {
        this.debouncedChangeSchedulerTimer.cancel();
        this.unsubscribeFromTemplateUpdates();
    },
};
</script>

<style scoped lang="scss">
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    margin-bottom: 1rem;
    width: 100%;
}

.md-card {
    margin-top: 0;
}

.loading-bar {
    margin: 0;
    width: 100%;
}

.template-config {
    width: fit-content;

    @media (max-width: 768px) {
        width: 100%;
    }

    &__toolbar {
        display: flex;
        gap: 16px;
        margin-bottom: 1rem;
    }

    &__toolbar-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // width: 100%;

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: start;
            justify-content: start;
            gap: 1rem;
            padding-bottom: 0.5rem;
        }
    }

    &__toolbar-content-left {
        display: flex;
        gap: 2rem;
        align-items: center;
        // width: 100%;

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: start;
            justify-content: start;
            gap: 1rem;
            padding-bottom: 0.5rem;
        }
    }

    &__toolbar-language {
        display: flex;
        gap: 0.5rem;
        align-items: center;
    }

    &__toolbar-status {
        display: flex;
        gap: 0.5rem;
        align-items: center;
    }

    &__container {
        display: flex;
        align-items: start;
        justify-content: start;
        gap: 1rem;

        @media (max-width: 768px) {
            flex-direction: column;
            gap: 1rem;
        }
    }

    &__editor {
        min-width: 0; // Prevent overflow issues
        width: 600px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        @media (max-width: 768px) {
            width: 100%;
        }
    }

    &__preview {
        min-width: 0; // Prevent overflow issues
    }

    .md-field {
        margin-bottom: 0;
        width: 120px;

        .md-input {
            width: 100%;
            justify-content: center;
        }
    }
}

.status-new {
    background-color: #2196f3 !important;
    color: white !important;
}

.status-draft {
    background-color: #9e9e9e !important;
    color: white !important;
}

.status-approved {
    background-color: #4caf50 !important;
    color: white !important;
}

.status-rejected {
    background-color: #f44336 !important;
    color: white !important;
}

.status-disabled {
    background-color: #607d8b !important;
    color: white !important;
}

.status-flagged {
    background-color: #ff9800 !important;
    color: white !important;
}
</style>
