<template>
    <div>
        <div class="section-header">
            <span class="section-title">Booking summary</span>
        </div>

        <div class="info-container">
            <!-- Date -->
            <div class="info-item">
                <svg-icon class="info-icon" name="schedule" />
                <div class="info-content">
                    <div class="info-label">Date</div>
                    <div class="info-value">{{ friendlyBookingDate }}</div>
                </div>
            </div>

            <!-- Guests -->
            <div class="info-item" v-if="hasGuests">
                <svg-icon class="info-icon" name="people" />
                <div class="info-content">
                    <div class="info-label">{{ guestsLabel }}</div>
                    <div class="info-value">{{ selectedAsset.amount_guests }}</div>
                </div>
            </div>

            <!-- Children -->
            <div class="info-item" v-if="hasChildren">
                <svg-icon class="info-icon" name="children" />
                <div class="info-content">
                    <div class="info-label">Children</div>
                    <div class="info-value">{{ selectedAsset.amount_children }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SvgIcon from '@/components/Chat/SvgIcon/SvgIcon.vue';

export default {
    name: 'CustomerOrderSummaryHostelryBooking',
    components: {
        SvgIcon,
    },
    props: {
        selectedAsset: {
            type: Object,
            required: true,
            validator(asset) {
                return asset.booking_date !== undefined;
            },
        },
    },
    computed: {
        friendlyBookingDate() {
            const options = {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
            };
            const date = new Date(this.selectedAsset.booking_date);
            return date.toLocaleDateString('en-US', options);
        },
        hasGuests() {
            return this.selectedAsset.amount_guests > 0;
        },
        hasChildren() {
            return this.selectedAsset.amount_children > 0;
        },
        guestsLabel() {
            return this.hasChildren ? 'Adults' : 'Guests';
        },
        totalGuests() {
            const adults = this.selectedAsset.amount_guests || 0;
            const children = this.selectedAsset.amount_children || 0;
            return adults + children;
        },
    },
};
</script>
