<template>
    <div class="info-item" @click="toggleShowDetailsOrder">
        <svg-icon class="info-icon" name="description" />
        <div class="info-content">
            <div class="info-header">
                <span class="info-title">{{ order.name }}</span>
            </div>
            <div class="status-badges">
                <span :class="['status-badge', `badge-${order.draft_order_creation_status.toLowerCase()}`]">
                    {{ removeUnderscores(order.draft_order_creation_status).toLocaleUpperCase() }}
                </span>
                <span :class="['status-badge', `badge-${order.draft_order_sync_status.toLowerCase()}`]">
                    {{ removeUnderscores(order.draft_order_sync_status).toLocaleUpperCase() }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import SvgIcon from '@/components/Chat/SvgIcon/SvgIcon.vue';
import { getCurrencySymbol, formatNumberWithDecimals, getBadgeClass } from '@/utils/common.js';

export default {
    name: 'CustomerAssetsCardEcommerceDraftOrder',
    components: {
        SvgIcon,
    },
    props: {
        order: { type: [Object], required: true },
        showDetailsOrder: { type: [Boolean], required: true },
    },
    methods: {
        toggleShowDetailsOrder() {
            this.$emit('toggle-details-order', this.order);
        },
        removeUnderscores(value) {
            return value.replace(/_/g, ' ');
        },
        getBadgeClass,
        getCurrencySymbol,
        formatNumberWithDecimals,
    },
};
</script>

<style lang="scss">
@import './CustomerAssetsCardEcommerceDraftOrder.scss';
</style>
