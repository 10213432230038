import BaseDto from '../../_common/BaseDto';
import TemplateDto from './TemplateDto';

class PageTemplateDtoBuilder {
    constructor() {
        this.totalElements = undefined;
        this.totalPages = undefined;
        this.first = undefined;
        this.last = undefined;
        this.numberOfElements = undefined;
        this.size = undefined;
        this.content = undefined;
        this.number = undefined;
        this.empty = undefined;
    }

    withTotalElements(totalElements) {
        this.totalElements = totalElements;
        return this;
    }

    withTotalPages(totalPages) {
        this.totalPages = totalPages;
        return this;
    }

    withFirst(first) {
        this.first = first;
        return this;
    }

    withLast(last) {
        this.last = last;
        return this;
    }

    withNumberOfElements(numberOfElements) {
        this.numberOfElements = numberOfElements;
        return this;
    }

    withSize(size) {
        this.size = size;
        return this;
    }

    withContent(content) {
        this.content = content.map((template) => {
            return new TemplateDto.Builder()
                .withUuid(template.uuid)
                .withAccountId(template.account_id)
                .withBody(template.body)
                .withHeader(template.header)
                .withChannelType(template.channel_type)
                .withTemplateName(template.template_name)
                .withLanguage(template.language)
                .withCategory(template.category)
                .withStatus(template.status)
                .withScore(template.score)
                .withType(template.type)
                .withTemplateType(template.template_type)
                .withFlowType(template.flow_type)
                .withSkillId(template.skill_id)
                .withInternalTemplateId(template.internal_template_id)
                .withTemplateParameters(template.template_parameters)
                .withExtraParameters(template.extra_parameters)
                .withIssues(template.issues)
                .withModifyBy(template.modify_by)
                .withDateCreated(template.date_created)
                .withVariables(template.variables)
                .build();
        });
        return this;
    }

    withNumber(number) {
        this.number = number;
        return this;
    }

    withEmpty(empty) {
        this.empty = empty;
        return this;
    }

    build() {
        return new PageTemplateDto(this);
    }
}

class PageTemplateDto extends BaseDto {
    constructor(builder) {
        super();
        this.totalElements = builder.totalElements;
        this.totalPages = builder.totalPages;
        this.first = builder.first;
        this.last = builder.last;
        this.numberOfElements = builder.numberOfElements;
        this.size = builder.size;
        this.content = builder.content;
        this.number = builder.number;
        this.empty = builder.empty;
    }

    static get Builder() {
        return PageTemplateDtoBuilder;
    }
}

export default PageTemplateDto;
