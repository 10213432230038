<template>
    <div class="custom-main-panel">
        <div class="md-layout custom-content-panel">
            <div class="ps__layout-item--margin-bottom md-layout-item md-size-100 switch-container">
                <p class="md-subheading">Manage your onboarding tours</p>
            </div>
            <div class="md-layout-item md-size-100">
                <md-card>
                    <md-card-content>
                        <p class="md-subheading settings-title">Available Tours</p>
                        <div class="tours-switches">
                            <div class="switch-container">
                                <div class="switch-info-container">
                                    <span class="switch-text switch-title">Reset all tours</span>
                                    <span class="switch-text">This will reactivate all onboarding tours</span>
                                </div>
                                <md-button class="md-primary md-sm" @click="resetAllTours">Reset all tours</md-button>
                            </div>
                            <md-divider></md-divider>

                            <!-- QnA Tour -->
                            <div class="switch-container">
                                <div class="switch-info-container">
                                    <span class="switch-text switch-title">Q&A Tour</span>
                                    <span class="switch-text"
                                        >Learn how to manage questions and answers for your agent</span
                                    >
                                </div>
                                <md-checkbox
                                    :value="!isCompleted('qna-tour')"
                                    class="md-primary"
                                    @change="toggleTour('qna-tour')"
                                >
                                </md-checkbox>
                            </div>
                            <md-divider></md-divider>

                            <!-- Business Knowledge Tour -->
                            <div class="switch-container">
                                <div class="switch-info-container">
                                    <span class="switch-text switch-title">Business Knowledge Tour</span>
                                    <span class="switch-text">Guide through the business knowledge section</span>
                                </div>
                                <md-checkbox
                                    :value="!isCompleted('business-knowledge-tour')"
                                    class="md-primary"
                                    @change="toggleTour('business-knowledge-tour')"
                                >
                                </md-checkbox>
                            </div>
                            <md-divider></md-divider>

                            <!-- Agent Tour -->
                            <div class="switch-container">
                                <div class="switch-info-container">
                                    <span class="switch-text switch-title">Agent Tour</span>
                                    <span class="switch-text">Learn about agent configuration</span>
                                </div>
                                <md-checkbox
                                    :value="!isCompleted('agent-tour')"
                                    class="md-primary"
                                    @change="toggleTour('agent-tour')"
                                >
                                </md-checkbox>
                            </div>
                            <md-divider></md-divider>

                            <!-- Playground Tour -->
                            <div class="switch-container">
                                <div class="switch-info-container">
                                    <span class="switch-text switch-title">Playground Tour</span>
                                    <span class="switch-text">Learn how to test and interact with your agent</span>
                                </div>
                                <md-checkbox
                                    :value="!isCompleted('playground-tour')"
                                    class="md-primary"
                                    @change="toggleTour('playground-tour')"
                                >
                                </md-checkbox>
                            </div>
                            <md-divider></md-divider>

                            <!-- Skills Tour -->
                            <div class="switch-container">
                                <div class="switch-info-container">
                                    <span class="switch-text switch-title">Skills Tour</span>
                                    <span class="switch-text">Discover available skills and capabilities</span>
                                </div>
                                <md-checkbox
                                    :value="!isCompleted('skills-tour')"
                                    class="md-primary"
                                    @change="toggleTour('skills-tour')"
                                >
                                </md-checkbox>
                            </div>
                            <md-divider></md-divider>

                            <!-- WhatsApp Channel Tour -->
                            <div class="switch-container">
                                <div class="switch-info-container">
                                    <span class="switch-text switch-title">WhatsApp Channel Tour</span>
                                    <span class="switch-text">Setup your WhatsApp integration</span>
                                </div>
                                <md-checkbox
                                    :value="!isCompleted('whatsapp-channel-tour')"
                                    class="md-primary"
                                    @change="toggleTour('whatsapp-channel-tour')"
                                >
                                </md-checkbox>
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'ToursSettings',
    data() {
        return {
            tours: [
                'qna-tour',
                'business-knowledge-tour',
                'agent-tour',
                'playground-tour',
                'skills-tour',
                'whatsapp-channel-tour',
            ],
        };
    },
    computed: {
        ...mapGetters('tours', ['isCompleted']),
    },
    methods: {
        ...mapActions('tours', ['clearToursState', 'resetTour', 'markComplete']),
        resetAllTours() {
            this.clearToursState();
            this.showSuccessToast('All tours have been reset');
        },
        toggleTour(tourId) {
            if (this.isCompleted(tourId)) {
                // If tour was completed, reset it by removing from completed list
                this.resetTour(tourId);

                this.showSuccessToast(`${tourId} has been reset`);
            } else {
                // If tour was not completed, mark it as complete
                this.markComplete(tourId);
                this.showSuccessToast(`${tourId} has been marked as completed`);
            }
        },
        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.settings-title {
    margin-top: 5px !important;
}

.custom-content-panel {
    margin-bottom: 40px;
    padding-left: 30px;
    padding-right: 30px;

    @media (max-width: 1278px) {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media (max-width: 959px) {
        padding-left: 0px;
        padding-right: 0px;
    }

    p {
        margin-top: 24px;
    }

    .md-card {
        margin-top: 0px;
    }
}

.tours-switches {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
}

.switch-title {
    font-weight: bold;
}

.switch-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.switch-info-container {
    display: flex;
    flex-direction: column;
}

.switch-text {
    margin-left: 10px;
    color: #000;
}
</style>
