<script>
import { Pagination } from '@/components';
import DynamicCatalogItemDetails from '@/components/Training/Catalog/CatalogItemDetails.vue';
import DynamicCatalogActionBar from '@/components/Training/Catalog/CatalogActionBar.vue';
import DynamicCatalogUpdateItemForm from '@/components/Training/Catalog/CatalogUpdateItemForm.vue';
import CatalogServiceV2 from '@/services/productCatalog/CatalogServiceV2';
import Swal from 'sweetalert2';
import { mapActions } from 'vuex';

export default {
    name: 'CatalogComponent',
    components: {
        DynamicCatalogUpdateItemForm,
        DynamicCatalogActionBar,
        Pagination,
    },
    computed: {
        queriedData() {
            const result = this.searchQuery ? this.searchedData : this.tableData;
            return result.slice(0, this.numberOfElements);
        },
        to() {
            return Math.min(this.pagination.currentPage * this.pagination.perPage, this.pagination.total);
        },
        from() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.searchedData.length > 0 ? this.searchedData.length : this.tableData.length;
        },
        noData() {
            return this.isEmpty && this.searchQuery === '';
        },
        defaultAvatarUrl() {
            return process.env.VUE_APP_USER_AVATAR_URL;
        },
    },
    data() {
        return {
            currentSort: 'name',
            currentSortOrder: 'asc',
            pagination: {
                perPage: 10,
                currentPage: 1,
                perPageOptions: [10, 20, 40, 80, 100, 200, 400, 800, 1000],
                total: 0,
            },
            searchQuery: '',
            tableData: [],
            searchedData: [],
            totalEntries: 0,
            totalPages: 0,
            numberOfElements: 0,
            isLoading: false,
            isSyncing: false,
            isTraining: false,
            isDeleting: false,
            isEmpty: true,
            catalogSync: true,
            catalogFetchStatus: 'FINISHED',
            catalogfetchProgression: 100,
            catalogfetchError: '',
        };
    },
    watch: {
        catalogSync(newValue) {
            if (newValue === null) {
                this.catalogSync = true;
            }
        },
        catalogFetchStatus(newValue) {
            if (newValue === null) {
                this.catalogFetchStatus = 'FINISHED';
            }
        },
        searchQuery(value) {
            if (value === '' && value.length > 3) {
                this.searchedData = this.tableData;
                return;
            }

            setTimeout(() => {
                this.searchItemsByTerm(value).then((searchResults) => {
                    this.searchedData = searchResults;
                });
            }, 500);
        },
        'pagination.currentPage': function () {
            if (this.searchQuery) {
                this.searchItemsByTerm(this.searchQuery).then((searchResults) => {
                    this.searchedData = searchResults;
                });
            } else {
                this.fetchTableData();
            }
        },
        'pagination.perPage': function () {
            if (this.searchQuery) {
                this.searchItemsByTerm(this.searchQuery).then((searchResults) => {
                    this.searchedData = searchResults;
                });
            } else {
                this.fetchTableData();
            }
        },
    },
    methods: {
        ...mapActions('modalStore', ['openModal']),
        ...mapActions('websocket', ['subscribe', 'unsubscribe']),

        handleImageError(event) {
            event.target.src = this.defaultAvatarUrl;
        },

        openItemDetails(item) {
            this.openModal({
                component: DynamicCatalogItemDetails,
                props: { item: item },
            });
        },

        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },

        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },

        showInfoToast(msg) {
            this.$toasted.info(msg, {
                position: 'bottom-center',
                icon: 'info',
                duration: 3000,
            });
        },

        truncateText(text, length) {
            if (text.length <= length) {
                return text;
            } else {
                return text.substring(0, length) + '...';
            }
        },

        parseJSON(jsonString) {
            try {
                return JSON.parse(jsonString);
            } catch (error) {
                console.error('Error parsing JSON:', error);
                return null;
            }
        },

        formatDate(timestamp) {
            const options = {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
            };
            const date = new Date(timestamp * 1000);
            return date.toLocaleDateString('en-US', options);
        },

        customSort(value) {
            return value.sort((a, b) => {
                const sortBy = this.currentSort;
                if (this.currentSortOrder === 'desc') {
                    return a[sortBy].localeCompare(b[sortBy]);
                }
                return b[sortBy].localeCompare(a[sortBy]);
            });
        },

        async searchItemsByTerm(searchTerm) {
            let results = this.searchedData.length > 0 ? this.searchedData : this.tableData;
            try {
                const response = await CatalogServiceV2.searchItems(
                    this.pagination.currentPage - 1,
                    this.pagination.perPage,
                    searchTerm,
                );
                if (response) {
                    results = response.data.content ? response.data.content : [];
                    this.totalEntries = response.data.totalElements;
                    this.totalPages = response.data.totalPages;
                    this.numberOfElements = response.data.numberOfElements;
                    this.pagination.total = response.data.totalElements;
                    this.isEmpty = response.data.empty;
                }
            } catch (error) {
                console.error('Error searching items: ', error);
            }

            return results;
        },

        async fetchTableData() {
            try {
                this.isLoading = true;
                const response = await CatalogServiceV2.fetchCatalog(
                    this.pagination.currentPage - 1,
                    this.pagination.perPage,
                );

                if (response && response.data) {
                    this.tableData = response.data.content;
                    this.totalEntries = response.data.totalElements;
                    this.totalPages = response.data.totalPages;
                    this.numberOfElements = response.data.numberOfElements;
                    this.pagination.total = response.data.totalElements;
                    this.isEmpty = response.data.empty;
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                this.isLoading = false;
            }
        },

        updateTableData(newData) {
            const index = this.queriedData.findIndex((data) => data.uuid === newData.uuid);
            if (index !== -1) {
                this.tableData = [...this.queriedData.slice(0, index), newData, ...this.queriedData.slice(index + 1)];
            }
        },

        handleDelete(item) {
            Swal.fire({
                title: 'Are you sure?',
                text: `You won't be able to revert this!`,
                type: 'warning',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#4caf50',
                confirmButtonText: 'Yes, delete it!',
                buttonsStyling: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteRow(item);
                    this.showSuccessToast('Item deleted');
                }
            });
        },

        deleteRow(item) {
            let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === item.id);
            if (indexToDelete >= 0) {
                this.tableData.splice(indexToDelete, 1);
                CatalogServiceV2.deleteItem(item.uuid)
                    .then(() => {
                        this.fetchTableData();
                    })
                    .catch((error) => {
                        console.error('An error occurred while deleting the item:', error);
                    });
            }
        },

        async changeVisibility(product) {
            try {
                product.is_visible = !product.is_visible;
                const updateData = {
                    is_visible: product.is_visible,
                };
                await CatalogServiceV2.updateItem(product.uuid, updateData);
            } catch (error) {
                console.error('Error changing visibility', error);
                product.is_visible = !product.is_visible;
                this.showErrorToast('Error changing visibility');
            }
        },

        subscribeToCatalog() {
            const topic = `/account/${this.$store.state.user.user.account_id}/catalog/${this.$store.state.user.user.account_id}`;
            this.subscribe({
                topic,
                callback: this.handleCatalogEvent,
            });
        },

        handleCatalogItemTrainingEvent(updatedData) {
            const newProductData = this.parseJSON(updatedData.data);
            if (!newProductData) return;

            this.updateTableData(newProductData);
        },

        handleCatalogEvent(message) {
            if (!message || !message.data) return;
            try {
                const parsedData = JSON.parse(message.data);
                if (!parsedData) return;

                switch (message.name) {
                    case 'CatalogItemFinishedTraining':
                    case 'CatalogItemStartedTraining':
                        this.handleCatalogItemTrainingEvent(message);
                        break;

                    case 'CatalogTrainingStarted':
                        this.showInfoToast('Catalog Training started');
                        break;

                    case 'CatalogTrainingFinished':
                        this.showInfoToast('Catalog Training Finished');
                        break;
                }
            } catch (error) {
                console.error('Error processing QnA event:', error);
            }
        },
    },

    async mounted() {
        this.fetchTableData();
        this.subscribeToCatalog();
    },

    beforeDestroy() {
        const topic = `/account/${this.$store.state.user.user.account_id}/catalog/${this.$store.state.user.user.account_id}`;
        this.unsubscribe({
            topic,
            callback: this.handleCatalogEvent,
        });
    },
};
</script>

<template>
    <div>
        <DynamicCatalogActionBar @item-created="fetchTableData" @bulk-delete-completed="fetchTableData" />
        <md-card>
            <md-card-content class="custom-loading-spinner" v-if="isLoading">
                <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
            </md-card-content>

            <md-card-content v-else-if="!isLoading">
                <md-progress-bar
                    v-if="catalogfetchProgression < 100"
                    md-mode="determinate"
                    :md-value="catalogfetchProgression"
                ></md-progress-bar>
                <div v-if="!noData">
                    <md-table
                        :value="queriedData"
                        :md-sort.sync="currentSort"
                        :md-sort-order.sync="currentSortOrder"
                        :md-sort-fn="customSort"
                        class="paginated-table table-shopping"
                    >
                        <md-table-toolbar>
                            <md-field>
                                <label for="pages">Per page</label>
                                <md-select v-model="pagination.perPage" name="pages">
                                    <md-option
                                        v-for="item in pagination.perPageOptions"
                                        :key="item"
                                        :label="item"
                                        :value="item"
                                    >
                                        {{ item }}
                                    </md-option>
                                </md-select>
                            </md-field>

                            <md-field>
                                <md-input
                                    type="search"
                                    class="mb-3"
                                    clearable
                                    style="width: 200px"
                                    placeholder="Search products"
                                    v-model="searchQuery"
                                >
                                </md-input>
                            </md-field>
                        </md-table-toolbar>

                        <md-table-row slot="md-table-row" slot-scope="{ item }">
                            <md-table-cell md-label="">
                                <div @click="openItemDetails(item)" class="img-container custom-image-clickable">
                                    <img
                                        @error="handleImageError"
                                        :src="item.images && item.images.length > 0 ? item.images[0] : defaultAvatarUrl"
                                        alt="products"
                                    />
                                </div>
                            </md-table-cell>
                            <md-table-cell md-label="Name">{{ item.name }}</md-table-cell>
                            <md-table-cell md-label="Description">{{
                                truncateText(item.description, 100)
                            }}</md-table-cell>
                            <md-table-cell md-label="Created">{{ formatDate(item.date_created) }}</md-table-cell>
                            <md-table-cell md-label="Updated">{{ formatDate(item.date_updated) }}</md-table-cell>

                            <!--              <md-table-cell class="custom-icon-cell" md-label="Training Status">-->
                            <!--                <i v-if="item.training_status === 'TRAINED'" class="fas fa-check custom-check-icon"></i>-->
                            <!--                <i v-else-if="item.training_status === 'PENDING'" class="fas fa-sync fa-spin custom-sync-icon"></i>-->
                            <!--                <i v-else-if="item.training_status === 'ERROR'" class="fas fa-exclamation-triangle custom-warning-icon">-->
                            <!--                  <md-tooltip md-direction="right"> <span class="md-caption">Something were wrong</span></md-tooltip>-->
                            <!--                </i>-->
                            <!--                <i v-else-if="item.training_status === 'NOT_TRAINED' || item.training_status === null"-->
                            <!--                   class="fas fa-times custom-error-icon"></i>-->
                            <!--              </md-table-cell>-->
                            <md-table-cell md-label="">
                                <md-button
                                    v-if="item.training_status === 'TRAINED'"
                                    class="md-just-icon md-simple md-green"
                                >
                                    <i class="material-symbols-outlined">check_circle</i>
                                    <md-tooltip md-direction="bottom"> Item trained </md-tooltip>
                                </md-button>
                                <md-button
                                    v-else-if="item.training_status === 'PENDING'"
                                    class="md-just-icon md-simple md-primary"
                                >
                                    <i class="material-symbols-outlined catalog__sync-icon">sync</i>
                                    <md-tooltip md-direction="bottom">
                                        Training item. <strong>Please wait</strong>
                                    </md-tooltip>
                                </md-button>
                                <md-button
                                    v-else-if="item.training_status === 'ERROR'"
                                    class="md-just-icon md-simple md-danger"
                                >
                                    <i class="material-symbols-outlined">error</i>
                                    <md-tooltip md-direction="bottom">
                                        Error training.
                                        <!--                    <strong>Click to re-train</strong>-->
                                    </md-tooltip>
                                </md-button>
                                <md-button
                                    v-else-if="item.training_status === 'NOT_TRAINED' || item.training_status === null"
                                    class="md-just-icon md-simple md-warning"
                                >
                                    <i class="material-symbols-outlined">warning</i>
                                    <md-tooltip md-direction="bottom">
                                        Item not trained.
                                        <!--                    <strong>Click to train</strong>-->
                                    </md-tooltip>
                                </md-button>
                                <md-button @click="handleDelete(item)" class="md-just-icon md-danger md-simple">
                                    <i class="material-symbols-outlined">delete</i>
                                    <md-tooltip md-direction="bottom">Delete product</md-tooltip>
                                </md-button>
                                <DynamicCatalogUpdateItemForm
                                    class="catalog__action-icon--margin"
                                    :product="item"
                                    @item-edited="fetchTableData"
                                ></DynamicCatalogUpdateItemForm>
                                <md-button
                                    v-if="item.is_visible"
                                    @click="changeVisibility(item)"
                                    class="md-just-icon md-primary md-simple"
                                >
                                    <i class="material-symbols-outlined">toggle_on</i>
                                    <md-tooltip md-direction="bottom"
                                        >Lixsa will consider this product in chats.
                                        <strong>Click to change visibility</strong></md-tooltip
                                    >
                                </md-button>
                                <md-button
                                    v-else
                                    @click="changeVisibility(item)"
                                    class="md-just-icon md-gray md-simple"
                                >
                                    <i class="material-symbols-outlined">toggle_off</i>
                                    <md-tooltip md-direction="bottom"
                                        >Lixsa will not consider this product in chats.
                                        <strong>Click to change visibility</strong></md-tooltip
                                    >
                                </md-button>
                                <!--                <md-switch ></md-switch>-->
                            </md-table-cell>
                        </md-table-row>
                    </md-table>
                </div>

                <div v-else-if="noData">
                    <md-empty-state
                        md-icon="shelves"
                        md-label="No Items Found"
                        md-description="Don't let your Catalog table stay empty. Help improve Lixsa accuracy by giving it information about your products."
                    >
                    </md-empty-state>
                </div>
            </md-card-content>
            <md-card-actions v-if="!noData">
                <pagination
                    class="pagination-no-border pagination-success"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total="totalEntries"
                    :from="from + 1"
                    :to="to"
                >
                </pagination>
            </md-card-actions>
        </md-card>
    </div>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/md/variables';

.md-card {
    margin-top: 5px;
}

.custom-loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
}

.table-shopping .md-table-head:nth-child(5),
.table-shopping .md-table-head:nth-child(7),
.table-shopping .md-table-head:nth-child(6) {
    text-align: right;
}

.custom-image-clickable {
    cursor: pointer;
}

.catalog__sync-icon {
    animation: spin-animation 0.8s infinite;
    display: inline-block;
}

@keyframes spin-animation {
    0% {
        transform: rotate(359deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

.catalog__action-icon--margin {
    margin-left: 0;
    margin-right: 10px;
}
</style>
