import axios from 'axios';
import store from '@/store';
import CustomerResponseData from './Dto/CustomerResponseData';

const baseURL = `${process.env.VUE_APP_API_URL}`;

const getAuthHeader = (token) => {
    const devMode = store.state.user.devMode;

    if (devMode) {
        return { 'X-API-KEY': `${token}` };
    } else {
        return { Authorization: `Bearer ${token}` };
    }
};

const apiRequest = async (method, url, data = null, params = {}) => {
    try {
        return store.dispatch('user/apiCall', async (token) => {
            const config = {
                method: method,
                url: `${baseURL}/accounts/${store.state.user.user.account_id}/${url}`,
                headers: getAuthHeader(token),
                params: { ...params },
                data: data,
            };
            return await axios(config);
        });
    } catch (error) {
        throw error;
    }
};

/**
 * Repository for Customer API operations.
 * @namespace
 */
const customerApiRepository = {
    /**
     * Fetch customer data by UUID.
     * @param {string} customerId - The UUID of the customer to fetch.
     * @param {string | null} phone - Optional phone number to filter customers.
     * @param {string} customerName
     * @returns {Promise<CustomerResponseData>} A promise that resolves to a CustomerResponseData object.
     * @throws {Error} If the API call fails or the response is invalid.
     */
    getCustomer: async (customerId, phone = null, customerName = 'Guest') => {
        try {
            let url = `customers/${customerId}?customerName=${customerName}`;
            if (phone) {
                url += `&phone=${phone}`;
            }
            const response = await apiRequest('GET', url);

            const customerData = response.data;

            return new CustomerResponseData.Builder()
                .withUuid(customerData.uuid)
                .withExternalId(customerData.external_id)
                .withFullName(customerData.full_name)
                .withFirstName(customerData.first_name)
                .withLastName(customerData.last_name)
                .withEmail(customerData.email)
                .withPhone(customerData.phone)
                .withPhoneWithoutPrefix(customerData.phone_without_prefix)
                .withAccount(customerData.account)
                .withNote(customerData.note)
                .withPlatformsInfo(customerData.platforms_info)
                .withConversations(customerData.conversations)
                .withMarketingConsent(customerData.marketing_consent)
                .withMarketingConsentUpdated(customerData.marketing_consent_updated)
                .withBusinessType(customerData.business_type)
                .withTags(customerData.tags)
                .withDateCreated(customerData.date_created)
                .withDateUpdated(customerData.date_updated)
                .withChannelType(customerData.channel_type)
                .build();
        } catch (error) {
            console.error('Error fetching customer data: ', error);
            throw error;
        }
    },
};

export default customerApiRepository;
