import { v4 as uuidv4 } from 'uuid';
import Firebase from '@/Firebase';
import store from '@/store';

// Constants
const ERROR_MESSAGES = {
    AUTH_FAILED: 'Authentication failed',
    ACCOUNT_EXISTS: 'Account already exists',
    SHOP_REGISTERED: 'Shop already registered',
    INVALID_PROVIDER: 'Invalid provider specified',
    AUTH_TIMEOUT: 'Authentication timed out',
};

const AUTH_TIMEOUT_MS = 30000; // 30 seconds timeout
const VALID_PROVIDERS = ['google', 'facebook'];

/**
 * @typedef {Object} SignInResponse
 * @property {boolean} isNewUser - Whether this is a new user registration
 * @property {Object} userData - The user data from Firebase
 * @property {Object} accountData - The account data if this was a registration
 */

/**
 * @typedef {Object} SignInOptions
 * @property {Object} queryParams - Query parameters from the URL
 * @property {string} currentLanguage - Current language setting
 */

/**
 * Creates account data for a new user
 * @param {Object} signupResponse - The response from Firebase authentication
 * @param {Object} queryParams - Query parameters from the URL
 * @param {string} currentLanguage - Current language setting
 * @returns {Object} The account data object
 */
function createAccountData(signupResponse, queryParams, currentLanguage) {
    return {
        uuid: uuidv4(),
        email: signupResponse._tokenResponse.email,
        user_id: signupResponse._tokenResponse.localId,
        contact_name: signupResponse._tokenResponse.displayName,
        source_platform: queryParams?.source?.toLowerCase() === 'shopify' ? 'SHOPIFY_BILLING' : 'STRIPE',
        referredBy: typeof window !== 'undefined' && window.tolt_referral ? window.tolt_referral : null,
        language: currentLanguage,
        profile_picture: signupResponse._tokenResponse.photoUrl,
    };
}

/**
 * Handles social sign in with popup, determining if it's a login or registration
 * @param {string} provider - The provider to use ('google' or 'facebook')
 * @param {SignInOptions} options - Sign in options
 * @returns {Promise<SignInResponse>} The sign in response
 */
export async function signInWithPopup(provider, options) {
    const { queryParams, currentLanguage } = options;

    // Validate provider
    if (!VALID_PROVIDERS.includes(provider)) {
        throw new Error(ERROR_MESSAGES.INVALID_PROVIDER);
    }

    try {
        // Set up authentication timeout
        const authPromise = provider === 'google' ? Firebase.signInWithGoogle() : Firebase.signInWithFacebook();

        const timeoutPromise = new Promise((_, reject) => {
            setTimeout(() => reject(new Error(ERROR_MESSAGES.AUTH_TIMEOUT)), AUTH_TIMEOUT_MS);
        });

        const signupResponse = await Promise.race([authPromise, timeoutPromise]);

        if (!signupResponse) {
            throw new Error(ERROR_MESSAGES.AUTH_FAILED);
        }

        const { isNewUser } = signupResponse;

        // If this is a new user, create an account
        if (isNewUser) {
            const accData = createAccountData(signupResponse, queryParams, currentLanguage);

            try {
                await store.dispatch('user/actionCreateAccount', accData, 'social');

                // Track signup with Tolt if available
                if (typeof window !== 'undefined' && window.tolt) {
                    window.tolt.signup(accData.email);
                }
            } catch (createAccountError) {
                if (createAccountError.response?.status === 400) {
                    throw new Error(ERROR_MESSAGES.ACCOUNT_EXISTS);
                } else if (createAccountError.response?.status === 409) {
                    throw new Error(ERROR_MESSAGES.SHOP_REGISTERED);
                }
                throw createAccountError;
            }
        }

        const response = {
            isNewUser,
            userData: signupResponse._tokenResponse,
            accountData: isNewUser
                ? {
                      email: signupResponse._tokenResponse.email,
                      contact_name: signupResponse._tokenResponse.displayName,
                      source_platform: queryParams?.source?.toLowerCase() === 'shopify' ? 'SHOPIFY_BILLING' : 'STRIPE',
                      language: currentLanguage,
                  }
                : null,
        };
        return response;
    } catch (error) {
        console.error('Error in signInWithPopup:', error);
        throw error;
    }
}
