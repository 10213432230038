// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/icons/google-logo.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".login-social-button-google__icon[data-v-722c04ee],\n.register-social-button-google__icon[data-v-722c04ee] {\n  height: 17px;\n  width: 17px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  margin-right: 0.7rem;\n}\n.login-social-button-facebook__icon[data-v-722c04ee],\n.register-social-button-facebook__icon[data-v-722c04ee] {\n  margin-right: 1rem;\n}\n.register-social-button-facebook__icon[data-v-722c04ee] {\n  margin: 0 !important;\n}\n.register-social-button-google[data-v-722c04ee] {\n  width: 100%;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
