<script>
import { mapActions } from 'vuex';
import agentSettingsService from '@/services/account/agentSettingsService';
import ThirdPartySkillInstallerLoader from '@/components/Skills/ThirdPartySkills/ThirdPartySkillInstallerLoader.vue';
import accountService from '@/services/account/accountService';

export default {
    name: 'ThirdPartySkillInstaller',
    components: { ThirdPartySkillInstallerLoader },
    props: {
        skillInstallParams: {
            type: Object,
            default: () => ({
                shop: 'my.shopify.store',
                email: 'MyEmail@shopify.com',
                businessName: 'MyBusinessName',
            }),
        },
    },
    data() {
        return {
            localSkillInstallParams: JSON.parse(JSON.stringify(this.skillInstallParams)),
            processStatus: 'inProgress',
        };
    },
    watch: {
        skillInstallParams: {
            immediate: true,
            handler(newVal) {
                this.localSkillInstallParams = JSON.parse(JSON.stringify(newVal));
            },
        },
    },
    computed: {
        isMobile() {
            return window.innerWidth < Number(900);
        },

        getSkillId() {
            if (this.skillInstallParams.source === 'Shopify') {
                return 'd74f8439-bda9-420a-8b20-cbb80e5f7510';
            }

            if (this.skillInstallParams.source === 'Prestashop') {
                return '124f8439-bca9-420a-8f20-c1b80e5f7512';
            }
        },

        lixsaLogoUrl() {
            return (
                process.env.VUE_APP_LIXSA_AVATAR_URL ||
                'https://firebasestorage.googleapis.com/v0/b/lixsa-prod.appspot.com/o/public_images%2FfotoPerfilLixsaNuevo2.png?alt=media&token=c8ed9bae-3c4e-4534-8c8a-2533259b5d1f'
            );
        },
    },
    methods: {
        ...mapActions('modalStore', ['closeModal']),
        ...mapActions('user', [
            'actionUpdateAccount', // Action from the 'user' module
        ]),

        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },

        async updateAccountSourcePlatform() {
            const formData = {
                source_platform: this.localSkillInstallParams.source.toUpperCase(),
            };
            await this.actionUpdateAccount(formData);
        },

        isShopifySkill() {
            return this.getSkillId === 'd74f8439-bda9-420a-8b20-cbb80e5f7510';
        },

        isPrestashopSkill() {
            return this.getSkillId === '124f8439-bca9-420a-8f20-c1b80e5f7512';
        },

        hasShopifySkill() {
            const shopifySKillId = 'd74f8439-bda9-420a-8b20-cbb80e5f7510';

            const activeSkills = this.$store.getters['agentSettings/agentSettings'].skills;

            const hasShopifySkill = activeSkills.some((skill) => skill.skill_id === shopifySKillId);

            return hasShopifySkill;
        },

        hasPrestashopSkill() {
            const prestashopSkillId = '124f8439-bca9-420a-8f20-c1b80e5f7512';

            const activeSkills = this.$store.getters['agentSettings/agentSettings'].skills;

            const hasPrestashopSkill = activeSkills.some((skill) => skill.skill_id === prestashopSkillId);

            return hasPrestashopSkill;
        },

        async startConnection() {
            try {
                if (!this.isShopifySkill() || (this.isShopifySkill() && !this.hasShopifySkill())) {
                    await agentSettingsService.modifyAgentSettings(this.localSkillInstallParams, this.getSkillId);
                    // Set the status to loading immediately when this method is called
                    this.processStatus = 'loading';
                } else if (!this.isPrestashopSkill() || (this.isPrestashopSkill() && !this.hasPrestashopSkill())) {
                    await agentSettingsService.modifyAgentSettings(this.localSkillInstallParams, this.getSkillId);
                    // Set the status to loading immediately when this method is called
                    this.processStatus = 'loading';
                } else {
                    this.handleShopSyncedEvent();
                }
            } catch (e) {
                if (e.response && e.response.status === 409) {
                    this.showErrorToast('This shop domain is already registered.');
                    this.closeModal();
                } else {
                    this.showErrorToast('An error occurred while connecting the skill.');
                }
                this.processStatus = 'error';
                console.error('Error connecting skill: ', e);
            }
        },

        handleShopSyncedEvent() {
            this.processStatus = 'done';

            setTimeout(() => {
                this.$emit('modalEvent', { eventName: 'third-party-skill-installed', eventPayload: this.getSkillId });

                this.closeModal();
            }, 500);
        },

        customCloseModal() {
            this.handleShopSyncedEvent();
        },
    },
    mounted() {
        this.startConnection();
    },
};
</script>

<template>
    <div class="third-party-skill-installer">
        <md-dialog-content>
            <div class="third-party-skill-installer__content">
                <div class="third-party-skill-installer__content__logos-header">
                    <img
                        class="third-party-skill-installer__content__logos-header__image"
                        :src="lixsaLogoUrl"
                        alt="Lixsa logo"
                    />
                    <i class="material-symbols-outlined">sync_alt</i>
                    <img
                        class="third-party-skill-installer__content__logos-header__image"
                        src="https://firebasestorage.googleapis.com/v0/b/lixsa-prod.appspot.com/o/public_images%2Fshopify_image.png?alt=media&token=aee962b5-4c75-4b19-80b2-06bb905054fb"
                        alt="Shopify Logo"
                        v-if="isShopifySkill()"
                    />
                    <img
                        class="third-party-skill-installer__content__logos-header__image"
                        src="https://firebasestorage.googleapis.com/v0/b/lixsa-prod.appspot.com/o/public_images%2Fskills%2Fv2.0.0%2Fprestashop.svg?alt=media&token=c7c99fb4-5c86-41b1-b08e-7accd577933f"
                        alt="Prestashop Logo"
                        v-if="isPrestashopSkill()"
                    />
                </div>
                <div :class="[isMobile ? 'md-headline' : 'md-display-1']" v-if="isShopifySkill()">
                    <span v-if="processStatus === 'inProgress'"> Connect Lixsa to Shopify </span>
                    <span v-else> Connecting Lixsa to Shopify </span>
                </div>
                <div :class="[isMobile ? 'md-headline' : 'md-display-1']" v-if="isPrestashopSkill()">
                    <span v-if="processStatus === 'inProgress'"> Connect Lixsa to Prestashop </span>
                    <span v-else> Connecting Lixsa to Prestashop </span>
                </div>
                <third-party-skill-installer-loader
                    @shop-synced="handleShopSyncedEvent"
                    v-if="processStatus === 'loading'"
                ></third-party-skill-installer-loader>

                <div v-else-if="processStatus === 'done'">
                    <i class="material-symbols-outlined third-party-skill-installer__content__done-icon">task_alt</i>
                </div>

                <div class="close-sync-modal">
                    <a
                        v-if="processStatus === 'loading'"
                        class="md-simple md-primary third-party-skill-installer__close-button"
                        @click="customCloseModal()"
                        >Close </a
                    ><span class="md-caption">(The sync won't stop, it will run in background)</span>
                </div>
            </div>
        </md-dialog-content>
    </div>
</template>

<style scoped lang="scss">
.third-party-skill-installer {
    width: 60vw;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 959px) {
        width: 100vw;
        height: 100vh;
    }

    &__close_bar {
        text-align: end;
    }

    &__content {
        //height: 95vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem;

        &__logos-header {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1rem;

            &__image {
                width: 6rem;
                border-radius: 12px;
            }
        }

        &__form-card {
            border: 2px #dedede solid;
            width: 40vw;

            @media (max-width: 599px) {
                width: 100%;
            }
        }

        &__done-icon {
            font-size: 6rem;
            color: #4caf50;
            animation: done-icon-enter 0.5s ease-out forwards; // Apply the animation
        }
    }

    &__close-button {
        margin-bottom: 5px;
        cursor: pointer;
    }
}

.close-sync-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@keyframes done-icon-enter {
    0% {
        transform: scale(0.5); // Start the icon at half its size
        opacity: 0; // Start the icon fully transparent
    }
    100% {
        transform: scale(1); // Scale the icon to its full size
        opacity: 1; // Make the icon fully visible
    }
}
</style>
