// Import the functions you need from the SDKs you need
import userService from './services/account/userService';
import { initializeApp } from 'firebase/app';
import {
    getAuth,
    signInWithPopup,
    GoogleAuthProvider,
    FacebookAuthProvider,
    sendPasswordResetEmail,
    signOut,
    getAdditionalUserInfo,
} from 'firebase/auth';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

// import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE,
    authDomain: process.env.VUE_APP_AUTHDOMAIND,
    projectId: process.env.VUE_APP_PROJECTID,
    storageBucket: process.env.VUE_APP_STORAGEBUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
    appId: process.env.VUE_APP_APPID,
    // measurementId: process.env.VUE_APP_MEASUREMENTID,
};

// Initialize Firebase
// const analytics = getAnalytics(app);
const app = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging
const messaging = getMessaging(app);

async function generateToken(userId) {
    try {
        const token = await getToken(messaging, {
            vapidKey: process.env.VUE_APP_FIREBASE_VAPID,
        });

        userService.addPushNotificationToken(userId, token);
        return token;
    } catch (error) {
        console.error('Error getting FCM token:', error);
        throw error;
    }
}

async function getExistingToken() {
    try {
        const currentToken = await getToken(this.messaging);
        return currentToken;
    } catch {
        return null;
    }
}

onMessage(messaging, (payload) => {
    console.log('Message received. ', payload);
});

export default {
    async requestPermission() {
        try {
            const userId = localStorage.getItem('userId');
            if (userId) {
                const existingToken = await getExistingToken();
                if (!existingToken) {
                    await Notification.requestPermission();
                    const token = await generateToken(userId);

                    userService.addPushNotificationToken(userId, token);
                }
            }
        } catch (error) {
            console.error('Error getting FCM token:', error);
        }
    },

    async signInWithGoogle() {
        try {
            const provider = new GoogleAuthProvider();
            const auth = getAuth();
            const res = await signInWithPopup(auth, provider);
            const { isNewUser } = getAdditionalUserInfo(res);
            return { ...res, isNewUser };
        } catch (error) {
            throw error;
        }
    },

    async signInWithFacebook() {
        try {
            const provider = new FacebookAuthProvider();
            const auth = getAuth();
            const res = await signInWithPopup(auth, provider);
            const { isNewUser } = getAdditionalUserInfo(res);
            return { ...res, isNewUser };
        } catch (error) {
            throw error;
        }
    },

    async SignOut() {
        try {
            const auth = getAuth();
            return await signOut(auth);
        } catch (error) {
            console.error('Error en el servicio de firebae: ', error);
            throw error;
        }
    },

    async sendPasswordResetEmail(email) {
        try {
            const auth = getAuth();
            return await sendPasswordResetEmail(auth, email);
        } catch (error) {
            throw error;
        }
    },

    messaging,
};
