<template>
    <div class="info-item" @click="toggleShowDetailsOrder">
        <svg-icon class="info-icon" name="description" />
        <div class="info-content">
            <div class="info-header">
                <span class="info-title">{{ order.customer_full_name }}</span>
                <span class="info-guests">{{ order.amount_guests }} guests</span>
            </div>
            <div class="status-badges">
                <span v-if="order.status" :class="['status-badge', `badge-${order.status.toLowerCase()}`]">
                    {{ order.status.toLocaleUpperCase() }}
                </span>
                <span v-if="order.external_asset_id" class="status-badge badge-neutral">
                    #{{ order.external_asset_id }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import SvgIcon from '@/components/Chat/SvgIcon/SvgIcon.vue';
import { getCurrencySymbol, formatNumberWithDecimals, getBadgeClass } from '@/utils/common.js';
import './CustomerAssetsCardHostelryBooking.scss';

export default {
    name: 'CustomerAssetsCardHostelryBooking',
    components: {
        SvgIcon,
    },
    props: {
        order: { type: [Object], required: true },
        showDetailsOrder: { type: [Boolean], required: true },
    },
    methods: {
        toggleShowDetailsOrder() {
            this.$emit('toggle-details-order', this.order);
        },
        getBadgeClass,
        getCurrencySymbol,
        formatNumberWithDecimals,
    },
};
</script>
