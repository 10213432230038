var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"conversations-usage"},[_c('div',{staticClass:"conversations-usage__header"},[_vm._m(0),_c('span',{staticClass:"conversations-usage__count"},[_vm._v(_vm._s(_vm.consumedConversations)+" / "+_vm._s(_vm.totalConversations))])]),_c('md-progress-bar',{staticClass:"conversations-usage__progress-bar",class:{
            'md-primary': _vm.conversationsPercentage < 90,
            'md-warning': _vm.conversationsPercentage >= 90 && _vm.conversationsPercentage < 100,
            'md-danger': _vm.conversationsPercentage >= 100,
        },attrs:{"md-mode":"determinate","md-value":_vm.conversationsPercentage}}),_c('p',{staticClass:"conversations-usage__caption"},[_vm._v(" You have used "+_vm._s(_vm.conversationsPercentage.toFixed(1))+"% of your available conversations ")]),(_vm.conversationsPercentage >= 90 && _vm.conversationsPercentage < 100)?_c('div',{staticClass:"conversations-usage__alert"},[_vm._m(1),_c('p',{staticClass:"md-body-2 conversations-usage__alert-text"},[_vm._v(" You are running low on conversations. Consider upgrading your plan or reducing usage to avoid interruptions. ")])]):_vm._e(),(_vm.conversationsPercentage >= 100)?_c('div',{staticClass:"conversations-usage__alert conversations-usage__alert--error"},[_vm._m(2),_c('p',{staticClass:"md-body-2 conversations-usage__alert-text"},[_vm._v(" You have no conversations left. Lixsa will not respond until you purchase more conversations or upgrade your plan. ")])]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"conversations-usage__title md-subheading"},[_c('i',{staticClass:"material-symbols-outlined conversations-usage__icon"},[_vm._v("chat_bubble")]),_vm._v(" Conversation Usage ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"conversations-usage__alert-content"},[_c('i',{staticClass:"material-symbols-outlined conversations-usage__alert-icon"},[_vm._v("feedback")]),_c('h4',{staticClass:"md-subheading conversations-usage__alert-title"},[_vm._v("Warning: Low on Conversations")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"conversations-usage__alert-content"},[_c('i',{staticClass:"material-symbols-outlined conversations-usage__alert-icon"},[_vm._v("error")]),_c('h4',{staticClass:"md-subheading conversations-usage__alert-title"},[_vm._v("No Conversations Available")])])
}]
render._withStripped = true
export { render, staticRenderFns }