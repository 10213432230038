<script>
import { mapGetters } from 'vuex';

export default {
    name: 'ChannelsInstagramAboutCard',
    props: ['communicationChannel'],
    data() {
        return {
            // Mock data for the connected Instagram account
            instagramAccount: {
                username: null,
                profilePicture: null,
            },
        };
    },
    computed: {
        ...mapGetters('agentSettings', ['getSkillByInternalName']),
    },
    watch: {
        communicationChannel: {
            immediate: true,
            handler(newChannel) {
                if (newChannel) {
                    this.updateInstagramAccount(newChannel);
                }
            },
        },
    },
    methods: {
        async getInstagramSkill() {
            return this.getSkillByInternalName('instagram');
        },
        openInstagramProfile() {
            const username = this.getConnectedUsername().replace('@', '');
            window.open(`https://instagram.com/${username}`, '_blank').focus();
        },
        async updateInstagramAccount(channel) {
            this.getInstagramSkill();
            this.instagramAccount = {
                username: channel?.config_values?.username || null,
                profilePicture: channel?.config_values?.profile_picture || null,
            };
        },
        getConnectedUsername() {
            return this.getInstagramSkill()?.config_values?.username || this.instagramAccount.username;
        },

        getProfilePicture() {
            return this.getInstagramSkill()?.config_values?.profile_picture || this.instagramAccount.profilePicture;
        },
    },
    mounted() {
        this.getInstagramSkill();
    },
};
</script>

<template>
    <div class="instagram-container" v-if="getConnectedUsername()">
        <h3>Connected Instagram Accounts</h3>
        <md-card class="instagram-card">
            <md-card-content class="instagram-card__content">
                <div class="instagram-profile">
                    <md-avatar class="instagram-avatar">
                        <img :src="getProfilePicture()" alt="Profile Picture" v-if="getProfilePicture()" />
                        <span class="avatar-placeholder" v-else>{{
                            getConnectedUsername().charAt(0).toUpperCase()
                        }}</span>
                    </md-avatar>
                    <div class="instagram-username">{{ getConnectedUsername() }}</div>
                </div>
                <md-button @click="openInstagramProfile" class="view-profile-btn">
                    <md-icon>visibility</md-icon>
                    View Profile
                </md-button>
            </md-card-content>
        </md-card>
    </div>
</template>

<style scoped lang="scss">
.instagram-container {
    margin-bottom: 16px;
}

.instagram-card {
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

    &__content {
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.instagram-profile {
    display: flex;
    align-items: center;
    gap: 12px;
}

.instagram-avatar {
    width: 40px;
    height: 40px;
    background: #e1306c;

    .avatar-placeholder {
        font-size: 16px;
        color: white;
        font-weight: bold;
    }
}

.instagram-username {
    font-weight: 500;
}

.view-profile-btn {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 0 12px;
    height: 32px;
    text-transform: none;
    font-weight: normal;
    color: #e1306c;
    min-width: auto;
}
</style>
