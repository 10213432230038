class TemplateContentAdapter {
    /**
     * @deprecated Use toWysiwyg instead
     * @param {string} templateContent
     * @returns {string}
     */
    static execute(templateContent) {
        return this.toWysiwyg(templateContent);
    }

    /**
     * Converts plain text to WYSIWYG format with HTML and encoded characters
     * @param {string} plainText
     * @returns {string}
     */
    static toWysiwyg(plainText) {
        if (!plainText) {
            return '';
        }

        return plainText
            .split('\n')
            .filter((line) => line.trim())
            .map((line) => {
                // Convert markdown to HTML, but skip content inside {{ }}
                let converted = line.replace(
                    /{{.*?}}|_([^_]+)_|\*([^*]+)\*|~([^~]+)~|`([^`]+)`/g,
                    (match, italic, bold, strike, code) => {
                        if (match.startsWith('{{')) return match;
                        if (italic) return `<em>${italic}</em>`;
                        if (bold) return `<strong>${bold}</strong>`;
                        if (strike) return `<del>${strike}</del>`;
                        if (code) return `<code>${code}</code>`;
                        return match;
                    },
                );

                // Handle special characters
                const encoded = converted
                    .replace(/á/g, '&aacute;')
                    .replace(/é/g, '&eacute;')
                    .replace(/í/g, '&iacute;')
                    .replace(/ó/g, '&oacute;')
                    .replace(/ú/g, '&uacute;')
                    .replace(/ñ/g, '&ntilde;')
                    .replace(/¿/g, '&iquest;')
                    .replace(/¡/g, '&iexcl;');
                return `<p>${encoded}</p>`;
            })
            .join('\n')
            .replace(/{{\s*([^}]*)\s*}}/g, (match) => {
                return `<span contenteditable="false" class="variable mceNonEditable" data-variable="${match}">${match}</span>`;
            });
    }

    /**
     * Converts WYSIWYG format back to plain text
     * @param {string} wysiwygContent
     * @returns {string}
     */
    static fromWysiwyg(wysiwygContent) {
        if (!wysiwygContent) {
            return '';
        }

        return (
            wysiwygContent
                .replace(/<p>(.*?)<\/p>/g, '$1\n')
                .replace(/<span[^>]*?class="[^"]*?variable[^"]*?"[^>]*>({{[^}]*}})<\/span>/g, '$1')
                // Convert HTML back to markdown
                .replace(/<strong>(.*?)<\/strong>/g, '*$1*')
                .replace(/<em>(.*?)<\/em>/g, '_$1_')
                .replace(/<del>(.*?)<\/del>/g, '~$1~')
                .replace(/<code>(.*?)<\/code>/g, '`$1`')
                // Handle special characters
                .replace(/&aacute;/g, 'á')
                .replace(/&eacute;/g, 'é')
                .replace(/&iacute;/g, 'í')
                .replace(/&oacute;/g, 'ó')
                .replace(/&uacute;/g, 'ú')
                .replace(/&ntilde;/g, 'ñ')
                .replace(/&iquest;/g, '¿')
                .replace(/&iexcl;/g, '¡')
                .replace(/&nbsp;/g, '')
                .trim()
        );
    }
}

export default TemplateContentAdapter;
