<template>
    <div class="profile-information">
        <div class="profile-information__header md-layout-item">
            <div :class="showDescription ? 'md-subheading' : 'md-title'">Profile Information</div>
            <div v-if="showDescription" class="md-caption">
                Update your profile information and how others see you on the platform.
            </div>
        </div>
        <div class="profile-information__content">
            <div class="profile-information__content__picture">
                <md-avatar class="md-avatar-icon profile-information__content__picture__avatar">
                    <img
                        v-if="user.profile_picture"
                        :src="user.profile_picture"
                        :alt="user.username"
                        referrerpolicy="no-referrer"
                    />
                    <span v-else class="md-display-3">{{ firstLetterOfUsername }}</span>
                </md-avatar>
                <md-button class="md-primary md-outline" @click="openFileDialog">
                    <i class="material-symbols-outlined"> upload </i>
                    Upload new photo
                </md-button>
            </div>
            <div class="profile-information__content__details">
                <div class="md-layout">
                    <div class="custom-field md-layout-item md-size-50 md-small-size-100">
                        <span class="md-caption">Full Name</span>
                        <md-field>
                            <md-input v-model="formData.username" type="text"></md-input>
                            <span class="md-helper-text">The full name associated with this profile</span>
                        </md-field>
                    </div>
                    <div class="custom-field md-layout-item md-size-50 md-small-size-100">
                        <span class="md-caption">Email</span>
                        <md-field>
                            <md-input disabled v-model="user.email" type="text"></md-input>
                            <span class="md-helper-text">The email associated with this profile</span>
                        </md-field>
                    </div>
                    <div class="custom-field md-layout-item md-size-100">
                        <span class="md-caption">Role</span>
                        <md-field>
                            <md-input
                                v-model="formData.role"
                                type="text"
                                placeholder="e.g. Customer Success, Sales, etc."
                            ></md-input>
                            <span class="md-helper-text">The role associated with this profile</span>
                        </md-field>
                    </div>
                    <div class="custom-field md-layout-item md-size-100">
                        <span class="md-caption">Permission</span>
                        <md-field>
                            <md-select v-model="formData.permission" :disabled="isPermissionDisabled">
                                <md-option value="ADMIN">Admin</md-option>
                                <md-option value="EMPLOYEE">Employee</md-option>
                            </md-select>
                            <span class="md-helper-text">Your permission level in the platform</span>
                            <md-tooltip v-if="isPermissionDisabled" md-direction="bottom">
                                You cannot change your own permission level
                            </md-tooltip>
                        </md-field>
                    </div>
                    <div class="custom-field md-layout-item md-size-100">
                        <span class="md-caption">Capabilities</span>
                        <md-field>
                            <md-textarea
                                v-model="formData.capabilities"
                                placeholder="e.g. This user is a customer success manager and his responsibilities include managing customer relationships, providing support, and ensuring customer satisfaction."
                            ></md-textarea>
                        </md-field>
                    </div>
                    <div class="custom-field md-layout-item md-size-100">
                        <span class="md-caption">Availability</span>
                        <md-chips
                            v-model="formData.availability"
                            :md-auto-insert="true"
                            :md-placeholder="'e.g. morning, afternoon, weekends'"
                        >
                            <span class="md-helper-text">Your current availability status</span>
                        </md-chips>
                    </div>
                    <div class="custom-field md-layout-item md-size-100">
                        <span class="md-caption">Operational Region</span>

                        <md-chips
                            v-model="formData.operational_region"
                            :md-auto-insert="true"
                            :md-placeholder="'e.g. Europe, Worldwide, Sevilla, etc.'"
                        >
                            <span class="md-helper-text">Your operational region</span>
                        </md-chips>
                    </div>
                    <div class="custom-field md-layout-item md-size-100">
                        <div class="phone-input">
                            <div class="country-code">
                                <span class="md-caption">Country</span>
                                <md-field>
                                    <md-select
                                        v-model="selectedCountryCode"
                                        name="country-code"
                                        id="country-code"
                                        @md-selected="updatePhoneNumber"
                                    >
                                        <md-option
                                            v-for="country in countries"
                                            :key="country.code"
                                            :value="country.code"
                                        >
                                            {{ country.name }} (+{{ country.dialCode }})
                                        </md-option>
                                    </md-select>
                                </md-field>
                            </div>
                            <div class="phone-number">
                                <span class="md-caption">Phone Number (optional)</span>
                                <md-field :class="{ 'md-invalid': showPhoneError }">
                                    <md-input
                                        v-model="phoneNumber"
                                        type="tel"
                                        @input="validatePhoneInput"
                                        @focusout="validatePhoneInput"
                                    ></md-input>
                                    <span class="md-helper-text">The phone number associated with this profile</span>
                                    <span class="md-error" v-if="showPhoneError">Invalid phone number</span>
                                </md-field>
                            </div>
                        </div>
                    </div>
                    <div class="md-layout-item md-size-100">
                        <p class="md-caption">
                            User created at
                            {{ epochToDate(props.user.date_created) }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, watch, getCurrentInstance, nextTick, onMounted } from 'vue';
import countryCodes from '@/assets/countryCodesData.json';
import { useFileDialog } from '@vueuse/core';
import userService from '@/services/account/userService';
import storageService from '@/services/google/storageService';
import { epochToDate } from '@/utils/date-utils';

const props = defineProps({
    user: {
        type: Object,
        required: true,
    },
    isPermissionDisabled: {
        type: Boolean,
        default: false,
    },
    showDescription: {
        type: Boolean,
        default: true,
    },
});

const emit = defineEmits(['update:user', 'update:profile-picture']);

const formData = ref({
    username: props.user.username || '',
    permission: props.user.permission,
    role: props.user.role || '',
    capabilities: props.user.capabilities || '',
    availability: Array.from(props.user.availability || []),
    operational_region: Array.from(props.user.operational_region || []),
    contact_phone: props.user.contact_phone || '',
});

const isUpdatingFromParent = ref(false);

const selectedCountryCode = ref('ES');
const phoneNumber = ref('');
const isPhoneValid = ref(true);
const hasPhoneBeenEdited = ref(false);
const countries = ref(sortCountries(countryCodes));

const showPhoneError = computed(() => !isPhoneValid.value && hasPhoneBeenEdited.value);

const firstLetterOfUsername = computed(() => props.user.username?.charAt(0).toUpperCase());

const { open: openFileDialog, onChange } = useFileDialog({
    accept: 'image/*',
    multiple: false,
});

const { proxy } = getCurrentInstance();

// Watch formData changes and emit updates
watch(
    formData,
    (newValue) => {
        if (!isUpdatingFromParent.value) {
            emit('update:user', {
                ...props.user,
                ...newValue,
                availability: Array.from(newValue.availability || []),
                operational_region: Array.from(newValue.operational_region || []),
            });
        }
    },
    { deep: true },
);

// Watch props.user changes and update formData
watch(
    () => props.user,
    (newValue) => {
        isUpdatingFromParent.value = true;
        formData.value = {
            username: newValue.username || '',
            permission: newValue.permission,
            role: newValue.role || '',
            capabilities: newValue.capabilities || '',
            availability: Array.from(newValue.availability || []),
            operational_region: Array.from(newValue.operational_region || []),
            contact_phone: newValue.contact_phone || '',
        };
        processPhoneNumber();
        // Reset the flag after the next tick to ensure the formData watch doesn't fire
        nextTick(() => {
            isUpdatingFromParent.value = false;
        });
    },
    { deep: true },
);

// on change of the profile picture, update the user profile picture
onChange(async (files) => {
    try {
        const getStorageUrlResponse = await userService.getStorageURL(props.user.uuid, files[0].type);
        await storageService.uploadFileToGoogleCloud(getStorageUrlResponse.data, files[0], files[0].type);
        await updateUserProfilePicture(getStorageUrlResponse.data);
        proxy.$store.dispatch('user/actionGetUser');
        showSuccessToast('Profile picture updated successfully');
        emit('update:profile-picture', getStorageUrlResponse.data);
    } catch (error) {
        console.error('Error getting storage URL: ', error);
    }
});

function processPhoneNumber() {
    if (props.user.contact_phone) {
        const phoneNumberStr = props.user.contact_phone;
        for (const country of countries.value) {
            if (phoneNumberStr.startsWith(`+${country.dialCode}`)) {
                selectedCountryCode.value = country.code;
                phoneNumber.value = phoneNumberStr.slice(country.dialCode.length + 1);
                formData.value.contact_phone = phoneNumberStr;
                break;
            }
        }
    }
}

function showSuccessToast(msg) {
    proxy.$toasted.success(msg, {
        position: 'bottom-center',
        icon: 'check_circle',
        duration: 3000,
    });
}

async function updateUserProfilePicture(url) {
    try {
        const req = {
            user_id: props.user.uuid,
            profile_picture: url.split('?')[0],
        };
        await userService.editUser(props.user.uuid, req);
    } catch (error) {
        console.error('Error updating user profile picture: ', error);
    }
}

function sortCountries(countries) {
    const spain = countries.find((country) => country.code === 'ES');
    const otherCountries = countries.filter((country) => country.code !== 'ES');
    return [spain, ...otherCountries.sort((a, b) => a.name.localeCompare(b.name))];
}

function validatePhoneInput() {
    if (!phoneNumber.value) {
        isPhoneValid.value = true;
        formData.value.contact_phone = '';
        return;
    }

    const phoneRegex = /^(\+)?(?:[0-9] ?){6,14}[0-9]$/;
    const fullPhoneNumber = getFullPhoneNumber();
    isPhoneValid.value = phoneRegex.test(fullPhoneNumber);
    hasPhoneBeenEdited.value = true;
    formData.value.contact_phone = fullPhoneNumber;
}

function updatePhoneNumber() {
    phoneNumber.value = '';
    isPhoneValid.value = true;
    hasPhoneBeenEdited.value = false;
    formData.value.contact_phone = '';
}

function getFullPhoneNumber() {
    return phoneNumber.value ? `+${selectedCountry.value.dialCode}${phoneNumber.value}` : '';
}

const selectedCountry = computed(() => {
    return countries.value.find((country) => country.code === selectedCountryCode.value) || countries.value[0];
});

onMounted(() => {
    processPhoneNumber();
});
</script>

<style scoped lang="scss">
.profile-information {
    &__content {
        display: flex;
        gap: 2rem;
        margin-top: 2rem;
        flex-direction: column;

        &__picture {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            justify-content: center;
            align-items: center;

            &__avatar {
                width: 150px;
                height: 150px;
                border-radius: 50%;
                object-fit: cover;
            }
        }

        &__details {
            flex: 1;
        }
    }
}

.custom-field {
    margin-top: 20px;
    margin-bottom: 20px;
}

.phone-input {
    display: flex;
    gap: 8px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.country-code {
    flex: 0 0 40%;
}

.phone-number {
    flex: 1;
}

.md-field {
    margin-bottom: 0;
    margin-top: 0;
}

.md-textarea {
    padding: 12px 0 10px 0 !important;
    line-height: normal !important;

    &::placeholder {
        color: #a9a9a9 !important;
        font-size: 14px;
        // font-style: italic;
    }
}
</style>
