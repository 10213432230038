<template>
    <div class="custom-main-panel">
        <div>
            <md-progress-bar v-if="isLoading" md-mode="indeterminate"></md-progress-bar>
        </div>
        <div class="md-layout custom-content-panel">
            <div v-if="isLoading" class="overlay md-layout-item md-size-100"></div>
            <div class="ak__layout-item--margin-bottom md-layout-item md-size-100">
                <p class="md-caption">
                    Listed below are your exclusive and confidential API keys. It's important to understand that for
                    security reasons, once these keys have been created and provided to you, we will not present them a
                    second time in any subsequent interactions.
                </p>
                <p class="md-caption">
                    It's of utmost importance to maintain the privacy of your API key. Please exercise caution and avoid
                    sharing it publicly, especially in environments like web browsers or within any exposed client-side
                    coding practices.
                </p>
            </div>
            <!--      <div class="ak__layout-item&#45;&#45;margin-bottom md-layout-item md-size-100">-->

            <!--        <p class="md-subheading">API keys</p>-->
            <!--      </div>-->
            <div class="md-layout-item md-size-100">
                <md-card v-if="tableData.length > 0">
                    <md-card-content>
                        <md-table v-model="tableData" class="paginated-table">
                            <md-table-row slot="md-table-row" slot-scope="{ item }">
                                <md-table-cell md-label="Name">
                                    <p class="md-body-1">{{ item.name }}</p>
                                </md-table-cell>
                                <md-table-cell md-label="Key">
                                    <p class="md-body-1">{{ transformToken(item.token) }}</p>
                                </md-table-cell>

                                <md-table-cell md-label="Creation">
                                    <p class="md-caption">{{ formatDate(item.date_created) }}</p>
                                </md-table-cell>
                                <md-table-cell md-label="Last Update">
                                    <p v-if="item.date_updated" class="md-caption">
                                        {{ formatDate(item.date_updated) }}
                                    </p>
                                    <p v-else class="md-caption">Never</p>
                                </md-table-cell>
                                <md-table-cell md-label="" class="text-right">
                                    <div class="action-buttons-container">
                                        <EditApiKeyWizard
                                            :disabled="!isAdminAccount"
                                            @ApiKeyEdited="fetchApiKeys"
                                            :apiKey="item"
                                        />
                                        <md-button
                                            :disabled="!isAdminAccount"
                                            class="md-just-icon md-danger md-simple"
                                            @click.native="handleDelete(item)"
                                        >
                                            <md-icon>delete</md-icon>
                                        </md-button>
                                    </div>
                                </md-table-cell>
                            </md-table-row>
                        </md-table>
                    </md-card-content>
                </md-card>
                <CreateApiKeyWizard v-if="isAdminAccount" @ApiCreated="fetchApiKeys" />
            </div>
        </div>
    </div>
</template>
<script>
import CreateApiKeyWizard from '@/components/ApiKeys/CreateApiKeyWizard.vue';
import EditApiKeyWizard from '@/components/ApiKeys/EditApiKeyWizard.vue';
import Swal from 'sweetalert2';

import apiKeysService from '@/services/account/apiKeysService.js';
import { mapGetters } from 'vuex';

export default {
    name: 'ApiKeys',
    components: {
        CreateApiKeyWizard,
        EditApiKeyWizard,
    },
    data() {
        return {
            tableData: [],

            isLoading: false, //Controls loading spinner.
        };
    },
    computed: {
        ...mapGetters({
            canAccessUser: 'user/canAccess',
            isWalkonLivosAccount: 'user/isWalkonLivosAccount',
        }),
        isAdminAccount() {
            return this.canAccessUser(['ADMIN']) || this.isWalkonLivosAccount;
        },
    },
    methods: {
        formatDate(timestamp) {
            const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
            const date = new Date(timestamp * 1000); // convert to milliseconds
            return date.toLocaleDateString('en-US', options); // adjust 'en-US' as per your need
        },
        async fetchApiKeys() {
            this.isLoading = true;
            try {
                const response = await apiKeysService.fetchApiKeys();
                if (response && response.data) {
                    this.tableData = response.data.content;
                }
            } catch (error) {
                console.error('Error fetching API keys: ' + error);
            } finally {
                this.isLoading = false;
            }
        },
        handleDelete(item) {
            if (!this.isAdminAccount) {
                this.showErrorToast("You don't have permission to perform this action");
                return;
            }
            Swal.fire({
                title: 'Are you sure?',
                text: `You won't be able to revert this!`,
                type: 'warning',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#4caf50',
                confirmButtonText: 'Yes, delete it!',
                buttonsStyling: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteApiKey(item);
                }
            });
        },
        async deleteApiKey(item) {
            this.isLoading = true;
            try {
                const response = await apiKeysService.deleteApiKey(item.uuid);
                if (response.status === 200) {
                    this.fetchApiKeys();
                    this.showSuccessToast('API Key Deleted');
                }
            } catch (error) {
                console.error('Error removing the API key: ' + error);
                this.showErrorToast('Error deleting API key');
            } finally {
                this.isLoading = false;
            }
        },
        transformToken(token) {
            if (token && token.length > 5) {
                return token.substring(0, 2) + '...' + token.substring(token.length - 3);
            }
            return token;
        },
        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },
        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },
    },
    mounted() {
        this.fetchApiKeys();
    },
};
</script>
<style lang="scss" scoped>
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(238, 238, 238, 0.5);
    z-index: 10;
    cursor: not-allowed;
}

.custom-main-panel {
    position: relative;
    overflow-x: auto;
    width: 100%;
}

.custom-content-panel {
    margin-bottom: 40px;
    padding-left: 30px;
    padding-right: 30px;

    @media (max-width: 1278px) {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media (max-width: 959px) {
        padding-left: 15px;
        padding-right: 15px;
    }

    .md-card {
        margin-top: 0px;
    }
}

.action-buttons-container {
    display: flex;
    align-items: center;
}

.ak__layout-item--margin-bottom {
    margin-bottom: 15px;
}
</style>
