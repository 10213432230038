import BaseDto from '../../_common/BaseDto';

class CheckoutSchedulerDtoBuilder {
    constructor() {
        this.accountId = undefined;
        this.skillId = undefined;
        this.agentName = undefined;
        this.ttlMinutes = undefined;
    }

    withAccountId(accountId) {
        this.accountId = accountId;
        return this;
    }

    withSkillId(skillId) {
        this.skillId = skillId;
        return this;
    }

    withAgentName(agentName) {
        this.agentName = agentName;
        return this;
    }

    withTtlMinutes(ttlMinutes) {
        this.ttlMinutes = ttlMinutes;
        return this;
    }

    build() {
        return new CheckoutSchedulerDto(this);
    }
}

class CheckoutSchedulerDto extends BaseDto {
    constructor(builder) {
        super();
        this.account_id = builder.accountId;
        this.skill_id = builder.skillId;
        this.agent_name = builder.agentName;
        this.ttl_minutes = builder.ttlMinutes;
    }

    static get Builder() {
        return CheckoutSchedulerDtoBuilder;
    }
}

export default CheckoutSchedulerDto;
