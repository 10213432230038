// src/services/tourService.js
import Shepherd from 'shepherd.js';
import store from '@/store';
import { i18n } from '@/i18n';

class TourService {
    constructor() {
        this.tours = {}; // Cache of created Shepherd tour instances by tourId
    }

    /**
     * Creates a new Shepherd tour instance with the provided steps
     * @private
     * @param {String} tourId - Unique identifier for the tour
     * @param {Array} steps - Array of step configurations
     * @returns {Shepherd.Tour} The configured tour instance
     */
    createTour(tourId, steps) {
        const shepherd = new Shepherd.Tour({
            useModalOverlay: true,
            defaultStepOptions: {
                classes: 'shepherd-theme-custom',
                scrollTo: { behavior: 'auto', block: 'center' },
                modalOverlayOpeningRadius: 12,
            },
        });

        // Configure tour steps
        this.configureSteps(shepherd, steps);

        // Set up event listeners
        this.setupEventListeners(shepherd, tourId);

        return shepherd;
    }

    /**
     * Configures the steps for a tour with translations
     * @private
     * @param {Shepherd.Tour} shepherd - The Shepherd tour instance
     * @param {Array} steps - Array of step configurations
     */
    configureSteps(shepherd, steps) {
        const translatedSteps = this.getTranslatedSteps(steps, shepherd);
        translatedSteps.forEach((step) => {
            const arrowClass = step.attachTo?.on ? `shepherd-arrow-${step.attachTo.on}` : '';
            shepherd.addStep({
                ...step,
                classes: arrowClass,
            });
        });
    }

    /**
     * Sets up event listeners for the tour
     * @private
     * @param {Shepherd.Tour} shepherd - The Shepherd tour instance
     * @param {String} tourId - Tour identifier
     */
    setupEventListeners(shepherd, tourId) {
        shepherd.on('complete', () => {
            // console.log(`Tour [${tourId}] completed`);
            store.dispatch('tours/markComplete', tourId);
        });

        shepherd.on('cancel', () => {
            // console.log(`Tour [${tourId}] canceled`);
            store.dispatch('tours/markComplete', tourId);
        });
    }

    /**
     * Translates text content, handling different formats
     * @private
     * @param {String} text - Text to translate
     * @returns {String} Translated text
     */
    translateText(text) {
        if (!text || typeof text !== 'string') {
            return text;
        }

        // Case 1: HTML template with translation keys
        if (text.includes('<') && text.includes('>')) {
            // Process translation keys in HTML content
            const translationPattern = /\$\{(?:i18n\.t\(['"])?tours\.[^'"}\s]+(?:['"]\))?}/g;
            return text.replace(translationPattern, (match) => {
                const key = match.match(/tours\.[^'"}\s]+/)?.[0];
                return key ? i18n.t(key) : match;
            });
        }

        // Case 2: Direct translation key
        if (text.startsWith('tours.')) {
            return i18n.t(text);
        }

        // Case 3: Return unchanged text
        return text;
    }

    /**
     * Translates step content and configures button actions
     * @private
     * @param {Array} steps - Original step configurations
     * @param {Shepherd.Tour} shepherd - Shepherd tour instance
     * @returns {Array} Translated and configured steps
     */
    getTranslatedSteps(steps, shepherd) {
        return steps.map((step) => {
            const translatedStep = { ...step };

            // Translate title if exists
            if (step.title) {
                translatedStep.title = this.translateText(step.title);
            }

            // Translate text content
            if (step.text) {
                translatedStep.text = this.translateText(step.text);
            }

            // Configure buttons with translations and actions
            if (step.buttons) {
                translatedStep.buttons = this.configureButtons(step.buttons, shepherd);
            }

            return translatedStep;
        });
    }

    /**
     * Configures buttons with translations and proper actions
     * @private
     * @param {Array} buttons - Button configurations
     * @param {Shepherd.Tour} shepherd - Shepherd tour instance
     * @returns {Array} Configured buttons
     */
    configureButtons(buttons, shepherd) {
        return buttons.map((button) => {
            const translatedButton = {
                text: this.translateText(button.text),
                secondary: button.secondary,
            };

            // Configure button action
            translatedButton.action = () => {
                switch (button.action) {
                    case 'next':
                        shepherd.next();
                        break;
                    case 'back':
                        shepherd.back();
                        break;
                    case 'complete':
                        shepherd.complete();
                        break;
                    case 'cancel':
                        shepherd.cancel();
                        break;
                    default:
                        shepherd.next();
                }
            };

            return translatedButton;
        });
    }

    /**
     * Updates translations for an active tour
     * @private
     * @param {Shepherd.Tour} shepherd - The active tour instance
     * @param {Array} originalSteps - Original step configurations
     * @param {String} tourId - Tour identifier
     */
    updateTourTranslations(shepherd, originalSteps, tourId) {
        const translatedSteps = this.getTranslatedSteps(originalSteps, tourId);
        shepherd.steps.forEach((step, index) => {
            const translatedStep = translatedSteps[index];
            step.options.title = translatedStep.title;
            step.options.text = translatedStep.text;
            if (step.options.buttons) {
                step.options.buttons = translatedStep.buttons;
            }
        });
    }

    /**
     * Starts a named tour with the provided steps
     * @public
     * @param {String} tourId - Unique name/ID of the tour
     * @param {Array} steps - The array of step definitions
     * @returns {Shepherd.Tour} The Shepherd instance
     */
    startTour(tourId, steps) {
        // Complete any existing tour
        if (this.tours[tourId]) {
            delete this.tours[tourId];
        }

        // Create new tour instance
        const shepherd = this.createTour(tourId, steps);
        this.tours[tourId] = shepherd;

        // Set up translation update handler
        i18n.onLocaleChanged = () => {
            this.updateTourTranslations(shepherd, steps, tourId);
        };

        shepherd.start();
        return shepherd;
    }

    /**
     * Checks if a specific tour is completed
     * @public
     * @param {String} tourId - Tour identifier to check
     * @returns {Boolean} Whether the tour is completed
     */
    isTourCompleted(tourId) {
        return store.getters['tours/isCompleted'](tourId);
    }
}

export default new TourService();
