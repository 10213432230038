<template>
    <div class="card-details-customer">
        <div class="card-details-body">
            <!-- Customer Information -->
            <div class="info-section">
                <div class="section-header">
                    <span class="section-title">Customer information</span>
                    <svg-icon
                        class="satisfaction-icon"
                        :class="getIconColor(userSatisfaction)"
                        :name="getSatisfactionSVGCode(userSatisfaction)"
                    />
                </div>

                <div class="info-grid">
                    <!-- Name -->
                    <div class="info-item" v-if="room.roomName">
                        <svg-icon class="info-icon" name="person" />
                        <span>{{ userName }}</span>
                    </div>

                    <!-- Email -->
                    <div class="info-item" v-if="userEmail">
                        <svg-icon class="info-icon" name="email" />
                        <span>{{ userEmail }}</span>
                        <button class="copy-btn" @click="copyCodeToClipboard(userEmail, 'email')">
                            <span v-if="copiedFields.email" class="copied">✓</span>
                            <span v-else>Copy</span>
                        </button>
                    </div>

                    <!-- Phone -->
                    <div class="info-item" v-if="userPhone">
                        <svg-icon class="info-icon" name="phone" />
                        <span>{{ userPhone }}</span>
                        <button class="copy-btn" @click="copyCodeToClipboard(userPhone, 'phone')">
                            <span v-if="copiedFields.phone" class="copied">✓</span>
                            <span v-else>Copy</span>
                        </button>
                    </div>

                    <!-- Location -->
                    <div class="info-item" v-if="userCountry">
                        <svg-icon class="info-icon" name="location" />
                        <span>{{ userCountry }}</span>
                    </div>
                </div>
            </div>

            <!-- Platforms -->
            <div class="platforms-section" v-if="hasPlatformInfo">
                <div class="section-header">
                    <span class="section-title">Connected Channels</span>
                </div>

                <div class="platform-item" v-if="platformInfo.facebook">
                    <img
                        class="platform-icon"
                        src="https://firebasestorage.googleapis.com/v0/b/lixsa-prod.appspot.com/o/public_images%2Ffacebook_messenger_icon.png?alt=media&token=b6442f05-380c-4fa1-b56c-d8bb7c70bd79"
                        alt="Facebook"
                    />
                    <div class="platform-details">
                        <span class="platform-name">{{ platformInfo.facebook.username }}</span>
                        <span class="platform-id">{{ platformInfo.facebook.id }}</span>
                    </div>
                    <img
                        v-if="platformInfo.facebook.profile_pic"
                        :src="platformInfo.facebook.profile_pic"
                        class="profile-pic"
                        alt="Profile"
                    />
                </div>

                <div class="platform-item" v-if="platformInfo.whatsapp">
                    <img
                        class="platform-icon"
                        src="https://firebasestorage.googleapis.com/v0/b/lixsa-prod.appspot.com/o/public_images%2FLogo-whatsapp.png?alt=media&token=94a44ade-fdad-4f57-87ad-ad4dd45a1daf"
                        alt="WhatsApp"
                    />
                    <div class="platform-details">
                        <span class="platform-name">{{ platformInfo.whatsapp.username }}</span>
                        <span class="platform-id">{{ platformInfo.whatsapp.id }}</span>
                    </div>
                    <img
                        v-if="platformInfo.whatsapp.profile_pic"
                        :src="platformInfo.whatsapp.profile_pic"
                        class="profile-pic"
                        alt="Profile"
                    />
                </div>

                <div class="platform-item" v-if="platformInfo.instagram">
                    <img
                        class="platform-icon"
                        src="https://firebasestorage.googleapis.com/v0/b/lixsa-prod.appspot.com/o/public_images%2Finstagram_icon.png?alt=media&token=c1949ac2-f91d-4655-a3d3-5a13810c5c35"
                        alt="Instagram"
                    />
                    <div class="platform-details instagram-details">
                        <div class="instagram-header">
                            <span class="platform-name">{{ platformInfo.instagram.username }}</span>
                            <div v-if="platformInfo.instagram.is_user_follow_business === 'true'" class="follows-badge">
                                <svg-icon class="follow-icon" name="check_circle" />
                                <span>Follows</span>
                            </div>
                        </div>
                        <div class="instagram-secondary">
                            <span class="platform-id">ID: {{ platformInfo.instagram.id }}</span>
                            <span v-if="platformInfo.instagram.follower_count" class="platform-followers"
                                >{{ platformInfo.instagram.follower_count }} followers</span
                            >
                        </div>
                    </div>
                    <a
                        v-if="platformInfo.instagram.profile_pic"
                        :href="`https://www.instagram.com/${platformInfo.instagram.username}/`"
                        target="_blank"
                        class="profile-pic-link"
                        title="Open Instagram profile"
                    >
                        <img :src="platformInfo.instagram.profile_pic" class="profile-pic" alt="Profile" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SvgIcon from '@/components/Chat/SvgIcon/SvgIcon.vue';
import { countryCodes } from './countryCodes.js';
import { timezones } from './timezones.js';

export default {
    name: 'CustomerCardHeader',
    components: {
        SvgIcon,
    },
    props: {
        room: { type: Object, required: true },
        currentUserId: { type: [String, Number], required: true },
        currentUserName: { type: String, required: true },
        currentUserEmail: { type: String, required: false, default: undefined },
        currentUserPhone: { type: String, required: false, default: undefined },
        currentCustomerPlatformsInfo: { type: Object, required: true },
        currentUserSatisfaction: { type: [String, undefined], required: true },
    },
    data() {
        return {
            currentTime: null,
            svgIcon: '',
            copiedFields: {
                email: false,
                phone: false,
            },
        };
    },
    computed: {
        userName() {
            return this.currentUserName;
        },
        userEmail() {
            return this.currentUserEmail ? this.currentUserEmail : null;
        },
        userPhone() {
            return this.currentUserPhone ? this.currentUserPhone : null;
        },
        userSatisfaction() {
            return this.currentUserSatisfaction;
        },
        userCountry() {
            if (!this.userPhone || this.userPhone.trim() === '') {
                return 'Unknown';
            }
            const countryCode = this.userPhone.substring(0, 3); // Adjust as needed
            return countryCodes[countryCode] || null;
        },
        localTime() {
            if (!this.userCountry || this.userCountry === 'Unknown') {
                return 'Unavailable';
            }
            if (this.currentTime) {
                const timezone = this.getTimezoneByCountry(this.userCountry);
                const options = {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false,
                    timeZone: timezone,
                };
                return new Intl.DateTimeFormat('en-US', options).format(this.currentTime);
            }
            return 'Loading...';
        },
        getCommunicationChannelPic() {
            let picURL = null;

            switch (this.room.channel_type) {
                case 'WHATSAPP':
                    picURL =
                        'https://firebasestorage.googleapis.com/v0/b/lixsa-prod.appspot.com/o/public_images%2FLogo-whatsapp.png?alt=media&token=94a44ade-fdad-4f57-87ad-ad4dd45a1daf';
                    break;
                default:
                    break;
            }
            return picURL;
        },
        platformInfo() {
            return this.currentCustomerPlatformsInfo || {};
        },
        hasPlatformInfo() {
            return Object.keys(this.platformInfo).length > 0;
        },
    },
    mounted() {
        this.updateTime();
        this.timer = setInterval(this.updateTime, 1000); // Update time every second
        this.svgIcon = this.getCopyIcon();
    },
    beforeDestroy() {
        clearInterval(this.timer); // Clear the timer
    },
    methods: {
        getSatisfactionSVGCode(satisfaction) {
            if (satisfaction === 'NOT ANALYZED') return 'NOT_ANALYZED';
            return satisfaction;
        },
        getIconColor(satisfaction) {
            switch (satisfaction) {
                case 'POSITIVE':
                    return 'icon-green';
                case 'NEGATIVE':
                    return 'icon-red';
                default:
                    return 'icon-basic';
            }
        },
        getTimezoneByCountry(country) {
            return timezones[country] || 'UTC';
        },
        updateTime() {
            this.currentTime = new Date(); // Update the current time
        },
        getCopyIcon() {
            return `
<svg xmlns="http://www.w3.org/2000/svg" height="17px" viewBox="0 -960 960 960" width="24px"><path d="M360-240q-33 0-56.5-23.5T280-320v-480q0-33 23.5-56.5T360-880h360q33 0 56.5 23.5T800-800v480q0 33-23.5 56.5T720-240H360Zm0-80h360v-480H360v480ZM200-80q-33 0-56.5-23.5T120-160v-560h80v560h440v80H200Zm160-240v-480 480Z"/></svg>`;
        },
        copyCodeToClipboard(text, field) {
            navigator.clipboard
                .writeText(text)
                .then(() => {
                    this.copiedFields[field] = true;
                    setTimeout(() => {
                        this.copiedFields[field] = false;
                    }, 1000);
                })
                .catch((err) => {
                    console.error('Could not copy text: ', err);
                });
        },
    },
};
</script>

<style scoped>
.card-details-customer {
    background: #fff;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.card-details-body {
    padding: 16px;
    width: 100%;
}

.section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}

.section-title {
    font-size: 14px;
    font-weight: 600;
    color: #2c3e50;
}

.info-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 8px;
    width: 100%;
}

.info-item {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    background: #f8f9fa;
    border-radius: 6px;
    font-size: 13px;
    width: 100%;
}

.info-icon {
    width: 16px;
    height: 16px;
    color: #6c757d;
}

.satisfaction-icon {
    width: 20px;
    height: 20px;
}

.copy-btn {
    margin-left: auto;
    padding: 4px 8px;
    border: none;
    background: transparent;
    color: #6c757d;
    cursor: pointer;
    font-size: 12px;
}

.copy-btn:hover {
    color: #2c3e50;
}

.copied {
    color: #28a745;
}

.platforms-section {
    margin-top: 16px;
    width: 100%;
}

.platform-item {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 8px;
    background: #f8f9fa;
    border-radius: 6px;
    width: 100%;
}

.platform-icon {
    width: 24px;
    height: 24px;
}

.platform-details {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.instagram-details {
    flex: 1;
    min-width: 0; /* Prevents flex items from overflowing */
}

.instagram-header {
    display: flex;
    align-items: center;
    gap: 8px;
}

.instagram-secondary {
    display: flex;
    gap: 12px;
}

.platform-name {
    font-size: 13px;
    font-weight: 500;
}

.platform-id {
    font-size: 12px;
    color: #6c757d;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.platform-followers {
    font-size: 12px;
    color: #6c757d;
    white-space: nowrap;
}

.follows-badge {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 11px;
    color: #28a745;
    background-color: rgba(40, 167, 69, 0.1);
    padding: 1px 4px;
    border-radius: 4px;
}

.follow-icon {
    width: 14px;
    height: 14px;
    color: #28a745;
}

.profile-pic {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-left: auto;
}

.profile-pic-link {
    text-decoration: none;
    color: inherit;
    display: flex;
    margin-left: auto;
    cursor: pointer;
    transition: transform 0.2s ease;
}

.profile-pic-link:hover {
    transform: scale(1.1);
}

.profile-pic-link:hover .profile-pic {
    box-shadow: 0 0 0 2px rgba(40, 167, 69, 0.3);
}

.icon-green {
    color: #28a745;
}
.icon-red {
    color: #dc3545;
}
.icon-basic {
    color: #6c757d;
}
</style>
