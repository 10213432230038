<script>
export default {
    name: 'ShippingUpdateNotificationsAboutCard',
};
</script>

<template>
    <md-card>
        <md-card-content class="shipping-update-notifications-about-card">
            <div class="md-subheading">
                <strong> About Shipping Update Notifications </strong>
            </div>
            <div class="shipping-update-notifications-about-card__content">
                <div class="md-body-1">
                    When you activate this skill, Lixsa will proactively notify your customers about their order
                    shipping status.
                </div>
                <div class="md-body-1">
                    Lixsa obtains this information from the order status in Shopify through the tracking number. Please
                    verify that your orders have the tracking number.
                </div>
                <div class="md-body-1">
                    This information will also be updated in Shopify, allowing Lixsa to correctly respond to customer
                    inquiries. Additionally, your customers will be able to see the status on the Shopify order status
                    page, and you will see it in your listing.
                </div>
                <div class="md-body-1">
                    The following statuses will be reported:
                    <ul>
                        <li>
                            <code>label_printed</code>: The shipping label has been physically printed. This is the
                            first step in the shipping process.
                        </li>
                        <li>
                            <code>label_purchased</code>: The shipping label has been purchased/generated in the system,
                            but may not have been printed yet.
                        </li>
                        <li>
                            <code>attempted_delivery</code>: The carrier attempted to deliver the package but was unable
                            to do so (for example, no one was home).
                        </li>
                        <li>
                            <code>ready_for_pickup</code>: The package is ready to be picked up, either by the carrier
                            at origin or by the customer at a pickup point.
                        </li>
                        <li>
                            <code>confirmed</code>: The shipment has been confirmed and registered in the system, but
                            hasn't started its physical movement yet.
                        </li>
                        <li>
                            <code>in_transit</code>: The package is in movement, being transported between locations.
                        </li>
                        <li>
                            <code>out_for_delivery</code>: The package is in the delivery vehicle for final delivery to
                            the recipient.
                        </li>
                        <li><code>delivered</code>: The package has been successfully delivered to the recipient.</li>
                        <li>
                            <code>failure</code>: There was a problem with the delivery that prevented completion (may
                            include cases such as incorrect address, damaged package, shipment rejection, etc.).
                        </li>
                    </ul>
                </div>
            </div>
        </md-card-content>
    </md-card>
</template>

<style scoped lang="scss">
.shipping-update-notifications-about-card {
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__content {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}
</style>
