<template>
    <div>
        <md-button @click="openInstallationModal" class="md-primary">Install app</md-button>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';
import PrestashopInstallInstructionsModal from '@/components/Modals/PrestashopInstallInstructionsModal.vue';

export default {
    name: 'MainAppsPrestashopConnectButton',

    data() {
        return {
            isVerifying: false,
        };
    },

    computed: {
        ...mapGetters('agentSettings', ['getSkillByInternalName']),
        moduleUrl() {
            return process.env.VUE_APP_PRESTASHOP_MODULE_URL || '#';
        },
    },

    methods: {
        ...mapActions('modalStore', ['openModal']),

        openInstallationModal() {
            this.openModal({
                component: PrestashopInstallInstructionsModal,
                props: {
                    moduleUrl: this.moduleUrl,
                },
                onEvent: async (eventName, eventData) => {
                    if (eventName === 'module-installed') {
                        this.isVerifying = true;
                        try {
                            // Verify installation by checking if the skill is properly configured
                            const prestashopSkill = this.getSkillByInternalName('Prestashop');
                            if (prestashopSkill && prestashopSkill.config_values) {
                                this.showSuccessToast('Prestashop module installed successfully');
                                this.$emit('account-connected');
                            } else {
                                this.showErrorToast(
                                    'Module installation could not be verified. Please check your configuration.',
                                );
                            }
                        } catch (error) {
                            console.error('Error verifying installation:', error);
                            this.showErrorToast('Error verifying installation. Please try again.');
                        } finally {
                            this.isVerifying = false;
                        }
                    }
                },
            });
        },

        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },

        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },
    },
};
</script>

<style scoped>
/* Add any additional styles here */
</style>
