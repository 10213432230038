<template>
    <div class="custom-main-panel">
        <div class="progress-container">
            <md-progress-bar v-if="isLoading" md-mode="indeterminate"></md-progress-bar>
        </div>
        <div class="md-layout custom-content-panel">
            <div v-if="isLoading" class="overlay md-layout-item md-size-100"></div>
            <div class="md-layout-item md-size-100">
                <!-- <p class="md-caption">Business knowledge encompasses the comprehension of an organization's processes, industry
          trends, competitive landscape, and core tenets underpinning its achievements. Presented below is a table
          detailing all the Business Knowledge accessible to Lixsa. The more Lixsa knows about your enterprise, the more
          proficiently it operates.</p> -->
                <div class="custom-action-save">
                    <p class="md-subheading">Business Knowledge</p>
                    <UploadWizard @fileUploaded="fetchTableData" @close="handleWizardClose" />
                </div>
            </div>
            <div class="table-panel md-layout-item md-size-100">
                <md-card id="business-knowledge-table-card">
                    <md-card-content class="custom-loading-spinner" v-if="isLoading">
                        <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
                    </md-card-content>

                    <md-card-content v-else>
                        <div v-if="!isEmpty">
                            <md-table
                                :value="queriedData"
                                :md-sort.sync="currentSort"
                                :md-sort-order.sync="currentSortOrder"
                                :md-sort-fn="customSort"
                                class="paginated-table"
                            >
                                <md-table-toolbar>
                                    <md-field>
                                        <label for="pages">Per page</label>
                                        <md-select v-model="pagination.perPage" name="pages">
                                            <md-option
                                                v-for="item in pagination.perPageOptions"
                                                :key="item"
                                                :label="item"
                                                :value="item"
                                            >
                                                {{ item }}
                                            </md-option>
                                        </md-select>
                                    </md-field>

                                    <md-field>
                                        <md-input
                                            type="search"
                                            class="mb-3"
                                            clearable
                                            style="width: 200px"
                                            placeholder="Search records"
                                            v-model="searchQuery"
                                        >
                                        </md-input>
                                    </md-field>
                                </md-table-toolbar>

                                <md-table-row slot="md-table-row" slot-scope="{ item }">
                                    <md-table-cell md-label="Name" md-sort-by="name">{{ item.name }}</md-table-cell>
                                    <md-table-cell md-label="Url">
                                        <a
                                            class="custom-url-field"
                                            :href="processedResource_url(item)"
                                            :title="item.resource_url"
                                            target="_blank"
                                            >{{ item.resource_url }}</a
                                        >
                                    </md-table-cell>
                                    <md-table-cell md-label="Type">{{ item.type }}</md-table-cell>

                                    <md-table-cell md-label="Training Status">
                                        <i
                                            v-if="item.training_status === 'SYNCED'"
                                            class="fas fa-check custom-check-icon"
                                        ></i>
                                        <i
                                            v-else-if="item.training_status === 'PENDING'"
                                            class="fas fa-sync fa-spin custom-sync-icon"
                                        ></i>
                                        <i
                                            v-else-if="item.training_status === 'ERROR'"
                                            class="fas fa-exclamation-triangle custom-warning-icon"
                                        >
                                            <md-tooltip md-direction="right">
                                                <span class="md-caption">Something were wrong</span></md-tooltip
                                            >
                                        </i>
                                        <i
                                            v-else-if="
                                                item.training_status === 'NOT_SYNCED' || item.training_status === null
                                            "
                                            class="fas fa-times custom-error-icon"
                                        ></i>
                                    </md-table-cell>
                                    <md-table-cell md-label="Creation">
                                        <p class="md-caption">{{ formatDate(item.date_created) }}</p>
                                    </md-table-cell>
                                    <md-table-cell md-label="Update">
                                        <p class="md-caption">{{ formatDate(item.date_updated) }}</p>
                                    </md-table-cell>
                                    <md-table-cell class="custom-icons" md-label="">
                                        <md-button
                                            class="md-just-icon md-info md-simple"
                                            :disabled="item.training_status === 'PENDING'"
                                            @click.native="handleSync(item)"
                                        >
                                            <md-icon>sync</md-icon>
                                        </md-button>

                                        <!-- EDIT ITEM -->
                                        <!-- <EditWizard :item="item" /> -->

                                        <md-button
                                            class="md-just-icon md-danger md-simple"
                                            @click.native="handleDelete(item)"
                                        >
                                            <md-icon>delete</md-icon>
                                        </md-button>
                                    </md-table-cell>
                                </md-table-row>
                            </md-table>
                        </div>

                        <div v-else-if="isEmpty">
                            <md-empty-state
                                md-icon="home_repair_service"
                                md-label="No Knowledge Found"
                                md-description="Don't let your Business Knowledge table stay empty. Help improve Lixsa accuracy by giving it key information about your business."
                            >
                            </md-empty-state>
                        </div>
                    </md-card-content>
                    <md-card-actions v-if="!isEmpty">
                        <pagination
                            class="pagination-no-border pagination-success"
                            v-model="pagination.currentPage"
                            :per-page="pagination.perPage"
                            :total="totalEntries"
                            :from="from + 1"
                            :to="to"
                        >
                        </pagination>
                    </md-card-actions>
                </md-card>
            </div>
        </div>
    </div>
</template>

<script>
import { Pagination } from '@/components';
import UploadWizard from '@/components/Training/UploadWizard.vue';
import businessKnowledgeService from '@/services/account/businessKnowledgesService';
import Fuse from 'fuse.js';
import Swal from 'sweetalert2';
import { mapActions, mapGetters } from 'vuex';

//tour
import businessKnowledgeTourSteps from '@/services/tours/sectionsTours/businessKnowledgeTour';
import TourService from '@/services/tours/tourService';

export default {
    components: {
        Pagination,
        UploadWizard,
    },
    computed: {
        ...mapGetters('tours', ['isCompleted']),

        queriedData() {
            let result = this.tableData;
            if (this.searchQuery !== '' && this.searchedData.length > 0) {
                result = this.searchedData;
            }
            return result.slice(0, this.numberOfElements);
        },
        to() {
            return Math.min(this.pagination.currentPage * this.pagination.perPage, this.pagination.total);
        },
        from() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.searchedData.length > 0 ? this.searchedData.length : this.tableData.length;
        },
    },
    data() {
        return {
            currentSort: 'name',
            currentSortOrder: 'asc',
            pagination: {
                perPage: 10,
                currentPage: 1,
                perPageOptions: [10, 20, 40, 80, 100],
                total: 0,
            },
            searchQuery: '',
            propsToSearch: ['name', 'resource_url', 'type'],
            tableData: [],
            searchedData: [],
            fuseSearch: null,
            totalEntries: 0,
            totalPages: 0,
            numberOfElements: 0,
            isLoading: false,
            isEmpty: true,
            tourInstance: null,
        };
    },
    methods: {
        ...mapActions('websocket', ['subscribe', 'unsubscribe']),

        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },
        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },
        parseJSON(jsonString) {
            try {
                return JSON.parse(jsonString);
            } catch (error) {
                console.error('Error parsing JSON:', error);
                return null;
            }
        },
        processedResource_url(item) {
            if (item.type !== 'WEB' && item.type !== 'JSON') {
                return item.resource_url.split('?')[0];
            } else {
                return item.resource_url;
            }
        },
        formatDate(timestamp) {
            const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
            const date = new Date(timestamp * 1000);
            return date.toLocaleDateString('en-US', options);
        },
        customSort(value) {
            return value.sort((a, b) => {
                const sortBy = this.currentSort;
                if (this.currentSortOrder === 'desc') {
                    return a[sortBy].localeCompare(b[sortBy]);
                }
                return b[sortBy].localeCompare(a[sortBy]);
            });
        },
        async handleSync(item) {
            try {
                const response = await businessKnowledgeService.syncBusinessKnowledge(item.uuid);
                if (response.status === 200) {
                    this.changeTrainingStatus(item);
                    this.showSuccessToast('Lixsa is learning, this process will take a few minutes...');
                }
            } catch (error) {
                console.error('An error occurred while syncing the item:', error);
                this.showErrorToast('An error occurred while syncing the item');
            }
        },
        changeTrainingStatus(item) {
            const id = this.tableData.findIndex((data) => data.uuid === item.uuid);
            this.tableData[id].training_status = 'PENDING';
        },
        handleDelete(item) {
            Swal.fire({
                title: 'Are you sure?',
                text: `You won't be able to revert this!`,
                type: 'warning',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#4caf50',
                confirmButtonText: 'Yes, delete it!',
                buttonsStyling: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteRow(item);
                    this.showSuccessToast('Item deleted');
                }
            });
        },
        deleteRow(item) {
            let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === item.id);
            if (indexToDelete >= 0) {
                this.tableData.splice(indexToDelete, 1);
                businessKnowledgeService
                    .deleteBusinessKnowledge(item.uuid)
                    .then(() => {
                        this.fetchTableData();
                    })
                    .catch((error) => {
                        console.error('An error occurred while deleting the item:', error);
                    });
            }
        },
        async fetchTableData() {
            try {
                this.isLoading = true;
                const response = await businessKnowledgeService.fetchBusinessKnowledges(
                    this.pagination.currentPage - 1,
                    this.pagination.perPage,
                );
                if (response && response.data) {
                    this.tableData = response.data.content;
                    this.totalEntries = response.data.totalElements;
                    this.totalPages = response.data.totalPages;
                    this.numberOfElements = response.data.numberOfElements;
                    this.pagination.total = response.data.totalElements;
                    this.isEmpty = response.data.empty;
                    this.fuseSearch = new Fuse(this.tableData, {
                        keys: ['name'],
                        threshold: 0.3,
                    });
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                this.isLoading = false;
            }
        },
        updateTableData(newData) {
            const index = this.tableData.findIndex((data) => data.uuid === newData.uuid);
            if (index !== -1) {
                this.tableData = [...this.tableData.slice(0, index), newData, ...this.tableData.slice(index + 1)];
            }
        },
        subscribeToBusinessKnowledge() {
            const topic = `/account/${this.$store.state.user.user.account_id}/business-knowledge`;
            this.subscribe({
                topic,
                callback: this.handleBusinessKnowledgeUpdate,
            });
        },
        handleBusinessKnowledgeUpdate(message) {
            if (!message || !message.data) return;

            if (message.name === 'BusinessKnowledgeSynced') {
                const newBusinessKnowledgeData = this.parseJSON(message.data);
                if (!newBusinessKnowledgeData) return;

                this.updateTableData(newBusinessKnowledgeData);
            }
        },
        handleWizardClose() {
            if (this.tourInstance) {
                this.tourInstance.next();
            }
        },
    },
    async mounted() {
        this.fetchTableData();
        this.subscribeToBusinessKnowledge();
        if (!this.isCompleted('business-knowledge-tour')) {
            this.$nextTick(() => {
                this.tourInstance = TourService.startTour('business-knowledge-tour', businessKnowledgeTourSteps);
            });
        }
    },
    beforeDestroy() {
        const topic = `/account/${this.$store.state.user.user.account_id}/business-knowledge`;
        this.unsubscribe({
            topic,
            callback: this.handleBusinessKnowledgeUpdate,
        });
    },
    watch: {
        searchQuery(value) {
            let result = this.tableData;
            if (value !== '') {
                const searchResults = this.fuseSearch.search(this.searchQuery);
                result = searchResults.map((res) => res.item);
            }
            this.searchedData = result;
        },
        'pagination.currentPage': function () {
            this.fetchTableData();
        },
        'pagination.perPage': function () {
            this.fetchTableData();
        },
    },
};
</script>

<style lang="scss" scoped>
.md-layout-item {
    padding-left: 0px;
    padding-right: 0px;
}

.md-card .md-card-actions {
    border: 0;
    margin-left: 20px;
    margin-right: 20px;
}

.buttons-panel {
    display: flex;
    align-items: center;
    height: fit-content;
}

.table-panel {
    display: flex;
    align-items: center;
    height: fit-content;
}

.custom-main-panel {
    position: relative;
    overflow-x: auto;
    width: 100%;
}

.custom-content-panel {
    margin-bottom: 40px;
    padding-left: 0;
    padding-right: 0;

    @media (max-width: 1278px) {
        padding-left: 0;
        padding-right: 0;
    }

    @media (max-width: 959px) {
        padding-left: 0;
        padding-right: 0;
    }

    .md-card {
        margin-top: 15px;
    }
}

.custom-url-field {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 250px;
    max-width: 100%;
}

.custom-check-icon {
    color: #4caf50;
}

.custom-sync-icon {
    color: #00bcd4;
}

.custom-error-icon {
    color: #f44336;
}

.custom-warning-icon {
    color: #ff9800;
}

.custom-loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-icons {
    padding-right: 35px !important;

    .md-button {
        margin-right: 6px;
    }
}

.custom-action-save {
    @media (min-width: 599px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(238, 238, 238, 0.5);
    z-index: 10;
    cursor: not-allowed;
}

.progress-container {
    position: relative;
    height: 4px; // Match md-progress-bar height

    .md-progress-bar {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
}
</style>
