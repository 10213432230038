<template>
    <div class="info-item" @click="toggleShowDetailsOrder">
        <svg-icon class="info-icon" name="description" />
        <div class="info-content">
            <div class="info-header">
                <span class="info-title">{{ order.name }}</span>
                <span class="info-price" v-if="order.total_price !== null">
                    {{ formatNumberWithDecimals(order.total_price) }}
                    {{ getCurrencySymbol(order.currency) }}
                </span>
            </div>
            <div class="status-badges">
                <span
                    v-if="order.financial_status"
                    :class="['status-badge', `badge-${order.financial_status.toLowerCase()}`]"
                >
                    {{ formatFinancialStatus }}
                </span>
                <span
                    v-if="order.fulfillment_status"
                    :class="['status-badge', `badge-${order.fulfillment_status.toLowerCase()}`]"
                >
                    {{ order.fulfillment_status.toLocaleUpperCase() }}
                </span>
                <span
                    v-if="hasShipmentStatus"
                    :class="['status-badge', `badge-${order.fulfillments[0].shipment_status}`]"
                >
                    {{ order.fulfillments[0].shipment_status.replaceAll('_', ' ').toLocaleUpperCase() }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import SvgIcon from '@/components/Chat/SvgIcon/SvgIcon.vue';
import { getCurrencySymbol, formatNumberWithDecimals, getBadgeClass } from '@/utils/common.js';

export default {
    name: 'CustomerAssetsCardEcommerceOrder',
    components: {
        SvgIcon,
    },
    props: {
        order: { type: [Object], required: true },
        showDetailsOrder: { type: [Boolean], required: true },
    },
    methods: {
        toggleShowDetailsOrder() {
            this.$emit('toggle-details-order', this.order);
        },
        getBadgeClass,
        getCurrencySymbol,
        formatNumberWithDecimals,
    },
    computed: {
        hasShipmentStatus() {
            return (
                this.order.fulfillments &&
                this.order.fulfillments.length > 0 &&
                this.order.fulfillments[0].shipment_status
            );
        },
        formatFinancialStatus() {
            const status = this.order.financial_status;
            if (status === 'pending') {
                return 'PENDING PAYMENT';
            }
            return status.split('_').join(' ').toUpperCase();
        },
    },
};
</script>
