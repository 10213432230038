import SockJS from 'sockjs-client';
import { Client } from '@stomp/stompjs';

/**
 * WebSocket service for handling real-time communication using STOMP protocol.
 * Manages connections, subscriptions, heartbeats, and automatic reconnection.
 */
class WebSocketService {
    constructor() {
        /** @type {SockJS|null} WebSocket connection instance */
        this.socket = null;
        /** @type {Client|null} STOMP client instance */
        this.stompClient = null;
        /** @type {number|null} Heartbeat interval ID */
        this.heartbeatInterval = null;
        /** @type {Map<string, Set>} Map of topic subscriptions */
        this.subscriptions = new Map();
        /** @type {Function|null} Connection state change callback */
        this.onConnectionChange = null;
        /** @type {number} Number of reconnection attempts */
        this.reconnectAttempts = 0;
        /** @type {Promise|null} Current connection promise */
        this.connectPromise = null;
    }

    /**
     * Establishes a WebSocket connection with authentication.
     * @param {string} token - Authentication token
     * @returns {Promise<void>} Connection promise
     */
    async connect(token) {
        if (this.connectPromise) {
            return this.connectPromise;
        }

        this.connectPromise = new Promise((resolve, reject) => {
            this.socket = new SockJS(`${process.env.VUE_APP_API_URL}/ws`, {
                transportOptions: {
                    'xhr-streaming': {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    },
                },
            });

            this.stompClient = new Client({
                webSocketFactory: () => this.socket,
                reconnectDelay: 1000,
            });

            this.stompClient.onConnect = () => {
                this.handleConnect();
                resolve();
            };

            this.stompClient.onWebSocketClose = () => {
                this.handleDisconnect();
                reject(new Error('WebSocket disconnected'));
            };

            this.stompClient.onStompError = (error) => {
                console.error('STOMP error:', error);
                this.handleDisconnect();
                reject(error);
            };

            this.stompClient.activate();
        });

        try {
            await this.connectPromise;
        } finally {
            this.connectPromise = null;
        }

        return this.connectPromise;
    }

    /**
     * Handles successful connection establishment.
     * @private
     */
    handleConnect() {
        this.reconnectAttempts = 0;
        this.notifyConnectionChange(true);
        this.resubscribeAll();
        this.startHeartbeat();
    }

    /**
     * Handles connection loss or disconnection.
     * @private
     */
    handleDisconnect() {
        this.notifyConnectionChange(false);
        this.stopHeartbeat();
        this.scheduleReconnect();
    }

    /**
     * Notifies connection state changes to registered handler.
     * @param {boolean} connected - Current connection state
     * @private
     */
    notifyConnectionChange(connected) {
        if (this.onConnectionChange) {
            this.onConnectionChange(connected);
        }
    }

    /**
     * Sets handler for connection state changes.
     * @param {Function} handler - Callback function for connection state changes
     */
    setConnectionChangeHandler(handler) {
        this.onConnectionChange = handler;
    }

    /**
     * Subscribes to a topic with a callback for messages.
     * @param {string} topic - Topic to subscribe to
     * @param {Function} callback - Callback function for received messages
     */
    async subscribe(topic, callback) {
        if (!this.subscriptions.has(topic)) {
            this.subscriptions.set(topic, new Set());
        }
        this.subscriptions.get(topic).add(callback);

        if (this.isConnected()) {
            await this.subscribeToTopic(topic);
        }
    }

    /**
     * Subscribes to a topic on the STOMP client.
     * @param {string} topic - Topic to subscribe to
     * @private
     */
    async subscribeToTopic(topic) {
        try {
            const subscription = this.stompClient.subscribe(topic, (frame) => {
                try {
                    const data = JSON.parse(frame.body);
                    this.notifySubscribers(topic, data);
                } catch (error) {
                    console.error('Error parsing message:', error);
                }
            });
            this.subscriptions.get(topic).subscription = subscription;
        } catch (error) {
            console.error('Error subscribing to topic:', topic, error);
        }
    }

    /**
     * Notifies all subscribers of a topic with received data.
     * @param {string} topic - Topic that received the message
     * @param {*} data - Message data
     * @private
     */
    notifySubscribers(topic, data) {
        const subscribers = this.subscriptions.get(topic);
        if (subscribers) {
            subscribers.forEach((callback) => {
                if (callback !== 'subscription') {
                    callback(data);
                }
            });
        }
    }

    /**
     * Unsubscribes a callback from a topic.
     * @param {string} topic - Topic to unsubscribe from
     * @param {Function} callback - Callback to remove
     */
    unsubscribe(topic, callback) {
        const subscribers = this.subscriptions.get(topic);
        if (subscribers) {
            subscribers.delete(callback);
            if (subscribers.size === 0) {
                const subscription = subscribers.subscription;
                if (subscription) {
                    subscription.unsubscribe();
                }
                this.subscriptions.delete(topic);
            }
        }
    }

    /**
     * Unsubscribes from all topics.
     */
    unsubscribeAll() {
        for (const subscribers of this.subscriptions.values()) {
            const subscription = subscribers.subscription;
            if (subscription) {
                subscription.unsubscribe();
            }
        }
        this.subscriptions.clear();
    }

    /**
     * Resubscribes to all topics (used after reconnection).
     * @private
     */
    async resubscribeAll() {
        for (const topic of this.subscriptions.keys()) {
            await this.subscribeToTopic(topic);
        }
    }

    /**
     * Schedules a reconnection attempt with exponential backoff.
     * @private
     */
    scheduleReconnect() {
        this.reconnectAttempts++;
        const delay = Math.min(5000 * 2 ** this.reconnectAttempts, 60000);
        setTimeout(() => {
            this.connect();
        }, delay);
    }

    /**
     * Starts the heartbeat mechanism.
     * @private
     */
    startHeartbeat() {
        this.stopHeartbeat();
        this.heartbeatInterval = setInterval(() => {
            if (this.isConnected()) {
                try {
                    this.stompClient.publish({
                        destination: '/app/heartbeat',
                        body: JSON.stringify({ type: 'heartbeat' }),
                    });
                } catch (error) {
                    console.error('[WebSocket] Failed to send heartbeat:', error);
                }
            }
        }, 15000);
    }

    /**
     * Stops the heartbeat mechanism.
     * @private
     */
    stopHeartbeat() {
        if (this.heartbeatInterval) {
            clearInterval(this.heartbeatInterval);
            this.heartbeatInterval = null;
        }
    }

    /**
     * Disconnects the WebSocket connection and cleans up resources.
     */
    disconnect() {
        this.stopHeartbeat();
        this.unsubscribeAll();
        if (this.stompClient) {
            this.stompClient.deactivate();
        }
        this.socket = null;
        this.stompClient = null;
        this.notifyConnectionChange(false);
    }

    /**
     * Checks if the WebSocket connection is currently active.
     * @returns {boolean} Connection status
     */
    isConnected() {
        return this.stompClient && this.stompClient.connected;
    }
}

export default new WebSocketService();
