<!-- eslint-disable prettier/prettier -->
<template>
    <div
        v-show="showRoomsList"
        class="vac-rooms-container"
        :class="{
            'vac-rooms-container-full': isMobile,
            'vac-app-border-r': !isMobile,
        }"
    >
        <filters-side-bar
            v-if="hasAnyChannel"
            ref="filtersSidebar"
            v-model="isSidebarOpen"
            :filters="activeFilters"
            @sidebar-toggle="handleSidebarToggle"
            @filters-applied="handleFiltersApplied"
        />
        <room-list-tool-bar :rooms="rooms" />

        <slot name="rooms-list-search">
            <rooms-search
                v-if="hasAnyChannel"
                ref="roomsSearch"
                :rooms="rooms"
                :loading-rooms="loadingRooms"
                :text-messages="textMessages"
                :show-search="showSearch"
                :show-add-room="false"
                @search-room="searchRoom"
                @add-room="$emit('add-room')"
                @focus-search-input="focusSearchInput"
                @filter-activated="(agent, satisfaction) => $emit('filter-activated', agent, satisfaction)"
                :avisar="onParamValue"
            >
                <template v-for="(idx, name) in $slots" #[name]="data">
                    <slot :name="name" v-bind="data" />
                </template>
            </rooms-search>
        </slot>

        <filter-actions-section
            v-if="hasAnyChannel"
            :can-save-preset="this.$store.getters['activeFilters/hasActiveFilters']"
            @open-filters="openFilters"
            @save-preset="openSaveFiltersPresetModal"
            @load-preset="openFiltersPresetsList"
        />

        <active-filters
            v-if="Object.keys(activeFilters).length && hasAnyChannel"
            :active-filters="activeFilters"
            @remove-filter="removeFilter"
        />

        <loader :show="loadingRooms" type="rooms">
            <template v-for="(idx, name) in $slots" #[name]="data">
                <slot :name="name" v-bind="data" />
            </template>
        </loader>

        <div
            v-if="!loadingRooms && !rooms.length && showAddRoom && hasWhatsappSkill"
            class="vac-new-conversation-button"
        >
            <md-button @click="$emit('add-room')" class="md-primary"> Create new conversation </md-button>
        </div>

        <div v-if="!loadingRooms && !rooms.length && showAddRoom && !hasAnyChannel" class="vac-connect-whatsapp-button">
            <p class="md-subheading">Connect a communication channel to start conversations</p>
            <md-button @click="goToCommunicationChannelSkills()" class="md-primary">Connect</md-button>
        </div>

        <div v-if="!loadingRooms && !rooms.length && hasAnyChannel" class="vac-rooms-empty">
            <slot name="rooms-empty">
                <SvgIcon name="empty_conversations" />
                <span>{{ textMessages.ROOMS_EMPTY }}</span>
            </slot>
        </div>

        <div v-if="!loadingRooms && hasAnyChannel" id="rooms-list" class="vac-room-list">
            <div
                v-for="fRoom in filteredRooms"
                :id="fRoom.roomId"
                :key="fRoom.roomId"
                class="vac-room-item"
                :class="{ 'vac-room-selected': selectedRoomId === fRoom.roomId }"
                @click="openRoom(fRoom)"
            >
                <room-content
                    :current-user-id="currentUserId"
                    :room="fRoom"
                    :text-formatting="textFormatting"
                    :link-options="linkOptions"
                    :text-messages="textMessages"
                    :room-actions="roomActions"
                    @room-action-handler="$emit('room-action-handler', $event)"
                >
                    <template v-for="(idx, name) in $slots" #[name]="data">
                        <slot :name="name" v-bind="data" />
                    </template>
                </room-content>
            </div>
            <transition name="vac-fade-message">
                <div v-if="rooms.length && !loadingRooms" id="infinite-loader-rooms">
                    <loader :show="showLoader" :infinite="true" type="infinite-rooms">
                        <template v-for="(idx, name) in $slots" #[name]="data">
                            <slot :name="name" v-bind="data" />
                        </template>
                    </loader>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import Loader from '@/components/Chat/Loader/Loader.vue';

import RoomsSearch from './RoomsSearch/RoomsSearch.vue';
import RoomContent from './RoomContent/RoomContent.vue';
import RoomListToolBar from './RoomListToolBar/RoomListToolBar.vue';

import filteredItems from '@/utils/filter-items';

import SvgIcon from '@/components/Chat/SvgIcon/SvgIcon.vue';
import FiltersSideBar from './FiltersSideBar/FiltersSideBar.vue';
import ActiveFilters from './ActiveFilters/ActiveFilters.vue';
import FilterActionsSection from './FilterActionsSection/FilterActionsSection.vue';

import { mapActions } from 'vuex';
import SaveFiltersPreset from './SaveFiltersPreset/SaveFiltersPreset.vue';
import FiltersPresetsList from './FiltersPresetsList/FiltersPresetsList.vue';

export default {
    name: 'RoomsList',
    components: {
        Loader,
        RoomsSearch,
        RoomContent,
        SvgIcon,
        RoomListToolBar,
        FiltersSideBar,
        ActiveFilters,
        FilterActionsSection,
    },

    props: {
        currentUserId: { type: [String, Number], required: true },
        textMessages: { type: Object, required: true },
        showRoomsList: { type: Boolean, required: true },
        showSearch: { type: Boolean, required: true },
        showAddRoom: { type: Boolean, required: true },
        textFormatting: { type: Object, required: true },
        linkOptions: { type: Object, required: true },
        isMobile: { type: Boolean, required: true },
        rooms: { type: Array, required: true },
        loadingRooms: { type: Boolean, required: true },
        roomsLoaded: { type: Boolean, required: true },
        room: { type: Object, required: true },
        customSearchRoomEnabled: { type: [Boolean, String], default: false },
        roomActions: { type: Array, required: true },
        scrollDistance: { type: Number, required: true },
    },

    emits: [
        'add-room',
        'search-room',
        'room-action-handler',
        'loading-more-rooms',
        'fetch-room',
        'fetch-more-rooms',
        'filters-applied',
    ],

    data() {
        return {
            filteredRooms: this.rooms || [],
            observer: null,
            showLoader: true,
            loadingMoreRooms: false,
            selectedRoomId: '',
            counterVal: 0,
            searchParam: '',
            valueSearch: '',
            isSidebarOpen: false,
        };
    },

    watch: {
        rooms: {
            deep: true,
            handler(newVal, oldVal) {
                this.filteredRooms = newVal;
                if (newVal.length !== oldVal.length || this.roomsLoaded) {
                    this.loadingMoreRooms = false;
                }
            },
        },
        loadingRooms(val) {
            if (!val) {
                setTimeout(() => this.initIntersectionObserver());
                if (this.searchParam !== '' && this.valueSearch !== '') {
                    this.$emit('search-room', this.searchParam);
                }
            }
        },
        loadingMoreRooms(val) {
            this.$emit('loading-more-rooms', val);
        },
        roomsLoaded: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.loadingMoreRooms = false;
                    if (!this.loadingRooms) {
                        this.showLoader = false;
                    }
                }
            },
        },
        room: {
            immediate: true,
            handler(val) {
                if (val && !this.isMobile) this.selectedRoomId = val.roomId;
            },
        },
    },

    computed: {
        activeFilters() {
            return this.$store.getters['activeFilters/activeFilters'];
        },
        hasAnyChannel() {
            const hasActiveChannels = this.$store.getters['agentSettings/hasAnyCommunicationChannel'] || false;
            const hasConversations = this.rooms.length > 0;
            return hasActiveChannels || hasConversations;
        },
        hasWhatsappSkill() {
            return this.$store.getters['agentSettings/getSkillByInternalName']('whatsapp') || false;
        },
    },

    methods: {
        ...mapActions('modalStore', ['openModal']),
        goToCommunicationChannelSkills() {
            this.$router.push('/skills/discovery?type=channel');
        },
        openSaveFiltersPresetModal() {
            this.openModal({
                component: SaveFiltersPreset,
                props: { activeFilters: this.activeFilters },
            });
        },
        emitFiltersApplied() {
            this.$emit(
                'filter-activated',
                this.activeFilters.agent || 'ALL',
                this.activeFilters.sentiment || 'ALL',
                this.activeFilters.tags || [],
                this.activeFilters.excludedTags || [],
                this.activeFilters.channel || 'ALL',
            );
        },

        removeFilter(filterToRemove) {
            this.$store.dispatch('activeFilters/removeFilter', filterToRemove);
            this.emitFiltersApplied();
        },

        handleFiltersApplied(filters) {
            this.$store.dispatch('activeFilters/setActiveFilters', filters);
            this.emitFiltersApplied();
        },

        openFilters() {
            this.$nextTick(() => {
                this.isSidebarOpen = !this.isSidebarOpen;
            });
        },

        handleSidebarToggle(isOpen) {
            this.isSidebarOpen = isOpen;
        },
        initIntersectionObserver() {
            if (this.observer) {
                // this.showLoader = true;
                this.observer.disconnect();
            }
            // this.showLoader = true;
            const loader = this.$el.querySelector('#infinite-loader-rooms');

            if (loader) {
                const options = {
                    root: this.$el.querySelector('#rooms-list'),
                    rootMargin: `${this.scrollDistance}px`,
                    threshold: 0,
                };

                this.observer = new IntersectionObserver((entries) => {
                    if (entries[0].isIntersecting) {
                        this.loadMoreRooms();
                    }
                }, options);

                this.observer.observe(loader);
            }
        },
        onParamValue(v) {
            this.valueSearch = v;
        },
        searchRoom(ev) {
            this.valueSearch = ev.target.value;
            if (this.customSearchRoomEnabled) {
                // this.$emit("search-room", ev.target.value);
                this.$emit('search-room', this.valueSearch);
            } else {
                this.filteredRooms = filteredItems(
                    this.rooms,
                    'roomName',
                    this.valueSearch,
                    // ev.target.value
                );
            }
        },
        openRoom(room) {
            if (room.roomId === this.room.roomId && !this.isMobile) return;
            if (!this.isMobile) this.selectedRoomId = room.roomId;

            this.$emit('fetch-room', { room });
            this.$emit('room-changed', { room });
        },
        loadMoreRooms() {
            if (this.loadingMoreRooms) return;

            if (this.roomsLoaded) {
                this.loadingMoreRooms = false;
                this.showLoader = false;
                return;
            }

            this.$emit('fetch-more-rooms');
            this.loadingMoreRooms = true;
        },
        focusSearchInput() {
            this.$nextTick(() => {
                this.$refs.roomsSearch.$emit('focus-input');
            });
        },
        openFiltersPresetsList() {
            this.openModal({
                component: FiltersPresetsList,
                onEvent: (eventName, eventPayload) => {
                    if (eventName === 'preset-applied') {
                        this.emitFiltersApplied();
                    }
                },
            });
        },
    },

    mounted() {
        // Verificar si hay parametro en la URL
        const search = this.$route.fullPath.split('?search=')[1];

        if (search) {
            this.searchParam = search;
        }

        this.$nextTick(() => {
            this.initIntersectionObserver();
        });
        this.focusSearchInput();
    },
};
</script>

<style lang="scss">
@import './RoomsList.scss';

.vac-rooms-container {
    position: relative;
}
</style>
