<script>
import CircularProgressBar from '@/components/CircularProgressBar.vue';
import { mapGetters } from 'vuex';

function setSidebarShowEventListener() {
    document.querySelector('.sidebar').addEventListener('transitionend', function (event) {
        if (event.propertyName === 'width') {
            // Check if the sidebar is fully expanded
            if (getComputedStyle(event.target).width === '260px') {
                document.querySelector('.sidebar-subs-panel__call-to-action').style.display = 'flex';

                const sidebarPanel = document.querySelector('.sidebar-notifications-panel');
                if (sidebarPanel) {
                    sidebarPanel.style.left = '270px';
                }
            }
        }
    });
}

function setSidebarHideEventListener() {
    document.querySelector('.sidebar').addEventListener('mouseleave', function () {
        if (this.closest('.sidebar-mini')) {
            document.querySelector('.sidebar-subs-panel__call-to-action').style.display = 'none';

            const sidebarPanel = document.querySelector('.sidebar-notifications-panel');
            if (sidebarPanel) {
                sidebarPanel.style.left = '90px';
            }
        }
    });
}

export default {
    name: 'SidebarFreePlanPanel',
    components: { CircularProgressBar },
    computed: {
        ...mapGetters('membership', ['consumedConversations', 'totalConversations']),
        conversationsPercentage() {
            const percentage = (this.consumedConversations * 100) / this.totalConversations;
            return percentage > 0 ? Number(percentage.toFixed(0)) : Number(percentage);
        },
    },
    methods: {
        goToPage(route) {
            if (this.$route.path !== route) {
                this.$router.push(route);
            }
        },
    },
    mounted() {
        setSidebarShowEventListener();
        setSidebarHideEventListener();
    },
};
</script>

<template>
    <div class="sidebar-subs-panel">
        <p>FREE</p>
        <div class="sidebar-subs-panel__call-to-action">
            <circular-progress-bar :value="conversationsPercentage || 0" />
            <div style="text-align: center" class="md-caption">
                You have consumed {{ conversationsPercentage || 0 }}% of your free conversations
            </div>
            <md-button
                @click="goToPage('/settings/billing')"
                class="md-primary md-sm md-block sidebar-subs-panel__call-to-action__button"
                >Upgrade</md-button
            >
        </div>
    </div>
</template>

<style scoped lang="scss">
.sidebar-subs-panel__call-to-action {
    padding: 0.5rem 0.5rem;
}
</style>
