<template>
    <div class="custom-main-panel">
        <div class="progress-container">
            <md-progress-bar v-if="isLoading" md-mode="indeterminate"></md-progress-bar>
        </div>
        <div id="agent-basic-info-card" class="md-layout custom-content-panel">
            <div v-if="isLoading" class="overlay md-layout-item custom-no-padding md-size-100"></div>
            <!-- <div class="md-layout-item custom-no-padding md-size-100">
                <p class="md-caption">Customize your experience with Lixsa by choosing a unique name and selecting the
          conversational style that best represents your business. This personalization will enhance Lixsa's interactions,
          making it more relatable and engaging for your customers.</p>
            </div> -->
            <div class="md-layout-item md-size-100">
                <div class="custom-action-save">
                    <p class="md-subheading">Basic Information</p>
                    <div>
                        <md-button
                            id="agent-basic-info-card-save-button"
                            v-if="!isEditing"
                            :disabled="!canSave"
                            @click="editAgentSettings"
                            class="md-raised md-primary"
                            >Save</md-button
                        >
                        <md-progress-spinner
                            v-else
                            :md-diameter="20"
                            :md-stroke="3"
                            class="md-white"
                            md-mode="indeterminate"
                        ></md-progress-spinner>
                    </div>
                </div>
            </div>
            <div class="md-layout-item md-size-100">
                <md-card>
                    <md-card-content>
                        <div class="custom-form">
                            <md-field :md-counter="false">
                                <label>Agent name</label>
                                <md-input maxlength="30" v-model="agentData.name"></md-input>
                                <span class="md-helper-text"
                                    >The name by which your agent will introduce himself to clients.</span
                                >
                            </md-field>

                            <md-field>
                                <label>Conversation Tone</label>
                                <md-textarea
                                    v-model="agentData.conversation_tone"
                                    md-autogrow
                                    maxlength="250"
                                ></md-textarea>
                                <span class="md-helper-text"
                                    >The general character and attitude your agent will have in conversations</span
                                >
                            </md-field>
                        </div>
                    </md-card-content>
                </md-card>
            </div>

            <!--      <div class="md-layout-item custom-action-save md-size-100">-->
            <!--        <p class="md-subheading">Skills</p>-->
            <!--        <div class="custom-search-input">-->
            <!--          <md-field>-->
            <!--            <md-input type="search" v-model="searchTerm" clearable style="width: 200px"-->
            <!--              placeholder="Search Skills"></md-input>-->
            <!--          </md-field>-->
            <!--        </div>-->
            <!--      </div>-->

            <!--      <div class="md-layout-item custom-no-padding md-size-100 md-layout">-->
            <!--        <div v-for="skill in filteredSkills"-->
            <!--          class=" md-layout-item md-size-25 md-medium-size-33 md-small-size-50 md-xsmall-size-100">-->
            <!--          <SkillCard :active="skill.active" :miniature="skill.miniature" :title="skill.title"-->
            <!--            :description="skill.description" :price="skill.price" :category="skill.category" />-->
            <!--        </div>-->
            <!--        &lt;!&ndash; <div class="md-layout-item md-size-100 custom-pagination">-->
            <!--          <Pagination />-->
            <!--        </div> &ndash;&gt;-->
            <!--      </div>-->
        </div>
    </div>
</template>

<script>
// Components
import { Pagination } from '@/components';
import agentSettingsService from '@/services/account/agentSettingsService.js';
import SkillCard from '@/components/Training/Agent/SkillCard.vue';
import Skills from '@/pages/Dashboard/Training/Skills.js';
import { mapGetters } from 'vuex';

// Tours
import agentTourSteps from '@/services/tours/sectionsTours/agentTour.js';
import TourService from '@/services/tours/tourService.js';

export default {
    name: 'Agent',
    components: {
        Pagination,
        SkillCard,
    },
    data() {
        return {
            defaultAgentData: {},
            agentData: {
                uuid: '',
                name: '',
                conversation_tone: '',
                account_id: '',
                date_created: 0,
            },
            searchTerm: '',
            skills: Skills,

            isLoading: false,
            isEditing: false,

            tourInstance: null,
        };
    },
    computed: {
        ...mapGetters('tours', ['isCompleted']),

        isEdited() {
            return (
                this.agentData.name != this.defaultAgentData.name ||
                this.agentData.conversation_tone != this.defaultAgentData.conversation_tone
            );
        },
        canSave() {
            return this.isEdited && this.agentData.name && this.agentData.conversation_tone;
        },
        filteredSkills() {
            let filtered = this.skills;

            if (this.searchTerm.trim() !== '') {
                const term = this.searchTerm.toLowerCase();
                filtered = filtered.filter((skill) => skill.title.toLowerCase().includes(term));
            }

            return filtered;
        },
    },
    methods: {
        async fetchAngentSettings() {
            this.isLoading = true;
            try {
                const response = await agentSettingsService.getAgentSettings();
                if (response.data) {
                    this.agentData = { ...response.data };
                    this.defaultAgentData = { ...response.data };
                }
            } catch (error) {
                console.error('Error fetching agent settings', error);
            } finally {
                this.isLoading = false;
            }
        },
        async editAgentSettings() {
            this.isEditing = true;
            try {
                const request = {
                    name: this.agentData.name,
                    conversation_tone: this.agentData.conversation_tone,
                    agent_settings_id: this.agentData.uuid
                        ? this.agentData.uuid
                        : this.$store.state.user.user.account_id,
                };
                await agentSettingsService.editAgentSettings(request);
                this.showSuccessToast('Agent Settings Edited');
                this.fetchAngentSettings();
            } catch (error) {
                console.error('Error editing agent settings', error);
                this.showErrorToast('Error editing agent settings');
                this.fetchAngentSettings();
            } finally {
                this.isEditing = false;
            }
        },
        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },
        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },
    },
    mounted() {
        this.fetchAngentSettings();
        if (!this.isCompleted('agent-tour')) {
            this.$nextTick(() => {
                this.tourInstance = TourService.startTour('agent-tour', agentTourSteps);

                this.tourInstance.on('complete', () => {
                    console.log('Tour in agent completed');
                    this.$router.push({ name: 'Playground' });
                });
            });
        }
    },
    watch: {},
};
</script>

<style lang="scss" scoped>
.custom-skills-section {
    gap: 10px;
}

.custom-no-padding {
    padding-left: 0px;
    padding-right: 0px;
}

.custom-main-panel {
    position: relative;
    overflow-x: auto;
    width: 100%;
}

.custom-content-panel {
    margin-bottom: 40px;
    padding-left: 0;
    padding-right: 0;

    @media (max-width: 1278px) {
        padding-left: 0;
        padding-right: 0;
    }

    @media (max-width: 959px) {
        padding-left: 0;
        padding-right: 0;
    }

    .md-card {
        margin-top: 15px;
    }
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(238, 238, 238, 0.5);
    z-index: 10;
    cursor: not-allowed;
}

.custom-form {
    .md-field {
        margin: 35px 0px;
    }
}

.custom-action-save {
    @media (min-width: 599px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.custom-search-input {
    margin-bottom: 10px;
}

.custom-pagination {
    display: flex;
    align-items: center;
    justify-content: end;
}

.progress-container {
    position: relative;
    height: 4px; // Match md-progress-bar height

    .md-progress-bar {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
}
</style>
