import TemplateDto from '@/services/skills/Dto/TemplateDto';
import PageTemplateDto from '@/services/skills/Dto/PageTemplateDto';
import TemplateRequestDto from '@/services/skills/Dto/TemplateRequestDto';
import TemplatesApiRepository from '@/services/skills/TemplatesApiRepository';
import { v4 as uuidv4 } from 'uuid';

const FLOW_TYPE = 'ABANDONED_CHECKOUT';
const ABANDONED_CHECKOUT_SKILL_ID = 'ab10e42f-fb65-48f9-bd0d-28b7489cccbj';
const CATEGORY = 'MARKETING';

const state = () => ({
    templatesList: null,
    currentLanguage: 'en',
    currentTemplate: null,
    schedulerTimer: null,
    availableLanguages: [],
});

const formatLanguage = (language) => {
    if (!language) return '';
    const [base, region] = language.split('_');
    return region ? `${base.toLowerCase()}_${region.toUpperCase()}` : base.toLowerCase();
};

const getters = {
    getCurrentTemplate: (state) => state.currentTemplate,
    getSchedulerTimer: (state) => state.schedulerTimer,
    getCurrentLanguage: (state) => state.currentLanguage,
    getAvailableLanguages: (state) => state.availableLanguages,
    currentTemplateIsSavedInTemplatesList: (state) => {
        if (!state.currentTemplate) return false;

        return state.templatesList.content.some((template) => template.uuid === state.currentTemplate.uuid);
    },
    getCurrentTemplateStatus: (state) => {
        if (!state.currentTemplate) return null;

        return state.currentTemplate.status || 'NEW';
    },
};

const mutations = {
    SET_TEMPLATE_LIST(state, templatesList) {
        state.templatesList = templatesList;
    },
    SET_CURRENT_LANGUAGE(state, language) {
        state.currentLanguage = language;
    },
    SET_SCHEDULER_TIMER(state, timer) {
        state.schedulerTimer = timer;
    },
    SET_AVAILABLE_LANGUAGES(state, languages) {
        state.availableLanguages = languages;
    },
    SET_CURRENT_TEMPLATE(state, template) {
        state.currentTemplate = template;
    },
    SET_CHECKOUT_SCHEDULER(state, checkoutScheduler) {
        state.schedulerTimer = checkoutScheduler;
    },
};

const actions = {
    async fetchTemplatesList({ commit }) {
        try {
            const templates = await TemplatesApiRepository.getTemplates(FLOW_TYPE);
            commit('SET_TEMPLATE_LIST', templates);
            return templates;
        } catch (error) {
            console.error('Error fetching templates:', error);
            throw error;
        }
    },

    async fetchCurrentTemplate({ commit, state }) {
        const foundTemplate = state.templatesList.content.find(
            (template) => formatLanguage(template.language) === formatLanguage(state.currentLanguage),
        );
        if (foundTemplate) {
            const existingTemplate = new TemplateDto(foundTemplate);
            commit('SET_CURRENT_TEMPLATE', existingTemplate);
            return;
        }

        try {
            const templateVariables = await TemplatesApiRepository.getTemplateVariables(
                ABANDONED_CHECKOUT_SKILL_ID,
                FLOW_TYPE,
                state.currentLanguage,
            );
            const existingTemplate = new TemplateDto.Builder()
                .withUuid(templateVariables.uuid)
                .withTemplateType(templateVariables.templateType)
                .withFlowType(templateVariables.flowType)
                .withSkillId(templateVariables.skillId)
                .withVariables([templateVariables])
                .withBody(templateVariables.contentExample)
                .withLanguage(templateVariables.language)
                .withDateCreated(templateVariables.dateCreated)
                .build();

            commit('SET_CURRENT_TEMPLATE', existingTemplate);
        } catch (error) {
            console.error('Error fetching current template:', error);
            throw error;
        }
    },

    async fetchAvailableLanguages({ commit }) {
        try {
            const languages = await TemplatesApiRepository.getAvailableLanguages();
            commit('SET_AVAILABLE_LANGUAGES', languages);
        } catch (error) {
            console.error('Error fetching available languages:', error);
            throw error;
        }
    },

    async fetchCurrentLanguage({ rootGetters, state, commit }) {
        const hasShopifySkill = rootGetters['agentSettings/hasShopifySkill'];
        if (!hasShopifySkill) {
            commit('SET_CURRENT_LANGUAGE', 'en');
            return;
        }

        const shopifySkill = rootGetters['agentSettings/getSkillByInternalName']('Shopify');
        const shopifyLanguage = shopifySkill?.config_values?.countryCode;

        const language = state.availableLanguages.find((language) => language.tag === shopifyLanguage);

        commit('SET_CURRENT_LANGUAGE', language || 'en');
    },

    async fetchCheckoutScheduler({ commit }) {
        try {
            const checkoutScheduler = await TemplatesApiRepository.getCheckoutScheduler(ABANDONED_CHECKOUT_SKILL_ID);
            commit('SET_CHECKOUT_SCHEDULER', checkoutScheduler.ttl_minutes || 30);
        } catch (error) {
            commit('SET_CHECKOUT_SCHEDULER', 30);
            throw error;
        }
    },

    // eslint-disable-next-line no-unused-vars
    async updateTemplate({ commit, state }, { templateContent, accountId }) {
        try {
            const foundTemplate = state.templatesList.content.find(
                (template) => formatLanguage(template.language) === formatLanguage(state.currentLanguage),
            );
            if (foundTemplate) {
                // Update template
                const templateRequest = new TemplateRequestDto(foundTemplate);
                templateRequest.body = templateContent;
                await TemplatesApiRepository.updateTemplate(foundTemplate.uuid, templateRequest);
            } else {
                // Create template
                const templateRequest = new TemplateRequestDto.Builder()
                    .withUuid(uuidv4())
                    .withAccountId(accountId)
                    .withBody(templateContent)
                    .withLanguage(formatLanguage(state.currentLanguage))
                    .withTemplateName('ac')
                    .withCategory(CATEGORY)
                    .withType('TEXT')
                    .withTemplateType('FLOW')
                    .withFlowType(FLOW_TYPE)
                    .withSkillId(ABANDONED_CHECKOUT_SKILL_ID)
                    .withChannelType('WHATSAPP')
                    .build();
                await TemplatesApiRepository.createTemplate(templateRequest);
            }
        } catch (error) {
            console.error('Error updating template:', error);
            throw error;
        }
    },
    setCurrentLanguage({ commit }, language) {
        commit('SET_CURRENT_LANGUAGE', formatLanguage(language));
    },
    async setSchedulerTimer({ commit }, timer) {
        try {
            timer.skill_id = ABANDONED_CHECKOUT_SKILL_ID;
            await TemplatesApiRepository.createCheckoutScheduler(ABANDONED_CHECKOUT_SKILL_ID, timer);
            commit('SET_SCHEDULER_TIMER', timer.ttl_minutes);
        } catch (error) {
            console.error('Error creating checkout scheduler:', error);
            throw error;
        }
    },

    async subscribeToTemplateUpdates({ rootState, dispatch }) {
        const topic = `/account/${rootState.user.account.uuid}/template`;
        try {
            await dispatch(
                'websocket/subscribe',
                { topic, callback: (data) => dispatch('handleTemplateEvent', data) },
                { root: true },
            );
        } catch (error) {
            console.error(`Failed to subscribe to ${topic}:`, error);
        }
    },

    unsubscribeFromTemplateUpdates({ rootState, dispatch }) {
        const topic = `/account/${rootState.user.account.uuid}/template`;
        dispatch('websocket/unsubscribe', { topic }, { root: true });
    },

    handleTemplateEvent({ commit, state, dispatch }, event) {
        if (!event || !event.data) return;

        // Parse JSON string if it's a string
        const templateData = typeof event.data === 'string' ? JSON.parse(event.data) : event.data;

        // Create template using builder pattern to ensure proper object construction
        const template = new TemplateDto.Builder()
            .withUuid(templateData.uuid)
            .withAccountId(templateData.accountId)
            .withBody(templateData.body)
            .withHeader(templateData.header)
            .withChannelType(templateData.channelType)
            .withTemplateName(templateData.templateName)
            .withLanguage(templateData.language)
            .withCategory(templateData.category)
            .withStatus(templateData.status)
            .withScore(templateData.score)
            .withType(templateData.type)
            .withTemplateType(templateData.templateType)
            .withFlowType(templateData.flowType)
            .withSkillId(templateData.skillId)
            .withInternalTemplateId(templateData.internalTemplateId)
            .withTemplateParameters(templateData.templateParameters)
            .withExtraParameters(templateData.extraParameters)
            .withIssues(templateData.issues)
            .withModifyBy(templateData.modifyBy)
            .withDateCreated(templateData.dateCreated)
            .withVariables(templateData.variables || [])
            .build();

        const updatedTemplatesList = new PageTemplateDto(state.templatesList);
        const templateIndex = updatedTemplatesList.content.findIndex((t) => t.uuid === template.uuid);
        switch (event.name) {
            case 'TemplateUpdated':
                // Find and update the template in the list
                if (templateIndex !== -1) {
                    updatedTemplatesList.content[templateIndex] = template;
                    commit('SET_TEMPLATE_LIST', updatedTemplatesList);

                    // If current template is the updated one, fetch it again
                    if (state.currentTemplate?.uuid === template.uuid) {
                        dispatch('fetchCurrentTemplate');
                    }
                }
                break;
            default:
                console.warn(`Unhandled template event: ${event.name}`);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
