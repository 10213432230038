<script>
import countryCodes from '@/assets/countryCodesData.json';

export default {
    name: 'RegisterForm',
    emits: ['perform-register'],
    props: ['loading'],
    data() {
        return {
            error: '',
            selectedCountryCode: 'ES',
            phoneNumber: '',
            isPhoneValid: true,
            countries: this.sortCountries(countryCodes),
            hasPhoneBeenEdited: false,
            inputs: [
                {
                    name: 'Name',
                    nameAttr: 'firstname',
                    type: 'text',
                    hasError: false,
                    errorMessage: '',
                    value: null,
                },
                {
                    name: 'Email',
                    nameAttr: 'email',
                    type: 'email',
                    hasError: false,
                    errorMessage: '',
                    value: null,
                    formatValue: (value) => value.replace(/\s/g, '+'),
                },
                {
                    name: 'Password',
                    nameAttr: 'password',
                    type: 'password',
                    hasError: false,
                    errorMessage: '',
                    value: null,
                },
            ],
        };
    },
    computed: {
        isFormValid() {
            return this.inputs.every((input) => input.hasError === false) && this.isPhoneValid;
        },
        selectedCountry() {
            return this.countries.find((country) => country.code === this.selectedCountryCode) || this.countries[0];
        },
        showPhoneError() {
            return !this.isPhoneValid && this.hasPhoneBeenEdited;
        },
    },
    methods: {
        messageClass(hasError) {
            return {
                'md-invalid': hasError,
            };
        },

        register() {
            this.inputs.forEach((input) => {
                this.handleValidation(input);
            });
            this.validatePhoneInput();
            this.hasPhoneBeenEdited = true;

            if (this.isFormValid) {
                const inputsWithPhone = [...this.inputs];
                inputsWithPhone.push({
                    name: 'Phone',
                    nameAttr: 'phone',
                    type: 'tel',
                    hasError: false,
                    errorMessage: '',
                    value: this.getFullPhoneNumber(),
                });
                this.$emit('perform-register', inputsWithPhone);
            }
        },

        handleValidation(item) {
            switch (item.nameAttr) {
                case 'firstname':
                    this.validateNameInput(item);
                    break;
                case 'email':
                    this.validateEmailInput(item);
                    break;
                case 'password':
                    this.validatePasswordInput(item);
                    break;
            }
        },

        validateNameInput(item) {
            if (!item.value) {
                item.hasError = true;
                item.errorMessage = 'Value must not be empty';
                return;
            }

            item.hasError = false;
            item.errorMessage = '';
        },

        validateEmailInput(item) {
            if (!item.value) {
                item.hasError = true;
                item.errorMessage = 'Value must not be empty';
                return;
            }

            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(item.value)) {
                item.hasError = true;
                item.errorMessage = 'Invalid email format';
                return;
            }

            item.hasError = false;
            item.errorMessage = '';
        },

        validatePasswordInput(item) {
            if (!item.value) {
                item.hasError = true;
                item.errorMessage = 'Value must not be empty';
                return;
            }

            const passwordRegex = /^(?=.*[0-9a-zA-Z]).{6,}$/;
            if (!passwordRegex.test(item.value)) {
                item.hasError = true;
                item.errorMessage = 'Password must be at least 6 characters and include alphanumeric characters';
                return;
            }

            item.hasError = false;
            item.errorMessage = '';
        },

        validatePhoneInput() {
            if (!this.phoneNumber) {
                this.isPhoneValid = false;
                this.hasPhoneBeenEdited = true;
                return;
            }

            const phoneRegex = /^(\+)?(?:[0-9] ?){6,14}[0-9]$/;
            const fullPhoneNumber = this.getFullPhoneNumber();
            this.isPhoneValid = phoneRegex.test(fullPhoneNumber);
            this.hasPhoneBeenEdited = true;
        },

        updatePhoneNumber() {
            this.phoneNumber = '';
            this.isPhoneValid = false;
            this.hasPhoneBeenEdited = false;
        },

        getFullPhoneNumber() {
            return `+${this.selectedCountry.dialCode}${this.phoneNumber}`;
        },

        sortCountries(countries) {
            const spain = countries.find((country) => country.code === 'ES');
            const otherCountries = countries.filter((country) => country.code !== 'ES');
            return [spain, ...otherCountries.sort((a, b) => a.name.localeCompare(b.name))];
        },
    },
};
</script>

<template>
    <div class="register-form">
        <div class="register-form__inputs-section md-layout-item md-size-100">
            <md-field v-for="item in inputs" :key="item.name" :class="messageClass(item.hasError)">
                <label
                    ><strong>{{ item.name }}</strong></label
                >
                <md-input
                    @focusout="handleValidation(item)"
                    v-model="item.value"
                    :type="item.type"
                    @keyup.enter="register"
                ></md-input>
                <span class="md-error register-form__text--left">{{ item.errorMessage }}</span>
            </md-field>

            <div class="phone-input">
                <md-field class="country-code">
                    <label for="country-code">Country</label>
                    <md-select
                        v-model="selectedCountryCode"
                        name="country-code"
                        id="country-code"
                        @md-selected="updatePhoneNumber"
                    >
                        <md-option v-for="country in countries" :key="country.code" :value="country.code">
                            {{ country.name }} (+{{ country.dialCode }})
                        </md-option>
                    </md-select>
                </md-field>
                <md-field :class="{ 'md-invalid': showPhoneError }">
                    <label>Phone Number *</label>
                    <md-input
                        v-model="phoneNumber"
                        type="tel"
                        @input="validatePhoneInput"
                        @focusout="validatePhoneInput"
                    ></md-input>
                    <span class="md-error" v-if="showPhoneError">{{
                        phoneNumber ? 'Invalid phone number' : 'Phone number is required'
                    }}</span>
                </md-field>
            </div>
        </div>

        <div class="md-layout-item md-size-100">
            <md-button class="md-primary md-block" v-on:click="register">
                <md-progress-spinner
                    v-if="loading"
                    :md-diameter="12"
                    :md-stroke="2"
                    class="md-white"
                    md-mode="indeterminate"
                ></md-progress-spinner>
                <span class="md-body-2">Sign Up for FREE</span>
            </md-button>
        </div>
    </div>
</template>

<style scoped lang="scss">
.register-form {
    width: 100%;
    height: 100%;
}

.register-form__inputs-section {
    margin-top: 15px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.register-form__text--left {
    text-align: start;
}

.md-progress-spinner {
    margin-right: 1rem;
}

.phone-input {
    display: flex;
    gap: 8px;
}

.country-code {
    flex: 0 0 40%;
}

.md-field {
    margin-bottom: 0;
}
</style>
