const state = () => ({
    completedTours: JSON.parse(localStorage.getItem('completedTours')) || [],
});

const getters = {
    isCompleted: (state) => (tourId) => state.completedTours.includes(tourId),
};

const mutations = {
    MARK_COMPLETE(state, tourId) {
        if (!state.completedTours.includes(tourId)) {
            state.completedTours.push(tourId);
            localStorage.setItem('completedTours', JSON.stringify(state.completedTours));
        }
    },
    CLEAR_TOURS_STATE(state) {
        state.completedTours = [];
    },
    RESET_TOUR(state, tourId) {
        state.completedTours = state.completedTours.filter((id) => id !== tourId);
        localStorage.setItem('completedTours', JSON.stringify(state.completedTours));
    },
};

const actions = {
    markComplete({ commit }, tourId) {
        commit('MARK_COMPLETE', tourId);
    },
    resetTour({ commit }, tourId) {
        commit('RESET_TOUR', tourId);
    },
    clearToursState({ commit }) {
        localStorage.removeItem('completedTours');
        commit('CLEAR_TOURS_STATE');
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
