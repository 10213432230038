<template>
    <div class="whatsapp-preview">
        <div class="whatsapp-header">
            <span>Template preview</span>
        </div>
        <div class="whatsapp-container">
            <div class="message-bubble">
                <div class="message-content" v-html="processedContent"></div>
                <div class="message-time">{{ currentTime }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LixsaSkillAbandonCartWhatsappPreview',
    props: {
        content: {
            type: String,
            default: '',
        },
        variables: {
            type: Array,
            default: [],
        },
    },
    computed: {
        processedContent() {
            let processed = this.content
                // Remove extra spaces but keep newlines
                .replace(/[^\S\n]+/g, ' ')
                // Convert <br> to newlines
                .replace(/<br\s*\/?>/gi, '\n')
                // Remove empty paragraphs
                .replace(/<p>\s*<\/p>/gi, '')
                // Handle paragraphs with double newline
                .replace(/<p>(.*?)<\/p>/gi, '$1\n\n')
                // Strip remaining HTML except links and basic formatting
                .replace(/<(?!\/?(a|b|strong|i|em)\b)[^>]+>/gi, '')
                // Remove extra newlines (more than 2)
                .replace(/\n{3,}/g, '\n\n');

            // Replace variables with their example values
            this.variables.forEach((variable) => {
                const regex = new RegExp(variable.value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'g');
                processed = processed.replace(regex, `<span class="variable">${variable.example}</span>`);
            });

            return processed.trim();
        },
        currentTime() {
            const now = new Date();
            return now
                .toLocaleTimeString('en-US', {
                    hour: 'numeric',
                    minute: '2-digit',
                    hour12: true,
                })
                .replace(/\s/g, ''); // Removes space between time and AM/PM
        },
    },
};
</script>

<style scoped lang="scss">
.whatsapp-header {
    background: #00a884;
    color: white;
    padding: 10px 16px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 8px 8px 0 0;
    margin: -20px -20px 20px -20px;
}

.whatsapp-preview {
    max-width: 400px;
    min-width: 400px;
    margin: 0 auto;
    background: #e5ddd5;
    padding: 20px;
    border-radius: 8px;

    @media (max-width: 768px) {
        min-width: 300px;
    }
}

.whatsapp-container {
    display: flex;
}

.message-bubble {
    background: white;
    padding: 8px 12px;
    border-radius: 0 7.5px 7.5px 7.5px;
    max-width: 85%;
    position: relative;
    box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.13);

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -8px;
        width: 0;
        height: 0;
        border-top: 8px solid white;
        border-left: 8px solid transparent;
    }
}

.message-content {
    color: #303030;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 5px;
    white-space: pre-wrap;

    :deep(a) {
        color: #039be5;
        text-decoration: none;
    }

    :deep(p) {
        margin: 0;
    }

    :deep(.variable) {
        background-color: #e8f5e9;
        padding: 0 2px;
        border-radius: 2px;
    }
}

.message-time {
    color: #999;
    font-size: 11px;
    text-align: right;
}
</style>
