<template>
    <div class="custom-main-panel">
        <div>
            <md-progress-bar v-if="isLoading" md-mode="indeterminate"></md-progress-bar>
        </div>
        <div class="md-layout custom-content-panel">
            <div v-if="isLoading" class="overlay md-layout-item md-size-100"></div>
            <!-- <div class="md-layout-item md-size-100">
                <div class="custom-action-clear">
                    <div class="custom-svg-icon">
                        <svg-icon :name="currentAgent === '' ? 'SUPPORT_AGENT' : currentAgent" />
                    </div>

                    <md-field class="custom-selector">
                        <label for="movie">Agent</label>
                        <md-select v-model="currentAgent" name="Agent">
                            <md-option v-for="item in agents" :key="item.code" :label="item.name" :value="item.code">
                                {{ item.name }}
                            </md-option>
                        </md-select>
                    </md-field>
                    <md-button v-if="!isClearing" @click="clearChat()" class="md-raised md-primary"
                        >Clear Conversation</md-button
                    >
                    <md-progress-spinner
                        v-else
                        :md-diameter="20"
                        :md-stroke="3"
                        class="md-white"
                        md-mode="indeterminate"
                    ></md-progress-spinner>
                </div>
            </div> -->
            <div class="md-layout-item md-size-100 playground-chat__chat-container">
                <template>
                    <vue-advanced-chat
                        id="playground-chat"
                        class="custom-chat force-mobile-styles"
                        :height="screenHeight"
                        :single-room="true"
                        :current-user-id="currentUserId"
                        :current-user-name="currentUserName"
                        :current-user-email="currentUserEmail"
                        :rooms="JSON.stringify(rooms)"
                        :rooms-loaded="true"
                        :messages="JSON.stringify(messages)"
                        :messages-loaded="messagesLoaded"
                        :stateAI="stateAI"
                        :showAIToggle="true"
                        :enable-info-customer="enableInfoCustomer"
                        :show-files="true"
                        :show-audio="false"
                        :show-new-messages-divider="false"
                        :responsive-breakpoint="responsiveBreakpoint"
                        :message-actions="this.$store.getters['user/isDevMode'] ? messageActions : []"
                        @fetch-messages="fetchMessages()"
                        @update-send-new-menssage="updateSendNewMenssage"
                        @send-message="sendMessage($event)"
                        @fetch-state-AI="fetchStateAI($event)"
                        @message-action-handler="handleMessageActions"
                    />
                </template>
                <div class="playground-chat__config-bar">
                    <div id="playground-chat__agent-selector" class="playground-chat__config-bar-item">
                        <div class="custom-svg-icon">
                            <svg-icon :name="currentAgent === '' ? 'SUPPORT_AGENT' : currentAgent" />
                        </div>

                        <md-field class="custom-selector">
                            <label for="movie">Agent</label>
                            <md-select v-model="currentAgent" name="Agent">
                                <md-option
                                    v-for="item in agents"
                                    :key="item.code"
                                    :label="item.name"
                                    :value="item.code"
                                >
                                    {{ item.name }}
                                </md-option>
                            </md-select>
                        </md-field>
                    </div>
                    <md-button
                        id="playground-chat__clear-conversation"
                        v-if="!isClearing"
                        @click="clearChat()"
                        class="md-raised md-primary"
                    >
                        Clear Conversation
                    </md-button>
                    <md-progress-spinner
                        v-else
                        :md-diameter="20"
                        :md-stroke="3"
                        class="md-white"
                        md-mode="indeterminate"
                    ></md-progress-spinner>
                </div>
            </div>

            <div v-if="!whatsappSkill" class="md-layout-item md-size-100">
                <playground-connect-to-whatsapp-cta />
            </div>
        </div>
    </div>
</template>

<script>
import conversationsService from '@/services/conversations/conversationsService.js';
import storageService from '@/services/google/storageService.js';
import { v4 as uuidv4 } from 'uuid';
import SvgIcon from '@/components/Chat/SvgIcon/SvgIcon.vue';
import PlaygroundConnectToWhatsappCta from '@/components/Playground/PlaygroundConnectToWhatsappCta.vue';
import { mapActions, mapGetters } from 'vuex';

// Tours
import playgroundTourSteps from '@/services/tours/sectionsTours/playgroundTour.js';
import TourService from '@/services/tours/tourService.js';

import { MessageActionsMixin } from '@/pages/Dashboard/Conversations/Mixins';

export default {
    name: 'PlaygroundChat',
    mixins: [MessageActionsMixin],
    components: {
        SvgIcon,
        PlaygroundConnectToWhatsappCta,
    },
    data() {
        return {
            tourInstance: null,
            responsiveBreakpoint: 2000,
            isLoading: false,
            currentRoomId: '',
            currentUserId: this.$store.state.user.user.uuid, // usuario propietario del chat
            currentIdCustomer: '', // usuario propietario del chat
            currentUserName: '', // usuario en la conversacion
            currentUserEmail: '',
            rooms: [],
            messages: [],
            messagesLoaded: false,
            currentMessagesPage: 0,
            totalMessages: 0,
            messagePageSize: 20,
            currentChannelId: '',
            currentChannelType: '',
            lastLoadedMessage: null,
            previousLastLoadedMessage: null,
            listeners: [],

            // Lixsa
            LIXSA_ROOM_NAME: 'Lixsa',
            LIXSA_USER_ID: '4321',
            AVATAR_URL: process.env.VUE_APP_LIXSA_AVATAR_URL,

            // Socket
            stateAI: false,

            isClearing: false,

            // Agent filters
            currentAgent: '',
            agents: [
                {
                    code: 'SALES_AGENT',
                    name: 'Sales Agent',
                },
                {
                    code: 'SUPPORT_AGENT',
                    name: 'Support Agent',
                },
                {
                    code: 'HUMAN_AGENT',
                    name: 'Human Agent',
                },
                {
                    code: 'RETURN_EXCHANGE_AGENT',
                    name: 'Returns Agent',
                },
            ],
        };
    },
    computed: {
        ...mapGetters('tours', ['isCompleted']),
        screenHeight() {
            // return this.isDevice ? window.innerHeight + "px" : "calc(100vh - 80px)";
            return this.isDevice ? window.innerHeight + 'px' : '70vh';
        },
        isCopilotEnabled() {
            return this.$store.getters['user/isCopilotEnabled'];
        },
        whatsappSkill() {
            return this.$store.getters['agentSettings/getSkillByInternalName']('whatsapp');
        },
    },
    watch: {
        currentAgent(newValue, oldValue) {
            if (oldValue === '') return;

            this.scaleToAgent(newValue);
        },
    },
    methods: {
        ...mapActions('websocket', { subscribe: 'subscribe', unsubscribe: 'unsubscribe' }),

        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },
        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },
        async clearChat() {
            this.isClearing = true;
            try {
                await conversationsService.clearConversation(this.currentRoomId);
                this.showSuccessToast('Conversation cleaned correctly');
                this.messages = [];
                this.fetchMessages();
            } catch (error) {
                console.error('Error clearing chat', error);
                this.showErrorToast('Error clearing chat');
            } finally {
                this.isClearing = false;
            }
        },
        async scaleToAgent(ai_type) {
            try {
                await conversationsService.scaleAgent(ai_type, this.currentRoomId);
                this.showSuccessToast('Scaled to agent succesfully');
            } catch (error) {
                console.error('Error scaling to agent: ', error);
                this.showErrorToast('Error scaling to agent');
            }
        },
        async setAiType() {
            try {
                const response = await conversationsService.getConversation(this.$store.state.user.user.account_id);
                const conversation = response.data;
                this.currentAgent = conversation.ai_type;

                this.stateAI = conversation.is_aion;
                this.rooms[0].is_aion = conversation.is_aion;
            } catch (error) {
                console.error('Error fetching conversation: ', error);
            }
        },

        /* AUXILIAR CHAT METHODS */

        formatTimestamp(date_created) {
            const date = new Date(date_created * 1000);
            return date.toLocaleTimeString('es-ES', { hour: 'numeric', minute: 'numeric', hour12: false });
        },

        async buildMessageData(account_id, message) {
            const CHANNEL_TYPE = 'PLATFORM';
            const CHANNEL_MESSAGE_TYPE = this.extractTypeFromMessage(message);
            const ROLE_TYPE = 'HUMAN';
            const channel_id = uuidv4();
            const content = await this.processMessageContent(message, channel_id);

            return {
                account: account_id,
                customer_id: this.rooms[0].customer_id,
                uuid: uuidv4(),
                conversation: this.$store.state.user.user.account_id,
                channel_id,
                channel_message_type: CHANNEL_MESSAGE_TYPE,
                caption: CHANNEL_MESSAGE_TYPE !== 'TEXT' ? message.content : '',
                channel_type: CHANNEL_TYPE,
                content,
                role_type: ROLE_TYPE,
            };
        },
        async processMessageContent(message, myChannelID) {
            if (message.files === null) return message.content;

            try {
                const file = message.files[0];
                const fileContent = this.getFileContent(file);
                const urlStorage = await this.getStorageUrl(myChannelID, file.type);

                if (urlStorage) {
                    const responseGoogle = await this.uploadFileToGoogleCloud(urlStorage, fileContent, file.type);
                    if (responseGoogle.status === 200) {
                        return urlStorage.split('?')[0];
                    }
                }
            } catch (error) {
                console.error('Error procesing message content', error);
            }
        },
        getFileContent(mediaContent) {
            return /^image\//.test(mediaContent.type) ? mediaContent.selectedFile : mediaContent;
        },
        extractTypeFromMessage(message) {
            if (message.files === null) return 'TEXT';

            let messageType;
            if (/^image\//.test(message.files[0].type)) {
                messageType = 'IMAGE';
            } else if (
                message.files[0].type === 'audio/mp3' ||
                message.files[0].type === 'audio/mpeg' ||
                message.files[0].type === 'audio/ogg'
            ) {
                messageType = 'AUDIO';
            } else {
                messageType = message.files[0].type;
            }

            return messageType;
        },
        async getStorageUrl(channelId, fileType) {
            try {
                return await conversationsService.getStorageURL(channelId, fileType, uuidv4());
            } catch (error) {
                console.error('Error getting storage URL:', error);
                return null;
            }
        },
        async uploadFileToGoogleCloud(url, content, type) {
            try {
                return await storageService.uploadFileToGoogleCloud(url, content, type);
            } catch (error) {
                console.error('Error uploading file:', error);
                return { status: null };
            }
        },
        buildRoomData(user, conversation) {
            const { account_id: roomId, uuid: userId, username } = user;

            return {
                roomId,
                roomName: this.LIXSA_ROOM_NAME,
                avatar: this.AVATAR_URL,
                customer_id: conversation.customer_id || conversation.account,
                users: [
                    { _id: userId, username },
                    {
                        _id: this.LIXSA_USER_ID,
                        username: this.LIXSA_ROOM_NAME,
                        status: {
                            state: 'online',
                        },
                    },
                ],
                typingUsers: [''],
            };
        },

        /* MAIN CHAT METHODS */

        addHumanOrLixsaMessages(message) {
            const HUMAN_ROLE_TYPE = 'HUMAN';

            const { uuid: _id, content, date_created, role_type } = message;
            const user = this.$store.state.user.user;

            const timestamp = this.formatTimestamp(date_created);

            const isHuman = role_type === HUMAN_ROLE_TYPE;
            const senderId = isHuman ? user.uuid : this.LIXSA_USER_ID;
            const username = isHuman ? user.username : this.LIXSA_ROOM_NAME;

            const distributed = true; // Add double check to all user messages.
            const seen = true; // Add blue check to all user messages.

            this.rooms[0].typingUsers[0] = isHuman ? this.LIXSA_USER_ID : user.uuid; // Add "Lixsa is typing".

            let files = '';
            if (
                message.channel_message_type.toUpperCase() === 'IMAGE' ||
                message.channel_message_type.toUpperCase() === 'AUDIO'
            ) {
                this.findAndShowFilesInMessage(message); // Extract files from message and sub.
            }
            const messageContent =
                message.channel_message_type.toUpperCase() === 'IMAGE' ||
                message.channel_message_type.toUpperCase() === 'AUDIO'
                    ? message.caption
                    : content; // If there is files, set content variable to empty string.

            return [{ _id, content: messageContent, senderId, username, timestamp, distributed, seen, files }];
        },

        /**
         * Finds and shows the image or audio before load.
         * @param {any} message The message from API
         */
        findAndShowFilesInMessage(message) {
            let file = '';

            if (message.channel_message_type.toUpperCase() === 'IMAGE') {
                storageService
                    .fetchImageDetails(message.content)
                    .then((imageFileInfo) => {
                        file = [
                            {
                                name: 'Image',
                                size: imageFileInfo.size,
                                type: imageFileInfo.type,
                                url: message.content,
                                preview: imageFileInfo.preview,
                            },
                        ];

                        const index = this.messages.findIndex((element) => element._id === message.uuid);

                        if (index !== -1) {
                            this.messages[index].files = [...file];
                        }
                    })
                    .catch((error) => {
                        console.error('Error fetching image details: ', error);
                    });
            } else if (message.channel_message_type.toUpperCase() === 'AUDIO') {
                storageService
                    .fetchAudioDetails(message.content)
                    .then((audioFileInfo) => {
                        file = [
                            {
                                audio: true,
                                duration: audioFileInfo.duration,
                                name: 'audio',
                                extension: 'audio/mp3',
                                size: audioFileInfo.size,
                                // type: audioFileInfo.type,
                                type: 'audio/mp3',
                                url: message.content,
                            },
                        ];

                        const index = this.messages.findIndex((element) => element._id === message.uuid);

                        if (index !== -1) {
                            this.messages[index].files = [...file];
                        }
                    })
                    .catch((error) => {
                        console.error('Error fetching audio details: ', error);
                    });
            }

            return file;
        },

        async sendMessage(message) {
            const account_id = this.$store.state.user.user.account_id;

            const messageData = await this.buildMessageData(account_id, message);

            try {
                await conversationsService.sendMessage(messageData);
            } catch (error) {
                console.error('Error sending message: ', error);
            }
        },

        async fixSpellAndGrammar(message) {
            try {
                const previousMessage = this.lastClientMessage();
                const response = await conversationsService.getCopilotCorrection(
                    this.currentRoomId,
                    message,
                    previousMessage,
                );
                console.log(response);
                return response.data;
            } catch (error) {
                console.error('Error fixing spelling and grammar: ', error);
            }
        },

        lastClientMessage() {
            if (this.messages.length === 0) return null;

            let lastMessage = null;

            for (let i = this.messages.length - 1; i >= 0; i--) {
                if (this.messages[i].role_type !== 'HUMAN') {
                    lastMessage = this.messages[i];
                    break;
                }
            }

            return lastMessage.content;
        },

        async createRoom() {
            try {
                const user = this.$store.state.user.user;

                const conversation = await conversationsService.getConversation(this.$store.state.user.account.uuid);
                const room = this.buildRoomData(user, conversation.data);

                this.rooms.push(room);
            } catch (error) {}
        },

        async fetchMessages() {
            if (!this.messages.length) {
                await this.loadMessagesFirstTime();
            } else {
                await this.loadMoreMessages();
            }

            this.messagesLoaded = this.messages.length >= this.totalMessages;
        },

        async loadMessagesFirstTime() {
            this.messages = [];
            this.currentMessagesPage = 0;
            this.messagesLoaded = false;

            try {
                const response = await conversationsService.fetchMessages(
                    this.$store.state.user.user.account_id,
                    this.currentMessagesPage,
                    this.messagePageSize,
                );

                const newMessages = response.data.content;
                this.totalMessages = response.data.totalElements;

                this.checkoutMessages(newMessages);
            } catch (error) {
                console.error('Error fetching the messages', error);
            }
        },

        async loadMoreMessages() {
            try {
                this.currentMessagesPage++;

                const response = await conversationsService.fetchMessages(
                    this.$store.state.user.user.account_id,
                    this.currentMessagesPage,
                    this.messagePageSize,
                );

                let newMessages = response.data.content;
                this.totalMessages = response.data.totalElements;

                newMessages = newMessages.reverse();

                newMessages.map((message) => {
                    this.messages = [...this.addHumanOrLixsaMessages(message), ...this.messages];
                });
            } catch (error) {
                console.error('Error fetching the messages', error);
            }
        },

        checkoutMessages(data) {
            if (!this.rooms.length) {
                console.error('No rooms available to checkout messages.');
                return;
            }

            this.currentRoomId = this.rooms[0].roomId;
            const account_id = this.$store.getters['user/user'].account_id;

            this.subscribeToMessages();

            this.processMessages(data);
        },

        reorderMessage(messageInfo) {
            if (!messageInfo) return null;

            let parsedData;
            try {
                parsedData = JSON.parse(messageInfo.data);
            } catch (error) {
                console.error('Error parsing message data:', error);
                return null;
            }

            if (!parsedData) return null;

            const reorderedData = [
                {
                    account: parsedData.account,
                    content: parsedData.content,
                    conversation: parsedData.conversation,
                    customer_id: parsedData.customer_id,
                    date_created: parsedData.date_created,
                    date_deleted: null,
                    date_updated: null,
                    role_type: parsedData.role_type,
                    summary: null,
                    uuid: parsedData.uuid,
                    reply_uuid: parsedData.reply_uuid,
                    channel_message_id: parsedData.channel_message_id,
                    channel_message_type: parsedData.channel_message_type.toUpperCase(),
                    caption: parsedData.caption,
                    channel_status: parsedData.channel_status,
                    valoration: parsedData.valoration,
                    scenario_id: parsedData.scenario_id,
                    created_by: parsedData.created_by,
                },
            ];

            return reorderedData;
        },

        processMessages(data) {
            if (!data || !Array.isArray(data)) return;

            data.forEach(async (message) => {
                let newMessages = this.addHumanOrLixsaMessages(message);

                // Filter out messages that already exist in this.messages
                newMessages = newMessages.filter(
                    (newMsg) => !this.messages.some((existingMsg) => existingMsg._id === newMsg._id),
                );

                // Only update this.messages if there are new messages to add
                if (newMessages.length > 0) {
                    this.messages = this.messages.length ? [...this.messages, ...newMessages] : newMessages;
                }
            });
        },

        async fetchStateAI(info) {
            if (!info) return;

            try {
                const aiEnabled = !info.ai_enabled;
                await conversationsService.updateStateAI(info.conversation, aiEnabled);

                this.stateAI = aiEnabled;
                this.rooms[0].is_aion = aiEnabled;
            } catch (error) {
                console.error('Error fetching the state of the AI: ', error);
            }
        },

        /* SOCKET HANDLING */

        handleMessagesEvent(messageEvent) {
            const message = this.reorderMessage(messageEvent);
            this.processMessages(message);
        },

        async subscribeToMessages() {
            try {
                await this.subscribe({
                    topic: `/account/${this.$store.getters['user/user'].account_id}/conversation/${this.currentRoomId}`,
                    callback: this.handleMessagesEvent,
                });
            } catch (error) {
                console.error('Failed to subscribe to room messages topic:', error);
            }
        },

        unsubscribeToMessages() {
            const topic = `/account/${this.$store.getters['user/user'].account_id}/conversation/${this.currentRoomId}`;

            this.unsubscribe({
                topic,
                callback: this.handleMessagesEvent,
            });
        },
    },
    async mounted() {
        try {
            this.createRoom();
            this.setAiType();
            // this.subscribeToMessages();
        } catch (error) {
            console.error('Error establishing socket connection:', error);
        }

        if (!this.isCompleted('playground-tour')) {
            this.$nextTick(() => {
                this.tourInstance = TourService.startTour('playground-tour', playgroundTourSteps);

                this.tourInstance.on('complete', () => {
                    console.log('Tour in playground completed');
                    this.$router.push({ name: 'Skills' });
                });
            });
        }
    },
    beforeDestroy() {
        this.unsubscribeToMessages();
    },
};
</script>

<style lang="scss" scoped>
.md-layout-item {
    padding-left: 0px;
    padding-right: 0px;
}

.playground-chat__chat-container {
    display: flex;
    justify-content: center;

    @media (max-width: 959px) {
        flex-direction: column;
    }
}

.custom-main-panel {
    position: relative;
    overflow-x: auto;
    width: 100%;
}

.custom-content-panel {
    margin-bottom: 40px;
    padding-left: 30px;
    padding-right: 30px;

    @media (max-width: 1278px) {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media (max-width: 959px) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(238, 238, 238, 0.5);
    z-index: 10;
    cursor: not-allowed;
}

.custom-chat {
    width: 390px;
    border-radius: 12px;
    overflow: hidden;
    box-shadow:
        0 3px 3px -2px rgba(0, 0, 0, 0.2),
        0 3px 4px 0 rgba(0, 0, 0, 0.14),
        0 1px 8px 0 rgba(0, 0, 0, 0.12);

    // Force mobile styles regardless of screen size
    &.force-mobile-styles :deep() {
        .vac-message-container {
            padding: 2px 3px 1px !important;
        }

        .vac-message-container-offset {
            margin-top: 10px !important;
        }

        .vac-message-box {
            flex: 0 0 80% !important;
            max-width: 80% !important;
        }

        .vac-avatar {
            height: 17px !important;
            width: 17px !important;
            min-height: 17px !important;
            min-width: 17px !important;
            margin: 0 6px 1px 0 !important;

            &.vac-avatar-current {
                margin: 0 0 1px 6px !important;
            }
        }

        .vac-avatar-current-offset {
            margin-right: 23px !important;
        }

        .vac-avatar-offset {
            margin-left: 23px !important;
        }

        .vac-failure-container {
            margin-left: 2px !important;

            &.vac-failure-container-avatar {
                margin-right: 0 !important;
            }
        }

        .vac-offset-current {
            margin-left: 20% !important;
        }

        .vac-progress-time {
            margin-left: 37px !important;
        }
    }

    @media (max-width: 959px) {
        width: 100%;
    }
}

// .custom-action-clear {
//     display: flex;
//     align-items: center;
//     justify-content: flex-end;
//     margin-bottom: 12px;

//     .custom-selector {
//         width: 200px;
//         margin-right: 20px;

//         @media (max-width: 959px) {
//             width: auto;
//         }
//     }

//     .custom-svg-icon {
//         margin-right: 7px;
//         margin-top: 4px;
//         display: flex;
//         justify-content: end;
//         align-items: end;

//         svg {
//             width: 15px;
//             height: 15px;
//         }
//     }

//     @media (max-width: 959px) {
//         display: block;
//     }
// }

.playground-chat__config-bar {
    // background-color: #ffff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    padding: 1rem 1rem;
    gap: 1rem;
    // justify-content: flex-end;
    // margin-bottom: 12px;

    .playground-chat__config-bar-item {
        display: flex;
        align-items: center;
    }

    .custom-selector {
        width: 200px;
        margin-right: 20px;

        @media (max-width: 959px) {
            width: auto;
        }
    }

    .custom-svg-icon {
        margin-right: 7px;
        margin-top: 4px;
        display: flex;
        justify-content: end;
        align-items: end;

        svg {
            width: 15px;
            height: 15px;
        }
    }

    @media (max-width: 959px) {
        display: block;
    }
}
</style>
