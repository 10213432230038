<template>
    <md-card class="prestashop-sandbox-cta">
        <md-card-content>
            <div class="md-title"><strong>Try Lixsa in the Playground</strong></div>
            <p class="md-body-1">
                Test Lixsa's PrestaShop integration in a safe chat environment. Use our playground to verify if Lixsa
                has successfully obtained all the information from your PrestaShop store.
            </p>
            <md-button class="md-raised md-primary" @click="openSandbox">Open Playground</md-button>
        </md-card-content>
    </md-card>
</template>

<script>
export default {
    name: 'MainAppsPrestashopSandboxCta',
    methods: {
        openSandbox() {
            this.$router.push('/playground');
        },
    },
};
</script>

<style scoped>
.prestashop-sandbox-cta {
    background-color: #f5f5f5;
    margin-top: 20px;
}
</style>
