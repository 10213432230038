<template>
    <div>
        <md-dialog :md-close-on-esc="false" :md-click-outside-to-close="false" :md-active.sync="showDialog">
            <md-dialog-title><strong>Edit API key</strong></md-dialog-title>
            <div class="modal-content">
                <md-field>
                    <label>Name</label>
                    <md-input type="text" v-model="localApiKey.name"></md-input>
                </md-field>
            </div>

            <md-dialog-actions>
                <md-button class="md-white modal-button" @click="handleClose">Close</md-button>
                <md-button class="md-primary modal-button" @click="handleEdit" :disabled="!canSave">
                    <span v-if="!isLoading">Save</span>
                    <md-progress-spinner
                        v-else
                        :md-diameter="20"
                        :md-stroke="3"
                        class="md-accent"
                        md-mode="indeterminate"
                    ></md-progress-spinner>
                </md-button>
            </md-dialog-actions>
        </md-dialog>
        <md-button :disabled="disabled" class="md-just-icon md-info md-simple" @click="handleShowDialog">
            <md-icon>edit</md-icon>
        </md-button>
    </div>
</template>

<script>
import apiKeysService from '@/services/account/apiKeysService.js';
import { mapGetters } from 'vuex';

export default {
    name: 'EditApiKey',
    props: {
        apiKey: {
            type: Object,
            default: () => ({}),
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        // Create a local copy avoiding double binding with parent's object.
        apiKey: {
            deep: true,
            handler(newValue) {
                this.localApiKey = JSON.parse(JSON.stringify(newValue));
            },
        },
    },
    data: () => ({
        showDialog: false,
        localApiKey: {},
        isLoading: false,
    }),
    computed: {
        ...mapGetters({
            canAccessUser: 'user/canAccess',
            isWalkonLivosAccount: 'user/isWalkonLivosAccount',
        }),
        isEdited() {
            return JSON.stringify(this.localApiKey) !== JSON.stringify(this.apiKey);
        },
        canSave() {
            return this.isEdited && this.localApiKey.name;
        },
        isAdminAccount() {
            return this.canAccessUser(['ADMIN']) || this.isWalkonLivosAccount;
        },
    },
    created() {
        this.localApiKey = JSON.parse(JSON.stringify(this.apiKey));
    },
    methods: {
        handleShowDialog() {
            if (!this.isAdminAccount) {
                this.showErrorToast("You don't have permission to perform this action");
                return;
            }

            this.isLoading = true;
        },

        handleClose() {
            this.localApiKey = JSON.parse(JSON.stringify(this.apiKey));
            this.showDialog = false;
            this.isLoading = false;
        },
        async handleEdit() {
            this.isLoading = true;

            try {
                const reqData = {
                    uuid: this.localApiKey.uuid,
                    name: this.localApiKey.name,
                };

                const createApiKeyResponse = await apiKeysService.editApiKey(this.localApiKey.uuid, reqData);
                if (createApiKeyResponse.status === 200) {
                    this.showSuccessToast('API key updated');
                    this.isLoading = false;
                    this.notifyParent();
                    this.showDialog = false;
                }
            } catch (error) {
                console.error('Error creating API key: ' + error);
                this.showErrorToast('Error creating API key');
                this.isLoading = false;
                this.handleClose();
            }
        },
        notifyParent() {
            this.$emit('ApiKeyEdited');
        },
        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },
        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/md/_variables.scss';

.md-dialog .md-dialog-container {
    max-width: 768px;
}

.modal-content {
    background: #fff;
    padding: 30px;
    padding-top: 0px;
    // text-align: center;
    margin: 0 auto;
    width: 500px;

    p {
        margin-bottom: 40px;
    }
}

.modal-content h4 {
    text-align: left;
}

.modal-button {
    text-transform: none !important;
    height: 40px !important;
}

.custom-copy-button {
    margin-left: 16px;
}

.custom-button {
    color: $black-color !important;

    .custom-button-icon {
        color: $black-color !important;
    }
}
</style>
