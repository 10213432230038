import BaseDto from '../../_common/BaseDto';
import TemplateVariablesResponseDto from './TemplateVariablesResponseDto';

class TemplateDtoBuilder {
    constructor() {
        this.uuid = undefined;
        this.accountId = undefined;
        this.body = undefined;
        this.header = undefined;
        this.channelType = undefined;
        this.templateName = undefined;
        this.language = undefined;
        this.category = undefined;
        this.status = undefined;
        this.score = undefined;
        this.type = undefined;
        this.templateType = undefined;
        this.flowType = undefined;
        this.skillId = undefined;
        this.internalTemplateId = undefined;
        this.templateParameters = undefined;
        this.extraParameters = undefined;
        this.issues = undefined;
        this.modifyBy = undefined;
        this.dateCreated = undefined;
        this.variables = undefined;
    }

    withUuid(uuid) {
        this.uuid = uuid;
        return this;
    }

    withAccountId(accountId) {
        this.accountId = accountId;
        return this;
    }

    withBody(body) {
        this.body = body;
        return this;
    }

    withHeader(header) {
        this.header = header;
        return this;
    }

    withChannelType(channelType) {
        this.channelType = channelType;
        return this;
    }

    withTemplateName(templateName) {
        this.templateName = templateName;
        return this;
    }

    withLanguage(language) {
        this.language = language;
        return this;
    }

    withCategory(category) {
        this.category = category;
        return this;
    }

    withStatus(status) {
        this.status = status;
        return this;
    }

    withScore(score) {
        this.score = score;
        return this;
    }

    withType(type) {
        this.type = type;
        return this;
    }

    withTemplateType(templateType) {
        this.templateType = templateType;
        return this;
    }

    withFlowType(flowType) {
        this.flowType = flowType;
        return this;
    }

    withSkillId(skillId) {
        this.skillId = skillId;
        return this;
    }

    withInternalTemplateId(internalTemplateId) {
        this.internalTemplateId = internalTemplateId;
        return this;
    }

    withTemplateParameters(templateParameters) {
        this.templateParameters = templateParameters;
        return this;
    }

    withExtraParameters(extraParameters) {
        this.extraParameters = extraParameters;
        return this;
    }

    withIssues(issues) {
        this.issues = issues;
        return this;
    }

    withModifyBy(modifyBy) {
        this.modifyBy = modifyBy;
        return this;
    }

    withDateCreated(dateCreated) {
        this.dateCreated = dateCreated;
        return this;
    }

    withVariables(variables) {
        this.variables = variables.map((variable) => {
            return new TemplateVariablesResponseDto.Builder()
                .withUuid(variable.uuid)
                .withTemplateType(variable.template_type)
                .withFlowType(variable.flow_type)
                .withSkillId(variable.skill_id)
                .withVariables(variable.variables)
                .withContentExample(variable.content_example)
                .withLanguage(variable.language)
                .withDateCreated(variable.date_created)
                .build();
        });
        return this;
    }

    build() {
        return new TemplateDto(this);
    }
}

class TemplateDto extends BaseDto {
    constructor(builder) {
        super();
        this.uuid = builder.uuid;
        this.accountId = builder.accountId;
        this.body = builder.body;
        this.header = builder.header;
        this.channelType = builder.channelType;
        this.templateName = builder.templateName;
        this.language = builder.language;
        this.category = builder.category;
        this.status = builder.status;
        this.score = builder.score;
        this.type = builder.type;
        this.templateType = builder.templateType;
        this.flowType = builder.flowType;
        this.skillId = builder.skillId;
        this.internalTemplateId = builder.internalTemplateId;
        this.templateParameters = builder.templateParameters;
        this.extraParameters = builder.extraParameters;
        this.issues = builder.issues;
        this.modifyBy = builder.modifyBy;
        this.dateCreated = builder.dateCreated;
        this.variables = builder.variables;
    }

    static get Builder() {
        return TemplateDtoBuilder;
    }
}

export default TemplateDto;
