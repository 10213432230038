<template>
    <Editor :key="editorKey" tinymce-script-src="/tinymce/tinymce.min.js" :init="editorConfig" v-model="editorData" />
</template>

<script>
import Editor from '@tinymce/tinymce-vue';

export default {
    name: 'TheTinymceEditor',
    components: {
        Editor,
    },
    props: {
        initialContent: {
            type: String,
            default: '',
        },
        variables: {
            type: Array,
            default: () => [
                { label: 'First Name', value: '{{firstName}}' },
                { label: 'Last Name', value: '{{lastName}}' },
                { label: 'Email', value: '{{email}}' },
            ],
        },
    },
    data() {
        return {
            editorKey: 0,
            editorData: this.initialContent,
            editorConfig: {
                license_key: 'gpl',
                branding: false,
                promotion: false,
                menubar: false,
                elementpath: false,
                selector: '#about',
                plugins: 'code autoresize link variables emoticons',
                toolbar: 'undo redo | fontsizeselect formatselect | bold italic emoticons | variables',
                variables_items: this.variables,
                setup: (editor) => {},
                content_style: `
                    body { font-family: Roboto, Helvetica, Arial, sans-serif; }
                `,
            },
        };
    },
    watch: {
        variables: {
            handler(newVars) {
                this.editorConfig.variables_items = newVars;
                this.editorKey++;
            },
            deep: true,
        },
        initialContent(newContent) {
            if (newContent !== this.editorData) {
                this.editorData = newContent;
            }
        },
        editorData(newContent) {
            this.$emit('update:content', newContent);
        },
    },
};
</script>

<style scoped lang="scss"></style>
