<template>
    <div>
        <div class="section-header">
            <span class="section-title">Order summary</span>
        </div>

        <div class="products-container">
            <div v-for="(product, index) in selectedOrder.products" :key="index" class="product-item">
                <div class="product-image">
                    <div class="image-wrapper">
                        <img
                            v-if="productImageUrls[`${product.product_id}-${product.variant_id}`]"
                            :alt="product.title"
                            :src="productImageUrls[`${product.product_id}-${product.variant_id}`]"
                        />
                        <md-progress-spinner
                            v-else
                            class="md-black"
                            :md-diameter="15"
                            :md-stroke="2"
                            md-mode="indeterminate"
                        ></md-progress-spinner>
                    </div>
                    <span class="quantity-badge">{{ product.quantity }}</span>
                </div>

                <div class="product-info">
                    <div class="product-details">
                        <span class="product-name">{{ product.title }}</span>
                        <span class="product-variant">{{ product.variant_title }}</span>
                    </div>
                    <span class="product-price">
                        {{ formatNumberWithDecimals(product.price) }} {{ getCurrencySymbol(selectedOrder.currency) }}
                    </span>
                </div>
            </div>
        </div>

        <div class="summary-container">
            <div class="summary-row">
                <span>Subtotal</span>
                <span>{{ formatNumberWithDecimals(subtotal) }} {{ getCurrencySymbol(selectedOrder.currency) }}</span>
            </div>
            <div class="summary-row">
                <span>Discount</span>
                <span
                    >{{ formatNumberWithDecimals(selectedOrder.total_discount) }}
                    {{ getCurrencySymbol(selectedOrder.currency) }}</span
                >
            </div>
            <div class="summary-row total">
                <span>Total</span>
                <span
                    >{{ formatNumberWithDecimals(selectedOrder.total_price) }}
                    {{ getCurrencySymbol(selectedOrder.currency) }}</span
                >
            </div>
        </div>
    </div>
</template>

<script>
import { formatNumberWithDecimals, getCurrencySymbol } from '@/utils/common.js';
import axios from 'axios';
import { mapActions } from 'vuex';

import conversationsService from '../../../../../../services/conversations/conversationsService.js';
import { find } from 'lodash';

export default {
    name: 'CustomerOrderSummaryEcommerceOrder',
    data() {
        return {
            URL: '',
            productImageUrls: {},
            defaultImageUrl: process.env.VUE_APP_USER_AVATAR_URL,
        };
    },
    props: {
        selectedOrder: { type: Object },
    },
    computed: {
        // Calcular el subtotal sumando los precios de los productos
        subtotal() {
            return this.selectedOrder.products.reduce((total, product) => total + product.price * product.quantity, 0);
        },
    },
    methods: {
        getCurrencySymbol,
        formatNumberWithDecimals,
        ...mapActions('user', ['apiCall']),
        async fetchProductInfo(product) {
            try {
                const productId = parseInt(product);
                const response = await conversationsService.getProductInfo(productId);
                return response.data;
            } catch (error) {
                return null;
            }
        },
        findVariantImages(variants, variantId) {
            const variant = find(variants, { id: variantId });
            if (variant && variant.image) {
                return variant.images;
            }
        },
        async loadProductImages() {
            const TIMEOUT_DURATION = 10000;

            const imageFetchPromises = this.selectedOrder.products.map(async (product) => {
                const key = `${product.product_id}-${product.variant_id}`;

                if (!this.productImageUrls[key]) {
                    try {
                        const timeoutPromise = new Promise((_, reject) => {
                            setTimeout(() => reject(new Error('Timeout')), TIMEOUT_DURATION);
                        });

                        const fetchPromise = (async () => {
                            const { catalog_item: productInfo, variants } = await this.fetchProductInfo(
                                product.product_id,
                            );
                            if (productInfo) {
                                const variantImages = this.findVariantImages(variants, product.variant_id);
                                let modifiedUrl = '';

                                if (variantImages) {
                                    modifiedUrl = this.modifyImageUrlForThumbnail(variantImages[0].src);
                                } else {
                                    modifiedUrl = productInfo.images[0];
                                }

                                return { key, modifiedUrl };
                            }
                            return null;
                        })();

                        return await Promise.race([fetchPromise, timeoutPromise]);
                    } catch (error) {
                        console.warn(`Failed to load image for product ${key}:`, error);
                        return { key, modifiedUrl: this.defaultImageUrl };
                    }
                }
                return null;
            });

            const results = await Promise.all(imageFetchPromises);
            results.forEach((result) => {
                if (result) {
                    this.$set(this.productImageUrls, result.key, result.modifiedUrl);
                }
            });
        },
        modifyImageUrlForThumbnail(url) {
            if (url.includes('?')) {
                return `${url}&width=100`;
            } else {
                return `${url}?width=100`;
            }
        },
    },
    async created() {
        this.URL = process.env.VUE_APP_API_URL;
        await this.loadProductImages();
    },
};
</script>
