export default {
    isDebugMode: process.env.VUE_APP_ENVIROMENT === 'local',

    debugLog(message, data = null) {
        if (this.isDebugMode) {
            console.log(`[GTM Debug] ${message}`, data || '');
        }
    },

    trackEvent(eventName, parameters = {}) {
        try {
            // Add common parameters
            const enhancedParameters = {
                ...parameters,
                environment: process.env.VUE_APP_ENVIROMENT,
                timestamp: new Date().toISOString(),
            };

            // Push to dataLayer for GTM
            window.dataLayer = window.dataLayer || [];
            const dataLayerEvent = {
                event: eventName,
                ...enhancedParameters,
            };
            window.dataLayer.push(dataLayerEvent);
            this.debugLog('Pushed to dataLayer:', dataLayerEvent);

            // Fallback to gtag if available
            if (typeof gtag !== 'undefined' && window.GA_INITIALIZED) {
                this.debugLog('Tracking via gtag:', { eventName, enhancedParameters });
                gtag('event', eventName, enhancedParameters);
            } else {
                this.debugLog('Tracking via dataLayer only:', { eventName, enhancedParameters });
            }
        } catch (error) {
            console.error('Error tracking event:', error);
        }
    },

    trackError(error, additionalInfo = {}) {
        this.trackEvent('error_occurred', {
            error_message: error.message,
            error_stack: error.stack,
            error_type: error.name,
            ...additionalInfo,
        });
    },

    // Add page view tracking
    trackPageView(page_title, page_path) {
        // Push to dataLayer for GTM
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'page_view',
            page_title,
            page_path,
            page_location: window.location.href,
        });

        // Fallback to gtag if available
        if (typeof gtag !== 'undefined' && window.GA_INITIALIZED) {
            this.trackEvent('page_view', {
                page_title,
                page_path,
                page_location: window.location.href,
            });
        }
    },

    // Add user tracking
    trackUser(userId, userProperties = {}) {
        // Push to dataLayer for GTM
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'user_properties',
            user_id: userId,
            ...userProperties,
        });

        // Fallback to gtag if available
        if (typeof gtag !== 'undefined') {
            gtag('set', 'user_properties', {
                user_id: userId,
                ...userProperties,
            });
        }
    },
};
