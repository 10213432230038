/**
 * Converts an epoch timestamp to a formatted date string
 * @param {number} epoch - The epoch timestamp in seconds
 * @param {string} [format='DD/MM/YYYY'] - The desired output format
 * @returns {string} The formatted date string
 */
export function epochToDate(epoch, format = 'DD/MM/YYYY') {
    // Convert seconds to milliseconds if needed
    const date = new Date(epoch * 1000);
    
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    
    // Replace format tokens with actual values
    return format
        .replace('DD', day)
        .replace('MM', month)
        .replace('YYYY', year);
}

// Example usage:
// epochToDate(1742489290) => "20/03/2025"
// epochToDate(1742489290, 'YYYY-MM-DD') => "2025-03-20" 