<template>
    <div class="custom-main-panel">
        <div>
            <md-progress-bar v-if="isLoading" md-mode="indeterminate"></md-progress-bar>
        </div>
        <div class="md-layout custom-content-panel">
            <div v-if="isLoading" class="overlay md-layout-item md-size-100"></div>
            <div v-else class="md-layout-item md-size-100">
                <tabs
                    :tab-name="['Account', 'API Keys', 'Members', 'Billing', 'Notifications', 'Tours']"
                    color-button="simple"
                >
                    <template v-slot:tab-pane-1>
                        <AccountSettings />
                    </template>
                    <template v-slot:tab-pane-2>
                        <ApiKeys />
                    </template>
                    <template v-slot:tab-pane-3>
                        <UsersManagement />
                    </template>
                    <template v-slot:tab-pane-4>
                        <Subscription />
                    </template>
                    <template v-slot:tab-pane-5>
                        <NotificationsSettings />
                    </template>
                    <template v-slot:tab-pane-6>
                        <ToursSettings />
                    </template>
                </tabs>
            </div>
        </div>
    </div>
</template>
<script>
import { Tabs } from '@/components';

import AccountSettings from '@/pages/Dashboard/Settings/Account/AccountSettings.vue';
import ApiKeys from '@/pages/Dashboard/Settings/apiKeys/ApiKeys.vue';
import UsersManagement from '@/pages/Dashboard/Settings/UsersManagement/UsersManagement.vue';
import Subscription from '@/pages/Dashboard/Settings/Subscription/Subscription.vue';
import NotificationsSettings from '@/pages/Dashboard/Settings/Notifications/NotificationsSettings.vue';
import ToursSettings from '@/pages/Dashboard/Settings/Tours/ToursSettings.vue';

export default {
    name: 'Settings',
    components: {
        Tabs,
        AccountSettings,
        ApiKeys,
        UsersManagement,
        Subscription,
        NotificationsSettings,
        ToursSettings,
    },
    data() {
        return {
            isLoading: false,
        };
    },
};
</script>
<style lang="scss" scoped>
.custom-main-panel {
    position: relative;
    overflow-x: auto;
    width: 100%;
}

.custom-content-panel {
    padding-left: 15px;
    padding-right: 15px;

    @media (max-width: 1278px) {
        padding-left: 15px;
        padding-right: 15px;
    }

    @media (max-width: 959px) {
        padding-left: 0px;
        padding-right: 0px;
    }
}
</style>
