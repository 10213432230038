import axios from 'axios';
import store from '@/store';

class BaseApiRepository {
    constructor() {
        this.baseURL = process.env.VUE_APP_API_URL;
    }

    getAuthHeader(token) {
        const devMode = store.state.user.devMode;
        return devMode ? { 'X-API-KEY': `${token}` } : { Authorization: `Bearer ${token}` };
    }

    async apiRequest(method, url, data = null, params = {}) {
        try {
            return store.dispatch('user/apiCall', async (token) => {
                const config = {
                    method,
                    url: `${this.baseURL}/accounts/${store.state.user.user.account_id}/${url}`,
                    headers: this.getAuthHeader(token),
                    params: { ...params },
                    data,
                };
                return await axios(config);
            });
        } catch (error) {
            throw error;
        }
    }
}

export default BaseApiRepository;
