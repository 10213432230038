<!-- eslint-disable prettier/prettier -->
<template>
    <div
        @click.ctrl.exact="onCtrlClick"
        @click.middle.exact="onCtrlClick"
        @click.meta.exact="onCtrlClick"
        class="vac-room-container"
    >
        <slot :name="'room-list-item_' + room.roomId">
            <slot :name="'room-list-avatar_' + room.roomId">
                <div class="vac-icon-container">
                    <div
                        v-if="room.avatar"
                        class="vac-avatar vac-profile-pic"
                        :style="{ 'background-image': `url('${room.avatar}')` }"
                    />
                    <div
                        class="vac-platform-avatar"
                        :style="{ 'background-image': `url('${getCommunicationChannelPic}')` }"
                    ></div>
                </div>
            </slot>
            <div class="vac-name-container vac-text-ellipsis">
                <div class="vac-title-container">
                    <!-- <div
						v-if="userStatus"
						class="vac-state-circle"
						:class="{ 'vac-state-online': userStatus === 'online' }"
					/> -->
                    <div class="vac-satifaction-icon">
                        <svg-icon :name="getSatisfactionSVGCode(room.userSatisfaction)" />
                        <md-tooltip md-direction="top">{{ formatSatisfaction(room.userSatisfaction) }}</md-tooltip>
                    </div>
                    <div class="vac-agent-icon">
                        <svg-icon :name="room.room_ai_type" />
                        <md-tooltip md-direction="top">{{ agentTooltip(room.room_ai_type) }}</md-tooltip>
                    </div>
                    <div class="vac-room-name vac-text-ellipsis">
                        {{ room.roomName }}
                    </div>
                    <div v-if="room.lastMessage" class="vac-text-date">
                        {{ lastMessageTime }}
                    </div>
                </div>
                <div
                    class="vac-text-last"
                    :class="{
                        'vac-message-new': room.lastMessage && room.lastMessage.new && !typingUsers,
                    }"
                >
                    <span v-if="isMessageCheckmarkVisible">
                        <slot :name="'checkmark-icon_' + room.roomId">
                            <svg-icon
                                :name="room.lastMessage.distributed ? 'double-checkmark' : 'checkmark'"
                                :param="room.lastMessage.seen ? 'seen' : ''"
                                class="vac-icon-check"
                            />
                        </slot>
                    </span>
                    <div v-if="room.lastMessage && !room.lastMessage.deleted && isAudio" class="vac-text-ellipsis">
                        <slot :name="'microphone-icon_' + room.roomId">
                            <svg-icon name="microphone" class="vac-icon-microphone" />
                        </slot>
                        {{ formattedDuration }}
                    </div>
                    <format-message
                        v-else-if="room.lastMessage"
                        :message-id="room.lastMessage._id"
                        :room-id="room.roomId"
                        :room-list="true"
                        :content="getLastMessage"
                        :deleted="!!room.lastMessage.deleted && !typingUsers"
                        :users="room.users"
                        :text-messages="textMessages"
                        :linkify="false"
                        :text-formatting="textFormatting"
                        :link-options="linkOptions"
                        :single-line="true"
                    >
                        <template v-for="(idx, name) in $slots" #[name]="data">
                            <slot :name="name" v-bind="data" />
                        </template>
                    </format-message>
                    <div v-if="!room.lastMessage && typingUsers" class="vac-text-ellipsis">
                        {{ typingUsers }}
                    </div>
                    <div class="vac-room-options-container">
                        <div v-if="room.unreadCount" class="vac-badge-counter vac-room-badge">
                            {{ room.unreadCount }}
                        </div>
                        <div v-if="!room.is_aion" class="vac-badge-counter">
                            <svg-icon name="speakerNotesOff" class="vac-icon-speakerNotesOff vac-icon-fill" />
                        </div>
                        <slot :name="'room-list-options_' + room.roomId">
                            <div
                                v-if="roomActions.length"
                                class="vac-svg-button vac-list-room-options"
                                @click.stop="roomMenuOpened = room.roomId"
                            >
                                <slot :name="'room-list-options-icon_' + room.roomId">
                                    <svg-icon name="dropdown" param="room" />
                                </slot>
                            </div>
                            <transition v-if="roomActions.length" name="vac-slide-left">
                                <div
                                    v-if="roomMenuOpened === room.roomId"
                                    v-click-outside="closeRoomMenu"
                                    class="vac-menu-options"
                                >
                                    <div class="vac-menu-list">
                                        <div v-for="action in filteredRoomActions" :key="action.name">
                                            <div class="vac-menu-item" @click.stop="roomActionHandler(action)">
                                                {{ action.title }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </transition>
                        </slot>
                    </div>
                </div>
                <div v-if="room.tags && room.tags.length" class="vac-tags-chips" @wheel="handleWheel">
                    <!-- <md-chip v-for="tag in formattedTags" :key="tag" class="vac-tag">
                        {{ tag }}
                    </md-chip> -->

                    <TagChip v-for="tagId in room.tags || []" :key="tagId" :uuid="tagId" :deletable="false" />
                </div>
            </div>
        </slot>
    </div>
</template>

<script>
import SvgIcon from '@/components/Chat/SvgIcon/SvgIcon.vue';
import FormatMessage from '@/components/Chat/FormatMessage/FormatMessage.vue';

import vClickOutside from '@/utils/on-click-outside';
import typingText from '@/utils/typing-text';
import { isAudioFile } from '@/utils/media-file';
import TagChip from '../TagChip/TagChip.vue';

export default {
    name: 'RoomsContent',
    components: {
        SvgIcon,
        FormatMessage,
        TagChip,
    },

    directives: {
        clickOutside: vClickOutside,
    },

    props: {
        currentUserId: { type: [String, Number], required: true },
        room: { type: Object, required: true },
        textFormatting: { type: Object, required: true },
        linkOptions: { type: Object, required: true },
        textMessages: { type: Object, required: true },
        roomActions: { type: Array, required: true },
    },

    emits: ['room-action-handler'],

    data() {
        return {
            roomMenuOpened: null,
        };
    },

    computed: {
        lastMessageTime() {
            if (!this.hasValidLastMessage()) {
                return '';
            }

            const messageDate = this.parseMessageDate();
            const { today, yesterday, messageDateNoTime } = this.getDateReferences(messageDate);

            // Determine what to display based on when the message was sent
            if (this.isToday(messageDateNoTime, today)) {
                return this.formatTimeOnly(messageDate);
            } else if (this.isYesterday(messageDateNoTime, yesterday)) {
                return 'Yesterday';
            } else if (this.isWithinLastWeek(today, messageDateNoTime)) {
                return this.getDayName(messageDate);
            } else {
                return this.room.lastMessage.date;
            }
        },
        getLastMessage() {
            const isTyping = this.typingUsers;
            if (isTyping) return isTyping;

            const content = this.room.lastMessage.content;

            if (this.room.users.length <= 2) {
                return content;
            }

            const user = this.room.users.find((user) => user._id === this.room.lastMessage.senderId);

            if (this.room.lastMessage.username) {
                return `${this.room.lastMessage.username} - ${content}`;
            } else if (!user || user._id === this.currentUserId) {
                return content;
            }

            return `${user.username} - ${content}`;
        },
        userStatus() {
            if (!this.room.users || this.room.users.length !== 2) return;

            const user = this.room.users.find((u) => u._id !== this.currentUserId);
            if (user && user.status) return user.status.state;

            return null;
        },
        typingUsers() {
            return typingText(this.room, this.currentUserId, this.textMessages);
        },
        isMessageCheckmarkVisible() {
            return (
                !this.typingUsers &&
                this.room.lastMessage &&
                !this.room.lastMessage.deleted &&
                this.room.lastMessage.senderId === this.currentUserId &&
                (this.room.lastMessage.saved || this.room.lastMessage.distributed || this.room.lastMessage.seen)
            );
        },
        formattedDuration() {
            const file = this.room.lastMessage?.files?.[0];
            if (file) {
                if (!file.duration) {
                    return `${file.name}.${file.extension}`;
                }

                let s = Math.floor(file.duration);
                return (s - (s %= 60)) / 60 + (s > 9 ? ':' : ':0') + s;
            }
            return '';
        },
        isAudio() {
            return this.room.lastMessage.files ? isAudioFile(this.room.lastMessage.files[0]) : false;
        },
        getCommunicationChannelPic() {
            let picURL = null;
            switch (this.room.communication_channel) {
                case 'WHATSAPP':
                    picURL =
                        'https://firebasestorage.googleapis.com/v0/b/lixsa-prod.appspot.com/o/public_images%2FLogo-whatsapp.png?alt=media&token=94a44ade-fdad-4f57-87ad-ad4dd45a1daf';
                    break;
                case 'FACEBOOK':
                    picURL =
                        'https://firebasestorage.googleapis.com/v0/b/lixsa-prod.appspot.com/o/public_images%2Ffacebook_messenger_icon.png?alt=media&token=b6442f05-380c-4fa1-b56c-d8bb7c70bd79';
                    break;
                case 'INSTAGRAM':
                    picURL =
                        'https://firebasestorage.googleapis.com/v0/b/lixsa-prod.appspot.com/o/public_images%2Finstagram_icon.png?alt=media&token=94a44ade-fdad-4f57-87ad-ad4dd45a1daf';
                    break;
                default:
                    break;
            }
            return picURL;
        },
        filteredRoomActions() {
            const otherActions = this.roomActions.filter(
                (action) => action.name !== 'MarkAsRead' && action.name !== 'MarkAsUnread',
            );

            if (this.room.unreadCount > 0) {
                return [...this.roomActions.filter((action) => action.name === 'MarkAsRead'), ...otherActions];
            }

            return [...this.roomActions.filter((action) => action.name === 'MarkAsUnread'), ...otherActions];
        },
        formattedTags() {
            return this.room.tags.map((tag) => tag.toLowerCase());
        },
    },

    methods: {
        formatSatisfaction(satisfaction) {
            return satisfaction
                .toLowerCase()
                .split(' ')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
        },
        getSatisfactionSVGCode(satisfaction) {
            if (satisfaction === 'NOT ANALYZED') return 'NOT_ANALYZED';
            return satisfaction;
        },
        onCtrlClick() {
            const roomRoute = this.$router.resolve({ name: 'Customer Support', query: { search: this.room.roomId } });
            window.open(roomRoute.href, '_blank');
        },
        agentTooltip(agent) {
            let tooltipText;
            switch (agent) {
                case 'SUPPORT_AGENT':
                    tooltipText = 'Support Agent';
                    break;
                case 'SALES_AGENT':
                    tooltipText = 'Sales Agent';

                    break;
                case 'HUMAN_AGENT':
                    tooltipText = 'Human Agent';

                    break;

                default:
                    break;
            }

            return tooltipText;
        },
        roomActionHandler(action) {
            this.closeRoomMenu();
            this.$emit('room-action-handler', { action, roomId: this.room.roomId });
        },
        closeRoomMenu() {
            this.roomMenuOpened = null;
        },
        handleWheel(event) {
            const container = event.currentTarget;
            container.scrollLeft += event.deltaY;
            event.preventDefault();
        },
        hasValidLastMessage() {
            return this.room.lastMessage && this.room.lastMessage.timestamp && this.room.lastMessage.date;
        },
        parseMessageDate() {
            const [dayOfWeek, month, day, year] = this.room.lastMessage.date.split(' ');
            const [time, period] = this.room.lastMessage.timestamp.split(' ');
            const [hours, minutes] = time.split(':').map(Number);

            const monthMap = {
                ene: 0,
                feb: 1,
                mar: 2,
                abr: 3,
                may: 4,
                jun: 5,
                jul: 6,
                ago: 7,
                sep: 8,
                oct: 9,
                nov: 10,
                dic: 11,
            };

            const messageDate = new Date(year, monthMap[month.toLowerCase()], day);
            messageDate.setHours(period === 'PM' && hours !== 12 ? hours + 12 : hours);
            messageDate.setMinutes(minutes);

            return messageDate;
        },
        getDateReferences(messageDate) {
            const currentDate = new Date();

            // Create dates without time components for accurate day comparison
            const today = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
            const yesterday = new Date(today);
            yesterday.setDate(yesterday.getDate() - 1);

            const messageDateNoTime = new Date(
                messageDate.getFullYear(),
                messageDate.getMonth(),
                messageDate.getDate(),
            );

            return { today, yesterday, messageDateNoTime };
        },
        isToday(messageDateNoTime, today) {
            return messageDateNoTime.getTime() === today.getTime();
        },
        isYesterday(messageDateNoTime, yesterday) {
            return messageDateNoTime.getTime() === yesterday.getTime();
        },
        isWithinLastWeek(today, messageDateNoTime) {
            const MILLISECONDS_IN_WEEK = 7 * 24 * 60 * 60 * 1000;
            return today.getTime() - messageDateNoTime.getTime() < MILLISECONDS_IN_WEEK;
        },
        formatTimeOnly(messageDate) {
            return messageDate.toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
            });
        },
        getDayName(messageDate) {
            const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            return days[messageDate.getDay()];
        },
    },
};
</script>

<style>
.vac-icon-fill {
    fill: #9ca6af;
    height: 18px;
    width: 18px;
}

.vac-tags-chips {
    display: flex;
    /* gap: 0.2rem; */
    margin: 8px 0;
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.vac-tags-chips::-webkit-scrollbar {
    display: none;
}
</style>
