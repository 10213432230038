<script>
import { mapGetters } from 'vuex';

export default {
    name: 'ChannelsFacebookAboutCard',
    props: ['communicationChannel'],
    data() {
        return {
            // Mock data for the connected Facebook account
            facebookAccount: {
                pageName: null,
                profilePicture: null,
                pageId: null,
            },
        };
    },
    computed: {
        ...mapGetters('agentSettings', ['getSkillByInternalName']),

        getFacebookSkill() {
            return this.getSkillByInternalName('facebook');
        },

        connectedPageName() {
            // In a real implementation, this would come from the skill config or API
            return this.getFacebookSkill?.config_values?.page_name || this.facebookAccount.pageName;
        },

        profilePicture() {
            // In a real implementation, this would come from the skill config or API
            return this.getFacebookSkill?.config_values?.picture || this.facebookAccount.profilePicture;
        },
        connectedPageId() {
            return this.getFacebookSkill?.config_values?.page_id || this.facebookAccount.pageId;
        },
    },
    methods: {
        openFacebookProfile() {
            const pageId = this.connectedPageId;
            window.open(`https://facebook.com/${pageId}`, '_blank').focus();
        },
    },
};
</script>

<template>
    <div class="facebook-container" v-if="connectedPageName && profilePicture">
        <h3>Connected Facebook Pages</h3>
        <md-card class="facebook-card">
            <md-card-content class="facebook-card__content">
                <div class="facebook-profile">
                    <md-avatar class="facebook-avatar">
                        <img :src="profilePicture" alt="Profile Picture" v-if="profilePicture" />
                        <span class="avatar-placeholder" v-else>{{ connectedPageName.charAt(0).toUpperCase() }}</span>
                    </md-avatar>
                    <div class="facebook-username">{{ connectedPageName }}</div>
                </div>
                <md-button @click="openFacebookProfile" class="view-profile-btn">
                    <md-icon>visibility</md-icon>
                    View Profile
                </md-button>
            </md-card-content>
        </md-card>
    </div>
</template>

<style scoped lang="scss">
.facebook-container {
    margin-bottom: 16px;
}

.facebook-card {
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

    &__content {
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.facebook-profile {
    display: flex;
    align-items: center;
    gap: 12px;
}

.facebook-avatar {
    width: 40px;
    height: 40px;
    background: #1877f2;

    .avatar-placeholder {
        font-size: 16px;
        color: white;
        font-weight: bold;
    }
}

.facebook-username {
    font-weight: 500;
}

.view-profile-btn {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 0 12px;
    height: 32px;
    text-transform: none;
    font-weight: normal;
    color: #1877f2;
    min-width: auto;
}
</style>
