<template>
    <div>
        <md-dialog :md-active="true" :md-close-on-esc="false" :md-click-outside-to-close="false">
            <md-dialog-title class="md-headline">
                Prestashop Module Installation
                <span>
                    <md-icon>help_outline</md-icon>
                    <md-tooltip md-direction="bottom">
                        <p class="md-body-1">Follow these steps to install the Prestashop module</p>
                    </md-tooltip>
                </span>
            </md-dialog-title>
            <div class="modal-content">
                <div class="instructions-container">
                    <h3 class="md-title">Installation Instructions</h3>

                    <div class="instruction-step">
                        <div class="step-number">1</div>
                        <div class="step-content">
                            <p class="md-body-1">Download the Lixsa Prestashop module</p>
                        </div>
                    </div>

                    <md-button
                        class="md-raised md-primary download-button instruction-step"
                        href="https://storage.googleapis.com/lixsa-statics/modules/prestashop/lixsaai.zip"
                        target="_blank"
                    >
                        <md-icon>download</md-icon>
                        Download Module
                    </md-button>

                    <div class="instruction-step">
                        <div class="step-number">2</div>
                        <div class="step-content">
                            <p class="md-body-1">Log in to your Prestashop admin panel</p>
                        </div>
                    </div>

                    <div class="instruction-step">
                        <div class="step-number">3</div>
                        <div class="step-content">
                            <p class="md-body-1">Go to <strong>Modules > Module Manager > Upload a module</strong></p>
                        </div>
                    </div>

                    <div class="instruction-step">
                        <div class="step-number">4</div>
                        <div class="step-content">
                            <p class="md-body-1">
                                Select the downloaded module ZIP file and click 'Upload this module'
                            </p>
                        </div>
                    </div>

                    <div class="instruction-step">
                        <div class="step-number">5</div>
                        <div class="step-content">
                            <p class="md-body-1">
                                Once installed, click 'Complete Setup' and you will be redirected to the Lixsa Dashboard
                            </p>
                        </div>
                    </div>

                    <div class="instruction-step">
                        <div class="step-number">6</div>
                        <div class="step-content">
                            <p class="md-body-1">
                                The skill will be automatically activated along all your store information
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <md-dialog-actions>
                <md-button class="md-default" @click="closeModal">Close</md-button>
            </md-dialog-actions>
        </md-dialog>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: 'PrestashopInstallInstructionsModal',

    data() {
        return {
            isVerifying: false,
        };
    },

    methods: {
        ...mapActions('modalStore', ['closeModal']),

        async notifyInstalled() {
            this.isVerifying = true;
            try {
                // Emit event to parent for verification
                this.$emit('module-installed');
            } finally {
                this.isVerifying = false;
            }
        },
    },
};
</script>

<style scoped>
.modal-content {
    padding: 0 24px;
    max-height: 70vh;
    overflow-y: auto;
}

.instructions-container {
    margin: 16px 0;
}

.instruction-step {
    display: flex;
    margin-bottom: 5px;
    align-items: center;
}

.step-number {
    width: 30px;
    height: 30px;
    background-color: #4a25e1;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    flex-shrink: 0;
}

.step-content {
    flex-grow: 1;
}

.download-button {
    margin-top: 8px;
    margin-bottom: 10px;
}

.md-button {
    min-width: 120px;
}

.md-progress-spinner {
    margin-right: 8px;
}
</style>
