<template>
    <div v-if="selectedAsset">
        <div class="section-header">
            <span class="section-title">Contact information</span>
        </div>

        <div class="info-container">
            <!-- Name -->
            <div class="info-item">
                <svg-icon class="info-icon" name="person" />
                <div class="info-content">
                    <span class="info-value">{{ currentUserName }}</span>
                </div>
            </div>

            <!-- Email -->
            <div class="info-item" v-if="currentUserEmail">
                <svg-icon class="info-icon" name="email" />
                <div class="info-content">
                    <span class="info-value">{{ currentUserEmail }}</span>
                </div>
                <div class="copy-container">
                    <span class="copy-status" v-if="copiedFields.email">Copied!</span>
                    <button class="copy-button" @click="copyCodeToClipboard(currentUserEmail, 'email')">
                        <svg-icon class="copy-icon" name="content_copy" />
                    </button>
                </div>
            </div>

            <!-- Phone -->
            <div class="info-item" v-if="currentUserPhone">
                <svg-icon class="info-icon" name="phone" />
                <div class="info-content">
                    <span class="info-value">{{ currentUserPhone }}</span>
                </div>
                <div class="copy-container">
                    <span class="copy-status" v-if="copiedFields.phone">Copied!</span>
                    <button class="copy-button" @click="copyCodeToClipboard(currentUserPhone, 'phone')">
                        <svg-icon class="copy-icon" name="content_copy" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SvgIcon from '@/components/Chat/SvgIcon/SvgIcon.vue';

export default {
    name: 'CustomerAssetsContactInformation',
    components: {
        SvgIcon,
    },
    data() {
        return {
            copiedFields: {
                email: false,
                phone: false,
            },
        };
    },
    props: {
        selectedAsset: { type: [Object, Array] },
        currentUserName: { type: String, required: false, default: undefined },
        currentUserEmail: { type: String, required: false, default: undefined },
        currentUserPhone: { type: String, required: false, default: undefined },
    },
    methods: {
        copyCodeToClipboard(text, field) {
            navigator.clipboard
                .writeText(text)
                .then(() => {
                    this.copiedFields[field] = true;
                    setTimeout(() => {
                        this.copiedFields[field] = false;
                    }, 1000);
                })
                .catch((err) => {
                    console.error('Could not copy text: ', err);
                });
        },
    },
};
</script>

<style lang="scss">
@import './CustomerAssetsContactInformation.scss';
</style>
