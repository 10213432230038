<script>
import { mapActions, mapGetters } from 'vuex';
import CancelSubscriptionShopify from '@/components/Settings/Subscription/BillingSection/BillingSectionShopify/cancelSubscriptionShopify.vue';
import UpdatePlanShopify from '@/components/Settings/Subscription/BillingSection/BillingSectionShopify/UpdatePlanShopify.vue';
import shopifyBillingService from '@/services/shopify/shopifyBillingService';
import ConversationsUsageProgressBar from '@/components/Settings/Subscription/ConversationsUsageProgressBar.vue';
export default {
    name: 'BillingSectionShopify',
    components: {
        ConversationsUsageProgressBar,
    },
    data() {
        return {
            isUpdatingPlan: false,
            isCreatingPackCheckoutLink: false,
        };
    },
    computed: {
        ...mapGetters('membership', [
            'membershipPlanName',
            'subscriptionEndDate',
            'shopifyCancelAtPeriodEnd',
            'shopifyCancelAt',
            'shopifySubscriptionTrialEndDate',
            'shopifyIsInTrialPeriod',
        ]),
    },
    methods: {
        ...mapActions('modalStore', ['openModal']),
        formatDate(timestamp) {
            const options = { month: 'long', day: 'numeric' };
            const date = new Date(timestamp * 1000);
            return date.toLocaleDateString('en-US', options);
        },
        capitalizeFirstLetter(str) {
            if (typeof str !== 'string' || str.length === 0) {
                return '';
            }
            str = str.toLowerCase();
            return str.charAt(0).toUpperCase() + str.slice(1);
        },
        async openCancelPlanModal() {
            this.openModal({
                component: CancelSubscriptionShopify,
            });
        },

        openManageBilling() {
            window.open('https://admin.shopify.com/settings/billing', '_blank');
        },

        openUpdatePlanModal() {
            this.openModal({
                component: UpdatePlanShopify,
            });
        },

        async openPackCheckoutLink() {
            this.isCreatingPackCheckoutLink = true;
            const packData = {
                membership_subscription_plan: this.membershipPlanName.toUpperCase(),
                return_url: window.location.origin + '/settings/billing',
            };
            try {
                const response = await shopifyBillingService.createShopifyPackCheckoutLink(packData);
                const confirmationUrl = response.data.confirmationUrl;
                window.open(confirmationUrl, '_blank');
            } catch (error) {
                console.error('Error creating Shopify pack checkout link:', error);
            } finally {
                this.isCreatingPackCheckoutLink = false;
            }
        },
    },
};
</script>

<template>
    <div class="billing-section-shopify">
        <md-card>
            <md-card-header>
                <div class="md-title bold">
                    <i class="material-symbols-outlined"> package_2 </i>
                    Plan {{ capitalizeFirstLetter(membershipPlanName) }}
                </div>
                <p v-if="!shopifyIsInTrialPeriod && !shopifyCancelAtPeriodEnd" class="md-caption">
                    Renews on {{ formatDate(subscriptionEndDate) }}
                </p>
                <p v-else-if="!shopifyCancelAtPeriodEnd" class="md-caption">
                    Your trial ends on {{ formatDate(shopifySubscriptionTrialEndDate) }}
                </p>
                <p v-if="shopifyCancelAtPeriodEnd" class="md-caption billing-section-shopify__cancel-message">
                    Your plan will cancel at {{ formatDate(shopifyCancelAt) }}.
                </p>
            </md-card-header>

            <md-card-content>
                <ConversationsUsageProgressBar />

                <div class="action-buttons">
                    <md-button class="md-outline md-primary" @click="openUpdatePlanModal">
                        <md-progress-spinner
                            v-if="isUpdatingPlan"
                            :md-diameter="12"
                            :md-stroke="2"
                            class="md-primary"
                            md-mode="indeterminate"
                        ></md-progress-spinner>
                        <i v-else class="material-symbols-outlined"> package_2 </i>
                        Update Plan
                    </md-button>
                    <md-button class="md-outline md-primary" @click="openPackCheckoutLink">
                        <md-progress-spinner
                            v-if="isCreatingPackCheckoutLink"
                            :md-diameter="12"
                            :md-stroke="2"
                            class="md-primary"
                            md-mode="indeterminate"
                        ></md-progress-spinner>
                        <i v-else class="material-symbols-outlined"> chat_bubble </i>
                        Buy More Conversations
                    </md-button>
                </div>
            </md-card-content>

            <md-card-actions>
                <md-button
                    :disabled="shopifyCancelAtPeriodEnd"
                    @click="openCancelPlanModal"
                    class="md-danger md-simple"
                >
                    <span>Cancel Subscription</span>
                </md-button>

                <!-- New Manage Billing Button -->
                <md-button class="md-primary md-raised" @click="openManageBilling">
                    <i class="material-symbols-outlined"> credit_card </i>
                    Manage Billing
                </md-button>
            </md-card-actions>
        </md-card>
    </div>
</template>

<style scoped lang="scss">
.billing-section-shopify {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .md-card-header {
        padding: 16px;

        .md-title {
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }

    .md-card-content {
        padding: 16px;
    }

    .md-card-actions {
        padding: 16px;
        border-top: 1px solid #e5e7eb;
        display: flex;
        justify-content: space-between;
    }

    .billing-section-shopify__cancel-message {
        color: #f44336;
    }

    .bold {
        font-weight: 600;
    }

    .action-buttons {
        display: grid;
        grid-template-columns: 1fr;
        gap: 16px;
        margin-bottom: 24px;
    }

    .md-progress-spinner {
        margin-right: 1rem;
    }

    @media (max-width: 640px) {
        .md-card-actions {
            flex-direction: column;
            gap: 16px;

            .md-button {
                width: 100%;
            }
        }

        .action-buttons {
            grid-template-columns: 1fr;
        }
    }
}
</style>
