<script>
export default {
    name: 'CircularProgressBar',
    props: ['value'],
    computed: {
        getBarColor() {
            if (this.value < 90) {
                return 'md-primary';
            } else if (this.value >= 90 && this.value < 100) {
                return 'md-warning';
            } else {
                return 'md-danger';
            }
        },
    },
    methods: {},
};
</script>

<template>
    <div class="circular-progress-bar">
        <md-progress-spinner
            md-mode="determinate"
            :md-value="value"
            :md-diameter="45"
            :md-stroke="4"
            class="circular-progress-bar__spinner with-background"
            :class="getBarColor"
        ></md-progress-spinner>
        <span class="md-caption circular-progress-bar__value">
            <strong>{{ value }}%</strong>
        </span>
    </div>
</template>

<style scoped lang="scss">
.circular-progress-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 60px;
    height: 60px;
}

.circular-progress-bar__spinner {
    position: absolute;
}

.circular-progress-bar__value {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
</style>
