<template>
    <div>
        <md-dialog :md-close-on-esc="false" :md-click-outside-to-close="false" :md-active.sync="showDialog">
            <md-dialog-title
                ><strong>Invite member to {{ $store.state.user.user.username }}'s organization</strong></md-dialog-title
            >
            <div class="modal-content">
                <div class="form-group">
                    <span class="md-body-1">Full name</span>
                    <md-field>
                        <md-input type="text" v-model="username" placeholder="e.g. John Doe"></md-input>
                    </md-field>
                </div>

                <div class="form-group">
                    <span class="md-body-1">Email</span>
                    <md-field>
                        <md-input type="email" v-model="email" placeholder="e.g. johndoe@example.com"></md-input>
                    </md-field>
                </div>

                <div class="form-group">
                    <span class="md-body-1">Permission</span>
                    <md-field>
                        <md-select v-model="permission">
                            <md-option value="EMPLOYEE">Employee</md-option>
                            <md-option value="ADMIN">Admin</md-option>
                        </md-select>
                    </md-field>
                </div>

                <div class="form-group">
                    <span class="md-body-1">Role</span>
                    <md-field>
                        <md-input
                            type="text"
                            v-model="role"
                            placeholder="e.g. Customer Success, Sales, etc."
                        ></md-input>
                    </md-field>
                </div>

                <div class="form-group">
                    <div class="form-group-header">
                        <span class="md-body-1">Capabilities</span>
                        <div class="form-group-header-icon">
                            <i class="material-symbols-outlined"> help </i>
                            <md-tooltip md-direction="top">
                                This will be used to determine the agent capabilities when escalating an incident in the
                                chat
                            </md-tooltip>
                        </div>
                    </div>
                    <md-field>
                        <md-textarea
                            v-model="capabilities"
                            md-autogrow
                            md-counter="250"
                            placeholder="e.g. This user is a customer success manager and his responsibilities include managing customer relationships, providing support, and ensuring customer satisfaction."
                        ></md-textarea>
                    </md-field>
                </div>
            </div>

            <md-dialog-actions>
                <md-button class="md-white modal-button" @click="handleClose">Close</md-button>
                <md-button class="md-primary modal-button" @click="handleCreate" :disabled="!canSave">
                    <span v-if="!isLoading">Send invitation</span>
                    <md-progress-spinner
                        v-else
                        :md-diameter="20"
                        :md-stroke="3"
                        class="md-white"
                        md-mode="indeterminate"
                    ></md-progress-spinner>
                </md-button>
            </md-dialog-actions>
        </md-dialog>

        <md-button class="md-primary custom-button" @click="showDialog = true">
            <div>
                <md-icon class="custom-button-icon">add</md-icon>
                <span>Invite member</span>
            </div>
        </md-button>
    </div>
</template>

<script>
import userService from '@/services/account/userService.js';

export default {
    name: 'InviteMemberWizard',
    data: () => ({
        showDialog: false,
        email: '',
        username: '',
        permission: 'EMPLOYEE',
        role: '',
        capabilities: '',
        isLoading: false,
    }),
    computed: {
        canSave() {
            return this.email !== '' && this.username !== '';
        },
    },
    methods: {
        handleClose() {
            this.email = '';
            this.username = '';
            this.showDialog = false;
            this.isLoading = false;
        },
        async handleCreate() {
            this.isLoading = true;

            try {
                const reqData = {
                    email: this.email,
                    username: this.username,
                    permission: this.permission,
                    role: this.role,
                    capabilities: this.capabilities,
                };
                const createUserResponse = await userService.inviteUser(reqData);
                if (createUserResponse.response.status === 201) {
                    this.handleClose();
                    this.notifyParent();
                    this.showSuccessToast('User invited');
                }
            } catch (error) {
                console.error('Error inviting member: ' + error);
                this.showErrorToast('Error inviting member');
            } finally {
                this.isLoading = false;
            }
        },
        notifyParent() {
            this.$emit('UserCreated');
        },
        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },
        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/md/_variables.scss';

.md-dialog .md-dialog-container {
    max-width: 768px;
}

.modal-content {
    background: #fff;
    padding: 30px;
    padding-top: 0px;
    // text-align: center;
    margin: 0 auto;
    width: 500px;

    p {
        margin-bottom: 40px;
    }
}

.md-field {
    margin-top: 0;
}

.form-group {
    margin-bottom: 1rem;

    //     .md-body-1 {
    //         margin-bottom: 4px;
    //         font-weight: 500;
    //     }
}

.modal-content h4 {
    text-align: left;
}

.modal-button {
    text-transform: none !important;
    height: 40px !important;
}

.custom-copy-button {
    margin-left: 16px;
}

.custom-button {
    color: $white-color !important;

    .custom-button-icon {
        color: $white-color !important;
    }
}

.form-group-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    i {
        color: #aaaaaa;
        font-size: 1.2rem;
        cursor: pointer;
    }
}

/* Styling for textarea placeholder */
::placeholder {
    font-size: 12px !important;
    color: #aaaaaa !important;
}

/* For Firefox */
::-moz-placeholder {
    font-size: 12px !important;
    color: #aaaaaa !important;
}

/* For Chrome/Opera/Safari */
::-webkit-input-placeholder {
    font-size: 12px !important;
    color: #aaaaaa !important;
}

/* For IE/Edge */
:-ms-input-placeholder {
    font-size: 12px !important;
    color: #aaaaaa !important;
}
</style>
