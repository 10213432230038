import countryLanguages from 'country-language';

/**
 * @typedef {Object} Language
 * @property {string} code - ISO 639-1 language code
 * @property {string} nativeName - Native name of the language
 * @property {string} name - English name of the language
 */

/**
 * Service class for handling language-related operations
 */
export default class LanguageService {
    /**
     * Default language code to fall back to when errors occur
     * @private
     */
    static DEFAULT_LANGUAGE = 'en';

    /**
     * Retrieves all available languages
     * @returns {Promise<Language[]>} Array of language objects
     * @throws {Error} If there's an error fetching languages
     */
    static async getAllLanguages() {
        try {
            const languages = countryLanguages.getLanguages();

            return languages
                .map((lang) => ({
                    code: lang.iso639_1,
                    nativeName: lang.nativeName[0],
                    name: lang.name[0],
                }))
                .filter((lang) => lang.code);
        } catch (error) {
            console.error('Failed to fetch all languages:', error);
            throw new Error('Unable to retrieve languages. Please try again later.');
        }
    }

    /**
     * Retrieves languages by their ISO codes
     * @param {string[]} codes - Array of ISO 639-1 language codes
     * @returns {Promise<Language[]>} Array of language objects
     * @throws {Error} If the codes parameter is invalid or if there's an error fetching languages
     */
    static async getLanguagesByCode(codes) {
        if (!Array.isArray(codes)) {
            throw new Error('Language codes must be provided as an array');
        }

        if (codes.length === 0) {
            return [];
        }

        try {
            const languages = codes
                .map((code) => {
                    if (typeof code !== 'string') {
                        console.warn(`Invalid language code type: ${typeof code}`);
                        return null;
                    }

                    const lang = countryLanguages.getLanguage(code);
                    if (!lang) {
                        console.warn(`Language not found for code: ${code}`);
                        return null;
                    }

                    return {
                        code: lang.iso639_1,
                        nativeName: lang.nativeName[0],
                        name: lang.name[0],
                    };
                })
                .filter((lang) => lang !== null);

            return languages;
        } catch (error) {
            console.error('Failed to fetch languages by code:', error);
            throw new Error('Unable to retrieve languages. Please try again later.');
        }
    }
}
