import BaseDto from '../../_common/BaseDto';

class TemplateRequestDtoBuilder {
    constructor() {
        this.uuid = undefined;
        this.accountId = undefined;
        this.body = undefined;
        this.header = undefined;
        this.channelType = undefined;
        this.templateName = undefined;
        this.language = undefined;
        this.category = undefined;
        this.type = undefined;
        this.templateType = undefined;
        this.flowType = undefined;
        this.skillId = undefined;
        this.templateParameters = undefined;
        this.extraParameters = undefined;
    }

    withUuid(uuid) {
        this.uuid = uuid;
        return this;
    }

    withAccountId(accountId) {
        this.accountId = accountId;
        return this;
    }

    withBody(body) {
        this.body = body;
        return this;
    }

    withHeader(header) {
        this.header = header;
        return this;
    }

    withChannelType(channelType) {
        this.channelType = channelType;
        return this;
    }

    withTemplateName(templateName) {
        this.templateName = templateName;
        return this;
    }

    withLanguage(language) {
        this.language = language;
        return this;
    }

    withCategory(category) {
        this.category = category;
        return this;
    }

    withType(type) {
        this.type = type;
        return this;
    }

    withTemplateType(templateType) {
        this.templateType = templateType;
        return this;
    }

    withFlowType(flowType) {
        this.flowType = flowType;
        return this;
    }

    withSkillId(skillId) {
        this.skillId = skillId;
        return this;
    }

    withTemplateParameters(templateParameters) {
        this.templateParameters = templateParameters;
        return this;
    }

    withExtraParameters(extraParameters) {
        this.extraParameters = extraParameters;
        return this;
    }

    build() {
        return new TemplateRequestDto(this);
    }
}

class TemplateRequestDto extends BaseDto {
    constructor(builder) {
        super();
        this.uuid = builder.uuid;
        this.account_id = builder.accountId;
        this.body = builder.body;
        this.header = builder.header;
        this.channel_type = builder.channelType;
        this.template_name = builder.templateName;
        this.language = builder.language;
        this.category = builder.category;
        this.type = builder.type;
        this.template_type = builder.templateType;
        this.flow_type = builder.flowType;
        this.skill_id = builder.skillId;
        this.template_parameters = builder.templateParameters;
        this.extra_parameters = builder.extraParameters;
    }

    static get Builder() {
        return TemplateRequestDtoBuilder;
    }
}

export default TemplateRequestDto;
