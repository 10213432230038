<template>
    <div class="conversations-usage">
        <div class="conversations-usage__header">
            <h3 class="conversations-usage__title md-subheading">
                <i class="material-symbols-outlined conversations-usage__icon">chat_bubble</i>
                Conversation Usage
            </h3>
            <span class="conversations-usage__count">{{ consumedConversations }} / {{ totalConversations }}</span>
        </div>
        <md-progress-bar
            class="conversations-usage__progress-bar"
            :class="{
                'md-primary': conversationsPercentage < 90,
                'md-warning': conversationsPercentage >= 90 && conversationsPercentage < 100,
                'md-danger': conversationsPercentage >= 100,
            }"
            md-mode="determinate"
            :md-value="conversationsPercentage"
        ></md-progress-bar>
        <p class="conversations-usage__caption">
            You have used {{ conversationsPercentage.toFixed(1) }}% of your available conversations
        </p>
        <div v-if="conversationsPercentage >= 90 && conversationsPercentage < 100" class="conversations-usage__alert">
            <div class="conversations-usage__alert-content">
                <i class="material-symbols-outlined conversations-usage__alert-icon">feedback</i>
                <h4 class="md-subheading conversations-usage__alert-title">Warning: Low on Conversations</h4>
            </div>
            <p class="md-body-2 conversations-usage__alert-text">
                You are running low on conversations. Consider upgrading your plan or reducing usage to avoid
                interruptions.
            </p>
        </div>
        <div v-if="conversationsPercentage >= 100" class="conversations-usage__alert conversations-usage__alert--error">
            <div class="conversations-usage__alert-content">
                <i class="material-symbols-outlined conversations-usage__alert-icon">error</i>
                <h4 class="md-subheading conversations-usage__alert-title">No Conversations Available</h4>
            </div>
            <p class="md-body-2 conversations-usage__alert-text">
                You have no conversations left. Lixsa will not respond until you purchase more conversations or upgrade
                your plan.
            </p>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'ConversationsUsageProgressBar',
    computed: {
        ...mapGetters('membership', ['consumedConversations', 'totalConversations']),
        conversationsPercentage() {
            const percentage = (this.consumedConversations * 100) / this.totalConversations;
            return percentage > 0 ? Number(percentage.toFixed(1)) : Number(percentage);
        },
    },
};
</script>

<style scoped lang="scss">
.conversations-usage {
    margin-bottom: 24px;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
    }

    &__title {
        display: flex;
        align-items: center;
        gap: 8px;
        font-weight: 600;
    }

    &__progress-bar {
        height: 8px;
        border-radius: 4px;
        margin-bottom: 8px;
    }

    &__caption {
        font-size: 0.8em;
        color: rgba(0, 0, 0, 0.54);
    }

    &__alert {
        background-color: #fef9c3;
        border-left: 3px solid #eab306;
        padding: 16px;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        margin-top: 16px;

        &--error {
            background-color: #fee2e2;
            border-left-color: #dc2626;

            .conversations-usage__alert-icon,
            .conversations-usage__alert-content,
            .conversations-usage__alert-title,
            .conversations-usage__alert-text {
                color: #991b1b;
            }
        }
    }

    &__alert-icon {
        color: #a66c1a;
    }

    &__alert-content {
        display: flex;
        align-items: center;
        gap: 8px;
        color: #a66c1a;
    }

    &__alert-title {
        font-weight: 600;
    }

    &__alert-text {
        font-size: 0.9em;
        color: #a66c1a;
    }
}
</style>
