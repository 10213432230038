<template>
    <md-button class="md-primary" :disabled="state === 'saved'" @click="handleClick">
        <md-progress-spinner
            v-if="state === 'saving'"
            :md-diameter="12"
            :md-stroke="2"
            class="md-white"
            md-mode="indeterminate"
        />
        <md-icon v-else-if="state === 'success'" class="success-icon">check</md-icon>
        <span class="md-body-2">{{ buttonText }}</span>
    </md-button>
</template>

<script>
export default {
    name: 'SaveButton',
    props: {
        state: {
            type: String,
            required: true,
            validator: (value) => ['save', 'saving', 'success', 'saved'].includes(value),
        },
    },
    emits: ['save'],
    computed: {
        buttonText() {
            const texts = {
                save: 'Save',
                saving: 'Saving',
                success: 'Saved',
                saved: 'Saved',
            };
            return texts[this.state];
        },
    },
    methods: {
        handleClick() {
            if (this.state === 'save') {
                this.$emit('save');
            }
        },
    },
};
</script>

<style scoped lang="scss">
.md-progress-spinner {
    margin-right: 0.5rem;
}

.success-icon {
    margin-right: 0.5rem;
    font-size: 16px !important;
}
</style>
