export default [
    {
        id: 'skills-intro-step',
        title: '<p class="md-title">${tours.skills.title}</p>',
        text: `
            <div>
                <p class="md-body-2">\${tours.skills.intro.p1}</p>
                <p class="md-caption">\${tours.skills.intro.p2}</p>
            </div>
        `,
        buttons: [
            {
                text: 'tours.skills.buttons.skipTour',
                action: 'cancel',
                secondary: true,
            },
            {
                text: 'tours.skills.buttons.startTour',
                action: 'next',
            },
        ],
    },
    {
        id: 'skills-list-step',
        attachTo: {
            element: '#skills-list',
            on: 'top',
        },
        text: `
            <div>
                <p class="md-body-2">\${tours.skills.skillsList.p1}</p>
                <p class="md-body-2">\${tours.skills.skillsList.p2}</p>
            </div>
        `,
        buttons: [
            {
                text: 'tours.skills.buttons.next',
                action: 'next',
            },
        ],
    },
    {
        id: 'skills-channels-step',
        attachTo: {
            element: '#channels-list',
            on: 'top',
        },
        text: `
            <div>
                <p class="md-body-2">\${tours.skills.channels.p1}</p>
                <p class="md-body-2">\${tours.skills.channels.p2}</p>
            </div>
        `,
    },
];
