<script>
import { mapGetters } from 'vuex';
import PricingSection from './Settings/Subscription/PricingCards/SubscriptionPricingSection.vue';
import SubscriptionStripePricingSection from './Settings/Subscription/PricingCards/SubscriptionStripePricingSection.vue';

export default {
    name: 'ThePaywallModal',
    components: {
        ShopifyPricingSection: PricingSection,
        StripePricingSection: SubscriptionStripePricingSection,
    },
    computed: {
        ...mapGetters('user', ['isShopifyBillingClient']),
    },
    methods: {
        closeModal() {
            this.$store.dispatch('modalStore/closeModal');
        },
    },
};
</script>

<template>
    <div class="paywall-modal">
        <div class="paywall-modal__header">
            <md-button @click="closeModal()" class="md-icon-button">
                <md-icon>close</md-icon>
            </md-button>
        </div>
        <md-content class="paywall-modal__content">
            <h2 class="paywall-modal__title md-display-1">Choose Your Plan</h2>
            <ShopifyPricingSection v-if="isShopifyBillingClient" />
            <StripePricingSection v-else />
        </md-content>
    </div>
</template>

<style lang="scss" scoped>
.paywall-modal {
    overflow-y: auto;

    &__header {
        display: flex;
        justify-content: flex-end;
        padding: 1rem 1rem 0 0;
    }

    &__content {
        padding: 0 1rem 1rem 1rem;

        :deep() {
            .subscription-pricing-section {
                .pricing-cards {
                    display: flex;
                    justify-content: center;
                    flex-wrap: nowrap;
                    gap: 1rem;

                    @media (max-width: 768px) {
                        flex-direction: column;
                        align-items: center;
                    }
                }
            }
        }
    }

    &__title {
        text-align: center;
        margin-bottom: 24px;
    }
}
</style>
