<template>
    <div class="timer-card">
        <div class="md-body-2">Send message after:</div>
        <md-field :class="{ 'md-invalid': isInvalid }">
            <md-input type="number" v-model.number="localTimer" required></md-input>
            <span class="md-suffix">minutes</span>
            <span class="md-error">Min 10 minutes</span>
        </md-field>
    </div>
</template>

<script>
export default {
    name: 'LixsaSkillAbandonCartTimer',
    props: {
        timer: {
            type: Number,
            required: true,
        },
        isInvalid: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            localTimer: this.timer,
        };
    },
    watch: {
        localTimer(newValue) {
            this.$emit('update:timer', newValue);
        },
        timer(newValue) {
            if (newValue !== this.localTimer) {
                this.localTimer = newValue;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.timer-card {
    display: flex;
    gap: 0.5rem;
    align-items: center;

    .md-field.md-invalid {
        margin-bottom: 8px;
    }

    .md-field {
        margin-bottom: 0;
        width: 120px;

        .md-input {
            width: 100%;
            text-align: center;
        }

        .md-suffix {
            margin-left: 8px;
        }
    }
}
</style>
