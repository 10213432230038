<template>
    <div v-if="selectedAsset">
        <div class="details-bar" @click="toggleShowInfoCustomer">
            <svg-icon class="back-icon" name="arrowBackAlt" />
            <span class="back-text">Back to customer details</span>
        </div>

        <div class="section-header">
            <div class="header-content">
                <span class="section-title">Order Details</span>
                <span class="order-id">{{ selectedAsset.name }}</span>
            </div>
            <a :href="shopifyURL" target="_blank" class="external-link">
                <span>View in Shopify</span>
                <svg-icon name="open-in-new" class="external-icon" />
            </a>
        </div>

        <md-divider class="section-divider"></md-divider>

        <CustomerAssetsContactInformation
            :selectedAsset="selectedAsset"
            :currentUserName="currentUserName"
            :currentUserEmail="currentUserEmail"
            :currentUserPhone="currentUserPhone"
        ></CustomerAssetsContactInformation>

        <md-divider class="section-divider"></md-divider>

        <customer-assets-summary :selected-asset="selectedAsset"></customer-assets-summary>

        <md-divider class="section-divider"></md-divider>

        <div class="details-container">
            <div class="card-details-body">
                <!-- Created At -->
                <div v-if="selectedAsset.created_at" class="info-item">
                    <svg-icon class="info-icon" name="calendar" />
                    <div class="info-content">
                        <span class="info-label">Created at</span>
                        <span class="info-value">{{ friendlyCreatedAt }}</span>
                    </div>
                </div>

                <!-- Status -->
                <div class="info-item">
                    <svg-icon class="info-icon" name="info" />
                    <div class="info-content">
                        <span class="info-label">Status</span>
                        <div class="status-badges">
                            <span :class="['status-badge', `badge-${selectedAsset.financial_status.toLowerCase()}`]">
                                {{ formatFinancialStatus }}
                            </span>
                            <span :class="['status-badge', `badge-${selectedAsset.fulfillment_status.toLowerCase()}`]">
                                {{ selectedAsset.fulfillment_status.toLocaleUpperCase() }}
                            </span>
                        </div>
                    </div>
                </div>

                <!-- Fulfillment and Shipping -->
                <div class="info-item" v-if="selectedAsset.fulfillments && selectedAsset.fulfillments.length > 0">
                    <svg-icon class="info-icon" name="local_shipping" />
                    <div class="info-content">
                        <span class="info-label">Fulfillment & Shipping</span>
                        <div class="shipping-details">
                            <div class="shipping-status">
                                <span class="info-sublabel">Shipment Status:</span>
                                <span
                                    v-if="selectedAsset.fulfillments[0].shipment_status"
                                    :class="['status-badge', `badge-${selectedAsset.fulfillments[0].shipment_status}`]"
                                >
                                    {{
                                        selectedAsset.fulfillments[0].shipment_status
                                            .replaceAll('_', ' ')
                                            .toLocaleUpperCase()
                                    }}
                                </span>
                                <span v-else class="status-badge badge-pending"> PENDING </span>
                            </div>
                            <div v-if="selectedAsset.fulfillments[0].tracking_company" class="shipping-item">
                                <span class="info-sublabel">Carrier:</span>
                                <span class="info-value">{{ selectedAsset.fulfillments[0].tracking_company }}</span>
                            </div>
                            <div v-if="selectedAsset.fulfillments[0].tracking_number" class="shipping-item">
                                <span class="info-sublabel">Tracking Number:</span>
                                <div class="tracking-info">
                                    <span class="info-value">{{ selectedAsset.fulfillments[0].tracking_number }}</span>
                                    <a
                                        v-if="selectedAsset.fulfillments[0].tracking_url"
                                        :href="selectedAsset.fulfillments[0].tracking_url"
                                        target="_blank"
                                        class="tracking-link"
                                    >
                                        Track Package
                                        <svg-icon name="open-in-new" class="tracking-icon" />
                                    </a>
                                </div>
                            </div>
                            <div
                                v-if="selectedAsset.shipping_lines && selectedAsset.shipping_lines.length > 0"
                                class="shipping-item"
                            >
                                <span class="info-sublabel">Shipping Method:</span>
                                <span class="info-value">{{ selectedAsset.shipping_lines[0].code }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Notes -->
                <div class="info-item" v-if="selectedAsset.note">
                    <svg-icon class="info-icon" name="note" />
                    <div class="info-content">
                        <span class="info-label">Notes</span>
                        <span class="info-value">{{ selectedAsset.note }}</span>
                    </div>
                </div>

                <!-- Order Status Link -->
                <div class="info-item" v-if="selectedAsset.order_status_url">
                    <svg-icon class="info-icon" name="link" />
                    <a :href="selectedAsset.order_status_url" target="_blank" class="status-link">
                        See order status details
                        <svg-icon name="open-in-new" class="details-icon-measures" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CustomerAssetsContactInformation from '@/lib/CustomerDetails/CustomerCardBody/CustomerAssets/CustomerAssetsDetails/CustomerAssetsContactInformation/CustomerAssetsContactInformation.vue';
import CustomerAssetsSummary from '@/lib/CustomerDetails/CustomerCardBody/CustomerAssets/CustomerAssetsSummary/CustomerAssetsSummary.vue';
import SvgIcon from '@/components/Chat/SvgIcon/SvgIcon.vue';
import { getCurrencySymbol, formatNumberWithDecimals, getBadgeClass } from '@/utils/common.js';

export default {
    name: 'CustomerAssetsDetailsEcommerceOrder',
    components: {
        CustomerAssetsContactInformation,
        CustomerAssetsSummary,
        SvgIcon,
    },
    data() {
        return {
            urlOrderStatus: null,
            svgIcon: '',
            copiedFields: {
                email: false,
                phone: false,
            },
        };
    },
    props: {
        selectedAsset: { type: [Object, Array] },
        currentUserId: { type: [String, Number], required: true },
        currentUserName: { type: String, required: true },
        currentUserEmail: { type: String, required: true },
        currentUserPhone: { type: String, required: true },
        showDetailsOrder: { type: [Boolean], required: true },
    },
    mounted() {
        this.svgIcon = this.getCopyIcon();
    },
    methods: {
        getCurrencySymbol,
        formatNumberWithDecimals,
        getBadgeClass,
        toggleShowDetailsOrder() {
            this.$emit('toggle-details-order');
        },
        toggleShowInfoCustomer() {
            this.$emit('toggle-info-customer');
        },
        getCopyIcon() {
            return `
<svg xmlns="http://www.w3.org/2000/svg" height="17px" viewBox="0 -960 960 960" width="24px"><path d="M360-240q-33 0-56.5-23.5T280-320v-480q0-33 23.5-56.5T360-880h360q33 0 56.5 23.5T800-800v480q0 33-23.5 56.5T720-240H360Zm0-80h360v-480H360v480ZM200-80q-33 0-56.5-23.5T120-160v-560h80v560h440v80H200Zm160-240v-480 480Z"/></svg>`;
        },
        copyCodeToClipboard(text, field) {
            navigator.clipboard
                .writeText(text)
                .then(() => {
                    this.copiedFields[field] = true;
                    setTimeout(() => {
                        this.copiedFields[field] = false;
                    }, 1000);
                })
                .catch((err) => {
                    console.error('Could not copy text: ', err);
                });
        },
    },
    computed: {
        shopName() {
            return this.$store.getters['user/getShopName'];
        },
        shopifyURL() {
            return `https://admin.shopify.com/store/${this.shopName}/orders/${this.selectedAsset.external_asset_id}`;
        },
        friendlyCreatedAt() {
            const date = new Date(this.selectedAsset.created_at * 1000);
            const options = {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false, // Use 24-hour format
            };
            const formatter = new Intl.DateTimeFormat('default', options);
            return formatter.format(date);
        },
        formatFinancialStatus() {
            const status = this.selectedAsset.financial_status;
            if (status === 'pending') {
                return 'PENDING PAYMENT';
            }
            return status.split('_').join(' ').toUpperCase();
        },
    },
    created() {
        this.urlOrderStatus = process.env.VUE_APP_URL_STATUS_ORDER;
    },
};
</script>
