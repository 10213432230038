import axios from 'axios';
import store from '@/store';
import { v4 as uuidv4 } from 'uuid';

const baseURL = process.env.VUE_APP_API_URL;
const getAuthHeader = (token) => {
    const devMode = store.state.user.devMode;

    if (devMode) {
        return { 'X-API-KEY': `${token}` };
    } else {
        return { Authorization: `Bearer ${token}` };
    }
};

const apiRequest = async (method, url, data = null, params = {}) => {
    try {
        return store.dispatch('user/apiCall', async (token) => {
            const config = {
                method: method,
                url: `${baseURL}/${url}`,
                headers: getAuthHeader(token),
                params: { ...params },
                data: data,
            };
            return await axios(config);
        });
    } catch (error) {
        throw error;
    }
};

const whatsappService = {
    setupWhatsapp(code, wabaId, phoneNumberId) {
        return apiRequest('post', `accounts/${store.state.user.user.account_id}/communication-channels`, {
            uuid: uuidv4(),
            account_id: store.state.user.user.account_id,
            skill_id: '8c0f230d-cca0-4335-a359-d3fd4ab284cc',
            channel_type: 'WHATSAPP',
            config_values: {
                code,
                waba_id: wabaId,
                phone_number_id: phoneNumberId,
            },
        });
    },
};

export default whatsappService;
