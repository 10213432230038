<template>
    <md-card class="prestashop-whatsapp-cta">
        <md-card-content>
            <div class="md-title"><strong>Connect WhatsApp to Lixsa</strong></div>
            <p class="md-body-1">
                Enhance your PrestaShop store by connecting WhatsApp as a communication channel for Lixsa. This
                integration allows for real-time customer support and improved shopping experiences. Connect now to
                start leveraging these powerful features!
            </p>
            <md-button class="md-raised md-primary" @click="connectWhatsApp"> Connect WhatsApp </md-button>
        </md-card-content>
    </md-card>
</template>

<script>
export default {
    name: 'MainAppsPrestashopConnectWhatsAppCta',
    methods: {
        connectWhatsApp() {
            // Navigate to WhatsApp skill details page
            this.$router.push('/skills/details/8c0f230d-cca0-4335-a359-d3fd4ab284cc');
        },
    },
};
</script>

<style scoped>
.prestashop-whatsapp-cta {
    background-color: #f5f5f5;
    margin-top: 20px;
}
</style>
