<template>
    <div class="language-selector">
        <md-field class="custom-field" :class="{ 'md-loading': loading }">
            <label>{{ $t('common.languageSelector.language') }}</label>
            <md-select v-model="selectedLanguageCode" name="language" @md-selected="handleLanguageChange">
                <md-option v-for="lang in availableLanguages" :key="lang.code" :value="lang.code">
                    {{ lang.name }} ({{ lang.nativeName }})
                </md-option>
            </md-select>
            <span class="md-helper-text">{{ $t('common.languageSelector.selectLanguage') }}</span>
        </md-field>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LanguageService from '@/services/_common/languageService';

export default {
    name: 'LanguageSelector',

    props: {
        mode: {
            type: String,
            default: 'global',
            validator: (value) => ['global', 'custom'].includes(value),
        },
        customLanguages: {
            type: Array,
            default: () => [],
            validator: (value) => Array.isArray(value) && value.every((code) => typeof code === 'string'),
        },
        value: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            loading: true,
            availableLanguages: [],
            selectedLanguageCode: this.value,
        };
    },

    computed: {
        ...mapGetters('user', ['currentLanguage']),
    },

    watch: {
        value(newValue) {
            this.selectedLanguageCode = newValue;
        },
        async mode() {
            await this.loadLanguages();
        },
        async customLanguages() {
            if (this.mode === 'custom') {
                await this.loadLanguages();
            }
        },
    },

    async created() {
        await this.loadLanguages();
    },

    methods: {
        async loadLanguages() {
            this.loading = true;
            try {
                if (this.mode === 'global') {
                    this.availableLanguages = await LanguageService.getAllLanguages();
                } else {
                    this.availableLanguages = await LanguageService.getLanguagesByCode(this.customLanguages);
                }
            } catch (error) {
                console.error('Error loading languages:', error);
                this.availableLanguages = [];
            } finally {
                this.loading = false;
            }
        },

        handleLanguageChange(code) {
            this.$emit('input', code);
            this.$emit('change', code);
        },
    },
};
</script>

<style lang="scss" scoped>
.language-selector {
    width: 100%;
}

.custom-field {
    margin-top: 20px;
    margin-bottom: 20px;
}
</style>
