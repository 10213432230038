<script>
import CodeSnippet from '@/components/codeSnippet.vue';
export default {
    name: 'MainAppsPrestashopAboutCard',
    components: { CodeSnippet },
};
</script>

<template>
    <md-card>
        <md-card-content class="skill-about-card">
            <div class="md-title">
                <strong> What can the PrestaShop Skill do for your business? </strong>
            </div>

            <div class="md-body-1">
                The PrestaShop skill allows you to connect your PrestaShop store to Lixsa. This will enable you to
                automate your customer service and sales processes, and provide a seamless experience for your
                customers.
            </div>

            <div class="md-subheading">
                <strong> Lixsa knows about your catalog </strong>
            </div>
            <div class="md-body-1">
                With the catalog Skill integration, Lixsa can access and interact with your PrestaShop product catalog
                and customer orders information. This capability enables more personalized and efficient customer
                service by allowing Lixsa to retrieve product details, order histories, track current order statuses,
                and provide updates directly to customers inquiring about their purchases.
            </div>

            <div class="md-subheading">
                <strong> Lixsa is ready to answer about your shop policies </strong>
            </div>
            <div class="md-body-1">
                Your PrestaShop store policies will be available for Lixsa to answer any questions your customers may
                have about your store. This includes information about shipping, returns, payment methods, and other
                policies you have configured in your PrestaShop store.
            </div>
        </md-card-content>
    </md-card>
</template>

<style scoped lang="scss">
.skill-about-card {
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
</style>
