import BaseDto from '../../_common/BaseDto';

class CustomerResponseDataBuilder {
    constructor() {
        this.uuid = undefined;
        this.externalId = undefined;
        this.fullName = undefined;
        this.firstName = undefined;
        this.lastName = undefined;
        this.email = undefined;
        this.phone = undefined;
        this.phoneWithoutPrefix = undefined;
        this.account = undefined;
        this.note = undefined;
        this.platformsInfo = undefined;
        this.conversations = undefined;
        this.marketingConsent = undefined;
        this.marketingConsentUpdated = undefined;
        this.businessType = undefined;
        this.tags = undefined;
        this.dateCreated = undefined;
        this.dateUpdated = undefined;
        this.channelType = undefined;
    }

    withUuid(uuid) {
        this.uuid = uuid;
        return this;
    }

    withExternalId(externalId) {
        this.externalId = externalId;
        return this;
    }

    withFullName(fullName) {
        this.fullName = fullName;
        return this;
    }

    withFirstName(firstName) {
        this.firstName = firstName;
        return this;
    }

    withLastName(lastName) {
        this.lastName = lastName;
        return this;
    }

    withEmail(email) {
        this.email = email;
        return this;
    }

    withPhone(phone) {
        this.phone = phone;
        return this;
    }

    withPhoneWithoutPrefix(phoneWithoutPrefix) {
        this.phoneWithoutPrefix = phoneWithoutPrefix;
        return this;
    }

    withAccount(account) {
        this.account = account;
        return this;
    }

    withNote(note) {
        this.note = note;
        return this;
    }

    withPlatformsInfo(platformsInfo) {
        this.platformsInfo = platformsInfo;
        return this;
    }

    withConversations(conversations) {
        this.conversations = conversations;
        return this;
    }

    withMarketingConsent(marketingConsent) {
        this.marketingConsent = marketingConsent;
        return this;
    }

    withMarketingConsentUpdated(marketingConsentUpdated) {
        this.marketingConsentUpdated = marketingConsentUpdated;
        return this;
    }

    withBusinessType(businessType) {
        this.businessType = businessType;
        return this;
    }

    withTags(tags) {
        this.tags = tags;
        return this;
    }

    withDateCreated(dateCreated) {
        this.dateCreated = dateCreated;
        return this;
    }

    withDateUpdated(dateUpdated) {
        this.dateUpdated = dateUpdated;
        return this;
    }

    withChannelType(channelType) {
        this.channelType = channelType;
        return this;
    }

    build() {
        return new CustomerResponseData(this);
    }
}

class CustomerResponseData extends BaseDto {
    constructor(builder) {
        super();
        this.uuid = builder.uuid;
        this.externalId = builder.externalId;
        this.fullName = builder.fullName;
        this.firstName = builder.firstName;
        this.lastName = builder.lastName;
        this.email = builder.email;
        this.phone = builder.phone;
        this.phoneWithoutPrefix = builder.phoneWithoutPrefix;
        this.account = builder.account;
        this.note = builder.note;
        this.platformsInfo = builder.platformsInfo;
        this.conversations = builder.conversations;
        this.marketingConsent = builder.marketingConsent;
        this.marketingConsentUpdated = builder.marketingConsentUpdated;
        this.businessType = builder.businessType;
        this.tags = builder.tags;
        this.dateCreated = builder.dateCreated;
        this.dateUpdated = builder.dateUpdated;
        this.channelType = builder.channelType;
    }

    static get Builder() {
        return CustomerResponseDataBuilder;
    }

    static fromObject(obj) {
        return new CustomerResponseDataBuilder()
            .withUuid(obj.uuid)
            .withExternalId(obj.external_id)
            .withFullName(obj.full_name)
            .withFirstName(obj.first_name)
            .withLastName(obj.last_name)
            .withEmail(obj.email)
            .withPhone(obj.phone)
            .withPhoneWithoutPrefix(obj.phone_without_prefix)
            .withAccount(obj.account)
            .withNote(obj.note)
            .withPlatformsInfo(obj.platforms_info)
            .withConversations(obj.conversations)
            .withMarketingConsent(obj.marketing_consent)
            .withMarketingConsentUpdated(obj.marketing_consent_updated)
            .withBusinessType(obj.business_type)
            .withTags(obj.tags)
            .withDateCreated(obj.date_created)
            .withDateUpdated(obj.date_updated)
            .withChannelType(obj.channel_type)
            .build();
    }
}

export default CustomerResponseData;
