<script>
import CodeSnippet from '@/components/codeSnippet.vue';
import ChannelsWhatsappEditProfileBanner from '@/components/Skills/Channels/ChannelsWhatsappEditProfileBanner.vue';
import shopifyService from '../../../services/shopify/shopifyService';
import { mapGetters } from 'vuex';
import { get } from 'jquery';
export default {
    name: 'ChannelsWhatsappAboutCard',
    props: ['communicationChannel'],
    data() {
        return {
            selectedThemeId: 'current',
            shopifyThemes: ['current'],
        };
    },
    components: { ChannelsWhatsappEditProfileBanner, CodeSnippet },
    computed: {
        ...mapGetters('agentSettings', ['getSkillByInternalName']),

        getWhatsappSkill() {
            return this.getSkillByInternalName('whatsapp');
        },

        whatsappScript() {
            const phoneNumber = this.getWhatsappSkill?.config_values
                ? this.getWhatsappSkill.config_values.phoneNumber ||
                  this.getWhatsappSkill.config_values.phone_number ||
                  '{Your-Phone-Number}'
                : '{Your-Phone-Number}';
            const defaultMessage = '{Your-Default-Message}';
            return `&lt;script src="https://wsp.lixsa.ai/whatsapp-widget.js" phone="${phoneNumber}" message="${defaultMessage}"&gt;&lt;/script&gt;`;
        },
    },
    methods: {
        addWhatsappToShopify() {
            const shopifySKillId = 'd74f8439-bda9-420a-8b20-cbb80e5f7510';
            const activeSkills = this.$store.getters['agentSettings/agentSettings'].skills;
            const shopifySkill = activeSkills.find((skill) => skill.skill_id === shopifySKillId);
            const myShopifyDomain = shopifySkill.config_values?.shop;
            const chatBubbleBlockId = 'fd9562b5-04d3-42f1-8257-0bdb4df0e3a3/chat-bubble';

            window.open(
                `https://${myShopifyDomain}/admin/themes/${this.selectedThemeId}/editor?template=index&addAppBlockId=${chatBubbleBlockId}&target=sectionGroup:header`,
            );
        },
        hasShopifySkill() {
            const shopifySKillId = 'd74f8439-bda9-420a-8b20-cbb80e5f7510';

            const activeSkills = this.$store.getters['agentSettings/agentSettings'].skills;

            const hasShopifySkill = activeSkills.some((skill) => skill.skill_id === shopifySKillId);

            return hasShopifySkill;
        },
        async getShopifyThemes() {
            try {
                const response = await shopifyService.getShopThemes();

                this.shopifyThemes = response.data;
                this.selectedThemeId = this.shopifyThemes.find((theme) => theme.role === 'main').id;

                this.shopifyThemes.sort((a, b) => {
                    if (a.role === 'main') return -1;
                    if (b.role === 'main') return 1;
                    return 0;
                });
            } catch (error) {
                console.error('Error fetching Shopify themes:', error);
            }
        },
    },
    mounted() {
        if (this.hasShopifySkill()) {
            this.getShopifyThemes();
        }
    },
};
</script>

<template>
    <div>
        <md-card>
            <md-card-content class="channels-whatsapp-about-card">
                <div class="whatsapp-how-to-setup_container">
                    <div class="text-section">
                        <div class="md-title"><strong> How to Activate WhatsApp on Your Website </strong></div>
                        <div class="md-body-1 text-section_info">
                            To enable WhatsApp on your website, a WhatsApp Business Cloud account will be created. All
                            messages between your customers and your Lixsa account will appear in your Lixsa Inbox.
                            These messages will no longer be available in your personal WhatsApp app or your traditional
                            WhatsApp Business account.
                        </div>

                        <div class="md-subheading text-section_info">
                            <strong> Requirements to Create a WhatsApp Business Cloud Account: </strong>
                        </div>
                        <div class="md-body-1 text-section_info">
                            <ul>
                                <li>A verified Facebook Business Manager account.</li>
                                <li>An active phone number (landline or mobile).</li>
                            </ul>
                        </div>

                        <div class="md-body-1 text-section_info">
                            You can check out our video for a step-by-step guide on how to set up WhatsApp.
                        </div>
                    </div>
                    <div class="video-section">
                        <iframe
                            width="860"
                            height="600"
                            src="https://www.youtube.com/embed/PmOIPG0Q7vw?si=lfSmz2Nsi6R_ZiOP"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerpolicy="strict-origin-when-cross-origin"
                            allowfullscreen
                        ></iframe>
                    </div>
                </div>
            </md-card-content>
        </md-card>
    </div>
</template>

<style scoped lang="scss">
.channels-whatsapp-about-card {
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 1.8rem;
}

.whatsapp-how-to-setup_container {
    display: flex;
    justify-content: space-between;
}

.text-section {
    flex: 1;
    margin-right: 20px;
}

.text-section_info {
    margin-top: 1.8rem;
}

.video-section {
    flex: 1;
    max-width: 600px;
}

iframe {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 959px) {
    .whatsapp-how-to-setup_container {
        flex-direction: column;
    }

    .text-section {
        margin-right: 0;
    }
}
</style>
