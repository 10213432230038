<script>
import { mapGetters } from 'vuex';

export default {
    name: 'ChannelsWhatsappEditProfileBanner',
    props: ['communicationChannel'],
    computed: {
        ...mapGetters('agentSettings', ['getSkillByInternalName']),

        getWhatsappSkill() {
            return this.getSkillByInternalName('whatsapp');
        },
    },
    methods: {
        openWhatsappBusiness() {
            const phoneNumber = this.getWhatsappSkill.config_values
                ? this.getWhatsappSkill.config_values.phoneNumber ||
                  this.getWhatsappSkill.config_values.phone_number ||
                  '000000000'
                : '000000000';
            const url = `https://business.facebook.com/wa/manage/phone-numbers/?waba_id=${this.communicationChannel.business_account_id}&childRoute=PHONE_PROFILE%2FPROFILE&phone_number=${phoneNumber} `;
            window.open(url, '_blank').focus;
        },
    },
};
</script>

<template>
    <md-card class="channels-whatsapp-edit-profile-banner">
        <md-card-content class="channels-whatsapp-edit-profile-banner__content">
            <div class="channels-whatsapp-edit-profile-banner__text">
                <div class="md-title">
                    <strong> Edit Your WhatsApp Profile </strong>
                </div>
                <div class="md-body-1">
                    Edit your WhatsApp profile to improve how it appears in your client's contact list. Update the name,
                    add your logo, description, and website. Simply click on 'Edit Profile' and access your WhatsApp
                    account directly through Meta.
                </div>
                <md-button @click="openWhatsappBusiness" class="md-primary">Edit Profile</md-button>
            </div>
            <div class="channels-whatsapp-edit-profile-banner__image">
                <img src="@/assets/img/perfil-whatsapp_3.png" alt="Skill Discovery Banner" />
            </div>
        </md-card-content>
    </md-card>
</template>

<style scoped lang="scss">
.channels-whatsapp-edit-profile-banner {
    //overflow: hidden;
    background-color: #f2f2f2;

    &__content {
        display: flex;
        align-items: center;
    }

    &__text {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        gap: 1rem;
        padding-right: 30rem;
        padding-top: 20px;
        padding-bottom: 20px;

        @media (max-width: 959px) {
            padding-right: 0;
        }
    }

    &__image {
        width: 270px;
        position: absolute;
        right: 5rem;

        @media (max-width: 959px) {
            display: none;
        }
    }
}
</style>
