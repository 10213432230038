import axios from 'axios';
import Firebase from '../../Firebase';
import { i18n } from '@/i18n';
import userService from '@/services/account/userService';
import { signInWithPopup } from '@/services/auth/signInWithPopupService';
import analyticsService from '@/services/analytics/analyticsService';

const getAuthHeader = (token, devMode) => {
    if (devMode) {
        return { 'X-API-KEY': token };
    } else {
        return { Authorization: `Bearer ${token}` };
    }
};

/**
 * @typedef {Object} ActiveNotifications
 * @property {boolean} conversationSentimentChanged - Whether the conversation sentiment changed
 * @property {boolean} conversationWindowCreated - Whether the conversation window was created
 * @property {boolean} conversationAgentChanged - Whether the conversation agent changed
 */

/**
 * @typedef {Object} NotificationsConfig
 * @property {string[]} pushNotificationIds - The user's push notification IDs
 * @property {ActiveNotifications} activeNotifications - The user's active notifications
 */

/**
 * @typedef {Object} User
 * @property {string} uuid - The user's UUID
 * @property {string} external_id - The user's external ID
 * @property {string} email - The user's email
 * @property {string} username - The user's username
 * @property {string} title - The user's title
 * @property {string} account_id - The user's account ID
 * @property {string} status - The user's status (ENABLED)
 * @property {string} language - The user's language
 * @property {string} contact_phone - The user's contact phone
 * @property {string} description - The user's description
 * @property {string} permission - The user's permission (ADMIN)
 * @property {number} date_created - The user's date created
 * @property {NotificationsConfig} notifications_config - The user's notifications config
 * @property {boolean} invited - Whether the user is invited
 */

/**
 * @typedef {Object} Account
 * @property {string} uuid - The account's UUID
 * @property {string} email - The account's email
 * @property {string} contact_name - The account's contact name
 * @property {string} gateway_customer_id - The account's gateway customer ID
 * @property {string} current_plan - The account's current plan
 * @property {string} customer_support_email - The account's customer support email
 * @property {string} short_business_description - The account's short business description
 * @property {string} api_url - The account's API URL
 * @property {string} business_type - The account's business type (e.g., 'ECOMMERCE')
 * @property {string} shop_public_domain - The account's shop public domain
 * @property {string} shop_name - The account's shop name
 * @property {string} shop_phone - The account's shop phone
 * @property {string} source_platform - The account's source platform (e.g., 'STRIPE')
 * @property {string} referred_by - Who referred the account
 * @property {number} date_created - The account's creation date timestamp
 */

/**
 * @typedef {Object} UserState
 * @property {string} idToken - The user's ID token
 * @property {string} userId - The user's ID
 * @property {string} refreshToken - The user's refresh token
 * @property {string} userProfilePicture - The user's profile picture
 * @property {boolean} copilotEnabled - Whether the copilot is enabled
 * @property {boolean} devMode - Whether the development mode is enabled
 * @property {string} defaultBrowserLanguage - The user's default browser language
 * @property {User} user - The user object
 * @property {Account} account - The account object
 */

/**
 * @typedef {Object} vuexContext
 * @property {Function} dispatch - The dispatch function
 * @property {Function} commit - The commit function
 * @property {UserState} state - The state object
 */

/**
 * @type {UserState} The initial state of the user module
 */
const state = () => {
    const browserLang = (navigator.language || navigator.userLanguage).toLowerCase();
    const defaultLang = browserLang.startsWith('es') ? 'es' : 'en';
    const savedLang = localStorage.getItem('defaultBrowserLanguage');

    return {
        idToken: localStorage.getItem('token') || null,
        userId: localStorage.getItem('userId') || null,
        refreshToken: localStorage.getItem('refreshToken') || null,
        userProfilePicture: localStorage.getItem('userProfilePicture') || null,
        copilotEnabled: String(localStorage.getItem('copilotEnabled')).toLowerCase() === 'true' || false,
        devMode: String(localStorage.getItem('devMode')).toLowerCase() === 'true' || false,
        defaultBrowserLanguage: savedLang || defaultLang,
        user: JSON.parse(localStorage.getItem('user')) || {
            uuid: null,
            external_id: null,
            email: null,
            username: null,
            account_id: null,
            status: null,
            permission: null,
            date_created: null,
            notifications_config: {
                pushNotificationIds: [],
                activeNotifications: {
                    conversation: {
                        conversationSentimentChanged: false,
                        conversationWindowCreated: false,
                        conversationAgentChanged: false,
                    },
                },
            },
            invited: false,
        },
        account: JSON.parse(localStorage.getItem('account')) || {
            uuid: null,
            email: null,
            contact_name: null,
            status: null,
            current_plan: null,
            customer_support_email: null,
            api_url: null,
            onboarding_current_state: null,
            ecommerce_platform_type: null,
            shop_domain: null,
            shop_public_domain: null,
            shop_name: null,
            shop_phone: null,
            date_created: 0,
            next_step_instructions: null,
            business_type: null,
            source_platform: null,
            referred_by: null,
        },
    };
};

// getters
const getters = {
    user(state) {
        return state.user;
    },

    account(state) {
        return state.account;
    },

    idToken(state) {
        return state.idToken;
    },

    ifAuthenticated(state) {
        return state.idToken !== null;
    },

    isCopilotEnabled(state) {
        return state.copilotEnabled;
    },

    getShopName(state) {
        if (state.account.shop_domain) {
            let parts = state.account.shop_domain.split('.');
            return parts[0];
        }
        return state.account.contact_name;
    },

    isDevMode(state) {
        return state.devMode;
    },

    canAccess: (state) => (permissions) => {
        const userPermissions = state.user.permission;
        return permissions.includes(userPermissions);
    },

    isShopifyBillingClient: (state) => {
        return state.account.source_platform === 'SHOPIFY_BILLING';
    },

    toltReferral: (state) => {
        return state.account.referred_by || null;
    },

    currentLanguage: (state) => state.user.language || state.defaultBrowserLanguage,

    /**
     * Check if the user is a walkonlivos account
     * @param {UserState} state - The state object
     * @returns {boolean} Whether the user is a walkonlivos account
     */
    isWalkonLivosAccount: (state) => {
        if (!state.user.email) {
            return false;
        }
        const email = state.user.email;
        const domain = email.substring(email.lastIndexOf('@') + 1);
        return domain === 'walkonlivos.com' || domain === 'lixsa.ai';
    },
};

// actions
const actions = {
    async actionSignUpWithPopup({ commit, state }, queryParams) {
        try {
            const response = await signInWithPopup('google', {
                queryParams,
                currentLanguage: state.user.language || state.defaultBrowserLanguage,
            });

            // Store the tokens and user data
            localStorage.setItem('token', response.userData.idToken);
            localStorage.setItem('userId', response.userData.localId);
            localStorage.setItem('refreshToken', response.userData.refreshToken);

            if (response.userData.photoUrl) {
                localStorage.setItem('userProfilePicture', response.userData.photoUrl);
                commit('SET_PROFILE_PICTURE', response.userData.photoUrl);
            }

            commit('authUser', {
                token: response.userData.idToken,
                userId: response.userData.localId,
                refreshToken: response.userData.refreshToken,
            });

            return response;
        } catch (error) {
            throw error;
        }
    },

    async actionSignUpWithFacebook({ commit, state }, queryParams) {
        try {
            const response = await signInWithPopup('facebook', {
                queryParams,
                currentLanguage: state.user.language || state.defaultBrowserLanguage,
            });

            // Store the tokens and user data
            localStorage.setItem('token', response.userData.idToken);
            localStorage.setItem('userId', response.userData.localId);
            localStorage.setItem('refreshToken', response.userData.refreshToken);

            if (response.userData.photoUrl) {
                localStorage.setItem('userProfilePicture', response.userData.photoUrl);
                commit('SET_PROFILE_PICTURE', response.userData.photoUrl);
            }

            commit('authUser', {
                token: response.userData.idToken,
                userId: response.userData.localId,
                refreshToken: response.userData.refreshToken,
            });

            return response;
        } catch (error) {
            throw error;
        }
    },

    async actionSignup({ commit }, authData) {
        try {
            const res = await axios.post(
                `https://www.googleapis.com/identitytoolkit/v3/relyingparty/signupNewUser?key=${process.env.VUE_APP_FIREBASE}`,
                {
                    email: authData.email,
                    password: authData.password,
                    returnSecureToken: true,
                },
            );
            localStorage.setItem('token', res.data.idToken);
            localStorage.setItem('userId', res.data.localId);
            localStorage.setItem('refreshToken', res.data.refreshToken);
            commit('authUser', {
                token: res.data.idToken,
                userId: res.data.localId,
                refreshToken: res.data.refreshToken,
            });
            return Promise.resolve(res.data);
        } catch (err) {
            throw err;
        }
    },

    async actionLogin({ commit }, authData) {
        try {
            const res = await axios.post(
                `https://www.googleapis.com/identitytoolkit/v3/relyingparty/verifyPassword?key=${process.env.VUE_APP_FIREBASE}`,
                {
                    email: authData.email,
                    password: authData.password,
                    returnSecureToken: true,
                },
            );
            localStorage.setItem('token', res.data.idToken);
            localStorage.setItem('userId', res.data.localId);
            localStorage.setItem('refreshToken', res.data.refreshToken);
            if (res.data.profilePicture) {
                localStorage.setItem('userProfilePicture', res.data.profilePicture);
                commit('SET_PROFILE_PICTURE', res.data.profilePicture);
            }
            commit('authUser', {
                token: res.data.idToken,
                userId: res.data.localId,
                refreshToken: res.data.refreshToken,
            });
            return Promise.resolve(res.data);
        } catch (err) {
            throw err;
        }
    },

    /**
     * Get the user's profile
     * @param {vuexContext} context - The Vuex context object
     * @param {string} [customUserId] - The user's ID to fetch (optional)
     * @returns {Promise<Object>} The user's profile data
     */
    async actionGetUser({ dispatch, commit, state }, customUserId = null) {
        const newUserId = customUserId ? customUserId : state.userId;

        try {
            const responseStatus = await dispatch('apiCall', async (token) => {
                const res = await axios.get(`${process.env.VUE_APP_API_URL}/users/${newUserId}`, {
                    headers: getAuthHeader(token, state.devMode),
                });
                return res;
            });
            const oldUser = JSON.parse(localStorage.getItem('user'));
            let newUser = responseStatus.data;
            if (state.devMode) {
                newUser.account_id = oldUser.account_id;
            }
            localStorage.setItem('user', JSON.stringify(newUser));
            commit('SET_USER', newUser);

            dispatch('setDefaultBrowserLanguage', responseStatus.data.language);
            return responseStatus;
        } catch (err) {
            console.error(err);
            throw err;
        }
    },

    logout({ dispatch, commit }) {
        Firebase.SignOut()
            .then(() => {
                // Limpiar el estado de Vuex
                commit('clearAuth');
                // Eliminar datos del localStorage
                localStorage.removeItem('token');
                localStorage.removeItem('userId');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('user');
                localStorage.removeItem('account');
                localStorage.removeItem('userProfilePicture');
                localStorage.removeItem('copilotEnabled');
                localStorage.removeItem('devMode');

                dispatch('agentSettings/clearAgentSettingsState', null, { root: true });
                dispatch('activeFilters/clearFilters', null, { root: true });
                dispatch('activeFilters/clearPresets', null, { root: true });

                // Clear membership module state.
                dispatch('membership/clearMembershipState', null, { root: true });
                dispatch('membership/clearStripeSubscriptionState', null, { root: true });

                // Clear tours module state
                dispatch('tours/clearToursState', null, { root: true });

                // Unsubscribe from all websocket topics
                dispatch('websocket/unsubscribeFromAll', null, { root: true });
            })
            .catch((error) => {
                // Manejar errores
                console.error('Error cerrando sesión en Firebase:', error);
            });
    },

    async refreshToken({ commit, state }) {
        try {
            const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
            const data = new URLSearchParams({
                grant_type: 'refresh_token',
                refresh_token: state.refreshToken,
            });
            const response = await axios.post(
                `https://securetoken.googleapis.com/v1/token?key=${process.env.VUE_APP_FIREBASE}`,
                data,
                { headers },
            );
            localStorage.setItem('token', response.data.idToken);
            commit('SET_TOKEN', response.data.id_token);
            return Promise.resolve(response.data.id_token);
        } catch (error) {
            console.error('Error refreshing token:', error.message);
            throw error;
        }
    },

    async apiCall({ dispatch, state }, apiFunction, cancelToken = null) {
        const options = cancelToken ? { cancelToken: cancelToken.token } : {};
        if (state.devMode) return await apiFunction(state.idToken, options);

        try {
            // Pasar el cancelToken como parte de las opciones si está disponible
            return await apiFunction(state.idToken, options);
        } catch (error) {
            console.log(error);
            if (axios.isCancel(error)) {
                // Manejar específicamente la cancelación de la petición
                console.log('Request canceled', error.message);
                throw error;
            } else if (error.response && (error.response.status === 401 || error.response.status === 0)) {
                // El token ha expirado o no es válido, intenta refrescarlo
                const newToken = await dispatch('refreshToken');
                // Pasar el cancelToken de nuevo si la primera petición fue cancelada
                return await apiFunction(newToken, options);
            } else {
                throw error;
            }
        }
    },

    // eslint-disable-next-line no-unused-vars
    async actionCreateAccount({ state }, formData, signupType = 'basic') {
        const {
            uuid,
            email,
            contact_name,
            user_id,
            source_platform,
            referredBy,
            language,
            contact_phone,
            profile_picture,
        } = formData;
        const data = {
            uuid,
            email,
            user_id,
            contact_name,
            source_platform,
            referred_by: referredBy,
            language,
            contact_phone,
            profile_picture,
        };
        try {
            await axios.post(`${process.env.VUE_APP_API_URL}/accounts`, data);
            analyticsService.trackEvent('account_created', {
                type: signupType,
                ...data,
            });
            return formData;
        } catch (err) {
            console.error('Error creating account:', err.message);
            throw err;
        }
    },

    async actionUpdateEcommerceSettings({ dispatch, state }, formData) {
        try {
            const { shop_domain, shop_access_token } = formData;
            const data = { shop_domain, shop_access_token };
            const responseStatus = await dispatch('apiCall', async (token) => {
                const response = await axios.post(
                    `${process.env.VUE_APP_API_URL}/accounts/${state.user.account_id}/update-ecommerce-settings`,
                    data,
                    {
                        headers: getAuthHeader(token, state.devMode),
                    },
                );
                return response.status;
            });
            return responseStatus;
        } catch (err) {
            throw err;
        }
    },

    async actionGetAccount({ dispatch, commit, state }) {
        try {
            return await dispatch('apiCall', async (token) => {
                const response = await axios.get(`${process.env.VUE_APP_API_URL}/accounts/${state.user.account_id}`, {
                    headers: getAuthHeader(token, state.devMode),
                });

                localStorage.setItem('account', JSON.stringify(response.data));
                commit('SET_ACCOUNT', response.data);
                return response;
            });
        } catch (err) {
            console.log('Error in getting account: ', err);
            throw err;
        }
    },

    async actionUpdateUser({ dispatch, state }, formData) {
        const { user_id, username, role, capabilities, availability, operational_region, contact_phone } = formData;
        const data = {
            user_id,
            username,
            account_id: state.user.account,
            permission: state.user.permission,
            role,
            capabilities,
            availability,
            operational_region,
            contact_phone,
        };
        try {
            return await dispatch('apiCall', async (token) => {
                const response = await axios.post(`${process.env.VUE_APP_API_URL}/users/${state.user.uuid}`, data, {
                    headers: getAuthHeader(token, state.devMode),
                });
                return response;
            });
        } catch (err) {
            throw err;
        }
    },

    async actionUpdateAccount({ dispatch, state }, formData) {
        // Initialize data with account_id which is always required
        const data = {
            account_id: state.account.uuid,
        };

        // Clean shop_phone from all spaces if present
        if (formData.shop_phone !== undefined && formData.shop_phone !== null) {
            formData.shop_phone = formData.shop_phone.replace(/\s+/g, '');
        }

        // Add only the fields that are provided in formData
        const fields = [
            'email',
            'contact_name',
            'customer_support_email',
            'shop_name',
            'shop_phone',
            'shop_domain',
            'shop_public_domain',
            'shop_currency',
            'short_business_description',
            'business_type',
            'source_platform',
        ];

        fields.forEach((field) => {
            if (formData[field] !== undefined && formData[field] !== null) {
                data[field] = formData[field];
            }
        });

        try {
            return await dispatch('apiCall', async (token) => {
                const response = await axios.post(
                    `${process.env.VUE_APP_API_URL}/accounts/${state.user.account_id}`,
                    data,
                    {
                        headers: getAuthHeader(token, state.devMode),
                    },
                );
                return response;
            });
        } catch (err) {
            throw err;
        }
    },

    enableCopilot({ commit }) {
        localStorage.setItem('copilotEnabled', true);
        commit('ENABLE_COPILOT');
    },

    disableCopilot({ commit }) {
        localStorage.setItem('copilotEnabled', false);
        commit('DISABLE_COPILOT');
    },

    setIdToken({ commit }, token) {
        localStorage.setItem('token', token); // Save the new token to localStorage
        commit('SET_TOKEN', token); // Commit the mutation to update the state
    },

    async toggleDevMode({ commit, dispatch, state }) {
        commit('SET_DEV_MODE', !state.devMode); // Toggle the current state
        if (!state.devMode) {
            await dispatch('refreshToken');
        }
    },

    async setDevMode({ commit, dispatch, state }, value) {
        commit('SET_DEV_MODE', value); // Set a specific value
        if (!state.devMode) {
            await dispatch('refreshToken');
        }
    },

    async updateUserAccountId({ commit, state }, newAccountId) {
        commit('SET_USER_ACCOUNT_ID', newAccountId);

        // Update the user object in the Vuex store
        const updatedUser = { ...state.user, account_id: newAccountId };

        // Serialize the updated user object and save it back to localStorage
        localStorage.setItem('user', JSON.stringify(updatedUser));
    },

    updateNotificationsConfig({ commit }, { pushNotificationIds, activeNotifications }) {
        commit('UPDATE_NOTIFICATIONS_CONFIG', { pushNotificationIds, activeNotifications });
    },

    async setLanguage({ commit, state }, lang) {
        // Only accept 'en' or 'es'
        if (!['en', 'es'].includes(lang)) {
            return;
        }

        const originalLang = state.defaultBrowserLanguage;
        const originalUser = state.user;

        try {
            // Update local storage and i18n first
            localStorage.setItem('defaultBrowserLanguage', lang);
            commit('SET_DEFAULT_BROWSER_LANGUAGE', lang);

            // Update user state and localStorage
            const updatedUser = { ...state.user, language: lang };
            localStorage.setItem('user', JSON.stringify(updatedUser));
            commit('SET_USER', updatedUser);

            i18n.setLocaleAndNotify(lang);

            // Call API to update user language
            await userService.editUser(state.user.uuid, { language: lang });
        } catch (error) {
            // Rollback all changes if API call fails
            localStorage.setItem('defaultBrowserLanguage', originalLang);
            commit('SET_DEFAULT_BROWSER_LANGUAGE', originalLang);

            localStorage.setItem('user', JSON.stringify(originalUser));
            commit('SET_USER', originalUser);

            i18n.setLocaleAndNotify(originalLang);

            throw error;
        }
    },

    setDefaultBrowserLanguage({ commit }, lang) {
        // Only accept 'en' or 'es'
        if (!['en', 'es'].includes(lang)) {
            return;
        }

        localStorage.setItem('defaultBrowserLanguage', lang);
        commit('SET_DEFAULT_BROWSER_LANGUAGE', lang);
        i18n.setLocaleAndNotify(lang);
    },

    initializeLanguage({ dispatch, state }) {
        // First try to get the language from localStorage
        const savedLang = localStorage.getItem('defaultBrowserLanguage');

        if (savedLang && ['en', 'es'].includes(savedLang)) {
            // If we have a valid saved language, use it
            dispatch('setDefaultBrowserLanguage', savedLang);
        } else {
            // If no saved language, get browser language
            const browserLang = (navigator.language || navigator.userLanguage).toLowerCase();
            // Set to Spanish if browser language starts with 'es', otherwise English
            const defaultLang = browserLang.startsWith('es') ? 'es' : 'en';

            // Set the language and save it
            dispatch('setDefaultBrowserLanguage', defaultLang);
        }

        // Ensure i18n is synced with the state
        if (i18n.locale !== state.defaultBrowserLanguage) {
            i18n.locale = state.defaultBrowserLanguage;
        }
    },
};

// mutations
const mutations = {
    authUser(state, userData) {
        state.idToken = userData.token;
        state.userId = userData.userId;
        state.refreshToken = userData.refreshToken;
    },

    SET_TOKEN(state, token) {
        state.idToken = token;
    },

    SET_USER(state, user) {
        state.user = { ...user };
    },

    SET_ACCOUNT(state, account) {
        state.account = { ...account };
    },

    SET_PROFILE_PICTURE(state, userProfilePicture) {
        state.userProfilePicture = userProfilePicture;
    },

    clearAuth(state) {
        state.idToken = null;
        state.userId = null;
        state.refreshToken = null;
        state.userProfilePicture = null;
        state.copilotEnabled = false;
        state.devMode = false;
        state.user = {
            uuid: null,
            account_id: null,
            username: null,
            permission: null,
            email: null,
        };
        state.account = {
            uuid: null,
            email: null,
            contact_name: null,
            status: null,
            current_plan: null,
            customer_support_email: null,
            api_url: null,
            onboarding_current_state: null,
            ecommerce_platform_type: null,
            shop_domain: null,
            shop_public_domain: null,
            shop_name: null,
            shop_phone: null,
            date_created: 0,
            next_step_instructions: null,
        };
        state.defaultBrowserLanguage = (navigator.language || navigator.userLanguage).toLowerCase().startsWith('es')
            ? 'es'
            : 'en';
    },

    ENABLE_COPILOT(state) {
        state.copilotEnabled = true;
    },

    DISABLE_COPILOT(state) {
        state.copilotEnabled = false;
    },

    SET_DEV_MODE(state, value) {
        state.devMode = value;
        localStorage.setItem('devMode', value); // Persist to localStorage
    },

    SET_USER_ACCOUNT_ID(state, accountId) {
        state.user.account_id = accountId;
    },

    UPDATE_NOTIFICATIONS_CONFIG(state, { pushNotificationIds, activeNotifications }) {
        state.user.notifications_config = {
            ...state.user.notifications_config,
            pushNotificationIds: pushNotificationIds || state.user.notifications_config.pushNotificationIds,
            activeNotifications: {
                ...state.user.notifications_config.activeNotifications,
                ...activeNotifications,
            },
        };

        // Update localStorage
        const updatedUser = { ...state.user };
        localStorage.setItem('user', JSON.stringify(updatedUser));
    },

    SET_DEFAULT_BROWSER_LANGUAGE(state, lang) {
        state.defaultBrowserLanguage = lang;
    },
};

/**
 * @typedef {Object} UserModule
 * @property {boolean} namespaced - Whether the module is namespaced
 * @property {UserState} state - The state of the module
 * @property {Object} getters - The getters of the module
 * @property {Object} actions - The actions of the module
 * @property {Object} mutations - The mutations of the module
 */

/**
 * @type {UserModule} The User module
 */
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
