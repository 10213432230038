<template>
    <md-chip
        v-if="tag"
        class="tag-chip"
        :md-deletable="deletable"
        :md-clickable="deletable"
        @md-delete="$emit('remove')"
        :style="chipStyle"
    >
        <div class="tag-chip__content">
            <span v-if="loading">Loading...</span>
            <span v-else>{{ tag.name }}</span>
        </div>
    </md-chip>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'TagChip',

    props: {
        uuid: {
            type: String,
            required: true,
        },
        deletable: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            error: null,
        };
    },

    computed: {
        ...mapGetters('tags', ['getTagById', 'isLoading']),

        loading() {
            return this.isLoading;
        },

        tag() {
            return this.getTagById(this.uuid);
        },

        chipStyle() {
            if (!this.tag) return {};

            const backgroundColor = this.tag.color;
            const textColor = this.getContrastColor(backgroundColor);

            return {
                backgroundColor: `${backgroundColor} !important`,
                color: `${textColor} !important`,
                borderColor: `${backgroundColor} !important`,
            };
        },
    },

    methods: {
        // Helper function to determine text color based on background
        getContrastColor(hexcolor) {
            if (!hexcolor) return '#000000';

            // Remove the # if present
            const hex = hexcolor.replace('#', '');

            // Convert to RGB
            const r = parseInt(hex.substr(0, 2), 16);
            const g = parseInt(hex.substr(2, 2), 16);
            const b = parseInt(hex.substr(4, 2), 16);

            // Calculate luminance
            const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

            // Return black or white based on luminance
            return luminance > 0.5 ? '#000000' : '#FFFFFF';
        },
    },
};
</script>

<style lang="scss" scoped>
.tag-chip {
    transition: all 0.3s ease;

    &__content {
        display: flex;
        align-items: center;
        gap: 4px;
    }

    .md-icon {
        color: currentColor !important;
    }
}
</style>
