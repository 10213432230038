<template>
    <div class="custom-main-panel">
        <div>
            <md-progress-bar v-if="isLoading" md-mode="indeterminate"></md-progress-bar>
        </div>
        <div class="md-layout custom-content-panel">
            <div v-if="isLoading" class="overlay md-layout-item md-size-100"></div>
            <div class="md-layout-item md-size-100">
                <div class="custom-action-save">
                    <InviteMemberWizard v-if="isAdminAccount" @UserCreated="fetchTableData" />
                </div>
            </div>
            <!--      <div class="md-layout-item md-size-100">-->
            <!--        <p class="md-subheading">Account members</p>-->
            <!--      </div>-->
            <div v-if="currentFilter === 'members'" class="md-layout-item md-size-100">
                <md-card v-if="queriedData.length > 0">
                    <md-card-content>
                        <md-table
                            :value="queriedData"
                            class="paginated-table"
                            :md-sort.sync="currentSort"
                            :md-sort-order.sync="currentSortOrder"
                            :md-sort-fn="customSort"
                        >
                            <md-table-toolbar>
                                <div class="md-table-toolbar__main">
                                    <md-field>
                                        <label for="pages">Per page</label>
                                        <md-select v-model="pagination.perPage" name="pages">
                                            <md-option
                                                v-for="item in pagination.perPageOptions"
                                                :key="item"
                                                :label="item"
                                                :value="item"
                                            >
                                                {{ item }}
                                            </md-option>
                                        </md-select>
                                    </md-field>

                                    <md-field>
                                        <md-input
                                            type="search"
                                            class="mb-3"
                                            clearable
                                            placeholder="Search members"
                                            v-model="searchQuery"
                                        >
                                        </md-input>
                                    </md-field>
                                </div>

                                <!-- <div class="md-table-toolbar__filters">
                                    <md-chip>
                                        <md-icon>person</md-icon>
                                        <span>Members</span>
                                    </md-chip>
                                </div> -->
                            </md-table-toolbar>
                            <md-table-row slot="md-table-row" slot-scope="{ item }">
                                <md-table-cell>
                                    <md-avatar class="md-avatar-icon md-small">
                                        <img
                                            v-if="item.profile_picture"
                                            :src="item.profile_picture"
                                            alt="Profile Picture"
                                            referrerpolicy="no-referrer"
                                        />
                                        <span v-else>{{ item.username.charAt(0).toUpperCase() }}</span>
                                    </md-avatar>
                                </md-table-cell>
                                <md-table-cell md-label="Name" md-sort-by="username">
                                    <p
                                        class="md-body-1 text-ellipsis"
                                        :title="
                                            item.username +
                                            (item.email === $store.state.user.user.email ? ' (you)' : '')
                                        "
                                    >
                                        {{
                                            item.username +
                                            (item.email === $store.state.user.user.email ? ' (you)' : '')
                                        }}
                                    </p>
                                </md-table-cell>
                                <md-table-cell md-label="Availability">
                                    <div v-if="item.availability" class="chip-container" @wheel.prevent="handleWheel">
                                        <md-chip
                                            v-for="availability in item.availability"
                                            :key="availability"
                                            :class="getColorClassFromText(availability)"
                                        >
                                            {{ availability }}
                                        </md-chip>
                                    </div>
                                    <p v-else class="md-body-1 text-ellipsis">
                                        {{ '-' }}
                                    </p>
                                </md-table-cell>
                                <md-table-cell md-label="Role" md-sort-by="role">
                                    <!--  -->
                                    <div v-if="item.role" class="chip-container" @wheel.prevent="handleWheel">
                                        <md-chip :key="item.role" :class="getColorClassFromText(item.role)">
                                            {{ item.role }}
                                        </md-chip>
                                    </div>
                                    <p v-else class="md-body-1 text-ellipsis" :title="item.role || '-'">
                                        {{ item.role || '-' }}
                                    </p>
                                </md-table-cell>
                                <md-table-cell md-label="Operational Region">
                                    <div
                                        v-if="item.operational_region"
                                        class="chip-container"
                                        @wheel.prevent="handleWheel"
                                    >
                                        <md-chip
                                            v-for="operational_region in item.operational_region"
                                            :key="operational_region"
                                            :class="getColorClassFromText(operational_region)"
                                        >
                                            {{ operational_region }}
                                        </md-chip>
                                    </div>
                                    <p v-else class="md-body-1 text-ellipsis">
                                        {{ '-' }}
                                    </p>
                                </md-table-cell>
                                <md-table-cell md-label="Capabilities" md-sort-by="capabilities">
                                    <p class="md-body-1 text-ellipsis" :title="item.capabilities || '-'">
                                        {{ item.capabilities || '-' }}
                                    </p>
                                </md-table-cell>
                                <md-table-cell md-label="Email">
                                    <p
                                        class="md-body-1 text-ellipsis"
                                        :title="
                                            item.email + (item.email === $store.state.user.user.email ? ' (you)' : '')
                                        "
                                    >
                                        {{ item.email }}
                                        <span v-if="item.email === $store.state.user.user.email">(you)</span>
                                    </p>
                                </md-table-cell>
                                <md-table-cell md-label="Contact Phone" md-sort-by="contact_phone">
                                    <p class="md-body-1 text-ellipsis" :title="item.contact_phone || '-'">
                                        {{ item.contact_phone || '-' }}
                                    </p>
                                </md-table-cell>

                                <md-table-cell
                                    class="md-table-cell-status"
                                    md-label="Status"
                                    :title="formatStatus(item.status)"
                                >
                                    <!-- {{ formatStatus(item.status) }} -->
                                    <i class="material-symbols-outlined" :style="getStatusStyle(item.status)">
                                        {{ getStatusIcon(item.status) }}
                                    </i>
                                </md-table-cell>
                                <!-- <md-table-cell md-label="Permissions">
                                    <md-field class="custom-role-field">
                                        <md-select
                                            :disabled="item.email === $store.state.user.user.email || !isAdminAccount"
                                            v-model="item.permission"
                                            name="role"
                                            id="role"
                                            @md-selected="handleEdit(item)"
                                        >
                                            <md-option value="ADMIN">ADMIN</md-option>
                                            <md-option value="EMPLOYEE">EMPLOYEE</md-option>
                                        </md-select>
                                    </md-field>
                                </md-table-cell> -->

                                <md-table-cell>
                                    <div class="md-table-cell-actions">
                                        <md-button
                                            class="md-just-icon md-simple md-primary"
                                            @click="sendPasswordResetEmail(item.email)"
                                            :disabled="item.status === 'PENDING'"
                                            :title="
                                                item.status === 'PENDING'
                                                    ? 'User is pending invitation'
                                                    : 'Reset password'
                                            "
                                        >
                                            <i class="material-symbols-outlined"> vpn_key </i>
                                        </md-button>
                                        <md-button
                                            class="md-just-icon md-simple md-primary"
                                            @click="openUserProfileModal(item)"
                                        >
                                            <i class="material-symbols-outlined"> edit </i>
                                        </md-button>
                                        <md-button
                                            :disabled="item.email === $store.state.user.user.email || !isAdminAccount"
                                            class="md-just-icon md-danger md-simple"
                                            @click.native="handleDelete(item)"
                                        >
                                            <md-icon>person_remove</md-icon>
                                        </md-button>
                                    </div>
                                </md-table-cell>
                            </md-table-row>
                        </md-table>
                    </md-card-content>
                    <md-card-actions>
                        <pagination
                            class="pagination-no-border pagination-success"
                            v-model="pagination.currentPage"
                            :per-page="pagination.perPage"
                            :total="totalEntries"
                            :from="from + 1"
                            :to="to"
                        >
                        </pagination>
                    </md-card-actions>
                </md-card>
            </div>
        </div>
    </div>
</template>
<script>
import InviteMemberWizard from '@/components/UsersManagement/InviteMemberWizard.vue';
import { Pagination } from '@/components';
import userService from '@/services/account/userService.js';
import Fuse from 'fuse.js';
import Swal from 'sweetalert2';
import { mapGetters } from 'vuex';
import UserProfileModal from '@/components/Settings/UsersManagement/UserProfileModal.vue';
import Firebase from '@/Firebase';

export default {
    name: 'UserManagement',
    components: {
        InviteMemberWizard,
        Pagination,
    },
    data() {
        return {
            searchQuery: '',
            propsToSearch: ['email', 'username'],
            tableData: [], // Empty as data will be fetched from the API
            searchedData: [],
            fuseSearch: null,
            totalEntries: 0,
            totalPages: 0,
            numberOfElements: 1, // Actual number of elements per page that came from request.
            pagination: {
                perPage: 5,
                currentPage: 1,
                perPageOptions: [5, 10, 25, 50],
                total: 0,
            },

            currentFilter: 'members',

            isLoading: false, //Controls loading spinner.

            currentSort: 'username',
            currentSortOrder: 'desc',
        };
    },
    computed: {
        ...mapGetters({
            canAccessUser: 'user/canAccess',
            isWalkonLivosAccount: 'user/isWalkonLivosAccount',
        }),
        queriedData() {
            let result = this.tableData;
            if (this.searchQuery != '' && this.searchedData.length > 0) {
                result = this.searchedData;
            } else {
                result = [...this.tableData].sort((a, b) => {
                    if (a.email === this.$store.state.user.user.email) return -1;
                    if (b.email === this.$store.state.user.user.email) return 1;
                    return 0;
                });
            }

            return result.slice(0, this.numberOfElements);
        },
        isAdminAccount() {
            return this.canAccessUser(['ADMIN']) || this.isWalkonLivosAccount;
        },
        to() {
            return Math.min(this.pagination.currentPage * this.pagination.perPage, this.pagination.total);
        },
        from() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
    },
    methods: {
        sendPasswordResetEmail(email) {
            Swal.fire({
                title: 'Send password reset email',
                text: `Are you sure you want to send a password reset email to ${email}?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#431ED9',
                confirmButtonText: 'Yes, send email',
                buttonsStyling: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    Firebase.sendPasswordResetEmail(email);
                    this.showSuccessToast('Password reset email sent');
                }
            });
        },
        customSort(value) {
            return value.sort((a, b) => {
                const sortBy = this.currentSort;

                if (this.currentSortOrder === 'desc') {
                    return a[sortBy].localeCompare(b[sortBy]);
                }

                return b[sortBy].localeCompare(a[sortBy]);
            });
        },
        getColorClassFromText(text) {
            // Generate a hash from the text
            let hash = 0;
            for (let i = 0; i < text.length; i++) {
                hash = text.charCodeAt(i) + ((hash << 5) - hash);
            }

            // Convert hash to a class name
            const hue = hash % 360;
            return `chip-color-${hue}`;
        },
        handleWheel(e) {
            const container = e.currentTarget;
            container.scrollLeft += e.deltaY;
        },
        openUserProfileModal(item) {
            this.$store.dispatch('modalStore/openModal', {
                component: UserProfileModal,
                props: { user: { ...item }, isPermissionDisabled: item.email === this.$store.state.user.user.email },
                onEvent: (eventName) => {
                    if (eventName === 'update:user') {
                        this.fetchTableData();
                    }
                },
            });
        },
        formatDate(timestamp) {
            const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
            const date = new Date(timestamp * 1000); // convert to milliseconds
            return date.toLocaleDateString('en-US', options); // adjust 'en-US' as per your need
        },
        formatStatus(status) {
            let statusMessage;
            switch (status) {
                case 'PENDING':
                    statusMessage = 'Pending Invitation';
                    break;

                case 'ENABLED':
                    statusMessage = 'Active';

                default:
                    break;
            }
            return statusMessage;
        },
        getStatusIcon(status) {
            return status === 'ENABLED' ? 'check_circle' : 'schedule';
        },
        getStatusStyle(status) {
            return status === 'ENABLED' ? { color: '#4caf50' } : { color: '#ff9800' };
        },
        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },
        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },
        async fetchTableData() {
            try {
                this.isLoading = true;
                const response = await userService.fetchUsers(this.pagination.currentPage - 1, this.pagination.perPage);
                if (response.status === 200 && response.data) {
                    this.tableData = response.data.content;
                    this.totalEntries = response.data.totalElements;
                    this.totalPages = response.data.totalPages;
                    this.numberOfElements = response.data.numberOfElements;
                    this.pagination.total = response.data.totalElements;
                    // Re-initialize fuse with the new data
                    this.fuseSearch = new Fuse(this.tableData, {
                        keys: this.propsToSearch,
                        threshold: 0.3,
                    });
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                this.isLoading = false;
            }
        },
        async handleEdit(item) {
            if (!this.isAdminAccount) {
                this.showErrorToast("You don't have permission to perform this action");
                return;
            }
            this.isLoading = true;
            try {
                const reqData = {
                    user_id: item.uuid,
                    account_id: item.account_id,
                    username: item.username,
                    permission: item.permission,
                };

                const editUserResponse = await userService.editUser(item.uuid, reqData);
                if (editUserResponse.status === 200) {
                    this.showSuccessToast('user edited');
                }
            } catch (error) {
                console.error('Error editing member: ', error);
                this.showErrorToast('Error editing member');
            } finally {
                this.isLoading = false;
                this.fetchTableData();
            }
        },
        handleDelete(item) {
            if (!this.isAdminAccount) {
                this.showErrorToast("You don't have permission to perform this action");
                return;
            }
            Swal.fire({
                title: 'Are you sure?',
                text: `You won't be able to revert this!`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#4caf50',
                confirmButtonText: 'Yes, delete it!',
                buttonsStyling: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteUser(item);
                }
            });
        },
        async deleteUser(item) {
            this.isLoading = true;
            try {
                const deleteUserResponse = await userService.deleteUser(item.uuid);
                if (deleteUserResponse.status === 200) {
                    this.showSuccessToast('user deleted');
                    this.fetchTableData();
                }
            } catch (error) {
                console.error('Error removing user:', error);
            } finally {
                this.isLoading = false;
            }
        },
    },
    mounted() {
        // Initial data fetch
        this.fetchTableData();
    },
    watch: {
        searchQuery(value) {
            let result = this.tableData;
            if (value !== '') {
                const searchResults = this.fuseSearch.search(this.searchQuery);
                result = searchResults.map((res) => res.item);
            }
            this.searchedData = result;
        },

        'pagination.currentPage': function () {
            // Fetch new page data when the current page changes
            this.fetchTableData();
        },
        'pagination.perPage': function () {
            // Fetch new data when the per page changes
            this.fetchTableData();
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/md/variables';

// Generate color classes for all possible hues (0-359)
@for $i from 0 through 359 {
    .chip-color-#{$i} {
        background-color: hsl($i, 70%, 85%) !important;
    }
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(238, 238, 238, 0.5);
    z-index: 10;
    cursor: not-allowed;
}

.custom-main-panel {
    position: relative;
    overflow-x: auto;
    width: 100%;
}

.custom-content-panel {
    padding-left: 30px;
    padding-right: 30px;

    @media (max-width: 1278px) {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media (max-width: 959px) {
        padding-left: 15px;
        padding-right: 15px;
    }

    .md-card {
        margin-top: 0px;
    }
}

.custom-action-save {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 15px;
}

.custom-role-field {
    min-width: 100px;
}

.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.chip-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;

    .md-chip {
        margin-left: 0;
    }
    // overflow-x: auto;
    // overflow-y: hidden;
    // scrollbar-width: none; /* Firefox */
    // -ms-overflow-style: none; /* IE and Edge */
    // &::-webkit-scrollbar {
    //     display: none; /* Chrome, Safari and Opera */
    // }
}

.md-table-cell-actions {
    display: flex;
    gap: 0.5rem;
}

.md-table-toolbar {
    flex-direction: column !important;
    gap: 1rem;
    &__main {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    &__filters {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
}

.md-table-cell-status .md-table-cell-container {
    display: flex;
    align-items: center;
}
</style>
